import logo from './logo.svg';
import './App.css';
import { useSelector } from 'react-redux';
import AppRouter from './config/router/AppRouter';

function App() {
  const {status} = useSelector(state=>state.auth)
  // console.log("status",status);
  return (
 <AppRouter/>
  );
}

export default App;
