import Home from "../pages/HomeScreen/Home";
import Login from "../pages/LoginScreen/Login";
import Cut from "../pages/ProductInformation/Master/StyleScreen/FitCut";
import Style from "../pages/ProductInformation/Master/StyleScreen/Style";
import Wash from "../pages/ProductInformation/Master/StyleScreen/Wash";
import FabricClass from "../pages/ProductInformation/Master/StyleScreen/FabricClass";
import FabricClassTwo from "../pages/ProductInformation/Master/StyleScreen/FabricClass2";
import FabricComposition from "../pages/ProductInformation/Master/StyleScreen/FabricComposition";
import FabricUsable from "../pages/ProductInformation/Master/StyleScreen/FabricUsable";
import FabricType from "../pages/ProductInformation/Master/StyleScreen/FabricType";
import Fabric from "../pages/ProductInformation/Master/StyleScreen/Fabric";
import SaleOrder from "../pages/OrderManagement/Transaction/SaleOrder/SaleOrder";
import FitCategory from "../pages/ProductInformation/Master/StyleScreen/FitCategory";
import Rise from "../pages/ProductInformation/Master/StyleScreen/Rise";
import RiseLabel from "../pages/ProductInformation/Master/StyleScreen/RiseLabel";
import ChargeBackType from "../pages/ProductInformation/Master/StyleScreen/ChargeBackType";
import ProductType from "../pages/ProductInformation/Master/StyleScreen/ProductType";
import SeasonYear from "../pages/ProductInformation/Master/StyleScreen/SeasonYear";
import ProductStatus from "../pages/ProductInformation/Master/StyleScreen/ProductStatus";
import ProductClass from "../pages/ProductInformation/Master/StyleScreen/ProductClass";
import WashType from "../pages/ProductInformation/Master/StyleScreen/WashType";
import Gender from "../pages/ProductInformation/Master/StyleScreen/Gender";
import GenderCategory from "../pages/ProductInformation/Master/StyleScreen/GenderCategory";
import Season from "../pages/ProductInformation/Master/StyleScreen/Season";
import Size from "../pages/ProductInformation/Master/StyleScreen/size";
import ItemType from "../pages/ProductInformation/Master/StyleScreen/ItemType";
import InseamLabel from "../pages/ProductInformation/Master/StyleScreen/InseamLabel";
import Inseam from "../pages/ProductInformation/Master/StyleScreen/Inseam";
import ProductInformation from "../pages/ProductInformation/Master/StyleScreen/ProductInformation";
import City from "../pages/OrderManagement/Master/City";
import PaymentMethodType from "../pages/OrderManagement/Master/PaymentMethodType";
import AdminJob from "../pages/OrderManagement/Master/AdminJob";
import TermForPrint from "../pages/OrderManagement/Master/TermForPrint";
import AdminDiscount from "../pages/OrderManagement/Master/AdminDiscount";
import AdminCurrency from "../pages/OrderManagement/Master/AdminCurrency";
import State from "../pages/OrderManagement/Master/State";
import Factor from "../pages/OrderManagement/Master/Factor";
import CustomerType from "../pages/OrderManagement/Master/CustomerType";
import Country from "../pages/OrderManagement/Master/Country";
import PaymentType from "../pages/OrderManagement/Master/PaymentType";
import PaymentTerm from "../pages/OrderManagement/Master/PaymentTerm";
import BergenPaymentTerm from "../pages/OrderManagement/Master/BergenPaymentTerm";
import FreightTerm from "../pages/OrderManagement/Master/FreightTerm";
import OrderStatus from "../pages/OrderManagement/Master/OrderStatus";
import Region from "../pages/OrderManagement/Master/Region";
import CommitCriteria from "../pages/OrderManagement/Master/CommitCriteria";
import ShipStatus from "../pages/OrderManagement/Master/ShipStatus";
import VendorCategory from "../pages/OrderManagement/Master/VendorCategory";
import CourierService from "../pages/OrderManagement/Master/CourierService";
import ReturnReason from "../pages/OrderManagement/Master/ReturnReason";
import ShipVia from "../pages/OrderManagement/Master/ShipVia";
import PtStatus from "../pages/OrderManagement/Master/PtStatus";
import ReturnType from "../pages/OrderManagement/Master/ReturnType";
import Vendor from "../pages/OrderManagement/Master/Vendor";
import SalesTarget from "../pages/OrderManagement/Master/SalesTarget";
import CAT from "../pages/OrderManagement/Master/CAT";
import BergenBilling from "../pages/OrderManagement/Master/BergenBillingOption";
import CustomerInformation from "../pages/OrderManagement/Master/CustomerInformation";
import CustomerCategory from "../pages/OrderManagement/Master/CustomerCategory";
import SalesPerson from "../pages/OrderManagement/Master/SalesPerson";
import ShippingMethod from '../pages/OrderManagement/Master/ShippingMethod'
import SaleOrderAdjustmentList from '../pages/OrderManagement/Transaction/SaleOrder/SaleOrderAdjustmentList'
import ReturnAuthorization from "../pages/OrderManagement/Transaction/SaleOrder/ReturnAuthorization";
import ItemRecieptList from "../pages/OrderManagement/Transaction/SaleOrder/ItemRecieptList";
import RelocateItems from "../pages/OrderManagement/Transaction/SaleOrder/RelocateItems";
import UserCompanyAccess from "../pages/Administration/Master/UserCompanyAccess";
import AdminUsers from "../pages/Administration/Master/AdminUsers";
import AdminMenuType from "../pages/Administration/Master/AdminMenuType";
import AdminLocation from "../pages/Administration/Master/AdminLocation";
import UserRights from "../pages/Administration/Master/UserRights";


export const mainRoutes = [
  {
    linkTo: "/login",
    element: <Login />,
    authRequired: false,
    check_cond: false,
  },
  {
    linkTo: "/",
    element: <Home />,
    authRequired: true,
    check_cond: false,
  },






  //  product  screens
  {
    linkTo: "/style",
    name:"Style",
    element: <Style />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/fitCut",
    name:"Fit/Cut",
    element: <Cut />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/fitCategory",
    name:"Fit Category",
    element: <FitCategory />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/wash",
    name:"Wash",
    element: <Wash />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/washType",
    name:"Wash Type",
    element: <WashType />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/gender",
    name:"Gender",
    element: <Gender />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/genderCategory",
    name:"Gender Category",
    element: <GenderCategory />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/season",
    name:"Season",
    element: <Season />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/inseam",
    name:"Inseam",
    element: <Inseam />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/size",
    name:"Size",
    element: <Size />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/itemType",
    name:"Item Type",
    element: <ItemType />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/inseamlabel",
    name:"Inseam Label",
    element: <InseamLabel />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/fabricClass",
    name:"Fabric Class",
    element: <FabricClass />,
    authRequired: true,
    check_cond: false,
  },

  {
    linkTo: "/fabricClass2",
    name:"Fabric Class 2",
    element: <FabricClassTwo />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/FabricComposition",
    name:"Fabric Composition",
    element: <FabricComposition />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/FabricUsable",
    name:"Fabric Usable",
    element: <FabricUsable />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/FabricType",
    name:"Fabric Type",
    element: <FabricType />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/Fabric",
    name:"Fabric",
    element: <Fabric />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/Rise",
    name:"Rise",
    element: <Rise />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/RiseLabel",
    name:"Rise Label",
    element: <RiseLabel />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/ChargeBackType",
    name:"Chargeback Type",
    element: <ChargeBackType />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/ProductType",
    name:"Product Type",
    element: <ProductType />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/SeasonYear",
    name:"Season Year",
    element: <SeasonYear />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/ProductStatus",
    name:"Product Status",
    element: <ProductStatus />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/ProductClass",
    name:"Product Class",
    element: <ProductClass />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/ProductInfo/*",
    name:"Product Info",
    element: <ProductInformation />,
    authRequired: true,
    check_cond: false,
  },
  
  //  product  screens

    
    
    // Order Management Screens
    
    
    {
      linkTo: "/city",
      name:"City",
      element: <City />,
      authRequired: true,
      check_cond: false,
    },
    {
      linkTo: "/customerCategory",
      name:"Customer Category",
      element: <CustomerCategory />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/paymentMethodType",
      name:"Payment Method Type",
      element: <PaymentMethodType />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/adminJob",
      name:"Admin Job",
      element: <AdminJob />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/termForPrint",
      name:"Term For Print",
      element: <TermForPrint />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/adminDiscount",
      name:"Admin Discount",
      element: <AdminDiscount />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/adminCurrency",
      name:"Admin Currency",
      element: <AdminCurrency />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/state",
      name:"State",
      element: <State />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/factor",
      name:"Factor",
      element: <Factor />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/customerType",
      name:"Customer Type",
      element: <CustomerType />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/country",
      name:"Country",
      element: <Country />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/paymentType",
      name:"Payment Type",
      element: <PaymentType />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/paymentTerm",
      name:"Payment Term",
      element: <PaymentTerm />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/bergenPaymentTerm",
      name:"Bergen Payment Term",
      element: <BergenPaymentTerm />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/freightTerm",
      name:"Freight Term",
      element: <FreightTerm />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/orderStatus",
      name:"Order Status",
      element: <OrderStatus />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/region",
      name:"Region",
      element: <Region />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/commitCriteria",
      name:"Commit Criteria",
      element: <CommitCriteria />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/shipStatus",
      name:"Ship Status",
      element: <ShipStatus />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/vendorCategory",
      name:"Vendor Category",
      element: <VendorCategory />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/courierService",
      name:"Courier Service",
      element: <CourierService />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/returnReason",
      name:"Return Reason",
      element: <ReturnReason />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/shipVia",
      name:"Ship Via",
      element: <ShipVia />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/returnType",
      name:"Return Type",
      element: <ReturnType />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/vendor/*",
      name:"Vendor",
      element: <Vendor />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/SalesTarget",
      name:"Sales Target",
      element: <SalesTarget />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/cat",
      name:"CAT",
      element: <CAT />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/bergenBillingOption",
      name:"Bergen Billing Option",
      element: <BergenBilling />,
      authRequired: true,
      check_cond: false,
    },
    
    
    {
      linkTo: "/customerInformation/*",
      name:"Customer Information",
      element: <CustomerInformation />,
      authRequired: true,
      check_cond: false,
    },
    {
      linkTo: "/sales-person",
      name:"Sales Person",
      element: <SalesPerson />,
      authRequired: true,
      check_cond: false,
    },
    
    
    // {
    //   linkTo: "/CustomerCategory",
    //   name:"Customer Category",
    //   element: <CustomerCategory />,
    //   authRequired: true,
    //   check_cond: false,
    // },
     
    
    
    // Order Management Screens
    
  {
    linkTo: "/sale-order/*",
    name:"Sale Order",
    element: <SaleOrder />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/ptStatus",
    name:"Pick Ticket Status",
    element: <PtStatus />,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/shippingMethod",
    name:"Shipping Method",
    element: <ShippingMethod/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/sale-order-adjustment-new-list",
    name:"Sales Order Adjustment List",
    element: <SaleOrderAdjustmentList/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/return-authorization-list",
    name:"Sale Return List",
    element: <ReturnAuthorization/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/item-receipt/list",
    name:"Item Receipt List",
    element: <ItemRecieptList/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/relocate-items",
    name:"Relocate Items",
    element: <RelocateItems/>,
    authRequired: true,
    check_cond: false,
  },
  
  
  
  // Administration
  
  
  {
    linkTo: "/Company",
    name:"company",
    element: <UserCompanyAccess/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/adminuser",
    name:"Admin User",
    element: <AdminUsers/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/AdminMenuType",
    name:"Admin Menu Type",
    element: <AdminMenuType/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/location",
    name:"Location",
    element: <AdminLocation/>,
    authRequired: true,
    check_cond: false,
  },
  {
    linkTo: "/userrights",
    name:"User Rights",
    element: <UserRights/>,
    authRequired: true,
    check_cond: false,
  },
  
  
];
