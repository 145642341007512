import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import { IconButton, Stack } from "@mui/material";
import { SiAddthis } from "react-icons/si";
import { primaryColor } from "../../../utils/themeColors";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style, keyname } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };
  return (
    <Typography component="li" noWrap style={inlineStyle}>
      {dataSet}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
let elem;
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, keyname, ...other } = props;
  const itemData = [];
  elem = children[0];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;
  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
          style={{ zIndex: 1000002 }}
        >
          {(props) => renderRow({ ...props, keyname })}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};
const VirtualizedSelect = ({
  required,
  value,
  disabled,
  sx,
  onChange,
  name,
  options = [],
  id_key_name,
  defaultValue,
  onAddClick,
  addOption,
  addOptionLoading
}) => {
  return (
    <Autocomplete
      id="virtualize-demo"
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      sx={{
        zIndex: 1000002, // Adjust the z-index as needed
        padding: 0,
        "& .MuiOutlinedInput-root": {
          padding: 0,
          "& .MuiAutocomplete-input": {
            padding: "3px",
            fontSize: 12,
            textIndent: 5,
          },
        },
        "& .css-1bycr2s-MuiModal-root": {
          backgroundColor: "blue",
        },
        ...sx,
      }}
      onChange={(_, data) => onChange(data)}
      ListboxComponent={(props) => (
        <ListboxComponent {...props} keyname={name} />
      )}
      getOptionSelected={(option, value) =>
        option[id_key_name] === value[id_key_name]
      }
      getOptionLabel={(option) => option[name] || ""}
      options={options || []}
      renderInput={(params) => (
        <Stack flexDirection={"row"}>
          <TextField required={required} {...params}  />
        {addOption&&  <IconButton
        disabled={!addOptionLoading}
            onClick={() => onAddClick()}
            sx={{ padding: 0, color: primaryColor }}
          >
            <SiAddthis size={22} />
          </IconButton>}
        </Stack>
      )}
    />
  );
};

export default VirtualizedSelect;
