import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InputComp from '../../../../components/common/InputComp/Input';
import Btn from '../../../../components/common/ButtonComp/Btn';
import TableComp from '../../../../components/common/TableComp/TableComp';



const AddItems = ({ open, setOpen }) => {


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    const handleClose = () => {
        setOpen(false);
    };



    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                fullWidth
                
                maxWidth={"xl"}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Add Item (Products Selection)
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", gap: 10 }}>
                        <InputComp
                            value={""}
                            // onChange={}
                            style={{ padding: 3, flex: 1 }} placeholder="SKU"
                        />
                        <InputComp
                            value={""}

                            // onChange={}
                            style={{ padding: 3, flex: 1 }} placeholder="Amount"
                        />
                        <Btn style={{ fontSize: 14, }} >View</Btn>
                    </div>

                    <div style={{ marginTop: 10 }}>
                        <TableComp
                            columns={[
                                {
                                    field: "companyId",
                                    headerName: "Selected",
                                    flex: true,
                                    width: 100
                                },

                                {
                                    field: "styleId",
                                    headerName: "Item Code",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Style Name",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Wash Name",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Gender Category",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Inseam",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Size",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Available",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Cost Price",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Quantity",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Amount",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Commit Status",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Commited",
                                    flex: true,
                                    width: 100,
                                },
                                {
                                    field: "styleId",
                                    headerName: "Back Order",
                                    flex: true,
                                    width: 100,
                                },



                            ]}
                            //   onRowClick={(e) => {
                            //     setSelectedStyle(e?.row);
                            //   }}
                            rows={[]}
                  
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddItems;