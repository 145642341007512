import { Checkbox, CircularProgress, Grid, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import { SiAddthis } from "react-icons/si";
import {
  addProductStyleAsync,
  addProductFabricAsync,
  deleteProductFabricAsync,
  editProductStyleAsync,
  editProductFabricAsync,
  getProductStyleAsync,
  getProductFabricAsync,
  getProductFabricClassAsync,
  getProductFabricTypeAsync,
  getProductFabricUsableAsync,
  getProductFabricClassTwoAsync,
  getProductFabricCompositionAsync,
  addProductFabricClassAsync,
  addProductFabricClassTwoAsync,
  addProductFabricTypeAsync,
  addProductFabricUsableAsync,
  addProductFabricCompositionAsync,
} from "../../../../store/services/productInfoServices";
import InputWithBtnLayoutGridComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutGridComp";
import { fabricStatusEdit } from "../../../../store/slices/productInfoSlice";
import CustomModal from "../../../../components/common/Modal/CustomModal";

const Fabric = () => {
  const dispatch = useDispatch();
  const {
    getProductFabricStatus,
    getProductFabricError,
    getProductFabricData,
    addProductFabricStatus,
    addProductFabricData,
    addProductFabricError,

    editProductFabricStatus,
    editProductFabricError,
    editProductFabricData,

    deleteProductFabricData,
    deleteProductFabricStatus,
    deleteProductFabricError,

    getProductFabricClassStatus,
    getProductFabricClassData,
    getProductFabricTypeStatus,
    getProductFabricTypeData,
    getProductFabricUsableStatus,
    getProductFabricUsableData,
    getProductFabricClassTwoStatus,
    getProductFabricClassTwoData,
    getProductFabricCompositionStatus,
    getProductFabricCompositionData,

    addProductFabricClassStatus,
    addProductFabricTypeStatus,
    addProductFabricUsableStatus,
    addProductFabricClassTwoStatus,
    addProductFabricCompositionStatus,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductFabricAsync());
  }, [dispatch]);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductFabricData?.length
    ? getProductFabricData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedFabric, setSelectedFabric] = useState();
  const [addFabricMode, setAddFabricMode] = useState(false);
  const [addFabricData, setAddFabricData] = useState();
  const [editFabricData, setEditFabricData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAddMoreFieldName, setSelectedAddMoreFieldName] = useState("");
  const [selectedAddMoreFieldData, setSelectedAddMoreFieldData] = useState({
    is_active: true,
  });
  const handleModalOpen = (e) => {
    console.log("e", e);
    setSelectedAddMoreFieldName(e);
    setModalOpen(true);
  };
  const handleModalClose = () => setModalOpen(false);
  // const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedFabric", selectedFabric);
  // console.log("editData", editData);
  useEffect(() => {
    if (addProductFabricStatus === asyncStatus.SUCCEEDED) {
      setAddFabricMode(false);
      setSaveDisabled(true);
      setAddFabricData();
    }
  }, [, addProductFabricStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteProductFabricAsync(selectedFabric?.fabric_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductFabricStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedFabric();
    }
  }, [, deleteProductFabricStatus]);
  useEffect(() => {
    if (editProductFabricStatus === asyncStatus.SUCCEEDED) {
      setSelectedFabric();
      setEditMode(false);
    }
  }, [, editProductFabricStatus]);
  console.log(
    "getProductFabricData",
    getProductFabricData,
    editProductFabricData
  );

  useEffect(() => {
    dispatch(getProductFabricClassAsync());
    dispatch(getProductFabricTypeAsync());
    dispatch(getProductFabricUsableAsync());
    dispatch(getProductFabricClassTwoAsync());
    dispatch(getProductFabricCompositionAsync());
  }, []);
  const addOnChangeHandle = (e) => {
    console.log("id", e);
    const { value, name } = e.target;
    setAddFabricData((prev) => ({ ...prev, [name]: value }));
  };
  const addOnChangeHandleForFields = (e) => {
    // function extractPrefix(str) {
    //   const index = str.indexOf("_id");
    //   return index !== -1 ? str.substring(0, index) + "_name" : str + "_name";
    // }
    const { value, name } = e.target;
    setSelectedAddMoreFieldData((prev) => ({
      is_active: prev?.is_active,
      [name === "fabric_class_id1"
        ? "fabric_class_name"
        : name.replace("id", "name")]: value,
    }));
  };
  const editOnChangeHandle = (e) => {
    console.log("id", e);
    const { value, name } = e.target;
    setEditFabricData((prev) => ({
      is_active: prev?.is_active,
      [name]: value,
    }));
  };
  console.log("test", addProductFabricStatus, editProductFabricStatus);
  const addField = () => {
    if (selectedAddMoreFieldName === "fabric_class_id1") {
      dispatch(addProductFabricClassAsync(selectedAddMoreFieldData));
    } else if (selectedAddMoreFieldName === "fabric_class_id2") {
      dispatch(addProductFabricClassTwoAsync(selectedAddMoreFieldData));
    } else if (selectedAddMoreFieldName === "fabric_type_id") {
      dispatch(addProductFabricTypeAsync(selectedAddMoreFieldData));
    } else if (selectedAddMoreFieldName === "fabric_useable_id") {
      dispatch(addProductFabricUsableAsync(selectedAddMoreFieldData));
    } else if (selectedAddMoreFieldName === "fabric_composition_id") {
      dispatch(addProductFabricCompositionAsync(selectedAddMoreFieldData));
    }
  };

  useEffect(() => {
    if (
      addProductFabricClassStatus === asyncStatus.SUCCEEDED ||
      addProductFabricTypeStatus === asyncStatus.SUCCEEDED ||
      addProductFabricUsableStatus === asyncStatus.SUCCEEDED ||
      addProductFabricClassTwoStatus === asyncStatus.SUCCEEDED ||
      addProductFabricCompositionStatus === asyncStatus.SUCCEEDED
    ) {
      dispatch(fabricStatusEdit(asyncStatus.IDLE));
      setModalOpen(false);
    }
  }, [
    addProductFabricClassStatus,
    addProductFabricTypeStatus,
    addProductFabricUsableStatus,
    addProductFabricClassTwoStatus,
    addProductFabricCompositionStatus,
  ]);

  return (
    <Stack padding={2}>
      <CustomModal
        handleOpen={handleModalOpen}
        handleClose={handleModalClose}
        open={modalOpen}
      >
        <Stack>
          <Stack padding={2}>
            <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
              Add Field
            </Typography>
            <InputComp
              style={{
                borderRadius: 5,
                fontSize: 12,
                padding: 4,
                fontSize: 16,
              }}
              placeholder="Field Name"
              name={selectedAddMoreFieldName}
              onChange={addOnChangeHandleForFields}
            />
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={14}>Is Active</Typography>
              <Checkbox
                defaultChecked
                defaultValue={true}
                onChange={(e) =>
                  setSelectedAddMoreFieldData({
                    ...selectedAddMoreFieldData,
                    is_active: e.target.checked,
                  })
                }
              />
            </Stack>
            <Btn
              loading={
                getProductFabricClassStatus === asyncStatus.LOADING ||
                getProductFabricTypeStatus === asyncStatus.LOADING ||
                getProductFabricUsableStatus === asyncStatus.LOADING ||
                getProductFabricClassTwoStatus === asyncStatus.LOADING ||
                getProductFabricCompositionStatus === asyncStatus.LOADING
              }
              onClick={addField}
            >
              Add
            </Btn>
          </Stack>
        </Stack>
      </CustomModal>

      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this fabric?"}
        heading={"Delete request"}
      />

      <ScreenCard
        title="Fabrics"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Fabric Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddFabricMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addFabricMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFabricMode || !selectedFabric}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddFabricMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFabricMode || !selectedFabric}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductFabricStatus === asyncStatus.LOADING ||
                  editProductFabricStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addFabricData) {
                    dispatch(addProductFabricAsync(addFabricData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedFabric, ...editFabricData };
                    delete a.id;
                    dispatch(editProductFabricAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
                columns={[
                  { field: "fabric_id", headerName: "ID", flex: true },
                  {
                    field: "fabric_name",
                    headerName: "Fabric Name",
                    flex: true,
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedFabric(e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductFabricStatus === asyncStatus.IDLE ||
                  getProductFabricStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addFabricMode ? (
              <InputWithBtnLayoutGridComp
                inputArray={[
                  {
                    label: "Fabric:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        name={"fabric_name"}
                        onChange={addOnChangeHandle}
                      />
                    ),
                  },
                  {
                    label: "Fabric Class:",
                    comp:
                      getProductFabricClassStatus === asyncStatus.IDLE ||
                      getProductFabricClassStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Stack flexDirection={"row"} padding={0}>
                          <Select
                            fullWidth
                            // size="small"
                            value={addFabricData?.fabric_class_id1}
                            sx={{
                              padding: "0px 0px !important",
                              height: "auto",
                              fontSize: 14,
                              "& .MuiSelect-select": {
                                padding: "1px",
                              },
                            }}
                            onChange={addOnChangeHandle}
                            name={"fabric_class_id1"}
                          >
                            {getProductFabricClassData?.length &&
                              getProductFabricClassData?.map((e) => {
                                return (
                                  <MenuItem
                                    sx={{ fontSize: 14 }}
                                    value={e.fabric_class_id}
                                    key={e.fabric_class_id}
                                  >
                                    {e?.fabric_class_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <IconButton
                            onClick={() => handleModalOpen("fabric_class_id1")}
                            sx={{ padding: 0, color: primaryColor }}
                          >
                            <SiAddthis size={20} />
                          </IconButton>
                        </Stack>
                      ),
                  },
                  {
                    label: "Fabric Class 2:",
                    comp:
                      getProductFabricClassTwoStatus === asyncStatus.IDLE ||
                      getProductFabricClassTwoStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Stack flexDirection={"row"} padding={0}>
                          <Select
                            fullWidth
                            // size="small"
                            value={addFabricData?.fabric_class_id2}
                            sx={{
                              padding: "0px 0px !important",
                              height: "auto",
                              fontSize: 14,
                              "& .MuiSelect-select": {
                                padding: "1px",
                              },
                            }}
                            onChange={addOnChangeHandle}
                            name={"fabric_class_id2"}
                          >
                            {getProductFabricClassTwoData?.length &&
                              getProductFabricClassTwoData?.map((e) => {
                                return (
                                  <MenuItem
                                    sx={{ fontSize: 14 }}
                                    value={e.fabric_class_id2}
                                    key={e.fabric_class_id2}
                                  >
                                    {e?.fabric_class_name2}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <IconButton
                            onClick={() => handleModalOpen("fabric_class_id2")}
                            sx={{ padding: 0, color: primaryColor }}
                          >
                            <SiAddthis size={20} />
                          </IconButton>
                        </Stack>
                      ),
                  },
                  {
                    label: "Fabric Type:",
                    comp:
                      getProductFabricTypeStatus === asyncStatus.IDLE ||
                      getProductFabricTypeStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Stack flexDirection={"row"} padding={0}>
                          <Select
                            fullWidth
                            // size="small"
                            value={addFabricData?.fabric_type_id}
                            sx={{
                              padding: "0px 0px !important",
                              height: "auto",
                              fontSize: 14,
                              "& .MuiSelect-select": {
                                padding: "1px",
                              },
                            }}
                            onChange={addOnChangeHandle}
                            name={"fabric_type_id"}
                          >
                            {getProductFabricTypeData?.length &&
                              getProductFabricTypeData?.map((e) => {
                                return (
                                  <MenuItem
                                    sx={{ fontSize: 14 }}
                                    key={e.fabric_type_id}
                                    value={e.fabric_type_id}
                                  >
                                    {e?.fabric_type_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <IconButton
                            onClick={() => handleModalOpen("fabric_type_id")}
                            sx={{ padding: 0, color: primaryColor }}
                          >
                            <SiAddthis size={20} />
                          </IconButton>
                        </Stack>
                      ),
                  },
                  {
                    label: "Fabric Usable:",
                    comp:
                      getProductFabricUsableStatus === asyncStatus.IDLE ||
                      getProductFabricUsableStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Stack flexDirection={"row"} padding={0}>
                          <Select
                            fullWidth
                            // size="small"
                            value={addFabricData?.fabric_useable_id}
                            sx={{
                              padding: "0px 0px !important",
                              height: "auto",
                              fontSize: 14,
                              "& .MuiSelect-select": {
                                padding: "1px",
                              },
                            }}
                            onChange={addOnChangeHandle}
                            name={"fabric_useable_id"}
                          >
                            {getProductFabricUsableData?.length &&
                              getProductFabricUsableData?.map((e) => {
                                return (
                                  <MenuItem
                                    sx={{ fontSize: 14 }}
                                    value={e.fabric_useable_id}
                                    key={e.fabric_useable_id}
                                  >
                                    {e?.fabric_useable_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <IconButton
                            onClick={() => handleModalOpen("fabric_useable_id")}
                            sx={{ padding: 0, color: primaryColor }}
                          >
                            <SiAddthis size={20} />
                          </IconButton>
                        </Stack>
                      ),
                  },
                  {
                    label: "Fabric Composition:",
                    comp:
                      getProductFabricCompositionStatus === asyncStatus.IDLE ||
                      getProductFabricCompositionStatus ===
                        asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Stack flexDirection={"row"} padding={0}>
                          <Select
                            fullWidth
                            // size="small"
                            value={addFabricData?.fabric_composition_id}
                            sx={{
                              padding: "0px 0px !important",
                              height: "auto",
                              fontSize: 14,
                              "& .MuiSelect-select": {
                                padding: "1px",
                              },
                            }}
                            onChange={addOnChangeHandle}
                            name={"fabric_composition_id"}
                          >
                            {getProductFabricCompositionData?.length &&
                              getProductFabricCompositionData?.map((e) => {
                                return (
                                  <MenuItem
                                    sx={{ fontSize: 14 }}
                                    value={e.fabric_composition_id}
                                    key={e.fabric_composition_id}
                                  >
                                    {e?.composition_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <IconButton
                            onClick={() =>
                              handleModalOpen("fabric_composition_id")
                            }
                            sx={{ padding: 0, color: primaryColor }}
                          >
                            <SiAddthis size={20} />
                          </IconButton>
                        </Stack>
                      ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddFabricData({
                            ...addFabricData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedFabric ? (
              <InputWithBtnLayoutGridComp
                inputArray={[
                  {
                    label: "Fabric:",

                    comp: (
                      <InputComp
                        defaultValue={selectedFabric?.fabric_name}
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        name={"fabric_name"}
                        disabled={!editMode}
                        key={selectedFabric?.fabric_name}
                        onChange={editOnChangeHandle}
                      />
                    ),
                  },
                  {
                    label: "Fabric Class:",
                    comp:
                      getProductFabricClassStatus === asyncStatus.IDLE ||
                      getProductFabricClassStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Select
                          fullWidth
                          disabled={!editMode}
                          key={selectedFabric?.fabric_class_id1}
                          defaultValue={selectedFabric?.fabric_class_id1}
                          // size="small"
                          value={editFabricData?.fabric_class_id1}
                          sx={{
                            padding: "0px 0px !important",
                            height: "auto",
                            fontSize: 14,
                            "& .MuiSelect-select": {
                              padding: "1px",
                            },
                          }}
                          onChange={editOnChangeHandle}
                          name={"fabric_class_id1"}
                        >
                          {getProductFabricClassData?.length &&
                            getProductFabricClassData?.map((e) => {
                              return (
                                <MenuItem
                                  sx={{ fontSize: 14 }}
                                  value={e.fabric_class_id}
                                  key={e.fabric_class_id}
                                >
                                  {e?.fabric_class_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      ),
                  },
                  {
                    label: "Fabric Class 2:",
                    comp:
                      getProductFabricClassTwoStatus === asyncStatus.IDLE ||
                      getProductFabricClassTwoStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Select
                          fullWidth
                          // size="small"
                          disabled={!editMode}
                          key={selectedFabric?.fabric_class_id2}
                          value={editFabricData?.fabric_class_id2}
                          defaultValue={selectedFabric?.fabric_class_id2}
                          sx={{
                            padding: "0px 0px !important",
                            height: "auto",
                            fontSize: 14,
                            "& .MuiSelect-select": {
                              padding: "1px",
                            },
                          }}
                          onChange={editOnChangeHandle}
                          name={"fabric_class_id2"}
                        >
                          {getProductFabricClassTwoData?.length &&
                            getProductFabricClassTwoData?.map((e) => {
                              return (
                                <MenuItem
                                  sx={{ fontSize: 14 }}
                                  value={e.fabric_class_id2}
                                  key={e.fabric_class_id2}
                                >
                                  {e?.fabric_class_name2}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      ),
                  },
                  {
                    label: "Fabric Type:",
                    comp:
                      getProductFabricTypeStatus === asyncStatus.IDLE ||
                      getProductFabricTypeStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Select
                          fullWidth
                          key={selectedFabric?.fabric_type_id}
                          defaultValue={selectedFabric?.fabric_type_id}
                          // size="small"
                          value={editFabricData?.fabric_type_id}
                          disabled={!editMode}
                          sx={{
                            padding: "0px 0px !important",
                            height: "auto",
                            fontSize: 14,
                            "& .MuiSelect-select": {
                              padding: "1px",
                            },
                          }}
                          onChange={editOnChangeHandle}
                          name={"fabric_type_id"}
                        >
                          {getProductFabricTypeData?.length &&
                            getProductFabricTypeData?.map((e) => {
                              return (
                                <MenuItem
                                  sx={{ fontSize: 14 }}
                                  value={e.fabric_type_id}
                                  key={e.fabric_type_id}
                                >
                                  {e?.fabric_type_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      ),
                  },
                  {
                    label: "Fabric Usable:",
                    comp:
                      getProductFabricUsableStatus === asyncStatus.IDLE ||
                      getProductFabricUsableStatus === asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Select
                          fullWidth
                          key={selectedFabric?.fabric_useable_id}
                          defaultValue={selectedFabric?.fabric_useable_id}
                          // size="small"
                          value={editFabricData?.fabric_useable_id}
                          disabled={!editMode}
                          sx={{
                            padding: "0px 0px !important",
                            height: "auto",
                            fontSize: 14,
                            "& .MuiSelect-select": {
                              padding: "1px",
                            },
                          }}
                          onChange={editOnChangeHandle}
                          name={"fabric_useable_id"}
                        >
                          {getProductFabricUsableData?.length &&
                            getProductFabricUsableData?.map((e) => {
                              return (
                                <MenuItem
                                  sx={{ fontSize: 14 }}
                                  value={e.fabric_useable_id}
                                  key={e.fabric_useable_id}
                                >
                                  {e?.fabric_useable_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      ),
                  },
                  {
                    label: "Fabric Composition:",
                    comp:
                      getProductFabricCompositionStatus === asyncStatus.IDLE ||
                      getProductFabricCompositionStatus ===
                        asyncStatus.LOADING ? (
                        <Stack alignItems={"center"}>
                          <CircularProgress size={16} />
                        </Stack>
                      ) : (
                        <Select
                          fullWidth
                          // size="small"
                          key={selectedFabric?.fabric_composition_id}
                          defaultValue={selectedFabric?.fabric_composition_id}
                          value={editFabricData?.fabric_composition_id}
                          disabled={!editMode}
                          sx={{
                            padding: "0px 0px !important",
                            height: "auto",
                            fontSize: 14,
                            "& .MuiSelect-select": {
                              padding: "1px",
                            },
                          }}
                          onChange={editOnChangeHandle}
                          name={"fabric_composition_id"}
                        >
                          {getProductFabricCompositionData?.length &&
                            getProductFabricCompositionData?.map((e) => {
                              return (
                                <MenuItem
                                  sx={{ fontSize: 14 }}
                                  value={e.fabric_composition_id}
                                  key={e.fabric_composition_id}
                                >
                                  {e?.composition_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultValue={selectedFabric?.is_active}
                        key={selectedFabric?.is_active}
                        disabled={!editMode}
                        // defaultChecked
                        onChange={(e) =>
                          setEditFabricData({
                            ...editFabricData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : (
              <Typography>Select any fabric</Typography>
            )}

            <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductFabricError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductFabricError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductFabricError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductFabricError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  // {
                  //   name: "Companies",
                  //   content: (
                  //     <Stack height={400} width={"100%"}>
                  //       <TableComp
                  //         columns={[
                  //           {
                  //             field: "companyId",
                  //             headerName: "Company ID",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "companyName",
                  //             headerName: "Company Name",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Action",
                  //             flex: true,
                  //           },
                  //         ]}
                  //         //   onRowClick={(e) => {
                  //         //     setSelectedFabric(e?.row);
                  //         //   }}
                  //         rows={[]}
                  //         //   loading={
                  //         //     getProductStyleStatus === asyncStatus.IDLE ||
                  //         //     getProductStyleStatus === asyncStatus.LOADING
                  //         //   }
                  //       />
                  //     </Stack>
                  //   ),
                  // },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedFabric(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default Fabric;
