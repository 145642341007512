import { createAsyncThunk } from "@reduxjs/toolkit";
import { type_constants } from "../constant";
import { apiHandle } from "../../config/apiHandle/apiHandle";

export const getProductStyleAsync = createAsyncThunk(
  type_constants.GET_STYLE,
  async () => {
    try {
      const res = await apiHandle.get(`/style`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addProductStyleAsync = createAsyncThunk(
  type_constants.ADD_STYLE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/style`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editProductStyleAsync = createAsyncThunk(
  type_constants.EDIT_STYLE,
  async (obj) => {
    try {
      let {style_id,...editObj}=obj
      const res = await apiHandle.patch(`/style/${obj?.style_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteProductStyleAsync = createAsyncThunk(
  type_constants.DELETE_STYLE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/style/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//wash start

export const getProductWashAsync = createAsyncThunk(
  type_constants.GET_WASH,
  async () => {
    try {
      const res = await apiHandle.get(`/wash`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductWashAsync = createAsyncThunk(
  type_constants.ADD_WASH,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/wash`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductWashAsync = createAsyncThunk(
  type_constants.EDIT_WASH,
  async (obj) => {
    try {
      let {wash_id,...editObj}=obj
      const res = await apiHandle.patch(`/wash/${obj?.wash_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductWashAsync = createAsyncThunk(
  type_constants.DELETE_WASH,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/wash/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//wash end

//cut start

export const getProductCutAsync = createAsyncThunk(
  type_constants.GET_CUT,
  async () => {
    try {
      const res = await apiHandle.get(`/invCut`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductCutAsync = createAsyncThunk(
  type_constants.ADD_CUT,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/invCut`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductCutAsync = createAsyncThunk(
  type_constants.EDIT_CUT,
  async (obj) => {
    try {
      let {cut_id,...editObj}=obj
      const res = await apiHandle.patch(`/invCut/${obj?.cut_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductCutAsync = createAsyncThunk(
  type_constants.DELETE_CUT,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/invCut/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//cut end

//fabric class start

export const getProductFabricClassAsync = createAsyncThunk(
  type_constants.GET_FABRIC_CLASS,
  async () => {
    try {
      const res = await apiHandle.get(`/fabricClass`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductFabricClassAsync = createAsyncThunk(
  type_constants.ADD_FABRIC_CLASS,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/fabricClass`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductFabricClassAsync = createAsyncThunk(
  type_constants.EDIT_FABRIC_CLASS,
  async (obj) => {
    try {
      console.log("test", obj);
      let {fabric_class_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/fabricClass/${obj.fabric_class_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductFabricClassAsync = createAsyncThunk(
  type_constants.DELETE_FABRIC_CLASS,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/fabricClass/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//fabric class end

//fabric type start

export const getProductFabricTypeAsync = createAsyncThunk(
  type_constants.GET_FABRIC_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/fabricType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductFabricTypeAsync = createAsyncThunk(
  type_constants.ADD_FABRIC_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/fabricType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductFabricTypeAsync = createAsyncThunk(
  type_constants.EDIT_FABRIC_TYPE,
  async (obj) => {
    try {
      console.log("test", obj);
      let {fabric_type_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/fabricType/${obj.fabric_type_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductFabricTypeAsync = createAsyncThunk(
  type_constants.DELETE_FABRIC_TYPE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/fabricType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//fabric type end

//fabric usable start

export const getProductFabricUsableAsync = createAsyncThunk(
  type_constants.GET_FABRIC_USABLE,
  async () => {
    try {
      const res = await apiHandle.get(`/fabricUsable`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductFabricUsableAsync = createAsyncThunk(
  type_constants.ADD_FABRIC_USABLE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/fabricUsable`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductFabricUsableAsync = createAsyncThunk(
  type_constants.EDIT_FABRIC_USABLE,
  async (obj) => {
    try {
      console.log("test", obj);
      let {fabric_useable_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/fabricUsable/${obj.fabric_useable_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductFabricUsableAsync = createAsyncThunk(
  type_constants.DELETE_FABRIC_USABLE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/fabricUsable/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//fabric usable end

//fabric class 2 start

export const getProductFabricClassTwoAsync = createAsyncThunk(
  type_constants.GET_FABRIC_CLASS_TWO,
  async () => {
    try {
      const res = await apiHandle.get(`/fabricClass2`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductFabricClassTwoAsync = createAsyncThunk(
  type_constants.ADD_FABRIC_CLASS_TWO,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/fabricClass2`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductFabricClassTwoAsync = createAsyncThunk(
  type_constants.EDIT_FABRIC_CLASS_TWO,
  async (obj) => {
    try {
      console.log("test", obj);
      let {fabric_class_id2,...editObj}=obj
      const res = await apiHandle.patch(
        `/fabricClass2/${obj.fabric_class_id2}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductFabricClassTwoAsync = createAsyncThunk(
  type_constants.DELETE_FABRIC_CLASS_TWO,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/fabricClass2/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//fabric class 2 end

//fabric composition start

export const getProductFabricCompositionAsync = createAsyncThunk(
  type_constants.GET_FABRIC_COMPOSITION,
  async () => {
    try {
      const res = await apiHandle.get(`/fabricComposition`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductFabricCompositionAsync = createAsyncThunk(
  type_constants.ADD_FABRIC_COMPOSITION,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/fabricComposition`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductFabricCompositionAsync = createAsyncThunk(
  type_constants.EDIT_FABRIC_COMPOSITION,
  async (obj) => {
    try {
      console.log("test", obj);
      let {fabric_composition_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/fabricComposition/${obj.fabric_composition_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductFabricCompositionAsync = createAsyncThunk(
  type_constants.DELETE_FABRIC_COMPOSITION,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/fabricComposition/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//fabric composition end

//fabric start

export const getProductFabricAsync = createAsyncThunk(
  type_constants.GET_FABRIC,
  async () => {
    try {
      const res = await apiHandle.get(`/fabric`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductFabricAsync = createAsyncThunk(
  type_constants.ADD_FABRIC,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/fabric`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductFabricAsync = createAsyncThunk(
  type_constants.EDIT_FABRIC,
  async (obj) => {
    try {
      console.log("test", obj);
      let {fabric_id,...editObj}=obj
      const res = await apiHandle.patch(`/fabric/${obj.fabric_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductFabricAsync = createAsyncThunk(
  type_constants.DELETE_FABRIC,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/fabric/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//fabric end

//gender start

export const getProductGenderAsync = createAsyncThunk(
  type_constants.GET_GENDER,
  async () => {
    try {
      const res = await apiHandle.get(`/gender`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductGenderAsync = createAsyncThunk(
  type_constants.ADD_GENDER,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/gender`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductGenderAsync = createAsyncThunk(
  type_constants.EDIT_GENDER,
  async (obj) => {
    try {
      console.log("test", obj);
      let {gender_id,...editObj}=obj
      const res = await apiHandle.patch(`/gender/${obj.gender_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductGenderAsync = createAsyncThunk(
  type_constants.DELETE_GENDER,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/gender/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//gender end

//washType start

export const getProductWashTypeAsync = createAsyncThunk(
  type_constants.GET_WASH_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/washType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductWashTypeAsync = createAsyncThunk(
  type_constants.ADD_WASH_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/washType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductWashTypeAsync = createAsyncThunk(
  type_constants.EDIT_WASH_TYPE,
  async (obj) => {
    try {
      console.log("test", obj);
      let {wash_type_id,...editObj}=obj
      const res = await apiHandle.patch(`/washType/${obj.wash_type_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductWashTypeAsync = createAsyncThunk(
  type_constants.DELETE_WASH_TYPE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/washType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//washType end

//ItemType start

export const getProductItemTypeAsync = createAsyncThunk(
  type_constants.GET_ITEM_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/ItemType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductItemTypeAsync = createAsyncThunk(
  type_constants.ADD_ITEM_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/ItemType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductItemTypeAsync = createAsyncThunk(
  type_constants.EDIT_ITEM_TYPE,
  async (obj) => {
    try {
      console.log("test", obj);
      let {item_type_id,...editObj}=obj
      const res = await apiHandle.patch(`/ItemType/${obj.item_type_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductItemTypeAsync = createAsyncThunk(
  type_constants.DELETE_ITEM_TYPE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/ItemType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//ItemType end

//season start

export const getProductSeasonAsync = createAsyncThunk(
  type_constants.GET_SEASON,
  async () => {
    try {
      const res = await apiHandle.get(`/season`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductSeasonAsync = createAsyncThunk(
  type_constants.ADD_SEASON,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/season`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductSeasonAsync = createAsyncThunk(
  type_constants.EDIT_SEASON,
  async (obj) => {
    try {
      console.log("test", obj);
      let {season_id,...editObj}=obj
      const res = await apiHandle.patch(`/season/${obj.season_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductSeasonAsync = createAsyncThunk(
  type_constants.DELETE_SEASON,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/season/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//season end

//genderCategory start

export const getProductGenderCategoryAsync = createAsyncThunk(
  type_constants.GET_GENDER_CATEGORY,
  async () => {
    try {
      const res = await apiHandle.get(`/genderCategory`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductGenderCategoryAsync = createAsyncThunk(
  type_constants.ADD_GENDER_CATEGORY,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/genderCategory`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductGenderCategoryAsync = createAsyncThunk(
  type_constants.EDIT_GENDER_CATEGORY,
  async (obj) => {
    try {
      console.log("test", obj);
      let {gender_category_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/genderCategory/${obj.gender_category_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductGenderCategoryAsync = createAsyncThunk(
  type_constants.DELETE_GENDER_CATEGORY,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/genderCategory/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//genderCategory end

//invFitCategory start

export const getProductFitCategoryAsync = createAsyncThunk(
  type_constants.GET_FIT_CATEGORY,
  async () => {
    try {
      const res = await apiHandle.get(`/fitCategory`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductFitCategoryAsync = createAsyncThunk(
  type_constants.ADD_FIT_CATEGORY,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/fitCategory`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductFitCategoryAsync = createAsyncThunk(
  type_constants.EDIT_FIT_CATEGORY,
  async (obj) => {
    try {
      console.log("test", obj);
      let {fit_category_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/fitCategory/${obj.fit_category_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductFitCategoryAsync = createAsyncThunk(
  type_constants.DELETE_FIT_CATEGORY,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/fitCategory/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//invFitCategory end

//inseam start

export const getProductinseamAsync = createAsyncThunk(
  type_constants.GET_INSEAM,
  async () => {
    try {
      const res = await apiHandle.get(`/inseam`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductinseamAsync = createAsyncThunk(
  type_constants.ADD_INSEAM,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/inseam`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductinseamAsync = createAsyncThunk(
  type_constants.EDIT_INSEAM,
  async (obj) => {
    try {
      console.log("test", obj);
      let {inseam_id,...editObj}=obj
      const res = await apiHandle.patch(`/inseam/${obj.inseam_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductinseamAsync = createAsyncThunk(
  type_constants.DELETE_INSEAM,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/inseam/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//inseam end

//Size start

export const getProductsizeAsync = createAsyncThunk(
  type_constants.GET_SIZE,
  async () => {
    try {
      const res = await apiHandle.get(`/size`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductsizeAsync = createAsyncThunk(
  type_constants.ADD_SIZE,
  async (obj) => {
    console.log("adddd", obj);
    try {
      const res = await apiHandle.post(`/size`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductsizeAsync = createAsyncThunk(
  type_constants.EDIT_SIZE,
  async (obj) => {
    console.log("acha", obj);
    try {
      console.log("test", obj);
      let {size_id,...editObj}=obj
      const res = await apiHandle.patch(`/size/${obj.size_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductsizeAsync = createAsyncThunk(
  type_constants.DELETE_SIZE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/size/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//Size end

//rise start

export const getProductRiseAsync = createAsyncThunk(
  type_constants.GET_RISE,
  async () => {
    try {
      const res = await apiHandle.get(`/rise`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductRiseAsync = createAsyncThunk(
  type_constants.ADD_RISE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/rise`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductRiseAsync = createAsyncThunk(
  type_constants.EDIT_RISE,
  async (obj) => {
    try {
      console.log("test", obj);
      let {rise_id,...editObj}=obj
      const res = await apiHandle.patch(`/rise/${obj.rise_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductRiseAsync = createAsyncThunk(
  type_constants.DELETE_RISE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/rise/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//rise end

//chargeBackType start

export const getProductChargeBackTypeAsync = createAsyncThunk(
  type_constants.GET_CHARGE_BACK_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/chargeBackType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductChargeBackTypeAsync = createAsyncThunk(
  type_constants.ADD_CHARGE_BACK_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/chargeBackType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductChargeBackTypeAsync = createAsyncThunk(
  type_constants.EDIT_CHARGE_BACK_TYPE,
  async (obj) => {
    try {
      console.log("test", obj);
      let {charge_back_type_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/chargeBackType/${obj.charge_back_type_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductChargeBackTypeAsync = createAsyncThunk(
  type_constants.DELETE_CHARGE_BACK_TYPE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/chargeBackType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//chargeBackType end

//Inseam Label start

export const getProductInseamLabelAsync = createAsyncThunk(
  type_constants.GET_INSEAM_LABEL,
  async () => {
    try {
      const res = await apiHandle.get(`/inseamLabel`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductInseamLabelAsync = createAsyncThunk(
  type_constants.ADD_INSEAM_LABEL,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/inseamLabel`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductInseamLabelAsync = createAsyncThunk(
  type_constants.EDIT_INSEAM_LABEL,
  async (obj) => {
    try {
      console.log("test", obj);
      let {inseam_label_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/inseamLabel/${obj.inseam_label_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductInseamLabelAsync = createAsyncThunk(
  type_constants.DELETE_INSEAM_LABEL,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/inseamLabel/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

///Inseam Label end

//RiseLabel start

export const getProductRiseLabelAsync = createAsyncThunk(
  type_constants.GET_RISE_LABEL,
  async () => {
    try {
      const res = await apiHandle.get(`/riseLabel`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductRiseLabelAsync = createAsyncThunk(
  type_constants.ADD_RISE_LABEL,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/riseLabel`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductRiseLabelAsync = createAsyncThunk(
  type_constants.EDIT_RISE_LABEL,
  async (obj) => {
    try {
      console.log("test", obj);
      let {rise_label_id,...editObj}=obj
      const res = await apiHandle.patch(`/riseLabel/${obj.rise_label_id}`, editObj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductRiseLabelAsync = createAsyncThunk(
  type_constants.DELETE_RISE_LABEL,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/riseLabel/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//RiseLabel end

//ProductType start

export const getProductProductTypeAsync = createAsyncThunk(
  type_constants.GET_PRODUCT_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/productType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductProductTypeAsync = createAsyncThunk(
  type_constants.ADD_PRODUCT_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/productType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductProductTypeAsync = createAsyncThunk(
  type_constants.EDIT_PRODUCT_TYPE,
  async (obj) => {
    try {
      console.log("test", obj);
      let {product_type_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/productType/${obj.product_type_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductProductTypeAsync = createAsyncThunk(
  type_constants.DELETE_PRODUCT_TYPE,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/productType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//ProductType end

//SeasonYear start

export const getProductSeasonYearAsync = createAsyncThunk(
  type_constants.GET_SEASON_YEAR,
  async () => {
    try {
      const res = await apiHandle.get(`/seasonYear`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductSeasonYearAsync = createAsyncThunk(
  type_constants.ADD_SEASON_YEAR,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/seasonYear`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductSeasonYearAsync = createAsyncThunk(
  type_constants.EDIT_SEASON_YEAR,
  async (obj) => {
    try {
      console.log("test", obj);
      let {season_year_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/seasonYear/${obj.season_year_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductSeasonYearAsync = createAsyncThunk(
  type_constants.DELETE_SEASON_YEAR,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/seasonYear/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//SeasonYear end

//ProductStatus start

export const getProductProductStatusAsync = createAsyncThunk(
  type_constants.GET_PRODUCT_STATUS,
  async () => {
    try {
      const res = await apiHandle.get(`/productStatus`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductProductStatusAsync = createAsyncThunk(
  type_constants.ADD_PRODUCT_STATUS,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/productStatus`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductProductStatusAsync = createAsyncThunk(
  type_constants.EDIT_PRODUCT_STATUS,
  async (obj) => {
    try {
      console.log("test", obj);
      let {product_status_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/productStatus/${obj.product_status_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductProductStatusAsync = createAsyncThunk(
  type_constants.DELETE_PRODUCT_STATUS,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/productStatus/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//ProductStatus end

//ProductClass start

export const getProductProductClassAsync = createAsyncThunk(
  type_constants.GET_PRODUCT_CLASS,
  async () => {
    try {
      const res = await apiHandle.get(`/productClass`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const addProductProductClassAsync = createAsyncThunk(
  type_constants.ADD_PRODUCT_CLASS,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/productClass`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const editProductProductClassAsync = createAsyncThunk(
  type_constants.EDIT_PRODUCT_CLASS,
  async (obj) => {
    try {
      console.log("test", obj);
      let {product_class_id,...editObj}=obj
      const res = await apiHandle.patch(
        `/productClass/${obj.product_class_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const deleteProductProductClassAsync = createAsyncThunk(
  type_constants.DELETE_PRODUCT_CLASS,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/productClass/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

//ProductClass end

// Product Info Apis Start

// get gender category by  id

export const getProductGenderCategoryByIdAsync = createAsyncThunk(
  type_constants.GET_GENDER_CATEGORY_BY_ID,
  async (id) => {
    try {
      const res = await apiHandle.get(`/genderCategory/getOne/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// get gender category by  id

// get company
export const getProductCompanyAsync = createAsyncThunk(
  type_constants.GET_COMPANY,
  async () => {
    try {
      const res = await apiHandle.get(`/adminCompany`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

// get company

// get size charts
export const getProductSizeChartByGenderCategoryIdAsync = createAsyncThunk(
  type_constants.GET_SIZE_CHART_BY_GENDER_CATEGORY_ID,
  async (id) => {
    try {
      const res = await apiHandle.post(`/sizeChart`, {
        gender_category_id: id,
      });
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

// get size charts

// post item product info
export const postItemAsync = createAsyncThunk(
  type_constants.ADD_ITEM,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/item`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// post item product info

// put item product info
export const putItemByIdAsync = createAsyncThunk(
  type_constants.PUT_ITEM,
  async ({id,obj}) => {
    try {
      // let {...editObj}=obj
      const res = await apiHandle.patch(`/item/${id}`,obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// put item product info

// delete item product info
export const deleteItemByIdAsync = createAsyncThunk(
  type_constants.DELETE_ITEM,
  async (id) => {
    try {
      const res = await apiHandle.delete(`/item/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// delete item product info

// get all items product info

export const getItemAsync = createAsyncThunk(
  type_constants.GET_ITEM,
  async () => {
    try {
      const res = await apiHandle.get(`/item/getAll`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

// get all items product info

// get item by id product info

export const getItemByIdAsync = createAsyncThunk(
  type_constants.GET_ITEM_BY_ID,
  async (id) => {
    try {
      const res = await apiHandle.get(`/item/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

// get item by id product info
// get combinations by item id product info

export const getCombinationsByItemIdAsync = createAsyncThunk(
  type_constants.GET_COMBINATIONS_BY_ITEM_ID,
  async (id) => {
    try {
      const res = await apiHandle.get(`/item/combination/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

// get combinations by item id product info


export const getLocationAsync = createAsyncThunk(
  type_constants.GET_LOCATION,
  async () => {
    try {
      const res = await apiHandle.get(`/adminLocation`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);