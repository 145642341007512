import { createAsyncThunk } from "@reduxjs/toolkit"
import { type_constants } from "../constant"
import { apiHandle } from "../../config/apiHandle/apiHandle"


export const login_async = createAsyncThunk(
  type_constants.LOGIN,
  async (login_data) => {
    try {
      const res = await apiHandle.post(`/auth/signin`, login_data)
      const data = await res.data
      return data
    } catch (error) {
      if (error?.response?.data) {
        console.log(error.response);
        throw Error(error.response.data.message)
      } else {
        throw Error(error.message)
      }
    }
  },
)
