import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import React from "react";
import { themeLightGray } from "../../../utils/themeColors";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport 
          csvOptions={{ allColumns: true }} // Export all columns
          excelOptions={{ allColumns: true }} // Export all columns    
      />
    </GridToolbarContainer>
  );
}
const TableComp = ({
  columns = [
    { field: "id", headerName: "Style Id", width: 200 },
    { field: "lastName", headerName: "Style Name", width: 200 },
  ],

  rows = [
    { id: 1, lastName: "Snow" },
    { id: 2, lastName: "Lannister" },
    { id: 3, lastName: "Lannister" },
    { id: 4, lastName: "Stark" },
    { id: 5, lastName: "Targaryen" },
    { id: 6, lastName: "Melisandre" },
    { id: 7, lastName: "Clifford" },
    { id: 8, lastName: "Frances" },
    { id: 9, lastName: "Roxie" },
  ],
  onRowClick,
  loading,
  exportToExcel
}) => {
  
  return ( 
    <DataGrid
    slots={{
      toolbar:exportToExcel&& CustomToolbar,
    }}
      //   sx={{
      //     // width: "100%",
      //     textAlign: "center",
      //     alignItems: "start",
      //     justifyContent: "center",
      //     "& .MuiDataGrid-footerContainer": {
      //       width: "100%",
      //     },
      //     // "& .MuiDataGrid-columnHeader":{
      //     // // backgroundColor:"green",
      //     // width:"100%"
      //     // },
      //     // "& .MuiDataGrid-columnHeaders":{
      //     // width:"100%"
      //     // },

      //     // "& .css-yrdy0g-MuiDataGrid-columnHeaderRow":{
      //     // backgroundColor:"blue",
      //     // width:"100%",
      //     // alignItems:"center"
      //     // },
      //     // "& .css-1gi0xgx-MuiDataGrid-root":{
      //     // backgroundColor:"yellow",
      //     // width:"100%",
      //     // alignItems:"center"
      //     // },
      //     // "& .css-204u17-MuiDataGrid-main":{
      //     // backgroundColor:"green",
      //     // width:"100%",
      //     // },
      //     // ":-webkit-align-items":

      //     "& .MuiDataGrid-virtualScroller": {
      //       width: "100%",
      //       "&::-webkit-scrollbar": {
      //         width: 0,
      //       },
      //       "&::-webkit-scrollbar-thumb": {
      //         width: 0,
      //       },
      //     },
      //     overflowX: "auto",
      //     "&::-webkit-scrollbar": {
      //       width: 0,
      //     },
      //     "&::-webkit-scrollbar-thumb": {
      //       width: 0,
      //     },
      //   }}
      sx={{
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: themeLightGray,
        },
        "& .MuiDataGrid-cell": {
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'normal',
          lineHeight: 'normal',
          flexWrap: 'wrap',
          maxHeight: 'none',
          padding: '15px', // Adjust the padding as needed

        },
        // "& .MuiDataGrid-row": {
        //   padding: '10px 0', // Adjust the padding as needed
        // },
      }}
      rows={rows}
      loading={loading}
      columns={columns.map((column) => ({
        ...column,
        width:column?.width?column?.width: column.flex ? undefined : 200, // Set a default width if flex is not provided
      }))}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 100 },
        },
      }}
      onRowClick={onRowClick}
      //   onSelectionModelChange={(e) => console.log(e)}
      pageSizeOptions={[25, 50, 100]}
      getRowHeight={() => 'auto'} // Dynamically adjust row height based on content
      // checkboxSelection
    />
  );
};

export default TableComp;
