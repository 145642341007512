import { createAsyncThunk } from "@reduxjs/toolkit";
import { type_constants } from "../constant";
import { apiHandle } from "../../config/apiHandle/apiHandle";

// city start
export const getOMCityAsync = createAsyncThunk(
  type_constants.GET_OM_CITY,
  async () => {
    try {
      const res = await apiHandle.get(`/adminCity`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCityAsync = createAsyncThunk(
  type_constants.POST_OM_CITY,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminCity`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMCityAsync = createAsyncThunk(
  type_constants.EDIT_OM_CITY,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/adminCity/${obj?.city_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMCityAsync = createAsyncThunk(
  type_constants.DELETE_OM_CITY,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminCity/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// city end

// payment_method_type start
export const getOMPaymentMethodTypeAsync = createAsyncThunk(
  type_constants.GET_OM_PAYMENT_METHOD_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/paymentmethodtype`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMPaymentMethodTypeAsync = createAsyncThunk(
  type_constants.POST_OM_PAYMENT_METHOD_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/paymentmethodtype`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMPaymentMethodTypeAsync = createAsyncThunk(
  type_constants.EDIT_OM_PAYMENT_METHOD_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/paymentmethodtype/${obj?.method_type_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMPaymentMethodTypeAsync = createAsyncThunk(
  type_constants.DELETE_OM_PAYMENT_METHOD_TYPE,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/paymentmethodtype/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// payment_method_type end

// payment_method_type start
export const getOMAdminJobAsync = createAsyncThunk(
  type_constants.GET_OM_ADMIN_JOB,
  async () => {
    try {
      const res = await apiHandle.get(`/adminJob`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMAdminJobAsync = createAsyncThunk(
  type_constants.POST_OM_ADMIN_JOB,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminJob`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMAdminJobAsync = createAsyncThunk(
  type_constants.EDIT_OM_ADMIN_JOB,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/adminJob/${obj?.job_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMAdminJobAsync = createAsyncThunk(
  type_constants.DELETE_OM_ADMIN_JOB,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminJob/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// payment_method_type end

// term for payment start
export const getOMTermForPrintAsync = createAsyncThunk(
  type_constants.GET_OM_TERM_FOR_PRINT,
  async () => {
    try {
      const res = await apiHandle.get(`/termForPrint`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMTermForPrintAsync = createAsyncThunk(
  type_constants.POST_OM_TERM_FOR_PRINT,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/termForPrint`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMTermForPrintAsync = createAsyncThunk(
  type_constants.EDIT_OM_TERM_FOR_PRINT,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/termForPrint/${obj?.term_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMTermForPrintAsync = createAsyncThunk(
  type_constants.DELETE_OM_TERM_FOR_PRINT,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/termForPrint/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// term for payment end

// Admin Discount start
export const getOMAdminDiscountAsync = createAsyncThunk(
  type_constants.GET_OM_ADMIN_DISCOUNT,
  async () => {
    try {
      const res = await apiHandle.get(`/adminDiscount`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMAdminDiscountAsync = createAsyncThunk(
  type_constants.POST_OM_ADMIN_DISCOUNT,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminDiscount`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMAdminDiscountAsync = createAsyncThunk(
  type_constants.EDIT_OM_ADMIN_DISCOUNT,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/adminDiscount/${obj?.discount_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMAdminDiscountAsync = createAsyncThunk(
  type_constants.DELETE_OM_ADMIN_DISCOUNT,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminDiscount/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Admin Discount end

// Admin Currency start
export const getOMAdminCurrencyAsync = createAsyncThunk(
  type_constants.GET_OM_ADMIN_CURRENCY,
  async () => {
    try {
      const res = await apiHandle.get(`/adminCurrency`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMAdminCurrencyAsync = createAsyncThunk(
  type_constants.POST_OM_ADMIN_CURRENCY,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminCurrency`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMAdminCurrencyAsync = createAsyncThunk(
  type_constants.EDIT_OM_ADMIN_CURRENCY,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/adminCurrency/${obj?.currency_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMAdminCurrencyAsync = createAsyncThunk(
  type_constants.DELETE_OM_ADMIN_CURRENCY,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminCurrency/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Admin Currency end

// State start
export const getOMStateAsync = createAsyncThunk(
  type_constants.GET_OM_STATE,
  async () => {
    try {
      const res = await apiHandle.get(`/adminState`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMStateAsync = createAsyncThunk(
  type_constants.POST_OM_STATE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminState`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMStateAsync = createAsyncThunk(
  type_constants.EDIT_OM_STATE,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/adminState/${obj?.state_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMStateAsync = createAsyncThunk(
  type_constants.DELETE_OM_STATE,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminState/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// State end

// Factor start
export const getOMFactorAsync = createAsyncThunk(
  type_constants.GET_OM_FACTOR,
  async () => {
    try {
      const res = await apiHandle.get(`/adminFactor`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMFactorAsync = createAsyncThunk(
  type_constants.POST_OM_FACTOR,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminFactor`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMFactorAsync = createAsyncThunk(
  type_constants.EDIT_OM_FACTOR,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/adminFactor/${obj?.factor_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMFactorAsync = createAsyncThunk(
  type_constants.DELETE_OM_FACTOR,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminFactor/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Factor end

// Customer Type start
export const getOMCustomerTypeAsync = createAsyncThunk(
  type_constants.GET_OM_CUSTOMER_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/customerType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCustomerTypeAsync = createAsyncThunk(
  type_constants.POST_OM_CUSTOMER_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/customerType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMCustomerTypeAsync = createAsyncThunk(
  type_constants.EDIT_OM_CUSTOMER_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/customerType/${obj?.customer_type_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMCustomerTypeAsync = createAsyncThunk(
  type_constants.DELETE_OM_CUSTOMER_TYPE,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/customerType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Customer Type end

// Country start
export const getOMCountryAsync = createAsyncThunk(
  type_constants.GET_OM_COUNTRY,
  async () => {
    try {
      const res = await apiHandle.get(`/country`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCountryAsync = createAsyncThunk(
  type_constants.POST_OM_COUNTRY,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/country`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMCountryAsync = createAsyncThunk(
  type_constants.EDIT_OM_COUNTRY,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/country/${obj?.country_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMCountryAsync = createAsyncThunk(
  type_constants.DELETE_OM_COUNTRY,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/country/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Country end

// Payment Type start
export const getOMPaymentTypeAsync = createAsyncThunk(
  type_constants.GET_OM_PAYMENT_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/paymentType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMPaymentTypeAsync = createAsyncThunk(
  type_constants.POST_OM_PAYMENT_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/paymentType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMPaymentTypeAsync = createAsyncThunk(
  type_constants.EDIT_OM_PAYMENT_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/paymentType/${obj?.payment_type_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMPaymentTypeAsync = createAsyncThunk(
  type_constants.DELETE_OM_PAYMENT_TYPE,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/paymentType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Payment Type end

// Payment Term start
export const getOMPaymentTermAsync = createAsyncThunk(
  type_constants.GET_OM_PAYMENT_TERM,
  async () => {
    try {
      const res = await apiHandle.get(`/term`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMPaymentTermAsync = createAsyncThunk(
  type_constants.POST_OM_PAYMENT_TERM,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/term`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMPaymentTermAsync = createAsyncThunk(
  type_constants.EDIT_OM_PAYMENT_TERM,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/term/${obj?.term_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMPaymentTermAsync = createAsyncThunk(
  type_constants.DELETE_OM_PAYMENT_TERM,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/term/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Payment Term end

// Bergen Payment Term start
export const getOMBergenPaymentTermAsync = createAsyncThunk(
  type_constants.GET_OM_BERGEN_PAYMENT_TERM,
  async () => {
    try {
      const res = await apiHandle.get(`/bergenPaymentTerm`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMBergenPaymentTermAsync = createAsyncThunk(
  type_constants.POST_OM_BERGEN_PAYMENT_TERM,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/bergenPaymentTerm`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMBergenPaymentTermAsync = createAsyncThunk(
  type_constants.EDIT_OM_BERGEN_PAYMENT_TERM,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/bergenPaymentTerm/${obj?.bergen_payment_term_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMBergenPaymentTermAsync = createAsyncThunk(
  type_constants.DELETE_OM_BERGEN_PAYMENT_TERM,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/bergenPaymentTerm/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Bergen Payment Term end

// Freight Term start
export const getOMFreightTermAsync = createAsyncThunk(
  type_constants.GET_OM_FREIGHT_TERM,
  async () => {
    try {
      const res = await apiHandle.get(`/adminFreightTerm`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMFreightTermAsync = createAsyncThunk(
  type_constants.POST_OM_FREIGHT_TERM,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminFreightTerm`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMFreightTermAsync = createAsyncThunk(
  type_constants.EDIT_OM_FREIGHT_TERM,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/adminFreightTerm/${obj?.freight_term_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMFreightTermAsync = createAsyncThunk(
  type_constants.DELETE_OM_FREIGHT_TERM,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminFreightTerm/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Freight Term end

// Order Status start
export const getOMOrderStatusAsync = createAsyncThunk(
  type_constants.GET_OM_ORDER_STATUS,
  async () => {
    try {
      const res = await apiHandle.get(`/orderStatus`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMOrderStatusAsync = createAsyncThunk(
  type_constants.POST_OM_ORDER_STATUS,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/orderStatus`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMOrderStatusAsync = createAsyncThunk(
  type_constants.EDIT_OM_ORDER_STATUS,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/orderStatus/${obj?.order_status_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMOrderStatusAsync = createAsyncThunk(
  type_constants.DELETE_OM_ORDER_STATUS,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/orderStatus/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Order Status end

// Region start
export const getOMRegionAsync = createAsyncThunk(
  type_constants.GET_OM_REGION,
  async () => {
    try {
      const res = await apiHandle.get(`/adminRegion`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMRegionAsync = createAsyncThunk(
  type_constants.POST_OM_REGION,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminRegion`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMRegionAsync = createAsyncThunk(
  type_constants.EDIT_OM_REGION,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/adminRegion/${obj?.region_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMRegionAsync = createAsyncThunk(
  type_constants.DELETE_OM_REGION,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminRegion/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Region end

// Commit Criteria start
export const getOMCommitCriteriaAsync = createAsyncThunk(
  type_constants.GET_OM_COMMIT_CRITERIA,
  async () => {
    try {
      const res = await apiHandle.get(`/commitCriteria`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCommitCriteriaAsync = createAsyncThunk(
  type_constants.POST_OM_COMMIT_CRITERIA,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/commitCriteria`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMCommitCriteriaAsync = createAsyncThunk(
  type_constants.EDIT_OM_COMMIT_CRITERIA,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/commitCriteria/${obj?.commit_criteria_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMCommitCriteriaAsync = createAsyncThunk(
  type_constants.DELETE_OM_COMMIT_CRITERIA,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/commitCriteria/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Commit Criteria end

// Ship Status start
export const getOMShipStatusAsync = createAsyncThunk(
  type_constants.GET_OM_SHIP_STATUS,
  async () => {
    try {
      const res = await apiHandle.get(`/shipStatus`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMShipStatusAsync = createAsyncThunk(
  type_constants.POST_OM_SHIP_STATUS,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/shipStatus`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMShipStatusAsync = createAsyncThunk(
  type_constants.EDIT_OM_SHIP_STATUS,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/shipStatus/${obj?.ship_status_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMShipStatusAsync = createAsyncThunk(
  type_constants.DELETE_OM_SHIP_STATUS,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/shipStatus/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Ship Status end

// Vendor Category start
export const getOMVendorCategoryAsync = createAsyncThunk(
  type_constants.GET_OM_VENDOR_CATEGORY,
  async () => {
    try {
      const res = await apiHandle.get(`/vendorCategory`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMVendorCategoryAsync = createAsyncThunk(
  type_constants.POST_OM_VENDOR_CATEGORY,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/vendorCategory`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMVendorCategoryAsync = createAsyncThunk(
  type_constants.EDIT_OM_VENDOR_CATEGORY,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/vendorCategory/${obj?.vendor_category_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMVendorCategoryAsync = createAsyncThunk(
  type_constants.DELETE_OM_VENDOR_CATEGORY,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/vendorCategory/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Vendor Category end

// Courier Service start
export const getOMCourierServiceAsync = createAsyncThunk(
  type_constants.GET_OM_COURIER_SERVICE,
  async () => {
    try {
      const res = await apiHandle.get(`/courierService`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCourierServiceAsync = createAsyncThunk(
  type_constants.POST_OM_COURIER_SERVICE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/courierService`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMCourierServiceAsync = createAsyncThunk(
  type_constants.EDIT_OM_COURIER_SERVICE,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/courierService/${obj?.courier_service_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMCourierServiceAsync = createAsyncThunk(
  type_constants.DELETE_OM_COURIER_SERVICE,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/courierService/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Courier Service end

// Return Reason start
export const getOMReturnReasonAsync = createAsyncThunk(
  type_constants.GET_OM_RETURN_REASON,
  async () => {
    try {
      const res = await apiHandle.get(`/adminReturnReason`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMReturnReasonAsync = createAsyncThunk(
  type_constants.POST_OM_RETURN_REASON,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminReturnReason`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMReturnReasonAsync = createAsyncThunk(
  type_constants.EDIT_OM_RETURN_REASON,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/adminReturnReason/${obj?.courier_service_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMReturnReasonAsync = createAsyncThunk(
  type_constants.DELETE_OM_RETURN_REASON,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminReturnReason/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Return Reason end

// Ship Via start
export const getOMShipViaAsync = createAsyncThunk(
  type_constants.GET_OM_SHIP_VIA,
  async () => {
    try {
      const res = await apiHandle.get(`/adminShipVia`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMShipViaAsync = createAsyncThunk(
  type_constants.POST_OM_SHIP_VIA,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminShipVia`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMShipViaAsync = createAsyncThunk(
  type_constants.EDIT_OM_SHIP_VIA,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/adminShipVia/${obj?.ship_via_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMShipViaAsync = createAsyncThunk(
  type_constants.DELETE_OM_SHIP_VIA,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminShipVia/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Ship Via end

// PT Status start
export const getOMPtStatusAsync = createAsyncThunk(
  type_constants.GET_OM_PT_STATUS,
  async () => {
    try {
      const res = await apiHandle.get(`/ptStatus`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMPtStatusAsync = createAsyncThunk(
  type_constants.POST_OM_PT_STATUS,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/ptStatus`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMPtStatusAsync = createAsyncThunk(
  type_constants.EDIT_OM_PT_STATUS,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/ptStatus/${obj?.pt_status_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMPtStatusAsync = createAsyncThunk(
  type_constants.DELETE_OM_PT_STATUS,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/ptStatus/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// PT Status end

// Return Type start
export const getOMReturnTypeAsync = createAsyncThunk(
  type_constants.GET_OM_RETURN_TYPE,
  async () => {
    try {
      const res = await apiHandle.get(`/adminReturnType`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMReturnTypeAsync = createAsyncThunk(
  type_constants.POST_OM_RETURN_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/adminReturnType`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMReturnTypeAsync = createAsyncThunk(
  type_constants.EDIT_OM_RETURN_TYPE,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/adminReturnType/${obj?.return_type_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMReturnTypeAsync = createAsyncThunk(
  type_constants.DELETE_OM_RETURN_TYPE,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/adminReturnType/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Return Type end

// Vendor

export const addOMVendorAsync = createAsyncThunk(
  type_constants.POST_OM_VENDOR,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/vendor`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const searchOMVendorAsync = createAsyncThunk(
  type_constants.SEARCH_OM_VENDOR,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/vendor/search`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const getOMVendorByIdAsync = createAsyncThunk(
  type_constants.GET_OM_VENDOR_BY_ID,
  async (id) => {
    try {
      const res = await apiHandle.get(`/vendor/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editVendorByIdAsync = createAsyncThunk(
  type_constants.EDIT_OM_VENDOR_BY_ID,
  async ({ id, obj }) => {
    try {
      const res = await apiHandle.patch(`/vendor/${id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteVendorByIdAsync = createAsyncThunk(
  type_constants.DELETE_OM_VENDOR_BY_ID,
  async (id) => {
    try {
      const res = await apiHandle.patch(`/vendor/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Vendor

// Sales Target start
export const getOMSalesTargetAsync = createAsyncThunk(
  type_constants.GET_OM_SALES_TARGET,
  async () => {
    try {
      const res = await apiHandle.get(`/salesTarget`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMSalesTargetAsync = createAsyncThunk(
  type_constants.POST_OM_SALES_TARGET,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/salesTarget`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMSalesTargetAsync = createAsyncThunk(
  type_constants.EDIT_OM_SALES_TARGET,
  async (obj) => {
    let editObj = {
      is_active: true,
      target_unit: null,
      brand_id: obj?.brand_id,
      region_id: obj?.region_id,
      figure_id: obj?.figure_id,
      target_year: obj?.target_year,
      target_month: obj?.target_month,
      gender_id: obj?.gender_id,
      target_amount: obj?.target_amount,
      target_date: obj?.target_date,
    };
    try {
      const res = await apiHandle.patch(
        `/salesTarget/${obj?.target_id}`,
        editObj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMSalesTargetAsync = createAsyncThunk(
  type_constants.DELETE_OM_SALES_TARGET,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/salesTarget/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Sales Target end

// Brand start
export const getOMBrandAsync = createAsyncThunk(
  type_constants.GET_OM_BRAND,
  async () => {
    try {
      const res = await apiHandle.get(`/brand`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMBrandAsync = createAsyncThunk(
  type_constants.POST_OM_BRAND,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/brand`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMBrandAsync = createAsyncThunk(
  type_constants.EDIT_OM_BRAND,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/brand/${obj?.brand_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMBrandAsync = createAsyncThunk(
  type_constants.DELETE_OM_BRAND,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/brand/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Brand end

// Figure start
export const getOMFigureAsync = createAsyncThunk(
  type_constants.GET_OM_FIGURE,
  async () => {
    try {
      const res = await apiHandle.get(`/figure`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMFigureAsync = createAsyncThunk(
  type_constants.POST_OM_FIGURE,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/figure`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMFigureAsync = createAsyncThunk(
  type_constants.EDIT_OM_FIGURE,
  async (obj) => {
    try {
      const res = await apiHandle.patch(`/figure/${obj?.figure_id}`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMFigureAsync = createAsyncThunk(
  type_constants.DELETE_OM_FIGURE,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/figure/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Figure end

// CustomerCategory start
export const getOMCustomerCategoryAsync = createAsyncThunk(
  type_constants.GET_OM_CUSTOMER_CATEGORY,
  async () => {
    try {
      const res = await apiHandle.get(`/customerCategory`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCustomerCategoryAsync = createAsyncThunk(
  type_constants.POST_OM_CUSTOMER_CATEGORY,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/customerCategory`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMCustomerCategoryAsync = createAsyncThunk(
  type_constants.EDIT_OM_CUSTOMER_CATEGORY,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/customerCategory/${obj?.customerCategory_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMCustomerCategoryAsync = createAsyncThunk(
  type_constants.DELETE_OM_CUSTOMER_CATEGORY,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/customerCategory/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// CustomerCategory end

// CAT start
export const getOMCATAsync = createAsyncThunk(
  type_constants.GET_OM_CAT,
  async () => {
    try {
      const res = await apiHandle.get(`/regionCategory`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCATAsync = createAsyncThunk(
  type_constants.POST_OM_CAT,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/regionCategory`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMCATAsync = createAsyncThunk(
  type_constants.EDIT_OM_CAT,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/regionCategory/${obj?.sp_region_category_id}`,
        {
          region_id: obj?.region_id,
          is_active: obj?.is_active,
          sales_person_id: obj?.sales_person_id,
          description: obj?.description,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMCATAsync = createAsyncThunk(
  type_constants.DELETE_OM_CAT,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/regionCategory/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// CAT end

// BergenBilling start
export const getOMBergenBillingAsync = createAsyncThunk(
  type_constants.GET_OM_BERGEN_BILLING,
  async () => {
    try {
      const res = await apiHandle.get(`/bergenBilling`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMBergenBillingAsync = createAsyncThunk(
  type_constants.POST_OM_BERGEN_BILLING,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/bergenBilling`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMBergenBillingAsync = createAsyncThunk(
  type_constants.EDIT_OM_BERGEN_BILLING,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/bergenBilling/${obj?.bergan_billing_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMBergenBillingAsync = createAsyncThunk(
  type_constants.DELETE_OM_BERGEN_BILLING,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/bergenBilling/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// BergenBilling end

// SalesPerson start
export const getOMSalesPersonAsync = createAsyncThunk(
  type_constants.GET_OM_SALES_PERSON,
  async () => {
    try {
      const res = await apiHandle.get(`/salesperson`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMSalesPersonAsync = createAsyncThunk(
  type_constants.POST_OM_SALES_PERSON,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/salesperson`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const editOMSalesPersonAsync = createAsyncThunk(
  type_constants.EDIT_OM_SALES_PERSON,
  async (obj) => {
    try {
      const res = await apiHandle.patch(
        `/salesperson/${obj?.sales_person_id}`,
        obj
      );
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const deleteOMSalesPersonAsync = createAsyncThunk(
  type_constants.DELETE_OM_SALES_PERSON,
  async (id) => {
    console.log(id);
    try {
      const res = await apiHandle.delete(`/salesperson/${id}`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// SalesPerson end

// Months start
export const getOMMonthsAsync = createAsyncThunk(
  type_constants.GET_OM_MONTHS,
  async () => {
    try {
      const res = await apiHandle.get(`/targetmonth`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
  );
  // Months end
  
  // Years start
  export const getOMYearsAsync = createAsyncThunk(
    type_constants.GET_OM_YEARS,
    async () => {
      try {
        const res = await apiHandle.get(`/targetYear`);
        const data = await res.data;
        return data;
      } catch (error) {
        if (error?.response?.data) {
          throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
// Years end

// CustomerInformation start
export const getOMCustomerInformationAsync = createAsyncThunk(
  type_constants.GET_OM_CUSTOMER_INFORMATION,
  async () => {
    try {
      const res = await apiHandle.get(`/customerInfo`);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
export const addOMCustomerInformationAsync = createAsyncThunk(
  type_constants.POST_OM_CUSTOMER_INFORMATION,
  async (obj) => {
    try {
      const res = await apiHandle.post(`/customer`, obj);
      const data = await res.data;
      return data;
    } catch (error) {
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
  );
  export const editOMCustomerInformationAsync = createAsyncThunk(
    type_constants.EDIT_OM_CUSTOMER_INFORMATION,
    async (obj) => {
      try {
        const res = await apiHandle.patch(
          `/customerInfo/${obj?.sales_person_id}`,
          obj
          );
          const data = await res.data;
          return data;
        } catch (error) {
          if (error?.response?.data) {
            throw Error(error.response.data.message);
          } else {
            throw Error(error.message);
          }
        }
      }
      );
      export const deleteOMCustomerInformationAsync = createAsyncThunk(
        type_constants.DELETE_OM_CUSTOMER_INFORMATION,
        async (id) => {
          console.log(id);
          try {
            const res = await apiHandle.delete(`/customerInfo/${id}`);
            const data = await res.data;
            return data;
          } catch (error) {
            if (error?.response?.data) {
              throw Error(error.response.data.message);
            } else {
              throw Error(error.message);
            }
          }
        }
        );
        // CustomerInformation end
        
        // Shipping Method
        export const getOMShippingMethodAsync = createAsyncThunk(
          type_constants.GET_OM_SHIPPING_METHOD,
          async () => {
            try {
              const res = await apiHandle.get(`/adminShippingMethod`);
              const data = await res.data;
              return data;
            } catch (error) {
              if (error?.response?.data) {
                throw Error(error.response.data.message);
              } else {
                throw Error(error.message);
              }
            }
          }
        );
        export const addOMShippingMethodAsync = createAsyncThunk(
          type_constants.POST_OM_SHIPPING_METHOD,
          async (obj) => {
            try {
              const res = await apiHandle.post(`/adminShippingMethod`,obj);
              const data = await res.data;
              return data;
            } catch (error) {
              if (error?.response?.data) {
                throw Error(error.response.data.message);
              } else {
                throw Error(error.message);
              }
            }
          }
        );
        export const editOMShippingMethodAsync = createAsyncThunk(
          type_constants.EDIT_OM_SHIPPING_METHOD,
          async (obj) => {
            try {
              const res = await apiHandle.patch(`/adminShippingMethod/${obj?.shipping_method_id}`);
              const data = await res.data;
              return data;
            } catch (error) {
              if (error?.response?.data) {
                throw Error(error.response.data.message);
              } else {
                throw Error(error.message);
              }
            }
          }
        );
        export const deleteOMShippingMethodAsync = createAsyncThunk(
          type_constants.GET_OM_SHIPPING_METHOD,
          async (id) => {
            try {
              const res = await apiHandle.get(`/adminShippingMethod/${id}`);
              const data = await res.data;
              return data;
            } catch (error) {
              if (error?.response?.data) {
                throw Error(error.response.data.message);
              } else {
                throw Error(error.message);
              }
            }
          }
        );
        // Shipping Method
        
        // Order Type
        export const getOMOrderTypeAsync = createAsyncThunk(
          type_constants.GET_OM_ORDER_TYPE,
          async () => {
            try {
              const res = await apiHandle.get(`/orderType`);
              const data = await res.data;
              return data;
            } catch (error) {
              if (error?.response?.data) {
                throw Error(error.response.data.message);
              } else {
                throw Error(error.message);
              }
            }
          }
        );
        // Order Type
        
