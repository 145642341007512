import { createSlice } from "@reduxjs/toolkit";
import {
  // editMyProfileAsync,
  // getMyProfileAsync,
  login_async,
} from "../services/authService";
import { asyncStatus } from "../../utils/asyncStatus";
import { setTokenCookie } from "../../helpers/cookieFunctions";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginStatus: asyncStatus.IDLE,
    userAuth: false,
    loginError: null,

    // getMyProfileStatus: asyncStatus.IDLE,
    // getMyProfileError: null,
    // getMyProfileData: null,

    // editMyProfileStatus: asyncStatus.IDLE,
    // editMyProfileError: null,
    // editMyProfileData: null,
  },
  reducers: {
    setUserAuth(state,{payload}){
      state.userAuth=payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login_async.pending, (state, action) => {
      state.loginStatus = asyncStatus.LOADING;
      state.loginError = null;
    });
    builder.addCase(login_async.fulfilled, (state, { payload }) => {
      state.userAuth = true;
      state.loginStatus = asyncStatus.SUCCEEDED;
      state.loginError = null;
      setTokenCookie(payload.access_token,1)
      // setTokenCookie(payload.access_token);
    });
    builder.addCase(login_async.rejected, (state, actions) => {
      state.loginStatus = asyncStatus.ERROR;
      state.userAuth = false;
      console.log(actions.error);
      state.loginError = actions?.error?.message;
    });

    // builder.addCase(getMyProfileAsync.pending, (state, action) => {
    //   state.getMyProfileStatus = asyncStatus.LOADING;
    //   state.getMyProfileError = null;
    //   state.getMyProfileData = null;
    // });
    // builder.addCase(getMyProfileAsync.fulfilled, (state, { payload }) => {
    //   state.getMyProfileStatus = asyncStatus.SUCCEEDED;
    //   state.getMyProfileData = payload;
    //   state.getMyProfileError = null;
    // });
    // builder.addCase(getMyProfileAsync.rejected, (state, actions) => {
    //   state.getMyProfileData = null;
    //   state.getMyProfileStatus = asyncStatus.ERROR;
    //   state.getMyProfileError = actions?.error?.message;
    // });

    // builder.addCase(editMyProfileAsync.pending, (state, action) => {
    //   state.editMyProfileStatus = asyncStatus.LOADING;
    //   state.editMyProfileError = null;
    //   state.editMyProfileData = null;
    // });
    // builder.addCase(editMyProfileAsync.fulfilled, (state, { payload }) => {
    //   state.editMyProfileStatus = asyncStatus.SUCCEEDED;
    //   state.editMyProfileData = payload;
    //   state.editMyProfileError = null;
    // });
    // builder.addCase(editMyProfileAsync.rejected, (state, actions) => {
    //   state.editMyProfileData = null;
    //   state.editMyProfileStatus = asyncStatus.ERROR;
    //   state.editMyProfileError = actions?.error?.message;
    // });
  },
});
export const {setUserAuth} = authSlice.actions;
export default authSlice.reducer;
