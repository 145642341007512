import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import InputComp from "../../components/common/InputComp/Input";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import { primaryColor } from "../../utils/themeColors";
import { asyncStatus } from "../../utils/asyncStatus";
import Btn from "../../components/common/ButtonComp/Btn";
import { login_async } from "../../store/services/authService";
const Login = () => {
  const dispatch = useDispatch();
  const { loginStatus, loginError, userAuth } = useSelector(
    (state) => state.auth
  );
  const [loginObj, setLoginObj] = useState({});
  const inputHandler = (event) => {
    const { id, value } = event.target;
    setLoginObj((prev) => {
      return { ...prev, [id]: value };
    });
  };

  return (
    <Stack
      width={1}
      height={"100vh"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      padding={0}
    >
      <Stack
        position={"absolute"}
        style={{ backgroundColor: primaryColor }}
        width={1}
        top={0}
        // paddingLeft={"35%"}
        // paddingLeft={"15px"}
      >
        <img
          alt="logo"
          src={logo}
          style={{
            filter: "invert(1)",
            objectFit: "contain",
            width: 70,
            height: 50,
            marginLeft:"15%"
          }}
        />
      </Stack>
      <Stack
        position={"absolute"}
        style={{ backgroundColor: primaryColor }}
        width={1}
        bottom={0}
        paddingY={1}
        flexDirection={{ xl: "row", lg: "row", md: "row" }}
        justifyContent={"space-around"}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: { xl: 16, lg: 16, md: 16, sm: 14, xs: 14 },
          }}
        >
          © OMS | OMS Terms of Use for ERP Support Portal | OMS Privacy Policy
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontSize: { xl: 16, lg: 16, md: 16, sm: 14, xs: 14 },
          }}
        >
          OMS Suit
        </Typography>
      </Stack>
       <Stack
        sx={{ boxShadow: "1px 1px 20px 0px rgb(0 0 0 / 14%)" }}
        width={{ xl: "40%", lg: "40%", md: "60%", sm: "80%", xs: "90%" }}
        maxWidth={"380px"}
        padding={"20px"}
        borderRadius={1}
        gap={3}
        component={"form"}
      >
        <Typography
          sx={{ fontSize: 22, fontWeight: "500", textAlign: "center" }}
          mb={2}
        >
          OMS LOGIN
        </Typography>
        <InputComp
          onChange={(e) => inputHandler(e)}
          id={"login_id"}
          placeholder="Username/Email"
        />
        <InputComp
          type={"password"}
          onChange={(e) => inputHandler(e)}
          id={"user_password"}
          placeholder="Password"
        />
        {loginStatus === asyncStatus.ERROR && (
          <Typography sx={{ color: "red", fontSize: 14 }}>
            {loginError}
          </Typography>
        )}
        <Btn
          loading={loginStatus === asyncStatus.LOADING}
          disabled={!(loginObj.user_password && loginObj.login_id)}
            onClick={() => dispatch(login_async(loginObj))}
        >
          Login
        </Btn>
        <Stack alignItems={"center"} paddingX={2}>
          <Typography
            marginBottom={2}
            fontSize={14}
            color={"gray"}
            sx={{ cursor: "pointer" }}
          >
            Forgot your password?
          </Typography>
          <Typography fontSize={14} color={"gray"}>
            By clicking on the Log In button, you understand and agree to{" "}
            <Typography
              variant="span"
              sx={{
                textAlign: "center",
                cursor: "pointer",
                fontSize: 14,
                color: "black",
                ":hover": { textDecorationLine: "underline" },
              }}
            >
              OMS Terms of Use
            </Typography>{" "}
            and{" "}
            <Typography
              variant="span"
              sx={{
                textAlign: "center",
                cursor: "pointer",
                fontSize: 14,
                color: "black",
                ":hover": { textDecorationLine: "underline" },
              }}
            >
              OMS Privacy Policy
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Login;
