import React from 'react'
import { Typography, Box, Card, CardActions, CardContent } from "@mui/material";




// ##### CSS Styles #####
const styles = {
    summaryContainer: {
        backgroundColor: "#3b4a57",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        height: 30
        // borderRadius: "5px",
    },
    summaryHeading: {
        fontSize: 14
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: 2,
    },
    contentkey: {
        fontSize: 12,
        fontWeight: "bold"
    }

}



const Summary = ({ heading = "", quantity = 10, subTotal = 5, discount = 10, tax = 2, shippingCost = 10, style }) => {



    const content = [
        {
            key: "Total Quantity:",
            value: quantity
        },
        {
            key: "Sub Total:",
            value: subTotal
        },
        {
            key: "Less Discount:",
            value: discount
        },
        {
            key: "Add Tax:",
            value: tax
        },
        {
            key: "Add Shipping Cost:",
            value: shippingCost
        }
    ];



    return (
        <div>
            <Box sx={{ minWidth: 275, border: "1px solid black", borderStyle: "dotted", ...style }}>
                <Card >
                    <CardContent>
                        <div style={styles.summaryContainer}>
                            <Typography style={styles.summaryHeading}>{heading}</Typography>
                        </div>
                        {
                            content?.map((v, i) => {
                                return (
                                    <div key={i} style={styles.contentContainer}>
                                        <Typography style={styles.contentkey}>{v?.key}</Typography>
                                        <Typography style={styles.summaryHeading}>{v?.value}</Typography>
                                    </div>
                                )
                            })
                        }
                        <hr />
                        <div style={styles.contentContainer}>
                            <Typography style={styles.contentkey}>Total:</Typography>
                            <Typography style={styles.summaryHeading}>{quantity + subTotal}</Typography>
                        </div>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Box>
        </div>
    )
}

export default Summary;