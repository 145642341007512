import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../../utils/asyncStatus";
import {
  addOMAdminCurrencyAsync,
  addOMAdminDiscountAsync,
  addOMAdminJobAsync,
  addOMBergenBillingAsync,
  addOMBergenPaymentTermAsync,
  addOMCATAsync,
  addOMCityAsync,
  addOMCommitCriteriaAsync,
  addOMCountryAsync,
  addOMCourierServiceAsync,
  addOMCustomerCategoryAsync,
  addOMCustomerTypeAsync,
  addOMFactorAsync,
  addOMFigureAsync,
  addOMFreightTermAsync,
  addOMOrderStatusAsync,
  addOMPaymentMethodTypeAsync,
  addOMPaymentTermAsync,
  addOMPaymentTypeAsync,
  addOMPtStatusAsync,
  addOMRegionAsync,
  addOMReturnReasonAsync,
  addOMReturnTypeAsync,
  addOMSalesPersonAsync,
  addOMSalesTargetAsync,
  addOMShipStatusAsync,
  addOMShipViaAsync,
  addOMStateAsync,
  addOMTermForPrintAsync,
  addOMVendorAsync,
  addOMVendorCategoryAsync,
  deleteOMAdminCurrencyAsync,
  deleteOMAdminDiscountAsync,
  deleteOMAdminJobAsync,
  deleteOMBergenBillingAsync,
  deleteOMBergenPaymentTermAsync,
  deleteOMCATAsync,
  deleteOMCityAsync,
  deleteOMCommitCriteriaAsync,
  deleteOMCountryAsync,
  deleteOMCourierServiceAsync,
  deleteOMCustomerCategoryAsync,
  deleteOMCustomerTypeAsync,
  deleteOMFactorAsync,
  deleteOMFigureAsync,
  deleteOMFreightTermAsync,
  deleteOMOrderStatusAsync,
  deleteOMPaymentMethodTypeAsync,
  deleteOMPaymentTermAsync,
  deleteOMPaymentTypeAsync,
  deleteOMPtStatusAsync,
  deleteOMRegionAsync,
  deleteOMReturnReasonAsync,
  deleteOMReturnTypeAsync,
  deleteOMSalesPersonAsync,
  deleteOMSalesTargetAsync,
  deleteOMShipStatusAsync,
  deleteOMShipViaAsync,
  deleteOMStateAsync,
  deleteOMTermForPrintAsync,
  deleteOMVendorCategoryAsync,
  deleteVendorByIdAsync,
  editOMAdminCurrencyAsync,
  editOMAdminDiscountAsync,
  editOMAdminJobAsync,
  editOMBergenBillingAsync,
  editOMBergenPaymentTermAsync,
  editOMCATAsync,
  editOMCityAsync,
  editOMCommitCriteriaAsync,
  editOMCountryAsync,
  editOMCourierServiceAsync,
  editOMCustomerCategoryAsync,
  editOMCustomerTypeAsync,
  editOMFactorAsync,
  editOMFigureAsync,
  editOMFreightTermAsync,
  editOMOrderStatusAsync,
  editOMPaymentMethodTypeAsync,
  editOMPaymentTermAsync,
  editOMPaymentTypeAsync,
  editOMPtStatusAsync,
  editOMRegionAsync,
  editOMReturnReasonAsync,
  editOMReturnTypeAsync,
  editOMSalesPersonAsync,
  editOMSalesTargetAsync,
  editOMShipStatusAsync,
  editOMShipViaAsync,
  editOMStateAsync,
  editOMTermForPrintAsync,
  editOMVendorCategoryAsync,
  editVendorByIdAsync,
  getOMAdminCurrencyAsync,
  getOMAdminDiscountAsync,
  getOMAdminJobAsync,
  getOMBergenBillingAsync,
  getOMBergenPaymentTermAsync,
  getOMBrandAsync,
  getOMCATAsync,
  getOMCityAsync,
  getOMCommitCriteriaAsync,
  getOMCountryAsync,
  getOMCourierServiceAsync,
  getOMCustomerCategoryAsync,
  getOMCustomerInformationAsync,
  getOMCustomerTypeAsync,
  getOMFactorAsync,
  getOMFigureAsync,
  getOMFreightTermAsync,
  getOMMonthsAsync,
  getOMOrderStatusAsync,
  getOMOrderTypeAsync,
  getOMPaymentMethodTypeAsync,
  getOMPaymentTermAsync,
  getOMPaymentTypeAsync,
  getOMPtStatusAsync,
  getOMRegionAsync,
  getOMReturnReasonAsync,
  getOMReturnTypeAsync,
  getOMSalesPersonAsync,
  getOMSalesTargetAsync,
  getOMShipStatusAsync,
  getOMShipViaAsync,
  getOMShippingMethodAsync,
  getOMStateAsync,
  getOMTermForPrintAsync,
  getOMVendorByIdAsync,
  getOMVendorCategoryAsync,
  getOMYearsAsync,
  searchOMVendorAsync,

  // 

} from "../services/orderManagementService";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_notification";

const OMSlice = createSlice({
  name: "orderManagement",
  initialState: {
    // city
    getOMCityStatus: asyncStatus.IDLE,
    getOMCityError: null,
    getOMCityData: null,

    addOMCityStatus: asyncStatus.IDLE,
    addOMCityError: null,
    addOMCityData: null,

    editOMCityStatus: asyncStatus.IDLE,
    editOMCityError: null,
    editOMCityData: null,

    deleteOMCityStatus: asyncStatus.IDLE,
    deleteOMCityError: null,
    deleteOMCityData: null,
    // city

    // Payment Method Type
    getOMPaymentMethodTypeStatus: asyncStatus.IDLE,
    getOMPaymentMethodTypeError: null,
    getOMPaymentMethodTypeData: null,

    addOMPaymentMethodTypeStatus: asyncStatus.IDLE,
    addOMPaymentMethodTypeError: null,
    addOMPaymentMethodTypeData: null,

    editOMPaymentMethodTypeStatus: asyncStatus.IDLE,
    editOMPaymentMethodTypeError: null,
    editOMPaymentMethodTypeData: null,

    deleteOMPaymentMethodTypeStatus: asyncStatus.IDLE,
    deleteOMPaymentMethodTypeError: null,
    deleteOMPaymentMethodTypeData: null,
    // Payment Method Type

    // Admin Job
    getOMAdminJobStatus: asyncStatus.IDLE,
    getOMAdminJobError: null,
    getOMAdminJobData: null,

    addOMAdminJobStatus: asyncStatus.IDLE,
    addOMAdminJobError: null,
    addOMAdminJobData: null,

    editOMAdminJobStatus: asyncStatus.IDLE,
    editOMAdminJobError: null,
    editOMAdminJobData: null,

    deleteOMAdminJobStatus: asyncStatus.IDLE,
    deleteOMAdminJobError: null,
    deleteOMAdminJobData: null,
    // Admin Job

    // Admin Discount
    getOMAdminDiscountStatus: asyncStatus.IDLE,
    getOMAdminDiscountError: null,
    getOMAdminDiscountData: null,

    addOMAdminDiscountStatus: asyncStatus.IDLE,
    addOMAdminDiscountError: null,
    addOMAdminDiscountData: null,

    editOMAdminDiscountStatus: asyncStatus.IDLE,
    editOMAdminDiscountError: null,
    editOMAdminDiscountData: null,

    deleteOMAdminDiscountStatus: asyncStatus.IDLE,
    deleteOMAdminDiscountError: null,
    deleteOMAdminDiscountData: null,
    // Admin Discount

    // Admin Currency
    getOMAdminCurrencyStatus: asyncStatus.IDLE,
    getOMAdminCurrencyError: null,
    getOMAdminCurrencyData: null,

    addOMAdminCurrencyStatus: asyncStatus.IDLE,
    addOMAdminCurrencyError: null,
    addOMAdminCurrencyData: null,

    editOMAdminCurrencyStatus: asyncStatus.IDLE,
    editOMAdminCurrencyError: null,
    editOMAdminCurrencyData: null,

    deleteOMAdminCurrencyStatus: asyncStatus.IDLE,
    deleteOMAdminCurrencyError: null,
    deleteOMAdminCurrencyData: null,
    // Admin Currency

    // State
    getOMStateStatus: asyncStatus.IDLE,
    getOMStateError: null,
    getOMStateData: null,

    addOMStateStatus: asyncStatus.IDLE,
    addOMStateError: null,
    addOMStateData: null,

    editOMStateStatus: asyncStatus.IDLE,
    editOMStateError: null,
    editOMStateData: null,

    deleteOMStateStatus: asyncStatus.IDLE,
    deleteOMStateError: null,
    deleteOMStateData: null,
    // State

    // Factor
    getOMFactorStatus: asyncStatus.IDLE,
    getOMFactorError: null,
    getOMFactorData: null,

    addOMFactorStatus: asyncStatus.IDLE,
    addOMFactorError: null,
    addOMFactorData: null,

    editOMFactorStatus: asyncStatus.IDLE,
    editOMFactorError: null,
    editOMFactorData: null,

    deleteOMFactorStatus: asyncStatus.IDLE,
    deleteOMFactorError: null,
    deleteOMFactorData: null,
    // Factor

    // CustomerType
    getOMCustomerTypeStatus: asyncStatus.IDLE,
    getOMCustomerTypeError: null,
    getOMCustomerTypeData: null,

    addOMCustomerTypeStatus: asyncStatus.IDLE,
    addOMCustomerTypeError: null,
    addOMCustomerTypeData: null,

    editOMCustomerTypeStatus: asyncStatus.IDLE,
    editOMCustomerTypeError: null,
    editOMCustomerTypeData: null,

    deleteOMCustomerTypeStatus: asyncStatus.IDLE,
    deleteOMCustomerTypeError: null,
    deleteOMCustomerTypeData: null,
    // CustomerType

    // Country
    getOMCountryStatus: asyncStatus.IDLE,
    getOMCountryError: null,
    getOMCountryData: null,

    addOMCountryStatus: asyncStatus.IDLE,
    addOMCountryError: null,
    addOMCountryData: null,

    editOMCountryStatus: asyncStatus.IDLE,
    editOMCountryError: null,
    editOMCountryData: null,

    deleteOMCountryStatus: asyncStatus.IDLE,
    deleteOMCountryError: null,
    deleteOMCountryData: null,
    // Country

    // PaymentType
    getOMPaymentTypeStatus: asyncStatus.IDLE,
    getOMPaymentTypeError: null,
    getOMPaymentTypeData: null,

    addOMPaymentTypeStatus: asyncStatus.IDLE,
    addOMPaymentTypeError: null,
    addOMPaymentTypeData: null,

    editOMPaymentTypeStatus: asyncStatus.IDLE,
    editOMPaymentTypeError: null,
    editOMPaymentTypeData: null,

    deleteOMPaymentTypeStatus: asyncStatus.IDLE,
    deleteOMPaymentTypeError: null,
    deleteOMPaymentTypeData: null,
    // PaymentType

    // PaymentTerm
    getOMPaymentTermStatus: asyncStatus.IDLE,
    getOMPaymentTermError: null,
    getOMPaymentTermData: null,

    addOMPaymentTermStatus: asyncStatus.IDLE,
    addOMPaymentTermError: null,
    addOMPaymentTermData: null,

    editOMPaymentTermStatus: asyncStatus.IDLE,
    editOMPaymentTermError: null,
    editOMPaymentTermData: null,

    deleteOMPaymentTermStatus: asyncStatus.IDLE,
    deleteOMPaymentTermError: null,
    deleteOMPaymentTermData: null,
    // PaymentTerm

    // BergenPaymentTerm
    getOMBergenPaymentTermStatus: asyncStatus.IDLE,
    getOMBergenPaymentTermError: null,
    getOMBergenPaymentTermData: null,

    addOMBergenPaymentTermStatus: asyncStatus.IDLE,
    addOMBergenPaymentTermError: null,
    addOMBergenPaymentTermData: null,

    editOMBergenPaymentTermStatus: asyncStatus.IDLE,
    editOMBergenPaymentTermError: null,
    editOMBergenPaymentTermData: null,

    deleteOMBergenPaymentTermStatus: asyncStatus.IDLE,
    deleteOMBergenPaymentTermError: null,
    deleteOMBergenPaymentTermData: null,
    // BergenPaymentTerm

    // FreightTerm
    getOMFreightTermStatus: asyncStatus.IDLE,
    getOMFreightTermError: null,
    getOMFreightTermData: null,

    addOMFreightTermStatus: asyncStatus.IDLE,
    addOMFreightTermError: null,
    addOMFreightTermData: null,

    editOMFreightTermStatus: asyncStatus.IDLE,
    editOMFreightTermError: null,
    editOMFreightTermData: null,

    deleteOMFreightTermStatus: asyncStatus.IDLE,
    deleteOMFreightTermError: null,
    deleteOMFreightTermData: null,
    // FreightTerm

    // OrderStatus
    getOMOrderStatusStatus: asyncStatus.IDLE,
    getOMOrderStatusError: null,
    getOMOrderStatusData: null,

    addOMOrderStatusStatus: asyncStatus.IDLE,
    addOMOrderStatusError: null,
    addOMOrderStatusData: null,

    editOMOrderStatusStatus: asyncStatus.IDLE,
    editOMOrderStatusError: null,
    editOMOrderStatusData: null,

    deleteOMOrderStatusStatus: asyncStatus.IDLE,
    deleteOMOrderStatusError: null,
    deleteOMOrderStatusData: null,
    // OrderStatus

    // Region
    getOMRegionStatus: asyncStatus.IDLE,
    getOMRegionError: null,
    getOMRegionData: null,

    addOMRegionStatus: asyncStatus.IDLE,
    addOMRegionError: null,
    addOMRegionData: null,

    editOMRegionStatus: asyncStatus.IDLE,
    editOMRegionError: null,
    editOMRegionData: null,

    deleteOMRegionStatus: asyncStatus.IDLE,
    deleteOMRegionError: null,
    deleteOMRegionData: null,
    // Region

    // CommitCriteria
    getOMCommitCriteriaStatus: asyncStatus.IDLE,
    getOMCommitCriteriaError: null,
    getOMCommitCriteriaData: null,

    addOMCommitCriteriaStatus: asyncStatus.IDLE,
    addOMCommitCriteriaError: null,
    addOMCommitCriteriaData: null,

    editOMCommitCriteriaStatus: asyncStatus.IDLE,
    editOMCommitCriteriaError: null,
    editOMCommitCriteriaData: null,

    deleteOMCommitCriteriaStatus: asyncStatus.IDLE,
    deleteOMCommitCriteriaError: null,
    deleteOMCommitCriteriaData: null,
    // CommitCriteria

    // ShipStatus
    getOMShipStatusStatus: asyncStatus.IDLE,
    getOMShipStatusError: null,
    getOMShipStatusData: null,

    addOMShipStatusStatus: asyncStatus.IDLE,
    addOMShipStatusError: null,
    addOMShipStatusData: null,

    editOMShipStatusStatus: asyncStatus.IDLE,
    editOMShipStatusError: null,
    editOMShipStatusData: null,

    deleteOMShipStatusStatus: asyncStatus.IDLE,
    deleteOMShipStatusError: null,
    deleteOMShipStatusData: null,
    // ShipStatus

    // CourierService
    getOMCourierServiceStatus: asyncStatus.IDLE,
    getOMCourierServiceError: null,
    getOMCourierServiceData: null,

    addOMCourierServiceStatus: asyncStatus.IDLE,
    addOMCourierServiceError: null,
    addOMCourierServiceData: null,

    editOMCourierServiceStatus: asyncStatus.IDLE,
    editOMCourierServiceError: null,
    editOMCourierServiceData: null,

    deleteOMCourierServiceStatus: asyncStatus.IDLE,
    deleteOMCourierServiceError: null,
    deleteOMCourierServiceData: null,
    // CourierService

    // ReturnReason
    getOMReturnReasonStatus: asyncStatus.IDLE,
    getOMReturnReasonError: null,
    getOMReturnReasonData: null,

    addOMReturnReasonStatus: asyncStatus.IDLE,
    addOMReturnReasonError: null,
    addOMReturnReasonData: null,

    editOMReturnReasonStatus: asyncStatus.IDLE,
    editOMReturnReasonError: null,
    editOMReturnReasonData: null,

    deleteOMReturnReasonStatus: asyncStatus.IDLE,
    deleteOMReturnReasonError: null,
    deleteOMReturnReasonData: null,
    // ReturnReason

    // ShipVia
    getOMShipViaStatus: asyncStatus.IDLE,
    getOMShipViaError: null,
    getOMShipViaData: null,

    addOMShipViaStatus: asyncStatus.IDLE,
    addOMShipViaError: null,
    addOMShipViaData: null,

    editOMShipViaStatus: asyncStatus.IDLE,
    editOMShipViaError: null,
    editOMShipViaData: null,

    deleteOMShipViaStatus: asyncStatus.IDLE,
    deleteOMShipViaError: null,
    deleteOMShipViaData: null,
    // ShipVia

    // PtStatus
    getOMPtStatusStatus: asyncStatus.IDLE,
    getOMPtStatusError: null,
    getOMPtStatusData: null,

    addOMPtStatusStatus: asyncStatus.IDLE,
    addOMPtStatusError: null,
    addOMPtStatusData: null,

    editOMPtStatusStatus: asyncStatus.IDLE,
    editOMPtStatusError: null,
    editOMPtStatusData: null,

    deleteOMPtStatusStatus: asyncStatus.IDLE,
    deleteOMPtStatusError: null,
    deleteOMPtStatusData: null,
    // PtStatus

    // ReturnType
    getOMReturnTypeStatus: asyncStatus.IDLE,
    getOMReturnTypeError: null,
    getOMReturnTypeData: null,

    addOMReturnTypeStatus: asyncStatus.IDLE,
    addOMReturnTypeError: null,
    addOMReturnTypeData: null,

    editOMReturnTypeStatus: asyncStatus.IDLE,
    editOMReturnTypeError: null,
    editOMReturnTypeData: null,

    deleteOMReturnTypeStatus: asyncStatus.IDLE,
    deleteOMReturnTypeError: null,
    deleteOMReturnTypeData: null,
    // ReturnType

    // Vendor
    addOMVendorStatus: asyncStatus.IDLE,
    addOMVendorError: null,
    addOMVendorData: null,

    searchOMVendorStatus: asyncStatus.IDLE,
    searchOMVendorError: null,
    searchOMVendorData: null,

    getVendorByIdStatus: asyncStatus.IDLE,
    getVendorByIdError: null,
    getVendorByIdData: null,

    editVendorByIdStatus: asyncStatus.IDLE,
    editVendorByIdError: null,
    editVendorByIdData: null,

    deleteVendorByIdStatus: asyncStatus.IDLE,
    deleteVendorByIdError: null,
    deleteVendorByIdData: null,
    // Vendor

    // SalesTarget
    getOMSalesTargetStatus: asyncStatus.IDLE,
    getOMSalesTargetError: null,
    getOMSalesTargetData: null,

    addOMSalesTargetStatus: asyncStatus.IDLE,
    addOMSalesTargetError: null,
    addOMSalesTargetData: null,

    editOMSalesTargetStatus: asyncStatus.IDLE,
    editOMSalesTargetError: null,
    editOMSalesTargetData: null,

    deleteOMSalesTargetStatus: asyncStatus.IDLE,
    deleteOMSalesTargetError: null,
    deleteOMSalesTargetData: null,
    // SalesTarget

    // Brand
    getOMBrandStatus: asyncStatus.IDLE,
    getOMBrandError: null,
    getOMBrandData: null,

    // Brand

    // Figure
    getOMFigureStatus: asyncStatus.IDLE,
    getOMFigureError: null,
    getOMFigureData: null,

    addOMFigureStatus: asyncStatus.IDLE,
    addOMFigureError: null,
    addOMFigureData: null,

    editOMFigureStatus: asyncStatus.IDLE,
    editOMFigureError: null,
    editOMFigureData: null,

    deleteOMFigureStatus: asyncStatus.IDLE,
    deleteOMFigureError: null,
    deleteOMFigureData: null,
    // Figure

    // CustomerCategory
    getOMCustomerCategoryStatus: asyncStatus.IDLE,
    getOMCustomerCategoryError: null,
    getOMCustomerCategoryData: null,

    addOMCustomerCategoryStatus: asyncStatus.IDLE,
    addOMCustomerCategoryError: null,
    addOMCustomerCategoryData: null,

    editOMCustomerCategoryStatus: asyncStatus.IDLE,
    editOMCustomerCategoryError: null,
    editOMCustomerCategoryData: null,

    deleteOMCustomerCategoryStatus: asyncStatus.IDLE,
    deleteOMCustomerCategoryError: null,
    deleteOMCustomerCategoryData: null,
    // CustomerCategory

    // CAT
    getOMCATStatus: asyncStatus.IDLE,
    getOMCATError: null,
    getOMCATData: null,

    addOMCATStatus: asyncStatus.IDLE,
    addOMCATError: null,
    addOMCATData: null,

    editOMCATStatus: asyncStatus.IDLE,
    editOMCATError: null,
    editOMCATData: null,

    deleteOMCATStatus: asyncStatus.IDLE,
    deleteOMCATError: null,
    deleteOMCATData: null,
    // CAT

    // BergenBilling
    getOMBergenBillingStatus: asyncStatus.IDLE,
    getOMBergenBillingError: null,
    getOMBergenBillingData: null,

    addOMBergenBillingStatus: asyncStatus.IDLE,
    addOMBergenBillingError: null,
    addOMBergenBillingData: null,

    editOMBergenBillingStatus: asyncStatus.IDLE,
    editOMBergenBillingError: null,
    editOMBergenBillingData: null,

    deleteOMBergenBillingStatus: asyncStatus.IDLE,
    deleteOMBergenBillingError: null,
    deleteOMBergenBillingData: null,
    // BergenBilling

    // SalesPerson
    getOMSalesPersonStatus: asyncStatus.IDLE,
    getOMSalesPersonError: null,
    getOMSalesPersonData: null,

    addOMSalesPersonStatus: asyncStatus.IDLE,
    addOMSalesPersonError: null,
    addOMSalesPersonData: null,

    editOMSalesPersonStatus: asyncStatus.IDLE,
    editOMSalesPersonError: null,
    editOMSalesPersonData: null,

    deleteOMSalesPersonStatus: asyncStatus.IDLE,
    deleteOMSalesPersonError: null,
    deleteOMSalesPersonData: null,
    // SalesPerson

    // Months
    getOMMonthsStatus: asyncStatus.IDLE,
    getOMMonthsError: null,
    getOMMonthsData: null,
    // Months

    // Years
    getOMYearsStatus: asyncStatus.IDLE,
    getOMYearsError: null,
    getOMYearsData: null,
    // Years

    // ShippingMethod
    getOMShippingMethodStatus: asyncStatus.IDLE,
    getOMShippingMethodError: null,
    getOMShippingMethodData: null,
    // ShippingMethod

    // OrderType
    getOMOrderTypeStatus: asyncStatus.IDLE,
    getOMOrderTypeError: null,
    getOMOrderTypeData: null,
    // OrderType

    // customer Information

    addOMCustomerInformationStatus: asyncStatus.IDLE,
    addOMCustomerInformationError: null,
    addOMCustomerInformationData: null,

    searchOMCustomerInformationStatus: asyncStatus.IDLE,
    searchOMCustomerInformationError: null,
    searchOMCustomerInformationData: null,

    getOMCustomerInformationStatusById: asyncStatus.IDLE,
    getOMCustomerInformationErrorById: null,
    getOMCustomerInformationDataById: null,

    editOMCustomerInformationStatus: asyncStatus.IDLE,
    editOMCustomerInformationError: null,
    editOMCustomerInformationData: null,

    deleteOMCustomerInformationStatus: asyncStatus.IDLE,
    deleteOMCustomerInformationError: null,
    deleteOMCustomerInformationData: null,

    // customer Information
  },
  reducers: {
    setEditVendorByIdEmpty(state, { payload }) {
      state.editVendorByIdStatus = asyncStatus.IDLE;
      state.editVendorByIdError = null;
      state.editVendorByIdData = null;
    },
  },
  extraReducers: (builder) => {
    // OM CITY

    builder.addCase(getOMCityAsync.pending, (state, action) => {
      state.getOMCityStatus = asyncStatus.LOADING;
      state.getOMCityError = null;
      state.getOMCityData = null;
    });
    builder.addCase(getOMCityAsync.fulfilled, (state, { payload }) => {
      state.getOMCityStatus = asyncStatus.SUCCEEDED;
      state.getOMCityData = payload.data;
      state.getOMCityError = null;
    });
    builder.addCase(getOMCityAsync.rejected, (state, actions) => {
      state.getOMCityData = null;
      state.getOMCityStatus = asyncStatus.ERROR;
      state.getOMCityError = actions?.error?.message;
    });

    builder.addCase(addOMCityAsync.pending, (state, action) => {
      state.addOMCityStatus = asyncStatus.LOADING;
      state.addOMCityError = null;
      state.addOMCityData = null;
    });
    builder.addCase(addOMCityAsync.fulfilled, (state, { payload }) => {
      state.addOMCityStatus = asyncStatus.SUCCEEDED;
      state.addOMCityData = payload.data;
      // let { getOMCityData } = state;
      state.getOMCityData = [...state.getOMCityData, payload.data];
      success_toast_message(payload.message);
      state.addOMCityError = null;
    });
    builder.addCase(addOMCityAsync.rejected, (state, actions) => {
      state.addOMCityData = null;
      state.addOMCityStatus = asyncStatus.ERROR;
      state.addOMCityError = actions?.error?.message;
    });

    builder.addCase(editOMCityAsync.pending, (state, action) => {
      state.editOMCityStatus = asyncStatus.LOADING;
      state.editOMCityError = null;
      state.getOMCityStatus = asyncStatus.LOADING;
      state.editOMCityData = null;
    });
    builder.addCase(editOMCityAsync.fulfilled, (state, { payload }) => {
      state.editOMCityStatus = asyncStatus.SUCCEEDED;
      state.getOMCityStatus = asyncStatus.SUCCEEDED;
      state.editOMCityData = payload.data;
      const { getOMCityData } = state;
      let updatedArray = [];
      getOMCityData?.map((e) => {
        var item = e;
        if (item?.city_id === payload?.data?.city_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMCityData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMCityError = null;
    });
    builder.addCase(editOMCityAsync.rejected, (state, actions) => {
      state.editOMCityData = null;
      state.editOMCityStatus = asyncStatus.ERROR;
      state.editOMCityError = actions?.error?.message;
    });

    builder.addCase(deleteOMCityAsync.pending, (state, action) => {
      state.deleteOMCityStatus = asyncStatus.LOADING;
      state.getOMCityStatus = asyncStatus.LOADING;
      state.deleteOMCityError = null;
      state.deleteOMCityData = null;
    });
    builder.addCase(deleteOMCityAsync.fulfilled, (state, { payload }) => {
      state.deleteOMCityStatus = asyncStatus.SUCCEEDED;
      state.getOMCityStatus = asyncStatus.SUCCEEDED;
      state.deleteOMCityData = payload.data;
      const { getOMCityData } = state;
      console.log("aaaaa", getOMCityData);
      let updatedArray = getOMCityData?.filter(
        (x) => x.city_id !== payload.data.city_id
      );
      state.getOMCityData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMCityError = null;
    });
    builder.addCase(deleteOMCityAsync.rejected, (state, actions) => {
      state.deleteOMCityData = null;
      state.deleteOMCityStatus = asyncStatus.ERROR;
      state.deleteOMCityError = actions?.error?.message;
    });

    // OM CITY

    // OM PAYMENT METHOD TYPE

    builder.addCase(getOMPaymentMethodTypeAsync.pending, (state, action) => {
      state.getOMPaymentMethodTypeStatus = asyncStatus.LOADING;
      state.getOMPaymentMethodTypeError = null;
      state.getOMPaymentMethodTypeData = null;
    });
    builder.addCase(
      getOMPaymentMethodTypeAsync.fulfilled,
      (state, { payload }) => {
        state.getOMPaymentMethodTypeStatus = asyncStatus.SUCCEEDED;
        state.getOMPaymentMethodTypeData = payload.data;
        state.getOMPaymentMethodTypeError = null;
      }
    );
    builder.addCase(getOMPaymentMethodTypeAsync.rejected, (state, actions) => {
      state.getOMPaymentMethodTypeData = null;
      state.getOMPaymentMethodTypeStatus = asyncStatus.ERROR;
      state.getOMPaymentMethodTypeError = actions?.error?.message;
    });

    builder.addCase(addOMPaymentMethodTypeAsync.pending, (state, action) => {
      state.addOMPaymentMethodTypeStatus = asyncStatus.LOADING;
      state.addOMPaymentMethodTypeError = null;
      state.addOMPaymentMethodTypeData = null;
    });
    builder.addCase(
      addOMPaymentMethodTypeAsync.fulfilled,
      (state, { payload }) => {
        state.addOMPaymentMethodTypeStatus = asyncStatus.SUCCEEDED;
        state.addOMPaymentMethodTypeData = payload.data;
        // let { getOMPaymentMethodTypeData } = state;
        state.getOMPaymentMethodTypeData = [
          ...state.getOMPaymentMethodTypeData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addOMPaymentMethodTypeError = null;
      }
    );
    builder.addCase(addOMPaymentMethodTypeAsync.rejected, (state, actions) => {
      state.addOMPaymentMethodTypeData = null;
      state.addOMPaymentMethodTypeStatus = asyncStatus.ERROR;
      state.addOMPaymentMethodTypeError = actions?.error?.message;
    });

    builder.addCase(editOMPaymentMethodTypeAsync.pending, (state, action) => {
      state.editOMPaymentMethodTypeStatus = asyncStatus.LOADING;
      state.editOMPaymentMethodTypeError = null;
      state.getOMPaymentMethodTypeStatus = asyncStatus.LOADING;
      state.editOMPaymentMethodTypeData = null;
    });
    builder.addCase(
      editOMPaymentMethodTypeAsync.fulfilled,
      (state, { payload }) => {
        state.editOMPaymentMethodTypeStatus = asyncStatus.SUCCEEDED;
        state.getOMPaymentMethodTypeStatus = asyncStatus.SUCCEEDED;
        state.editOMPaymentMethodTypeData = payload.data;
        const { getOMPaymentMethodTypeData } = state;
        let updatedArray = [];
        getOMPaymentMethodTypeData?.map((e) => {
          var item = e;
          if (item?.method_type_id === payload?.data?.method_type_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMPaymentMethodTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMPaymentMethodTypeError = null;
      }
    );
    builder.addCase(editOMPaymentMethodTypeAsync.rejected, (state, actions) => {
      state.editOMPaymentMethodTypeData = null;
      state.editOMPaymentMethodTypeStatus = asyncStatus.ERROR;
      state.editOMPaymentMethodTypeError = actions?.error?.message;
    });

    builder.addCase(deleteOMPaymentMethodTypeAsync.pending, (state, action) => {
      state.deleteOMPaymentMethodTypeStatus = asyncStatus.LOADING;
      state.getOMPaymentMethodTypeStatus = asyncStatus.LOADING;
      state.deleteOMPaymentMethodTypeError = null;
      state.deleteOMPaymentMethodTypeData = null;
    });
    builder.addCase(
      deleteOMPaymentMethodTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMPaymentMethodTypeStatus = asyncStatus.SUCCEEDED;
        state.getOMPaymentMethodTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteOMPaymentMethodTypeData = payload.data;
        const { getOMPaymentMethodTypeData } = state;
        console.log("aaaaa", getOMPaymentMethodTypeData);
        let updatedArray = getOMPaymentMethodTypeData?.filter(
          (x) => x.method_type_id !== payload.data.method_type_id
        );
        state.getOMPaymentMethodTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMPaymentMethodTypeError = null;
      }
    );
    builder.addCase(
      deleteOMPaymentMethodTypeAsync.rejected,
      (state, actions) => {
        state.deleteOMPaymentMethodTypeData = null;
        state.deleteOMPaymentMethodTypeStatus = asyncStatus.ERROR;
        state.deleteOMPaymentMethodTypeError = actions?.error?.message;
      }
    );

    // OM PAYMENT METHOD TYPE

    // OM ADMIN JOB

    builder.addCase(getOMAdminJobAsync.pending, (state, action) => {
      state.getOMAdminJobStatus = asyncStatus.LOADING;
      state.getOMAdminJobError = null;
      state.getOMAdminJobData = null;
    });
    builder.addCase(getOMAdminJobAsync.fulfilled, (state, { payload }) => {
      state.getOMAdminJobStatus = asyncStatus.SUCCEEDED;
      state.getOMAdminJobData = payload.data;
      state.getOMAdminJobError = null;
    });
    builder.addCase(getOMAdminJobAsync.rejected, (state, actions) => {
      state.getOMAdminJobData = null;
      state.getOMAdminJobStatus = asyncStatus.ERROR;
      state.getOMAdminJobError = actions?.error?.message;
    });

    builder.addCase(addOMAdminJobAsync.pending, (state, action) => {
      state.addOMAdminJobStatus = asyncStatus.LOADING;
      state.addOMAdminJobError = null;
      state.addOMAdminJobData = null;
    });
    builder.addCase(addOMAdminJobAsync.fulfilled, (state, { payload }) => {
      state.addOMAdminJobStatus = asyncStatus.SUCCEEDED;
      state.addOMAdminJobData = payload.data;
      // let { getOMAdminJobData } = state;
      state.getOMAdminJobData = [...state.getOMAdminJobData, payload.data];
      success_toast_message(payload.message);
      state.addOMAdminJobError = null;
    });
    builder.addCase(addOMAdminJobAsync.rejected, (state, actions) => {
      state.addOMAdminJobData = null;
      state.addOMAdminJobStatus = asyncStatus.ERROR;
      state.addOMAdminJobError = actions?.error?.message;
    });

    builder.addCase(editOMAdminJobAsync.pending, (state, action) => {
      state.editOMAdminJobStatus = asyncStatus.LOADING;
      state.editOMAdminJobError = null;
      state.getOMAdminJobStatus = asyncStatus.LOADING;
      state.editOMAdminJobData = null;
    });
    builder.addCase(editOMAdminJobAsync.fulfilled, (state, { payload }) => {
      state.editOMAdminJobStatus = asyncStatus.SUCCEEDED;
      state.getOMAdminJobStatus = asyncStatus.SUCCEEDED;
      state.editOMAdminJobData = payload.data;
      const { getOMAdminJobData } = state;
      let updatedArray = [];
      getOMAdminJobData?.map((e) => {
        var item = e;
        if (item?.job_id === payload?.data?.job_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMAdminJobData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMAdminJobError = null;
    });
    builder.addCase(editOMAdminJobAsync.rejected, (state, actions) => {
      state.editOMAdminJobData = null;
      state.editOMAdminJobStatus = asyncStatus.ERROR;
      state.editOMAdminJobError = actions?.error?.message;
    });

    builder.addCase(deleteOMAdminJobAsync.pending, (state, action) => {
      state.deleteOMAdminJobStatus = asyncStatus.LOADING;
      state.getOMAdminJobStatus = asyncStatus.LOADING;
      state.deleteOMAdminJobError = null;
      state.deleteOMAdminJobData = null;
    });
    builder.addCase(deleteOMAdminJobAsync.fulfilled, (state, { payload }) => {
      state.deleteOMAdminJobStatus = asyncStatus.SUCCEEDED;
      state.getOMAdminJobStatus = asyncStatus.SUCCEEDED;
      state.deleteOMAdminJobData = payload.data;
      const { getOMAdminJobData } = state;
      console.log("aaaaa", getOMAdminJobData);
      let updatedArray = getOMAdminJobData?.filter(
        (x) => x.job_id !== payload.data.job_id
      );
      state.getOMAdminJobData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMAdminJobError = null;
    });
    builder.addCase(deleteOMAdminJobAsync.rejected, (state, actions) => {
      state.deleteOMAdminJobData = null;
      state.deleteOMAdminJobStatus = asyncStatus.ERROR;
      state.deleteOMAdminJobError = actions?.error?.message;
    });

    // OM ADMIN JOB

    // OM Term For Print

    builder.addCase(getOMTermForPrintAsync.pending, (state, action) => {
      state.getOMTermForPrintStatus = asyncStatus.LOADING;
      state.getOMTermForPrintError = null;
      state.getOMTermForPrintData = null;
    });
    builder.addCase(getOMTermForPrintAsync.fulfilled, (state, { payload }) => {
      state.getOMTermForPrintStatus = asyncStatus.SUCCEEDED;
      state.getOMTermForPrintData = payload.data;
      state.getOMTermForPrintError = null;
    });
    builder.addCase(getOMTermForPrintAsync.rejected, (state, actions) => {
      state.getOMTermForPrintData = null;
      state.getOMTermForPrintStatus = asyncStatus.ERROR;
      state.getOMTermForPrintError = actions?.error?.message;
    });

    builder.addCase(addOMTermForPrintAsync.pending, (state, action) => {
      state.addOMTermForPrintStatus = asyncStatus.LOADING;
      state.addOMTermForPrintError = null;
      state.addOMTermForPrintData = null;
    });
    builder.addCase(addOMTermForPrintAsync.fulfilled, (state, { payload }) => {
      state.addOMTermForPrintStatus = asyncStatus.SUCCEEDED;
      state.addOMTermForPrintData = payload.data;
      // let { getOMTermForPrintData } = state;
      state.getOMTermForPrintData = [
        ...state.getOMTermForPrintData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMTermForPrintError = null;
    });
    builder.addCase(addOMTermForPrintAsync.rejected, (state, actions) => {
      state.addOMTermForPrintData = null;
      state.addOMTermForPrintStatus = asyncStatus.ERROR;
      state.addOMTermForPrintError = actions?.error?.message;
    });

    builder.addCase(editOMTermForPrintAsync.pending, (state, action) => {
      state.editOMTermForPrintStatus = asyncStatus.LOADING;
      state.editOMTermForPrintError = null;
      state.getOMTermForPrintStatus = asyncStatus.LOADING;
      state.editOMTermForPrintData = null;
    });
    builder.addCase(editOMTermForPrintAsync.fulfilled, (state, { payload }) => {
      state.editOMTermForPrintStatus = asyncStatus.SUCCEEDED;
      state.getOMTermForPrintStatus = asyncStatus.SUCCEEDED;
      state.editOMTermForPrintData = payload.data;
      const { getOMTermForPrintData } = state;
      let updatedArray = [];
      getOMTermForPrintData?.map((e) => {
        var item = e;
        if (item?.term_id === payload?.data?.term_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMTermForPrintData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMTermForPrintError = null;
    });
    builder.addCase(editOMTermForPrintAsync.rejected, (state, actions) => {
      state.editOMTermForPrintData = null;
      state.editOMTermForPrintStatus = asyncStatus.ERROR;
      state.editOMTermForPrintError = actions?.error?.message;
    });

    builder.addCase(deleteOMTermForPrintAsync.pending, (state, action) => {
      state.deleteOMTermForPrintStatus = asyncStatus.LOADING;
      state.getOMTermForPrintStatus = asyncStatus.LOADING;
      state.deleteOMTermForPrintError = null;
      state.deleteOMTermForPrintData = null;
    });
    builder.addCase(
      deleteOMTermForPrintAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMTermForPrintStatus = asyncStatus.SUCCEEDED;
        state.getOMTermForPrintStatus = asyncStatus.SUCCEEDED;
        state.deleteOMTermForPrintData = payload.data;
        const { getOMTermForPrintData } = state;
        let updatedArray = getOMTermForPrintData?.filter(
          (x) => x.term_id !== payload.data.term_id
        );
        state.getOMTermForPrintData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMTermForPrintError = null;
      }
    );
    builder.addCase(deleteOMTermForPrintAsync.rejected, (state, actions) => {
      state.deleteOMTermForPrintData = null;
      state.deleteOMTermForPrintStatus = asyncStatus.ERROR;
      state.deleteOMTermForPrintError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Term For Print

    // OM Admin Discount

    builder.addCase(getOMAdminDiscountAsync.pending, (state, action) => {
      state.getOMAdminDiscountStatus = asyncStatus.LOADING;
      state.getOMAdminDiscountError = null;
      state.getOMAdminDiscountData = null;
    });
    builder.addCase(getOMAdminDiscountAsync.fulfilled, (state, { payload }) => {
      state.getOMAdminDiscountStatus = asyncStatus.SUCCEEDED;
      state.getOMAdminDiscountData = payload.data;
      state.getOMAdminDiscountError = null;
    });
    builder.addCase(getOMAdminDiscountAsync.rejected, (state, actions) => {
      state.getOMAdminDiscountData = null;
      state.getOMAdminDiscountStatus = asyncStatus.ERROR;
      state.getOMAdminDiscountError = actions?.error?.message;
    });

    builder.addCase(addOMAdminDiscountAsync.pending, (state, action) => {
      state.addOMAdminDiscountStatus = asyncStatus.LOADING;
      state.addOMAdminDiscountError = null;
      state.addOMAdminDiscountData = null;
    });
    builder.addCase(addOMAdminDiscountAsync.fulfilled, (state, { payload }) => {
      state.addOMAdminDiscountStatus = asyncStatus.SUCCEEDED;
      state.addOMAdminDiscountData = payload.data;
      // let { getOMAdminDiscountData } = state;
      state.getOMAdminDiscountData = [
        ...state.getOMAdminDiscountData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMAdminDiscountError = null;
    });
    builder.addCase(addOMAdminDiscountAsync.rejected, (state, actions) => {
      state.addOMAdminDiscountData = null;
      state.addOMAdminDiscountStatus = asyncStatus.ERROR;
      state.addOMAdminDiscountError = actions?.error?.message;
    });

    builder.addCase(editOMAdminDiscountAsync.pending, (state, action) => {
      state.editOMAdminDiscountStatus = asyncStatus.LOADING;
      state.editOMAdminDiscountError = null;
      state.getOMAdminDiscountStatus = asyncStatus.LOADING;
      state.editOMAdminDiscountData = null;
    });
    builder.addCase(
      editOMAdminDiscountAsync.fulfilled,
      (state, { payload }) => {
        state.editOMAdminDiscountStatus = asyncStatus.SUCCEEDED;
        state.getOMAdminDiscountStatus = asyncStatus.SUCCEEDED;
        state.editOMAdminDiscountData = payload.data;
        const { getOMAdminDiscountData } = state;
        let updatedArray = [];
        getOMAdminDiscountData?.map((e) => {
          var item = e;
          if (item?.discount_id === payload?.data?.discount_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMAdminDiscountData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMAdminDiscountError = null;
      }
    );
    builder.addCase(editOMAdminDiscountAsync.rejected, (state, actions) => {
      state.editOMAdminDiscountData = null;
      state.editOMAdminDiscountStatus = asyncStatus.ERROR;
      state.editOMAdminDiscountError = actions?.error?.message;
    });

    builder.addCase(deleteOMAdminDiscountAsync.pending, (state, action) => {
      state.deleteOMAdminDiscountStatus = asyncStatus.LOADING;
      state.getOMAdminDiscountStatus = asyncStatus.LOADING;
      state.deleteOMAdminDiscountError = null;
      state.deleteOMAdminDiscountData = null;
    });
    builder.addCase(
      deleteOMAdminDiscountAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMAdminDiscountStatus = asyncStatus.SUCCEEDED;
        state.getOMAdminDiscountStatus = asyncStatus.SUCCEEDED;
        state.deleteOMAdminDiscountData = payload.data;
        const { getOMAdminDiscountData } = state;
        let updatedArray = getOMAdminDiscountData?.filter(
          (x) => x.discount_id !== payload.data.discount_id
        );
        state.getOMAdminDiscountData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMAdminDiscountError = null;
      }
    );
    builder.addCase(deleteOMAdminDiscountAsync.rejected, (state, actions) => {
      state.deleteOMAdminDiscountData = null;
      state.deleteOMAdminDiscountStatus = asyncStatus.ERROR;
      state.deleteOMAdminDiscountError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Admin Discount

    // OM Admin Currency

    builder.addCase(getOMAdminCurrencyAsync.pending, (state, action) => {
      state.getOMAdminCurrencyStatus = asyncStatus.LOADING;
      state.getOMAdminCurrencyError = null;
      state.getOMAdminCurrencyData = null;
    });
    builder.addCase(getOMAdminCurrencyAsync.fulfilled, (state, { payload }) => {
      state.getOMAdminCurrencyStatus = asyncStatus.SUCCEEDED;
      state.getOMAdminCurrencyData = payload.data;
      state.getOMAdminCurrencyError = null;
    });
    builder.addCase(getOMAdminCurrencyAsync.rejected, (state, actions) => {
      state.getOMAdminCurrencyData = null;
      state.getOMAdminCurrencyStatus = asyncStatus.ERROR;
      state.getOMAdminCurrencyError = actions?.error?.message;
    });

    builder.addCase(addOMAdminCurrencyAsync.pending, (state, action) => {
      state.addOMAdminCurrencyStatus = asyncStatus.LOADING;
      state.addOMAdminCurrencyError = null;
      state.addOMAdminCurrencyData = null;
    });
    builder.addCase(addOMAdminCurrencyAsync.fulfilled, (state, { payload }) => {
      state.addOMAdminCurrencyStatus = asyncStatus.SUCCEEDED;
      state.addOMAdminCurrencyData = payload.data;
      // let { getOMAdminCurrencyData } = state;
      state.getOMAdminCurrencyData = [
        ...state.getOMAdminCurrencyData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMAdminCurrencyError = null;
    });
    builder.addCase(addOMAdminCurrencyAsync.rejected, (state, actions) => {
      state.addOMAdminCurrencyData = null;
      state.addOMAdminCurrencyStatus = asyncStatus.ERROR;
      state.addOMAdminCurrencyError = actions?.error?.message;
    });

    builder.addCase(editOMAdminCurrencyAsync.pending, (state, action) => {
      state.editOMAdminCurrencyStatus = asyncStatus.LOADING;
      state.editOMAdminCurrencyError = null;
      state.getOMAdminCurrencyStatus = asyncStatus.LOADING;
      state.editOMAdminCurrencyData = null;
    });
    builder.addCase(
      editOMAdminCurrencyAsync.fulfilled,
      (state, { payload }) => {
        state.editOMAdminCurrencyStatus = asyncStatus.SUCCEEDED;
        state.getOMAdminCurrencyStatus = asyncStatus.SUCCEEDED;
        state.editOMAdminCurrencyData = payload.data;
        const { getOMAdminCurrencyData } = state;
        let updatedArray = [];
        getOMAdminCurrencyData?.map((e) => {
          var item = e;
          if (item?.currency_id === payload?.data?.currency_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMAdminCurrencyData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMAdminCurrencyError = null;
      }
    );
    builder.addCase(editOMAdminCurrencyAsync.rejected, (state, actions) => {
      state.editOMAdminCurrencyData = null;
      state.editOMAdminCurrencyStatus = asyncStatus.ERROR;
      state.editOMAdminCurrencyError = actions?.error?.message;
    });

    builder.addCase(deleteOMAdminCurrencyAsync.pending, (state, action) => {
      state.deleteOMAdminCurrencyStatus = asyncStatus.LOADING;
      state.getOMAdminCurrencyStatus = asyncStatus.LOADING;
      state.deleteOMAdminCurrencyError = null;
      state.deleteOMAdminCurrencyData = null;
    });
    builder.addCase(
      deleteOMAdminCurrencyAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMAdminCurrencyStatus = asyncStatus.SUCCEEDED;
        state.getOMAdminCurrencyStatus = asyncStatus.SUCCEEDED;
        state.deleteOMAdminCurrencyData = payload.data;
        const { getOMAdminCurrencyData } = state;
        let updatedArray = getOMAdminCurrencyData?.filter(
          (x) => x.currency_id !== payload.data.currency_id
        );
        state.getOMAdminCurrencyData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMAdminCurrencyError = null;
      }
    );
    builder.addCase(deleteOMAdminCurrencyAsync.rejected, (state, actions) => {
      state.deleteOMAdminCurrencyData = null;
      state.deleteOMAdminCurrencyStatus = asyncStatus.ERROR;
      state.deleteOMAdminCurrencyError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Admin Currency

    // OM State

    builder.addCase(getOMStateAsync.pending, (state, action) => {
      state.getOMStateStatus = asyncStatus.LOADING;
      state.getOMStateError = null;
      state.getOMStateData = null;
    });
    builder.addCase(getOMStateAsync.fulfilled, (state, { payload }) => {
      state.getOMStateStatus = asyncStatus.SUCCEEDED;
      state.getOMStateData = payload.data;
      state.getOMStateError = null;
    });
    builder.addCase(getOMStateAsync.rejected, (state, actions) => {
      state.getOMStateData = null;
      state.getOMStateStatus = asyncStatus.ERROR;
      state.getOMStateError = actions?.error?.message;
    });

    builder.addCase(addOMStateAsync.pending, (state, action) => {
      state.addOMStateStatus = asyncStatus.LOADING;
      state.addOMStateError = null;
      state.addOMStateData = null;
    });
    builder.addCase(addOMStateAsync.fulfilled, (state, { payload }) => {
      state.addOMStateStatus = asyncStatus.SUCCEEDED;
      state.addOMStateData = payload.data;
      // let { getOMStateData } = state;
      state.getOMStateData = [...state.getOMStateData, payload.data];
      success_toast_message(payload.message);
      state.addOMStateError = null;
    });
    builder.addCase(addOMStateAsync.rejected, (state, actions) => {
      state.addOMStateData = null;
      state.addOMStateStatus = asyncStatus.ERROR;
      state.addOMStateError = actions?.error?.message;
    });

    builder.addCase(editOMStateAsync.pending, (state, action) => {
      state.editOMStateStatus = asyncStatus.LOADING;
      state.editOMStateError = null;
      state.getOMStateStatus = asyncStatus.LOADING;
      state.editOMStateData = null;
    });
    builder.addCase(editOMStateAsync.fulfilled, (state, { payload }) => {
      state.editOMStateStatus = asyncStatus.SUCCEEDED;
      state.getOMStateStatus = asyncStatus.SUCCEEDED;
      state.editOMStateData = payload.data;
      const { getOMStateData } = state;
      let updatedArray = [];
      getOMStateData?.map((e) => {
        var item = e;
        if (item?.state_id === payload?.data?.state_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMStateData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMStateError = null;
    });
    builder.addCase(editOMStateAsync.rejected, (state, actions) => {
      state.editOMStateData = null;
      state.editOMStateStatus = asyncStatus.ERROR;
      state.editOMStateError = actions?.error?.message;
    });

    builder.addCase(deleteOMStateAsync.pending, (state, action) => {
      state.deleteOMStateStatus = asyncStatus.LOADING;
      state.getOMStateStatus = asyncStatus.LOADING;
      state.deleteOMStateError = null;
      state.deleteOMStateData = null;
    });
    builder.addCase(deleteOMStateAsync.fulfilled, (state, { payload }) => {
      state.deleteOMStateStatus = asyncStatus.SUCCEEDED;
      state.getOMStateStatus = asyncStatus.SUCCEEDED;
      state.deleteOMStateData = payload.data;
      const { getOMStateData } = state;
      let updatedArray = getOMStateData?.filter(
        (x) => x.state_id !== payload.data.state_id
      );
      state.getOMStateData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMStateError = null;
    });
    builder.addCase(deleteOMStateAsync.rejected, (state, actions) => {
      state.deleteOMStateData = null;
      state.deleteOMStateStatus = asyncStatus.ERROR;
      state.deleteOMStateError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM State

    // OM Factor

    builder.addCase(getOMFactorAsync.pending, (state, action) => {
      state.getOMFactorStatus = asyncStatus.LOADING;
      state.getOMFactorError = null;
      state.getOMFactorData = null;
    });
    builder.addCase(getOMFactorAsync.fulfilled, (state, { payload }) => {
      state.getOMFactorStatus = asyncStatus.SUCCEEDED;
      state.getOMFactorData = payload.data;
      state.getOMFactorError = null;
    });
    builder.addCase(getOMFactorAsync.rejected, (state, actions) => {
      state.getOMFactorData = null;
      state.getOMFactorStatus = asyncStatus.ERROR;
      state.getOMFactorError = actions?.error?.message;
    });

    builder.addCase(addOMFactorAsync.pending, (state, action) => {
      state.addOMFactorStatus = asyncStatus.LOADING;
      state.addOMFactorError = null;
      state.addOMFactorData = null;
    });
    builder.addCase(addOMFactorAsync.fulfilled, (state, { payload }) => {
      state.addOMFactorStatus = asyncStatus.SUCCEEDED;
      state.addOMFactorData = payload.data;
      // let { getOMFactorData } = state;
      state.getOMFactorData = [...state.getOMFactorData, payload.data];
      success_toast_message(payload.message);
      state.addOMFactorError = null;
    });
    builder.addCase(addOMFactorAsync.rejected, (state, actions) => {
      state.addOMFactorData = null;
      state.addOMFactorStatus = asyncStatus.ERROR;
      state.addOMFactorError = actions?.error?.message;
    });

    builder.addCase(editOMFactorAsync.pending, (state, action) => {
      state.editOMFactorStatus = asyncStatus.LOADING;
      state.editOMFactorError = null;
      state.getOMFactorStatus = asyncStatus.LOADING;
      state.editOMFactorData = null;
    });
    builder.addCase(editOMFactorAsync.fulfilled, (state, { payload }) => {
      state.editOMFactorStatus = asyncStatus.SUCCEEDED;
      state.getOMFactorStatus = asyncStatus.SUCCEEDED;
      state.editOMFactorData = payload.data;
      const { getOMFactorData } = state;
      let updatedArray = [];
      getOMFactorData?.map((e) => {
        var item = e;
        if (item?.factor_id === payload?.data?.factor_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMFactorData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMFactorError = null;
    });
    builder.addCase(editOMFactorAsync.rejected, (state, actions) => {
      state.editOMFactorData = null;
      state.editOMFactorStatus = asyncStatus.ERROR;
      state.editOMFactorError = actions?.error?.message;
    });

    builder.addCase(deleteOMFactorAsync.pending, (state, action) => {
      state.deleteOMFactorStatus = asyncStatus.LOADING;
      state.getOMFactorStatus = asyncStatus.LOADING;
      state.deleteOMFactorError = null;
      state.deleteOMFactorData = null;
    });
    builder.addCase(deleteOMFactorAsync.fulfilled, (state, { payload }) => {
      state.deleteOMFactorStatus = asyncStatus.SUCCEEDED;
      state.getOMFactorStatus = asyncStatus.SUCCEEDED;
      state.deleteOMFactorData = payload.data;
      const { getOMFactorData } = state;
      let updatedArray = getOMFactorData?.filter(
        (x) => x.factor_id !== payload.data.factor_id
      );
      state.getOMFactorData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMFactorError = null;
    });
    builder.addCase(deleteOMFactorAsync.rejected, (state, actions) => {
      state.deleteOMFactorData = null;
      state.deleteOMFactorStatus = asyncStatus.ERROR;
      state.deleteOMFactorError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Factor

    // OM Customer Type

    builder.addCase(getOMCustomerTypeAsync.pending, (state, action) => {
      state.getOMCustomerTypeStatus = asyncStatus.LOADING;
      state.getOMCustomerTypeError = null;
      state.getOMCustomerTypeData = null;
    });
    builder.addCase(getOMCustomerTypeAsync.fulfilled, (state, { payload }) => {
      state.getOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMCustomerTypeData = payload.data;
      state.getOMCustomerTypeError = null;
    });
    builder.addCase(getOMCustomerTypeAsync.rejected, (state, actions) => {
      state.getOMCustomerTypeData = null;
      state.getOMCustomerTypeStatus = asyncStatus.ERROR;
      state.getOMCustomerTypeError = actions?.error?.message;
    });

    builder.addCase(addOMCustomerTypeAsync.pending, (state, action) => {
      state.addOMCustomerTypeStatus = asyncStatus.LOADING;
      state.addOMCustomerTypeError = null;
      state.addOMCustomerTypeData = null;
    });
    builder.addCase(addOMCustomerTypeAsync.fulfilled, (state, { payload }) => {
      state.addOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
      state.addOMCustomerTypeData = payload.data;
      // let { getOMCustomerTypeData } = state;
      state.getOMCustomerTypeData = [
        ...state.getOMCustomerTypeData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMCustomerTypeError = null;
    });
    builder.addCase(addOMCustomerTypeAsync.rejected, (state, actions) => {
      state.addOMCustomerTypeData = null;
      state.addOMCustomerTypeStatus = asyncStatus.ERROR;
      state.addOMCustomerTypeError = actions?.error?.message;
    });

    builder.addCase(editOMCustomerTypeAsync.pending, (state, action) => {
      state.editOMCustomerTypeStatus = asyncStatus.LOADING;
      state.editOMCustomerTypeError = null;
      state.getOMCustomerTypeStatus = asyncStatus.LOADING;
      state.editOMCustomerTypeData = null;
    });
    builder.addCase(editOMCustomerTypeAsync.fulfilled, (state, { payload }) => {
      state.editOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
      state.editOMCustomerTypeData = payload.data;
      const { getOMCustomerTypeData } = state;
      let updatedArray = [];
      getOMCustomerTypeData?.map((e) => {
        var item = e;
        if (item?.customer_type_id === payload?.data?.customer_type_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMCustomerTypeData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMCustomerTypeError = null;
    });
    builder.addCase(editOMCustomerTypeAsync.rejected, (state, actions) => {
      state.editOMCustomerTypeData = null;
      state.editOMCustomerTypeStatus = asyncStatus.ERROR;
      state.editOMCustomerTypeError = actions?.error?.message;
    });

    builder.addCase(deleteOMCustomerTypeAsync.pending, (state, action) => {
      state.deleteOMCustomerTypeStatus = asyncStatus.LOADING;
      state.getOMCustomerTypeStatus = asyncStatus.LOADING;
      state.deleteOMCustomerTypeError = null;
      state.deleteOMCustomerTypeData = null;
    });
    builder.addCase(
      deleteOMCustomerTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
        state.getOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteOMCustomerTypeData = payload.data;
        const { getOMCustomerTypeData } = state;
        let updatedArray = getOMCustomerTypeData?.filter(
          (x) => x.customer_type_id !== payload.data.customer_type_id
        );
        state.getOMCustomerTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMCustomerTypeError = null;
      }
    );
    builder.addCase(deleteOMCustomerTypeAsync.rejected, (state, actions) => {
      state.deleteOMCustomerTypeData = null;
      state.deleteOMCustomerTypeStatus = asyncStatus.ERROR;
      state.deleteOMCustomerTypeError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Customer Type

    // OM Country

    builder.addCase(getOMCountryAsync.pending, (state, action) => {
      state.getOMCountryStatus = asyncStatus.LOADING;
      state.getOMCountryError = null;
      state.getOMCountryData = null;
    });
    builder.addCase(getOMCountryAsync.fulfilled, (state, { payload }) => {
      state.getOMCountryStatus = asyncStatus.SUCCEEDED;
      state.getOMCountryData = payload.data;
      state.getOMCountryError = null;
    });
    builder.addCase(getOMCountryAsync.rejected, (state, actions) => {
      state.getOMCountryData = null;
      state.getOMCountryStatus = asyncStatus.ERROR;
      state.getOMCountryError = actions?.error?.message;
    });

    builder.addCase(addOMCountryAsync.pending, (state, action) => {
      state.addOMCountryStatus = asyncStatus.LOADING;
      state.addOMCountryError = null;
      state.addOMCountryData = null;
    });
    builder.addCase(addOMCountryAsync.fulfilled, (state, { payload }) => {
      state.addOMCountryStatus = asyncStatus.SUCCEEDED;
      state.addOMCountryData = payload.data;
      // let { getOMCountryData } = state;
      state.getOMCountryData = [...state.getOMCountryData, payload.data];
      success_toast_message(payload.message);
      state.addOMCountryError = null;
    });
    builder.addCase(addOMCountryAsync.rejected, (state, actions) => {
      state.addOMCountryData = null;
      state.addOMCountryStatus = asyncStatus.ERROR;
      state.addOMCountryError = actions?.error?.message;
    });

    builder.addCase(editOMCountryAsync.pending, (state, action) => {
      state.editOMCountryStatus = asyncStatus.LOADING;
      state.editOMCountryError = null;
      state.getOMCountryStatus = asyncStatus.LOADING;
      state.editOMCountryData = null;
    });
    builder.addCase(editOMCountryAsync.fulfilled, (state, { payload }) => {
      state.editOMCountryStatus = asyncStatus.SUCCEEDED;
      state.getOMCountryStatus = asyncStatus.SUCCEEDED;
      state.editOMCountryData = payload.data;
      const { getOMCountryData } = state;
      let updatedArray = [];
      getOMCountryData?.map((e) => {
        var item = e;
        if (item?.country_id === payload?.data?.country_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMCountryData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMCountryError = null;
    });
    builder.addCase(editOMCountryAsync.rejected, (state, actions) => {
      state.editOMCountryData = null;
      state.editOMCountryStatus = asyncStatus.ERROR;
      state.editOMCountryError = actions?.error?.message;
    });

    builder.addCase(deleteOMCountryAsync.pending, (state, action) => {
      state.deleteOMCountryStatus = asyncStatus.LOADING;
      state.getOMCountryStatus = asyncStatus.LOADING;
      state.deleteOMCountryError = null;
      state.deleteOMCountryData = null;
    });
    builder.addCase(deleteOMCountryAsync.fulfilled, (state, { payload }) => {
      state.deleteOMCountryStatus = asyncStatus.SUCCEEDED;
      state.getOMCountryStatus = asyncStatus.SUCCEEDED;
      state.deleteOMCountryData = payload.data;
      const { getOMCountryData } = state;
      let updatedArray = getOMCountryData?.filter(
        (x) => x.country_id !== payload.data.country_id
      );
      state.getOMCountryData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMCountryError = null;
    });
    builder.addCase(deleteOMCountryAsync.rejected, (state, actions) => {
      state.deleteOMCountryData = null;
      state.deleteOMCountryStatus = asyncStatus.ERROR;
      state.deleteOMCountryError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Country

    // OM Payment Type

    builder.addCase(getOMPaymentTypeAsync.pending, (state, action) => {
      state.getOMPaymentTypeStatus = asyncStatus.LOADING;
      state.getOMPaymentTypeError = null;
      state.getOMPaymentTypeData = null;
    });
    builder.addCase(getOMPaymentTypeAsync.fulfilled, (state, { payload }) => {
      state.getOMPaymentTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMPaymentTypeData = payload.data;
      state.getOMPaymentTypeError = null;
    });
    builder.addCase(getOMPaymentTypeAsync.rejected, (state, actions) => {
      state.getOMPaymentTypeData = null;
      state.getOMPaymentTypeStatus = asyncStatus.ERROR;
      state.getOMPaymentTypeError = actions?.error?.message;
    });

    builder.addCase(addOMPaymentTypeAsync.pending, (state, action) => {
      state.addOMPaymentTypeStatus = asyncStatus.LOADING;
      state.addOMPaymentTypeError = null;
      state.addOMPaymentTypeData = null;
    });
    builder.addCase(addOMPaymentTypeAsync.fulfilled, (state, { payload }) => {
      state.addOMPaymentTypeStatus = asyncStatus.SUCCEEDED;
      state.addOMPaymentTypeData = payload.data;
      // let { getOMPaymentTypeData } = state;
      state.getOMPaymentTypeData = [
        ...state.getOMPaymentTypeData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMPaymentTypeError = null;
    });
    builder.addCase(addOMPaymentTypeAsync.rejected, (state, actions) => {
      state.addOMPaymentTypeData = null;
      state.addOMPaymentTypeStatus = asyncStatus.ERROR;
      state.addOMPaymentTypeError = actions?.error?.message;
    });

    builder.addCase(editOMPaymentTypeAsync.pending, (state, action) => {
      state.editOMPaymentTypeStatus = asyncStatus.LOADING;
      state.editOMPaymentTypeError = null;
      state.getOMPaymentTypeStatus = asyncStatus.LOADING;
      state.editOMPaymentTypeData = null;
    });
    builder.addCase(editOMPaymentTypeAsync.fulfilled, (state, { payload }) => {
      state.editOMPaymentTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMPaymentTypeStatus = asyncStatus.SUCCEEDED;
      state.editOMPaymentTypeData = payload.data;
      const { getOMPaymentTypeData } = state;
      let updatedArray = [];
      getOMPaymentTypeData?.map((e) => {
        var item = e;
        if (item?.payment_type_id === payload?.data?.payment_type_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMPaymentTypeData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMPaymentTypeError = null;
    });
    builder.addCase(editOMPaymentTypeAsync.rejected, (state, actions) => {
      state.editOMPaymentTypeData = null;
      state.editOMPaymentTypeStatus = asyncStatus.ERROR;
      state.editOMPaymentTypeError = actions?.error?.message;
    });

    builder.addCase(deleteOMPaymentTypeAsync.pending, (state, action) => {
      state.deleteOMPaymentTypeStatus = asyncStatus.LOADING;
      state.getOMPaymentTypeStatus = asyncStatus.LOADING;
      state.deleteOMPaymentTypeError = null;
      state.deleteOMPaymentTypeData = null;
    });
    builder.addCase(
      deleteOMPaymentTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMPaymentTypeStatus = asyncStatus.SUCCEEDED;
        state.getOMPaymentTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteOMPaymentTypeData = payload.data;
        const { getOMPaymentTypeData } = state;
        let updatedArray = getOMPaymentTypeData?.filter(
          (x) => x.payment_type_id !== payload.data.payment_type_id
        );
        state.getOMPaymentTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMPaymentTypeError = null;
      }
    );
    builder.addCase(deleteOMPaymentTypeAsync.rejected, (state, actions) => {
      state.deleteOMPaymentTypeData = null;
      state.deleteOMPaymentTypeStatus = asyncStatus.ERROR;
      state.deleteOMPaymentTypeError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Payment Type

    // OM Payment Term

    builder.addCase(getOMPaymentTermAsync.pending, (state, action) => {
      state.getOMPaymentTermStatus = asyncStatus.LOADING;
      state.getOMPaymentTermError = null;
      state.getOMPaymentTermData = null;
    });
    builder.addCase(getOMPaymentTermAsync.fulfilled, (state, { payload }) => {
      state.getOMPaymentTermStatus = asyncStatus.SUCCEEDED;
      state.getOMPaymentTermData = payload.data;
      state.getOMPaymentTermError = null;
    });
    builder.addCase(getOMPaymentTermAsync.rejected, (state, actions) => {
      state.getOMPaymentTermData = null;
      state.getOMPaymentTermStatus = asyncStatus.ERROR;
      state.getOMPaymentTermError = actions?.error?.message;
    });

    builder.addCase(addOMPaymentTermAsync.pending, (state, action) => {
      state.addOMPaymentTermStatus = asyncStatus.LOADING;
      state.addOMPaymentTermError = null;
      state.addOMPaymentTermData = null;
    });
    builder.addCase(addOMPaymentTermAsync.fulfilled, (state, { payload }) => {
      state.addOMPaymentTermStatus = asyncStatus.SUCCEEDED;
      state.addOMPaymentTermData = payload.data;
      // let { getOMPaymentTermData } = state;
      state.getOMPaymentTermData = [
        ...state.getOMPaymentTermData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMPaymentTermError = null;
    });
    builder.addCase(addOMPaymentTermAsync.rejected, (state, actions) => {
      state.addOMPaymentTermData = null;
      state.addOMPaymentTermStatus = asyncStatus.ERROR;
      state.addOMPaymentTermError = actions?.error?.message;
    });

    builder.addCase(editOMPaymentTermAsync.pending, (state, action) => {
      state.editOMPaymentTermStatus = asyncStatus.LOADING;
      state.editOMPaymentTermError = null;
      state.getOMPaymentTermStatus = asyncStatus.LOADING;
      state.editOMPaymentTermData = null;
    });
    builder.addCase(editOMPaymentTermAsync.fulfilled, (state, { payload }) => {
      state.editOMPaymentTermStatus = asyncStatus.SUCCEEDED;
      state.getOMPaymentTermStatus = asyncStatus.SUCCEEDED;
      state.editOMPaymentTermData = payload.data;
      const { getOMPaymentTermData } = state;
      let updatedArray = [];
      getOMPaymentTermData?.map((e) => {
        var item = e;
        if (item?.term_id === payload?.data?.term_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMPaymentTermData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMPaymentTermError = null;
    });
    builder.addCase(editOMPaymentTermAsync.rejected, (state, actions) => {
      state.editOMPaymentTermData = null;
      state.editOMPaymentTermStatus = asyncStatus.ERROR;
      state.editOMPaymentTermError = actions?.error?.message;
    });

    builder.addCase(deleteOMPaymentTermAsync.pending, (state, action) => {
      state.deleteOMPaymentTermStatus = asyncStatus.LOADING;
      state.getOMPaymentTermStatus = asyncStatus.LOADING;
      state.deleteOMPaymentTermError = null;
      state.deleteOMPaymentTermData = null;
    });
    builder.addCase(
      deleteOMPaymentTermAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.getOMPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.deleteOMPaymentTermData = payload.data;
        const { getOMPaymentTermData } = state;
        let updatedArray = getOMPaymentTermData?.filter(
          (x) => x.term_id !== payload.data.term_id
        );
        state.getOMPaymentTermData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMPaymentTermError = null;
      }
    );
    builder.addCase(deleteOMPaymentTermAsync.rejected, (state, actions) => {
      state.deleteOMPaymentTermData = null;
      state.deleteOMPaymentTermStatus = asyncStatus.ERROR;
      state.deleteOMPaymentTermError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Payment Term

    // OM Bergen Payment Term

    builder.addCase(getOMBergenPaymentTermAsync.pending, (state, action) => {
      state.getOMBergenPaymentTermStatus = asyncStatus.LOADING;
      state.getOMBergenPaymentTermError = null;
      state.getOMBergenPaymentTermData = null;
    });
    builder.addCase(
      getOMBergenPaymentTermAsync.fulfilled,
      (state, { payload }) => {
        state.getOMBergenPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.getOMBergenPaymentTermData = payload.data;
        state.getOMBergenPaymentTermError = null;
      }
    );
    builder.addCase(getOMBergenPaymentTermAsync.rejected, (state, actions) => {
      state.getOMBergenPaymentTermData = null;
      state.getOMBergenPaymentTermStatus = asyncStatus.ERROR;
      state.getOMBergenPaymentTermError = actions?.error?.message;
    });

    builder.addCase(addOMBergenPaymentTermAsync.pending, (state, action) => {
      state.addOMBergenPaymentTermStatus = asyncStatus.LOADING;
      state.addOMBergenPaymentTermError = null;
      state.addOMBergenPaymentTermData = null;
    });
    builder.addCase(
      addOMBergenPaymentTermAsync.fulfilled,
      (state, { payload }) => {
        state.addOMBergenPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.addOMBergenPaymentTermData = payload.data;
        // let { getOMBergenPaymentTermData } = state;
        state.getOMBergenPaymentTermData = [
          ...state.getOMBergenPaymentTermData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addOMBergenPaymentTermError = null;
      }
    );
    builder.addCase(addOMBergenPaymentTermAsync.rejected, (state, actions) => {
      state.addOMBergenPaymentTermData = null;
      state.addOMBergenPaymentTermStatus = asyncStatus.ERROR;
      state.addOMBergenPaymentTermError = actions?.error?.message;
    });

    builder.addCase(editOMBergenPaymentTermAsync.pending, (state, action) => {
      state.editOMBergenPaymentTermStatus = asyncStatus.LOADING;
      state.editOMBergenPaymentTermError = null;
      state.getOMBergenPaymentTermStatus = asyncStatus.LOADING;
      state.editOMBergenPaymentTermData = null;
    });
    builder.addCase(
      editOMBergenPaymentTermAsync.fulfilled,
      (state, { payload }) => {
        state.editOMBergenPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.getOMBergenPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.editOMBergenPaymentTermData = payload.data;
        const { getOMBergenPaymentTermData } = state;
        let updatedArray = [];
        getOMBergenPaymentTermData?.map((e) => {
          var item = e;
          if (
            item?.bergen_payment_term_id ===
            payload?.data?.bergen_payment_term_id
          ) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMBergenPaymentTermData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMBergenPaymentTermError = null;
      }
    );
    builder.addCase(editOMBergenPaymentTermAsync.rejected, (state, actions) => {
      state.editOMBergenPaymentTermData = null;
      state.editOMBergenPaymentTermStatus = asyncStatus.ERROR;
      state.editOMBergenPaymentTermError = actions?.error?.message;
    });

    builder.addCase(deleteOMBergenPaymentTermAsync.pending, (state, action) => {
      state.deleteOMBergenPaymentTermStatus = asyncStatus.LOADING;
      state.getOMBergenPaymentTermStatus = asyncStatus.LOADING;
      state.deleteOMBergenPaymentTermError = null;
      state.deleteOMBergenPaymentTermData = null;
    });
    builder.addCase(
      deleteOMBergenPaymentTermAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMBergenPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.getOMBergenPaymentTermStatus = asyncStatus.SUCCEEDED;
        state.deleteOMBergenPaymentTermData = payload.data;
        const { getOMBergenPaymentTermData } = state;
        let updatedArray = getOMBergenPaymentTermData?.filter(
          (x) =>
            x.bergen_payment_term_id !== payload.data.bergen_payment_term_id
        );
        state.getOMBergenPaymentTermData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMBergenPaymentTermError = null;
      }
    );
    builder.addCase(
      deleteOMBergenPaymentTermAsync.rejected,
      (state, actions) => {
        state.deleteOMBergenPaymentTermData = null;
        state.deleteOMBergenPaymentTermStatus = asyncStatus.ERROR;
        state.deleteOMBergenPaymentTermError = actions?.error?.message;
        error_toast_message(actions?.error?.message);
      }
    );

    // OM Bergen Payment Term

    // OM Freight Term

    builder.addCase(getOMFreightTermAsync.pending, (state, action) => {
      state.getOMFreightTermStatus = asyncStatus.LOADING;
      state.getOMFreightTermError = null;
      state.getOMFreightTermData = null;
    });
    builder.addCase(getOMFreightTermAsync.fulfilled, (state, { payload }) => {
      state.getOMFreightTermStatus = asyncStatus.SUCCEEDED;
      state.getOMFreightTermData = payload.data;
      state.getOMFreightTermError = null;
    });
    builder.addCase(getOMFreightTermAsync.rejected, (state, actions) => {
      state.getOMFreightTermData = null;
      state.getOMFreightTermStatus = asyncStatus.ERROR;
      state.getOMFreightTermError = actions?.error?.message;
    });

    builder.addCase(addOMFreightTermAsync.pending, (state, action) => {
      state.addOMFreightTermStatus = asyncStatus.LOADING;
      state.addOMFreightTermError = null;
      state.addOMFreightTermData = null;
    });
    builder.addCase(addOMFreightTermAsync.fulfilled, (state, { payload }) => {
      state.addOMFreightTermStatus = asyncStatus.SUCCEEDED;
      state.addOMFreightTermData = payload.data;
      // let { getOMFreightTermData } = state;
      state.getOMFreightTermData = [
        ...state.getOMFreightTermData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMFreightTermError = null;
    });
    builder.addCase(addOMFreightTermAsync.rejected, (state, actions) => {
      state.addOMFreightTermData = null;
      state.addOMFreightTermStatus = asyncStatus.ERROR;
      state.addOMFreightTermError = actions?.error?.message;
    });

    builder.addCase(editOMFreightTermAsync.pending, (state, action) => {
      state.editOMFreightTermStatus = asyncStatus.LOADING;
      state.editOMFreightTermError = null;
      state.getOMFreightTermStatus = asyncStatus.LOADING;
      state.editOMFreightTermData = null;
    });
    builder.addCase(editOMFreightTermAsync.fulfilled, (state, { payload }) => {
      state.editOMFreightTermStatus = asyncStatus.SUCCEEDED;
      state.getOMFreightTermStatus = asyncStatus.SUCCEEDED;
      state.editOMFreightTermData = payload.data;
      const { getOMFreightTermData } = state;
      let updatedArray = [];
      getOMFreightTermData?.map((e) => {
        var item = e;
        if (item?.freight_term_id === payload?.data?.freight_term_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMFreightTermData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMFreightTermError = null;
    });
    builder.addCase(editOMFreightTermAsync.rejected, (state, actions) => {
      state.editOMFreightTermData = null;
      state.editOMFreightTermStatus = asyncStatus.ERROR;
      state.editOMFreightTermError = actions?.error?.message;
    });

    builder.addCase(deleteOMFreightTermAsync.pending, (state, action) => {
      state.deleteOMFreightTermStatus = asyncStatus.LOADING;
      state.getOMFreightTermStatus = asyncStatus.LOADING;
      state.deleteOMFreightTermError = null;
      state.deleteOMFreightTermData = null;
    });
    builder.addCase(
      deleteOMFreightTermAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMFreightTermStatus = asyncStatus.SUCCEEDED;
        state.getOMFreightTermStatus = asyncStatus.SUCCEEDED;
        state.deleteOMFreightTermData = payload.data;
        const { getOMFreightTermData } = state;
        let updatedArray = getOMFreightTermData?.filter(
          (x) => x.freight_term_id !== payload.data.freight_term_id
        );
        state.getOMFreightTermData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMFreightTermError = null;
      }
    );
    builder.addCase(deleteOMFreightTermAsync.rejected, (state, actions) => {
      state.deleteOMFreightTermData = null;
      state.deleteOMFreightTermStatus = asyncStatus.ERROR;
      state.deleteOMFreightTermError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Freight Term

    // OM Order Status

    builder.addCase(getOMOrderStatusAsync.pending, (state, action) => {
      state.getOMOrderStatusStatus = asyncStatus.LOADING;
      state.getOMOrderStatusError = null;
      state.getOMOrderStatusData = null;
    });
    builder.addCase(getOMOrderStatusAsync.fulfilled, (state, { payload }) => {
      state.getOMOrderStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMOrderStatusData = payload.data;
      state.getOMOrderStatusError = null;
    });
    builder.addCase(getOMOrderStatusAsync.rejected, (state, actions) => {
      state.getOMOrderStatusData = null;
      state.getOMOrderStatusStatus = asyncStatus.ERROR;
      state.getOMOrderStatusError = actions?.error?.message;
    });

    builder.addCase(addOMOrderStatusAsync.pending, (state, action) => {
      state.addOMOrderStatusStatus = asyncStatus.LOADING;
      state.addOMOrderStatusError = null;
      state.addOMOrderStatusData = null;
    });
    builder.addCase(addOMOrderStatusAsync.fulfilled, (state, { payload }) => {
      state.addOMOrderStatusStatus = asyncStatus.SUCCEEDED;
      state.addOMOrderStatusData = payload.data;
      // let { getOMOrderStatusData } = state;
      state.getOMOrderStatusData = [
        ...state.getOMOrderStatusData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMOrderStatusError = null;
    });
    builder.addCase(addOMOrderStatusAsync.rejected, (state, actions) => {
      state.addOMOrderStatusData = null;
      state.addOMOrderStatusStatus = asyncStatus.ERROR;
      state.addOMOrderStatusError = actions?.error?.message;
    });

    builder.addCase(editOMOrderStatusAsync.pending, (state, action) => {
      state.editOMOrderStatusStatus = asyncStatus.LOADING;
      state.editOMOrderStatusError = null;
      state.getOMOrderStatusStatus = asyncStatus.LOADING;
      state.editOMOrderStatusData = null;
    });
    builder.addCase(editOMOrderStatusAsync.fulfilled, (state, { payload }) => {
      state.editOMOrderStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMOrderStatusStatus = asyncStatus.SUCCEEDED;
      state.editOMOrderStatusData = payload.data;
      const { getOMOrderStatusData } = state;
      let updatedArray = [];
      getOMOrderStatusData?.map((e) => {
        var item = e;
        if (item?.order_status_id === payload?.data?.order_status_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMOrderStatusData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMOrderStatusError = null;
    });
    builder.addCase(editOMOrderStatusAsync.rejected, (state, actions) => {
      state.editOMOrderStatusData = null;
      state.editOMOrderStatusStatus = asyncStatus.ERROR;
      state.editOMOrderStatusError = actions?.error?.message;
    });

    builder.addCase(deleteOMOrderStatusAsync.pending, (state, action) => {
      state.deleteOMOrderStatusStatus = asyncStatus.LOADING;
      state.getOMOrderStatusStatus = asyncStatus.LOADING;
      state.deleteOMOrderStatusError = null;
      state.deleteOMOrderStatusData = null;
    });
    builder.addCase(
      deleteOMOrderStatusAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMOrderStatusStatus = asyncStatus.SUCCEEDED;
        state.getOMOrderStatusStatus = asyncStatus.SUCCEEDED;
        state.deleteOMOrderStatusData = payload.data;
        const { getOMOrderStatusData } = state;
        let updatedArray = getOMOrderStatusData?.filter(
          (x) => x.order_status_id !== payload.data.order_status_id
        );
        state.getOMOrderStatusData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMOrderStatusError = null;
      }
    );
    builder.addCase(deleteOMOrderStatusAsync.rejected, (state, actions) => {
      state.deleteOMOrderStatusData = null;
      state.deleteOMOrderStatusStatus = asyncStatus.ERROR;
      state.deleteOMOrderStatusError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Order Status

    // OM Region

    builder.addCase(getOMRegionAsync.pending, (state, action) => {
      state.getOMRegionStatus = asyncStatus.LOADING;
      state.getOMRegionError = null;
      state.getOMRegionData = null;
    });
    builder.addCase(getOMRegionAsync.fulfilled, (state, { payload }) => {
      state.getOMRegionStatus = asyncStatus.SUCCEEDED;
      state.getOMRegionData = payload.data;
      state.getOMRegionError = null;
    });
    builder.addCase(getOMRegionAsync.rejected, (state, actions) => {
      state.getOMRegionData = null;
      state.getOMRegionStatus = asyncStatus.ERROR;
      state.getOMRegionError = actions?.error?.message;
    });

    builder.addCase(addOMRegionAsync.pending, (state, action) => {
      state.addOMRegionStatus = asyncStatus.LOADING;
      state.addOMRegionError = null;
      state.addOMRegionData = null;
    });
    builder.addCase(addOMRegionAsync.fulfilled, (state, { payload }) => {
      state.addOMRegionStatus = asyncStatus.SUCCEEDED;
      state.addOMRegionData = payload.data;
      // let { getOMRegionData } = state;
      state.getOMRegionData = [...state.getOMRegionData, payload.data];
      success_toast_message(payload.message);
      state.addOMRegionError = null;
    });
    builder.addCase(addOMRegionAsync.rejected, (state, actions) => {
      state.addOMRegionData = null;
      state.addOMRegionStatus = asyncStatus.ERROR;
      state.addOMRegionError = actions?.error?.message;
    });

    builder.addCase(editOMRegionAsync.pending, (state, action) => {
      state.editOMRegionStatus = asyncStatus.LOADING;
      state.editOMRegionError = null;
      state.getOMRegionStatus = asyncStatus.LOADING;
      state.editOMRegionData = null;
    });
    builder.addCase(editOMRegionAsync.fulfilled, (state, { payload }) => {
      state.editOMRegionStatus = asyncStatus.SUCCEEDED;
      state.getOMRegionStatus = asyncStatus.SUCCEEDED;
      state.editOMRegionData = payload.data;
      const { getOMRegionData } = state;
      let updatedArray = [];
      getOMRegionData?.map((e) => {
        var item = e;
        if (item?.region_id === payload?.data?.region_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMRegionData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMRegionError = null;
    });
    builder.addCase(editOMRegionAsync.rejected, (state, actions) => {
      state.editOMRegionData = null;
      state.editOMRegionStatus = asyncStatus.ERROR;
      state.editOMRegionError = actions?.error?.message;
    });

    builder.addCase(deleteOMRegionAsync.pending, (state, action) => {
      state.deleteOMRegionStatus = asyncStatus.LOADING;
      state.getOMRegionStatus = asyncStatus.LOADING;
      state.deleteOMRegionError = null;
      state.deleteOMRegionData = null;
    });
    builder.addCase(deleteOMRegionAsync.fulfilled, (state, { payload }) => {
      state.deleteOMRegionStatus = asyncStatus.SUCCEEDED;
      state.getOMRegionStatus = asyncStatus.SUCCEEDED;
      state.deleteOMRegionData = payload.data;
      const { getOMRegionData } = state;
      let updatedArray = getOMRegionData?.filter(
        (x) => x.region_id !== payload.data.region_id
      );
      state.getOMRegionData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMRegionError = null;
    });
    builder.addCase(deleteOMRegionAsync.rejected, (state, actions) => {
      state.deleteOMRegionData = null;
      state.deleteOMRegionStatus = asyncStatus.ERROR;
      state.deleteOMRegionError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Region

    // OM Commit Criteria

    builder.addCase(getOMCommitCriteriaAsync.pending, (state, action) => {
      state.getOMCommitCriteriaStatus = asyncStatus.LOADING;
      state.getOMCommitCriteriaError = null;
      state.getOMCommitCriteriaData = null;
    });
    builder.addCase(
      getOMCommitCriteriaAsync.fulfilled,
      (state, { payload }) => {
        state.getOMCommitCriteriaStatus = asyncStatus.SUCCEEDED;
        state.getOMCommitCriteriaData = payload.data;
        state.getOMCommitCriteriaError = null;
      }
    );
    builder.addCase(getOMCommitCriteriaAsync.rejected, (state, actions) => {
      state.getOMCommitCriteriaData = null;
      state.getOMCommitCriteriaStatus = asyncStatus.ERROR;
      state.getOMCommitCriteriaError = actions?.error?.message;
    });

    builder.addCase(addOMCommitCriteriaAsync.pending, (state, action) => {
      state.addOMCommitCriteriaStatus = asyncStatus.LOADING;
      state.addOMCommitCriteriaError = null;
      state.addOMCommitCriteriaData = null;
    });
    builder.addCase(
      addOMCommitCriteriaAsync.fulfilled,
      (state, { payload }) => {
        state.addOMCommitCriteriaStatus = asyncStatus.SUCCEEDED;
        state.addOMCommitCriteriaData = payload.data;
        // let { getOMCommitCriteriaData } = state;
        state.getOMCommitCriteriaData = [
          ...state.getOMCommitCriteriaData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addOMCommitCriteriaError = null;
      }
    );
    builder.addCase(addOMCommitCriteriaAsync.rejected, (state, actions) => {
      state.addOMCommitCriteriaData = null;
      state.addOMCommitCriteriaStatus = asyncStatus.ERROR;
      state.addOMCommitCriteriaError = actions?.error?.message;
    });

    builder.addCase(editOMCommitCriteriaAsync.pending, (state, action) => {
      state.editOMCommitCriteriaStatus = asyncStatus.LOADING;
      state.editOMCommitCriteriaError = null;
      state.getOMCommitCriteriaStatus = asyncStatus.LOADING;
      state.editOMCommitCriteriaData = null;
    });
    builder.addCase(
      editOMCommitCriteriaAsync.fulfilled,
      (state, { payload }) => {
        state.editOMCommitCriteriaStatus = asyncStatus.SUCCEEDED;
        state.getOMCommitCriteriaStatus = asyncStatus.SUCCEEDED;
        state.editOMCommitCriteriaData = payload.data;
        const { getOMCommitCriteriaData } = state;
        let updatedArray = [];
        getOMCommitCriteriaData?.map((e) => {
          var item = e;
          if (item?.commit_criteria_id === payload?.data?.commit_criteria_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMCommitCriteriaData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMCommitCriteriaError = null;
      }
    );
    builder.addCase(editOMCommitCriteriaAsync.rejected, (state, actions) => {
      state.editOMCommitCriteriaData = null;
      state.editOMCommitCriteriaStatus = asyncStatus.ERROR;
      state.editOMCommitCriteriaError = actions?.error?.message;
    });

    builder.addCase(deleteOMCommitCriteriaAsync.pending, (state, action) => {
      state.deleteOMCommitCriteriaStatus = asyncStatus.LOADING;
      state.getOMCommitCriteriaStatus = asyncStatus.LOADING;
      state.deleteOMCommitCriteriaError = null;
      state.deleteOMCommitCriteriaData = null;
    });
    builder.addCase(
      deleteOMCommitCriteriaAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMCommitCriteriaStatus = asyncStatus.SUCCEEDED;
        state.getOMCommitCriteriaStatus = asyncStatus.SUCCEEDED;
        state.deleteOMCommitCriteriaData = payload.data;
        const { getOMCommitCriteriaData } = state;
        let updatedArray = getOMCommitCriteriaData?.filter(
          (x) => x.commit_criteria_id !== payload.data.commit_criteria_id
        );
        state.getOMCommitCriteriaData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMCommitCriteriaError = null;
      }
    );
    builder.addCase(deleteOMCommitCriteriaAsync.rejected, (state, actions) => {
      state.deleteOMCommitCriteriaData = null;
      state.deleteOMCommitCriteriaStatus = asyncStatus.ERROR;
      state.deleteOMCommitCriteriaError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Commit Criteria

    // OM Ship Status

    builder.addCase(getOMShipStatusAsync.pending, (state, action) => {
      state.getOMShipStatusStatus = asyncStatus.LOADING;
      state.getOMShipStatusError = null;
      state.getOMShipStatusData = null;
    });
    builder.addCase(getOMShipStatusAsync.fulfilled, (state, { payload }) => {
      state.getOMShipStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMShipStatusData = payload.data;
      state.getOMShipStatusError = null;
    });
    builder.addCase(getOMShipStatusAsync.rejected, (state, actions) => {
      state.getOMShipStatusData = null;
      state.getOMShipStatusStatus = asyncStatus.ERROR;
      state.getOMShipStatusError = actions?.error?.message;
    });

    builder.addCase(addOMShipStatusAsync.pending, (state, action) => {
      state.addOMShipStatusStatus = asyncStatus.LOADING;
      state.addOMShipStatusError = null;
      state.addOMShipStatusData = null;
    });
    builder.addCase(addOMShipStatusAsync.fulfilled, (state, { payload }) => {
      state.addOMShipStatusStatus = asyncStatus.SUCCEEDED;
      state.addOMShipStatusData = payload.data;
      // let { getOMShipStatusData } = state;
      state.getOMShipStatusData = [...state.getOMShipStatusData, payload.data];
      success_toast_message(payload.message);
      state.addOMShipStatusError = null;
    });
    builder.addCase(addOMShipStatusAsync.rejected, (state, actions) => {
      state.addOMShipStatusData = null;
      state.addOMShipStatusStatus = asyncStatus.ERROR;
      state.addOMShipStatusError = actions?.error?.message;
    });

    builder.addCase(editOMShipStatusAsync.pending, (state, action) => {
      state.editOMShipStatusStatus = asyncStatus.LOADING;
      state.editOMShipStatusError = null;
      state.getOMShipStatusStatus = asyncStatus.LOADING;
      state.editOMShipStatusData = null;
    });
    builder.addCase(editOMShipStatusAsync.fulfilled, (state, { payload }) => {
      state.editOMShipStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMShipStatusStatus = asyncStatus.SUCCEEDED;
      state.editOMShipStatusData = payload.data;
      const { getOMShipStatusData } = state;
      let updatedArray = [];
      getOMShipStatusData?.map((e) => {
        var item = e;
        if (item?.ship_status_id === payload?.data?.ship_status_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMShipStatusData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMShipStatusError = null;
    });
    builder.addCase(editOMShipStatusAsync.rejected, (state, actions) => {
      state.editOMShipStatusData = null;
      state.editOMShipStatusStatus = asyncStatus.ERROR;
      state.editOMShipStatusError = actions?.error?.message;
    });

    builder.addCase(deleteOMShipStatusAsync.pending, (state, action) => {
      state.deleteOMShipStatusStatus = asyncStatus.LOADING;
      state.getOMShipStatusStatus = asyncStatus.LOADING;
      state.deleteOMShipStatusError = null;
      state.deleteOMShipStatusData = null;
    });
    builder.addCase(deleteOMShipStatusAsync.fulfilled, (state, { payload }) => {
      state.deleteOMShipStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMShipStatusStatus = asyncStatus.SUCCEEDED;
      state.deleteOMShipStatusData = payload.data;
      const { getOMShipStatusData } = state;
      let updatedArray = getOMShipStatusData?.filter(
        (x) => x.ship_status_id !== payload.data.ship_status_id
      );
      state.getOMShipStatusData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMShipStatusError = null;
    });
    builder.addCase(deleteOMShipStatusAsync.rejected, (state, actions) => {
      state.deleteOMShipStatusData = null;
      state.deleteOMShipStatusStatus = asyncStatus.ERROR;
      state.deleteOMShipStatusError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Ship Status

    // OM Vendor Category

    builder.addCase(getOMVendorCategoryAsync.pending, (state, action) => {
      state.getOMVendorCategoryStatus = asyncStatus.LOADING;
      state.getOMVendorCategoryError = null;
      state.getOMVendorCategoryData = null;
    });
    builder.addCase(
      getOMVendorCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.getOMVendorCategoryStatus = asyncStatus.SUCCEEDED;
        state.getOMVendorCategoryData = payload.data;
        state.getOMVendorCategoryError = null;
      }
    );
    builder.addCase(getOMVendorCategoryAsync.rejected, (state, actions) => {
      state.getOMVendorCategoryData = null;
      state.getOMVendorCategoryStatus = asyncStatus.ERROR;
      state.getOMVendorCategoryError = actions?.error?.message;
    });

    builder.addCase(addOMVendorCategoryAsync.pending, (state, action) => {
      state.addOMVendorCategoryStatus = asyncStatus.LOADING;
      state.addOMVendorCategoryError = null;
      state.addOMVendorCategoryData = null;
    });
    builder.addCase(
      addOMVendorCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.addOMVendorCategoryStatus = asyncStatus.SUCCEEDED;
        state.addOMVendorCategoryData = payload.data;
        // let { getOMVendorCategoryData } = state;
        state.getOMVendorCategoryData = [
          ...state.getOMVendorCategoryData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addOMVendorCategoryError = null;
      }
    );
    builder.addCase(addOMVendorCategoryAsync.rejected, (state, actions) => {
      state.addOMVendorCategoryData = null;
      state.addOMVendorCategoryStatus = asyncStatus.ERROR;
      state.addOMVendorCategoryError = actions?.error?.message;
    });

    builder.addCase(editOMVendorCategoryAsync.pending, (state, action) => {
      state.editOMVendorCategoryStatus = asyncStatus.LOADING;
      state.editOMVendorCategoryError = null;
      state.getOMVendorCategoryStatus = asyncStatus.LOADING;
      state.editOMVendorCategoryData = null;
    });
    builder.addCase(
      editOMVendorCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.editOMVendorCategoryStatus = asyncStatus.SUCCEEDED;
        state.getOMVendorCategoryStatus = asyncStatus.SUCCEEDED;
        state.editOMVendorCategoryData = payload.data;
        const { getOMVendorCategoryData } = state;
        let updatedArray = [];
        getOMVendorCategoryData?.map((e) => {
          var item = e;
          if (item?.vendor_category_id === payload?.data?.vendor_category_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMVendorCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMVendorCategoryError = null;
      }
    );
    builder.addCase(editOMVendorCategoryAsync.rejected, (state, actions) => {
      state.editOMVendorCategoryData = null;
      state.editOMVendorCategoryStatus = asyncStatus.ERROR;
      state.editOMVendorCategoryError = actions?.error?.message;
    });

    builder.addCase(deleteOMVendorCategoryAsync.pending, (state, action) => {
      state.deleteOMVendorCategoryStatus = asyncStatus.LOADING;
      state.getOMVendorCategoryStatus = asyncStatus.LOADING;
      state.deleteOMVendorCategoryError = null;
      state.deleteOMVendorCategoryData = null;
    });
    builder.addCase(
      deleteOMVendorCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMVendorCategoryStatus = asyncStatus.SUCCEEDED;
        state.getOMVendorCategoryStatus = asyncStatus.SUCCEEDED;
        state.deleteOMVendorCategoryData = payload.data;
        const { getOMVendorCategoryData } = state;
        let updatedArray = getOMVendorCategoryData?.filter(
          (x) => x.vendor_category_id !== payload.data.vendor_category_id
        );
        state.getOMVendorCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMVendorCategoryError = null;
      }
    );
    builder.addCase(deleteOMVendorCategoryAsync.rejected, (state, actions) => {
      state.deleteOMVendorCategoryData = null;
      state.deleteOMVendorCategoryStatus = asyncStatus.ERROR;
      state.deleteOMVendorCategoryError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Vendor Category

    // OM Courier Service

    builder.addCase(getOMCourierServiceAsync.pending, (state, action) => {
      state.getOMCourierServiceStatus = asyncStatus.LOADING;
      state.getOMCourierServiceError = null;
      state.getOMCourierServiceData = null;
    });
    builder.addCase(
      getOMCourierServiceAsync.fulfilled,
      (state, { payload }) => {
        state.getOMCourierServiceStatus = asyncStatus.SUCCEEDED;
        state.getOMCourierServiceData = payload.data;
        state.getOMCourierServiceError = null;
      }
    );
    builder.addCase(getOMCourierServiceAsync.rejected, (state, actions) => {
      state.getOMCourierServiceData = null;
      state.getOMCourierServiceStatus = asyncStatus.ERROR;
      state.getOMCourierServiceError = actions?.error?.message;
    });

    builder.addCase(addOMCourierServiceAsync.pending, (state, action) => {
      state.addOMCourierServiceStatus = asyncStatus.LOADING;
      state.addOMCourierServiceError = null;
      state.addOMCourierServiceData = null;
    });
    builder.addCase(
      addOMCourierServiceAsync.fulfilled,
      (state, { payload }) => {
        state.addOMCourierServiceStatus = asyncStatus.SUCCEEDED;
        state.addOMCourierServiceData = payload.data;
        // let { getOMCourierServiceData } = state;
        state.getOMCourierServiceData = [
          ...state.getOMCourierServiceData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addOMCourierServiceError = null;
      }
    );
    builder.addCase(addOMCourierServiceAsync.rejected, (state, actions) => {
      state.addOMCourierServiceData = null;
      state.addOMCourierServiceStatus = asyncStatus.ERROR;
      state.addOMCourierServiceError = actions?.error?.message;
    });

    builder.addCase(editOMCourierServiceAsync.pending, (state, action) => {
      state.editOMCourierServiceStatus = asyncStatus.LOADING;
      state.editOMCourierServiceError = null;
      state.getOMCourierServiceStatus = asyncStatus.LOADING;
      state.editOMCourierServiceData = null;
    });
    builder.addCase(
      editOMCourierServiceAsync.fulfilled,
      (state, { payload }) => {
        state.editOMCourierServiceStatus = asyncStatus.SUCCEEDED;
        state.getOMCourierServiceStatus = asyncStatus.SUCCEEDED;
        state.editOMCourierServiceData = payload.data;
        const { getOMCourierServiceData } = state;
        let updatedArray = [];
        getOMCourierServiceData?.map((e) => {
          var item = e;
          if (item?.courier_service_id === payload?.data?.courier_service_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMCourierServiceData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMCourierServiceError = null;
      }
    );
    builder.addCase(editOMCourierServiceAsync.rejected, (state, actions) => {
      state.editOMCourierServiceData = null;
      state.editOMCourierServiceStatus = asyncStatus.ERROR;
      state.editOMCourierServiceError = actions?.error?.message;
    });

    builder.addCase(deleteOMCourierServiceAsync.pending, (state, action) => {
      state.deleteOMCourierServiceStatus = asyncStatus.LOADING;
      state.getOMCourierServiceStatus = asyncStatus.LOADING;
      state.deleteOMCourierServiceError = null;
      state.deleteOMCourierServiceData = null;
    });
    builder.addCase(
      deleteOMCourierServiceAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMCourierServiceStatus = asyncStatus.SUCCEEDED;
        state.getOMCourierServiceStatus = asyncStatus.SUCCEEDED;
        state.deleteOMCourierServiceData = payload.data;
        const { getOMCourierServiceData } = state;
        let updatedArray = getOMCourierServiceData?.filter(
          (x) => x.courier_service_id !== payload.data.courier_service_id
        );
        state.getOMCourierServiceData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMCourierServiceError = null;
      }
    );
    builder.addCase(deleteOMCourierServiceAsync.rejected, (state, actions) => {
      state.deleteOMCourierServiceData = null;
      state.deleteOMCourierServiceStatus = asyncStatus.ERROR;
      state.deleteOMCourierServiceError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Courier Service

    // OM Return Reason

    builder.addCase(getOMReturnReasonAsync.pending, (state, action) => {
      state.getOMReturnReasonStatus = asyncStatus.LOADING;
      state.getOMReturnReasonError = null;
      state.getOMReturnReasonData = null;
    });
    builder.addCase(getOMReturnReasonAsync.fulfilled, (state, { payload }) => {
      state.getOMReturnReasonStatus = asyncStatus.SUCCEEDED;
      state.getOMReturnReasonData = payload.data;
      state.getOMReturnReasonError = null;
    });
    builder.addCase(getOMReturnReasonAsync.rejected, (state, actions) => {
      state.getOMReturnReasonData = null;
      state.getOMReturnReasonStatus = asyncStatus.ERROR;
      state.getOMReturnReasonError = actions?.error?.message;
    });

    builder.addCase(addOMReturnReasonAsync.pending, (state, action) => {
      state.addOMReturnReasonStatus = asyncStatus.LOADING;
      state.addOMReturnReasonError = null;
      state.addOMReturnReasonData = null;
    });
    builder.addCase(addOMReturnReasonAsync.fulfilled, (state, { payload }) => {
      state.addOMReturnReasonStatus = asyncStatus.SUCCEEDED;
      state.addOMReturnReasonData = payload.data;
      // let { getOMReturnReasonData } = state;
      state.getOMReturnReasonData = [
        ...state.getOMReturnReasonData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMReturnReasonError = null;
    });
    builder.addCase(addOMReturnReasonAsync.rejected, (state, actions) => {
      state.addOMReturnReasonData = null;
      state.addOMReturnReasonStatus = asyncStatus.ERROR;
      state.addOMReturnReasonError = actions?.error?.message;
    });

    builder.addCase(editOMReturnReasonAsync.pending, (state, action) => {
      state.editOMReturnReasonStatus = asyncStatus.LOADING;
      state.editOMReturnReasonError = null;
      state.getOMReturnReasonStatus = asyncStatus.LOADING;
      state.editOMReturnReasonData = null;
    });
    builder.addCase(editOMReturnReasonAsync.fulfilled, (state, { payload }) => {
      state.editOMReturnReasonStatus = asyncStatus.SUCCEEDED;
      state.getOMReturnReasonStatus = asyncStatus.SUCCEEDED;
      state.editOMReturnReasonData = payload.data;
      const { getOMReturnReasonData } = state;
      let updatedArray = [];
      getOMReturnReasonData?.map((e) => {
        var item = e;
        if (item?.return_reason_id === payload?.data?.return_reason_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMReturnReasonData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMReturnReasonError = null;
    });
    builder.addCase(editOMReturnReasonAsync.rejected, (state, actions) => {
      state.editOMReturnReasonData = null;
      state.editOMReturnReasonStatus = asyncStatus.ERROR;
      state.editOMReturnReasonError = actions?.error?.message;
    });

    builder.addCase(deleteOMReturnReasonAsync.pending, (state, action) => {
      state.deleteOMReturnReasonStatus = asyncStatus.LOADING;
      state.getOMReturnReasonStatus = asyncStatus.LOADING;
      state.deleteOMReturnReasonError = null;
      state.deleteOMReturnReasonData = null;
    });
    builder.addCase(
      deleteOMReturnReasonAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMReturnReasonStatus = asyncStatus.SUCCEEDED;
        state.getOMReturnReasonStatus = asyncStatus.SUCCEEDED;
        state.deleteOMReturnReasonData = payload.data;
        const { getOMReturnReasonData } = state;
        let updatedArray = getOMReturnReasonData?.filter(
          (x) => x.return_reason_id !== payload.data.return_reason_id
        );
        state.getOMReturnReasonData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMReturnReasonError = null;
      }
    );
    builder.addCase(deleteOMReturnReasonAsync.rejected, (state, actions) => {
      state.deleteOMReturnReasonData = null;
      state.deleteOMReturnReasonStatus = asyncStatus.ERROR;
      state.deleteOMReturnReasonError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Return Reason

    // OM Ship Via

    builder.addCase(getOMShipViaAsync.pending, (state, action) => {
      state.getOMShipViaStatus = asyncStatus.LOADING;
      state.getOMShipViaError = null;
      state.getOMShipViaData = null;
    });
    builder.addCase(getOMShipViaAsync.fulfilled, (state, { payload }) => {
      state.getOMShipViaStatus = asyncStatus.SUCCEEDED;
      state.getOMShipViaData = payload.data;
      state.getOMShipViaError = null;
    });
    builder.addCase(getOMShipViaAsync.rejected, (state, actions) => {
      state.getOMShipViaData = null;
      state.getOMShipViaStatus = asyncStatus.ERROR;
      state.getOMShipViaError = actions?.error?.message;
    });

    builder.addCase(addOMShipViaAsync.pending, (state, action) => {
      state.addOMShipViaStatus = asyncStatus.LOADING;
      state.addOMShipViaError = null;
      state.addOMShipViaData = null;
    });
    builder.addCase(addOMShipViaAsync.fulfilled, (state, { payload }) => {
      state.addOMShipViaStatus = asyncStatus.SUCCEEDED;
      state.addOMShipViaData = payload.data;
      // let { getOMShipViaData } = state;
      state.getOMShipViaData = [...state.getOMShipViaData, payload.data];
      success_toast_message(payload.message);
      state.addOMShipViaError = null;
    });
    builder.addCase(addOMShipViaAsync.rejected, (state, actions) => {
      state.addOMShipViaData = null;
      state.addOMShipViaStatus = asyncStatus.ERROR;
      state.addOMShipViaError = actions?.error?.message;
    });

    builder.addCase(editOMShipViaAsync.pending, (state, action) => {
      state.editOMShipViaStatus = asyncStatus.LOADING;
      state.editOMShipViaError = null;
      state.getOMShipViaStatus = asyncStatus.LOADING;
      state.editOMShipViaData = null;
    });
    builder.addCase(editOMShipViaAsync.fulfilled, (state, { payload }) => {
      state.editOMShipViaStatus = asyncStatus.SUCCEEDED;
      state.getOMShipViaStatus = asyncStatus.SUCCEEDED;
      state.editOMShipViaData = payload.data;
      const { getOMShipViaData } = state;
      let updatedArray = [];
      getOMShipViaData?.map((e) => {
        var item = e;
        if (item?.ship_via_id === payload?.data?.ship_via_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMShipViaData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMShipViaError = null;
    });
    builder.addCase(editOMShipViaAsync.rejected, (state, actions) => {
      state.editOMShipViaData = null;
      state.editOMShipViaStatus = asyncStatus.ERROR;
      state.editOMShipViaError = actions?.error?.message;
    });

    builder.addCase(deleteOMShipViaAsync.pending, (state, action) => {
      state.deleteOMShipViaStatus = asyncStatus.LOADING;
      state.getOMShipViaStatus = asyncStatus.LOADING;
      state.deleteOMShipViaError = null;
      state.deleteOMShipViaData = null;
    });
    builder.addCase(deleteOMShipViaAsync.fulfilled, (state, { payload }) => {
      state.deleteOMShipViaStatus = asyncStatus.SUCCEEDED;
      state.getOMShipViaStatus = asyncStatus.SUCCEEDED;
      state.deleteOMShipViaData = payload.data;
      const { getOMShipViaData } = state;
      let updatedArray = getOMShipViaData?.filter(
        (x) => x.ship_via_id !== payload.data.ship_via_id
      );
      state.getOMShipViaData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMShipViaError = null;
    });
    builder.addCase(deleteOMShipViaAsync.rejected, (state, actions) => {
      state.deleteOMShipViaData = null;
      state.deleteOMShipViaStatus = asyncStatus.ERROR;
      state.deleteOMShipViaError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Ship Via

    // OM PT Status

    builder.addCase(getOMPtStatusAsync.pending, (state, action) => {
      state.getOMPtStatusStatus = asyncStatus.LOADING;
      state.getOMPtStatusError = null;
      state.getOMPtStatusData = null;
    });
    builder.addCase(getOMPtStatusAsync.fulfilled, (state, { payload }) => {
      state.getOMPtStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMPtStatusData = payload.data;
      state.getOMPtStatusError = null;
    });
    builder.addCase(getOMPtStatusAsync.rejected, (state, actions) => {
      state.getOMPtStatusData = null;
      state.getOMPtStatusStatus = asyncStatus.ERROR;
      state.getOMPtStatusError = actions?.error?.message;
    });

    builder.addCase(addOMPtStatusAsync.pending, (state, action) => {
      state.addOMPtStatusStatus = asyncStatus.LOADING;
      state.addOMPtStatusError = null;
      state.addOMPtStatusData = null;
    });
    builder.addCase(addOMPtStatusAsync.fulfilled, (state, { payload }) => {
      state.addOMPtStatusStatus = asyncStatus.SUCCEEDED;
      state.addOMPtStatusData = payload.data;
      // let { getOMPtStatusData } = state;
      state.getOMPtStatusData = [...state.getOMPtStatusData, payload.data];
      success_toast_message(payload.message);
      state.addOMPtStatusError = null;
    });
    builder.addCase(addOMPtStatusAsync.rejected, (state, actions) => {
      state.addOMPtStatusData = null;
      state.addOMPtStatusStatus = asyncStatus.ERROR;
      state.addOMPtStatusError = actions?.error?.message;
    });

    builder.addCase(editOMPtStatusAsync.pending, (state, action) => {
      state.editOMPtStatusStatus = asyncStatus.LOADING;
      state.editOMPtStatusError = null;
      state.getOMPtStatusStatus = asyncStatus.LOADING;
      state.editOMPtStatusData = null;
    });
    builder.addCase(editOMPtStatusAsync.fulfilled, (state, { payload }) => {
      state.editOMPtStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMPtStatusStatus = asyncStatus.SUCCEEDED;
      state.editOMPtStatusData = payload.data;
      const { getOMPtStatusData } = state;
      let updatedArray = [];
      getOMPtStatusData?.map((e) => {
        var item = e;
        if (item?.pt_status_id === payload?.data?.pt_status_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMPtStatusData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMPtStatusError = null;
    });
    builder.addCase(editOMPtStatusAsync.rejected, (state, actions) => {
      state.editOMPtStatusData = null;
      state.editOMPtStatusStatus = asyncStatus.ERROR;
      state.editOMPtStatusError = actions?.error?.message;
    });

    builder.addCase(deleteOMPtStatusAsync.pending, (state, action) => {
      state.deleteOMPtStatusStatus = asyncStatus.LOADING;
      state.getOMPtStatusStatus = asyncStatus.LOADING;
      state.deleteOMPtStatusError = null;
      state.deleteOMPtStatusData = null;
    });
    builder.addCase(deleteOMPtStatusAsync.fulfilled, (state, { payload }) => {
      state.deleteOMPtStatusStatus = asyncStatus.SUCCEEDED;
      state.getOMPtStatusStatus = asyncStatus.SUCCEEDED;
      state.deleteOMPtStatusData = payload.data;
      const { getOMPtStatusData } = state;
      let updatedArray = getOMPtStatusData?.filter(
        (x) => x.pt_status_id !== payload.data.pt_status_id
      );
      state.getOMPtStatusData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMPtStatusError = null;
    });
    builder.addCase(deleteOMPtStatusAsync.rejected, (state, actions) => {
      state.deleteOMPtStatusData = null;
      state.deleteOMPtStatusStatus = asyncStatus.ERROR;
      state.deleteOMPtStatusError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM PT Status

    // OM Return Type

    builder.addCase(getOMReturnTypeAsync.pending, (state, action) => {
      state.getOMReturnTypeStatus = asyncStatus.LOADING;
      state.getOMReturnTypeError = null;
      state.getOMReturnTypeData = null;
    });
    builder.addCase(getOMReturnTypeAsync.fulfilled, (state, { payload }) => {
      state.getOMReturnTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMReturnTypeData = payload.data;
      state.getOMReturnTypeError = null;
    });
    builder.addCase(getOMReturnTypeAsync.rejected, (state, actions) => {
      state.getOMReturnTypeData = null;
      state.getOMReturnTypeStatus = asyncStatus.ERROR;
      state.getOMReturnTypeError = actions?.error?.message;
    });

    builder.addCase(addOMReturnTypeAsync.pending, (state, action) => {
      state.addOMReturnTypeStatus = asyncStatus.LOADING;
      state.addOMReturnTypeError = null;
      state.addOMReturnTypeData = null;
    });
    builder.addCase(addOMReturnTypeAsync.fulfilled, (state, { payload }) => {
      state.addOMReturnTypeStatus = asyncStatus.SUCCEEDED;
      state.addOMReturnTypeData = payload.data;
      // let { getOMReturnTypeData } = state;
      state.getOMReturnTypeData = [...state.getOMReturnTypeData, payload.data];
      success_toast_message(payload.message);
      state.addOMReturnTypeError = null;
    });
    builder.addCase(addOMReturnTypeAsync.rejected, (state, actions) => {
      state.addOMReturnTypeData = null;
      state.addOMReturnTypeStatus = asyncStatus.ERROR;
      state.addOMReturnTypeError = actions?.error?.message;
    });

    builder.addCase(editOMReturnTypeAsync.pending, (state, action) => {
      state.editOMReturnTypeStatus = asyncStatus.LOADING;
      state.editOMReturnTypeError = null;
      state.getOMReturnTypeStatus = asyncStatus.LOADING;
      state.editOMReturnTypeData = null;
    });
    builder.addCase(editOMReturnTypeAsync.fulfilled, (state, { payload }) => {
      state.editOMReturnTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMReturnTypeStatus = asyncStatus.SUCCEEDED;
      state.editOMReturnTypeData = payload.data;
      const { getOMReturnTypeData } = state;
      let updatedArray = [];
      getOMReturnTypeData?.map((e) => {
        var item = e;
        if (item?.return_type_id === payload?.data?.return_type_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMReturnTypeData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMReturnTypeError = null;
    });
    builder.addCase(editOMReturnTypeAsync.rejected, (state, actions) => {
      state.editOMReturnTypeData = null;
      state.editOMReturnTypeStatus = asyncStatus.ERROR;
      state.editOMReturnTypeError = actions?.error?.message;
    });

    builder.addCase(deleteOMReturnTypeAsync.pending, (state, action) => {
      state.deleteOMReturnTypeStatus = asyncStatus.LOADING;
      state.getOMReturnTypeStatus = asyncStatus.LOADING;
      state.deleteOMReturnTypeError = null;
      state.deleteOMReturnTypeData = null;
    });
    builder.addCase(deleteOMReturnTypeAsync.fulfilled, (state, { payload }) => {
      state.deleteOMReturnTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMReturnTypeStatus = asyncStatus.SUCCEEDED;
      state.deleteOMReturnTypeData = payload.data;
      const { getOMReturnTypeData } = state;
      let updatedArray = getOMReturnTypeData?.filter(
        (x) => x.return_type_id !== payload.data.return_type_id
      );
      state.getOMReturnTypeData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMReturnTypeError = null;
    });
    builder.addCase(deleteOMReturnTypeAsync.rejected, (state, actions) => {
      state.deleteOMReturnTypeData = null;
      state.deleteOMReturnTypeStatus = asyncStatus.ERROR;
      state.deleteOMReturnTypeError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Return Type

    // OM Vendor

    builder.addCase(addOMVendorAsync.pending, (state, action) => {
      state.addOMVendorStatus = asyncStatus.LOADING;
      state.addOMVendorError = null;
      state.addOMVendorData = null;
    });
    builder.addCase(addOMVendorAsync.fulfilled, (state, { payload }) => {
      state.addOMVendorStatus = asyncStatus.SUCCEEDED;
      state.addOMVendorData = payload.data;
      // let { getOMVendorData } = state;
      // state.getOMVendorData = [...state.getOMVendorData, payload.data];
      success_toast_message(payload.message);
      state.addOMVendorError = null;
    });
    builder.addCase(addOMVendorAsync.rejected, (state, actions) => {
      state.addOMVendorData = null;
      state.addOMVendorStatus = asyncStatus.ERROR;
      state.addOMVendorError = actions?.error?.message;
    });

    builder.addCase(searchOMVendorAsync.pending, (state, action) => {
      state.searchOMVendorStatus = asyncStatus.LOADING;
      state.searchOMVendorError = null;
      state.searchOMVendorData = null;
    });
    builder.addCase(searchOMVendorAsync.fulfilled, (state, { payload }) => {
      state.searchOMVendorStatus = asyncStatus.SUCCEEDED;
      state.searchOMVendorData = payload.data;
      // let { getOMVendorData } = state;
      // state.getOMVendorData = [...state.getOMVendorData, payload.data];
      success_toast_message(payload.message);
      state.searchOMVendorError = null;
    });
    builder.addCase(searchOMVendorAsync.rejected, (state, actions) => {
      state.searchOMVendorData = null;
      state.searchOMVendorStatus = asyncStatus.ERROR;
      state.searchOMVendorError = actions?.error?.message;
    });

    builder.addCase(getOMVendorByIdAsync.pending, (state, action) => {
      state.getVendorByIdStatus = asyncStatus.LOADING;
      state.getVendorByIdError = null;
      state.getVendorByIdData = null;
    });
    builder.addCase(getOMVendorByIdAsync.fulfilled, (state, { payload }) => {
      state.getVendorByIdStatus = asyncStatus.SUCCEEDED;
      state.getVendorByIdData = payload.data;
      // let { getOMVendorData } = state;
      // state.getOMVendorData = [...state.getOMVendorData, payload.data];
      // success_toast_message(payload.message);
      state.getVendorByIdError = null;
    });
    builder.addCase(getOMVendorByIdAsync.rejected, (state, actions) => {
      state.getVendorByIdData = null;
      state.getVendorByIdStatus = asyncStatus.ERROR;
      state.getVendorByIdError = actions?.error?.message;
    });

    builder.addCase(editVendorByIdAsync.pending, (state, action) => {
      state.editVendorByIdStatus = asyncStatus.LOADING;
      state.editVendorByIdError = null;
      state.editVendorByIdData = null;
    });
    builder.addCase(editVendorByIdAsync.fulfilled, (state, { payload }) => {
      state.editVendorByIdStatus = asyncStatus.SUCCEEDED;
      state.editVendorByIdData = payload.data;
      // let { editOMVendorData } = state;
      // state.editOMVendorData = [...state.editOMVendorData, payload.data];
      success_toast_message(payload.message);
      state.editVendorByIdError = null;
    });
    builder.addCase(editVendorByIdAsync.rejected, (state, actions) => {
      state.editVendorByIdData = null;
      state.editVendorByIdStatus = asyncStatus.ERROR;
      state.editVendorByIdError = actions?.error?.message;
    });

    builder.addCase(deleteVendorByIdAsync.pending, (state, action) => {
      state.deleteVendorByIdStatus = asyncStatus.LOADING;
      state.deleteVendorByIdError = null;
      state.deleteVendorByIdData = null;
    });
    builder.addCase(deleteVendorByIdAsync.fulfilled, (state, { payload }) => {
      state.deleteVendorByIdStatus = asyncStatus.SUCCEEDED;
      state.deleteVendorByIdData = payload.data;
      // let { deleteOMVendorData } = state;
      // state.deleteOMVendorData = [...state.deleteOMVendorData, payload.data];
      success_toast_message(payload.message);
      state.deleteVendorByIdError = null;
    });
    builder.addCase(deleteVendorByIdAsync.rejected, (state, actions) => {
      state.deleteVendorByIdData = null;
      state.deleteVendorByIdStatus = asyncStatus.ERROR;
      state.deleteVendorByIdError = actions?.error?.message;
    });

    // OM Vendor

    // OM Sales Target

    builder.addCase(getOMSalesTargetAsync.pending, (state, action) => {
      state.getOMSalesTargetStatus = asyncStatus.LOADING;
      state.getOMSalesTargetError = null;
      state.getOMSalesTargetData = null;
    });
    builder.addCase(getOMSalesTargetAsync.fulfilled, (state, { payload }) => {
      state.getOMSalesTargetStatus = asyncStatus.SUCCEEDED;
      state.getOMSalesTargetData = payload.data;
      state.getOMSalesTargetError = null;
    });
    builder.addCase(getOMSalesTargetAsync.rejected, (state, actions) => {
      state.getOMSalesTargetData = null;
      state.getOMSalesTargetStatus = asyncStatus.ERROR;
      state.getOMSalesTargetError = actions?.error?.message;
    });

    builder.addCase(addOMSalesTargetAsync.pending, (state, action) => {
      state.addOMSalesTargetStatus = asyncStatus.LOADING;
      state.addOMSalesTargetError = null;
      state.addOMSalesTargetData = null;
    });
    builder.addCase(addOMSalesTargetAsync.fulfilled, (state, { payload }) => {
      state.addOMSalesTargetStatus = asyncStatus.SUCCEEDED;
      state.addOMSalesTargetData = payload.data;
      // let { getOMSalesTargetData } = state;
      state.getOMSalesTargetData = [
        ...state.getOMSalesTargetData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMSalesTargetError = null;
    });
    builder.addCase(addOMSalesTargetAsync.rejected, (state, actions) => {
      state.addOMSalesTargetData = null;
      state.addOMSalesTargetStatus = asyncStatus.ERROR;
      state.addOMSalesTargetError = actions?.error?.message;
    });

    builder.addCase(editOMSalesTargetAsync.pending, (state, action) => {
      state.editOMSalesTargetStatus = asyncStatus.LOADING;
      state.editOMSalesTargetError = null;
      state.getOMSalesTargetStatus = asyncStatus.LOADING;
      state.editOMSalesTargetData = null;
    });
    builder.addCase(editOMSalesTargetAsync.fulfilled, (state, { payload }) => {
      state.editOMSalesTargetStatus = asyncStatus.SUCCEEDED;
      state.getOMSalesTargetStatus = asyncStatus.SUCCEEDED;
      state.editOMSalesTargetData = payload.data;
      const { getOMSalesTargetData } = state;
      let updatedArray = [];
      getOMSalesTargetData?.map((e) => {
        var item = e;
        if (item?.target_id === payload?.data?.target_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMSalesTargetData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMSalesTargetError = null;
    });
    builder.addCase(editOMSalesTargetAsync.rejected, (state, actions) => {
      state.editOMSalesTargetData = null;
      state.editOMSalesTargetStatus = asyncStatus.ERROR;
      state.editOMSalesTargetError = actions?.error?.message;
    });

    builder.addCase(deleteOMSalesTargetAsync.pending, (state, action) => {
      state.deleteOMSalesTargetStatus = asyncStatus.LOADING;
      state.getOMSalesTargetStatus = asyncStatus.LOADING;
      state.deleteOMSalesTargetError = null;
      state.deleteOMSalesTargetData = null;
    });
    builder.addCase(
      deleteOMSalesTargetAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMSalesTargetStatus = asyncStatus.SUCCEEDED;
        state.getOMSalesTargetStatus = asyncStatus.SUCCEEDED;
        state.deleteOMSalesTargetData = payload.data;
        const { getOMSalesTargetData } = state;
        let updatedArray = getOMSalesTargetData?.filter(
          (x) => x.target_id !== payload.data.target_id
        );
        state.getOMSalesTargetData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMSalesTargetError = null;
      }
    );
    builder.addCase(deleteOMSalesTargetAsync.rejected, (state, actions) => {
      state.deleteOMSalesTargetData = null;
      state.deleteOMSalesTargetStatus = asyncStatus.ERROR;
      state.deleteOMSalesTargetError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Sales Target

    // OM Brand

    builder.addCase(getOMBrandAsync.pending, (state, action) => {
      state.getOMBrandStatus = asyncStatus.LOADING;
      state.getOMBrandError = null;
      state.getOMBrandData = null;
    });
    builder.addCase(getOMBrandAsync.fulfilled, (state, { payload }) => {
      state.getOMBrandStatus = asyncStatus.SUCCEEDED;
      state.getOMBrandData = payload.data;
      state.getOMBrandError = null;
    });
    builder.addCase(getOMBrandAsync.rejected, (state, actions) => {
      state.getOMBrandData = null;
      state.getOMBrandStatus = asyncStatus.ERROR;
      state.getOMBrandError = actions?.error?.message;
    });

    // OM Brand

    // OM Figure

    builder.addCase(getOMFigureAsync.pending, (state, action) => {
      state.getOMFigureStatus = asyncStatus.LOADING;
      state.getOMFigureError = null;
      state.getOMFigureData = null;
    });
    builder.addCase(getOMFigureAsync.fulfilled, (state, { payload }) => {
      state.getOMFigureStatus = asyncStatus.SUCCEEDED;
      state.getOMFigureData = payload.data;
      state.getOMFigureError = null;
    });
    builder.addCase(getOMFigureAsync.rejected, (state, actions) => {
      state.getOMFigureData = null;
      state.getOMFigureStatus = asyncStatus.ERROR;
      state.getOMFigureError = actions?.error?.message;
    });

    builder.addCase(addOMFigureAsync.pending, (state, action) => {
      state.addOMFigureStatus = asyncStatus.LOADING;
      state.addOMFigureError = null;
      state.addOMFigureData = null;
    });
    builder.addCase(addOMFigureAsync.fulfilled, (state, { payload }) => {
      state.addOMFigureStatus = asyncStatus.SUCCEEDED;
      state.addOMFigureData = payload.data;
      // let { getOMFigureData } = state;
      state.getOMFigureData = [...state.getOMFigureData, payload.data];
      success_toast_message(payload.message);
      state.addOMFigureError = null;
    });
    builder.addCase(addOMFigureAsync.rejected, (state, actions) => {
      state.addOMFigureData = null;
      state.addOMFigureStatus = asyncStatus.ERROR;
      state.addOMFigureError = actions?.error?.message;
    });

    builder.addCase(editOMFigureAsync.pending, (state, action) => {
      state.editOMFigureStatus = asyncStatus.LOADING;
      state.editOMFigureError = null;
      state.getOMFigureStatus = asyncStatus.LOADING;
      state.editOMFigureData = null;
    });
    builder.addCase(editOMFigureAsync.fulfilled, (state, { payload }) => {
      state.editOMFigureStatus = asyncStatus.SUCCEEDED;
      state.getOMFigureStatus = asyncStatus.SUCCEEDED;
      state.editOMFigureData = payload.data;
      const { getOMFigureData } = state;
      let updatedArray = [];
      getOMFigureData?.map((e) => {
        var item = e;
        if (item?.figure_id === payload?.data?.figure_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMFigureData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMFigureError = null;
    });
    builder.addCase(editOMFigureAsync.rejected, (state, actions) => {
      state.editOMFigureData = null;
      state.editOMFigureStatus = asyncStatus.ERROR;
      state.editOMFigureError = actions?.error?.message;
    });

    builder.addCase(deleteOMFigureAsync.pending, (state, action) => {
      state.deleteOMFigureStatus = asyncStatus.LOADING;
      state.getOMFigureStatus = asyncStatus.LOADING;
      state.deleteOMFigureError = null;
      state.deleteOMFigureData = null;
    });
    builder.addCase(deleteOMFigureAsync.fulfilled, (state, { payload }) => {
      state.deleteOMFigureStatus = asyncStatus.SUCCEEDED;
      state.getOMFigureStatus = asyncStatus.SUCCEEDED;
      state.deleteOMFigureData = payload.data;
      const { getOMFigureData } = state;
      let updatedArray = getOMFigureData?.filter(
        (x) => x.figure_id !== payload.data.figure_id
      );
      state.getOMFigureData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMFigureError = null;
    });
    builder.addCase(deleteOMFigureAsync.rejected, (state, actions) => {
      state.deleteOMFigureData = null;
      state.deleteOMFigureStatus = asyncStatus.ERROR;
      state.deleteOMFigureError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM Figure

    // OM CustomerCategory

    builder.addCase(getOMCustomerCategoryAsync.pending, (state, action) => {
      state.getOMCustomerCategoryStatus = asyncStatus.LOADING;
      state.getOMCustomerCategoryError = null;
      state.getOMCustomerCategoryData = null;
    });
    builder.addCase(
      getOMCustomerCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.getOMCustomerCategoryStatus = asyncStatus.SUCCEEDED;
        state.getOMCustomerCategoryData = payload.data;
        state.getOMCustomerCategoryError = null;
      }
    );
    builder.addCase(getOMCustomerCategoryAsync.rejected, (state, actions) => {
      state.getOMCustomerCategoryData = null;
      state.getOMCustomerCategoryStatus = asyncStatus.ERROR;
      state.getOMCustomerCategoryError = actions?.error?.message;
    });

    builder.addCase(addOMCustomerCategoryAsync.pending, (state, action) => {
      state.addOMCustomerCategoryStatus = asyncStatus.LOADING;
      state.addOMCustomerCategoryError = null;
      state.addOMCustomerCategoryData = null;
    });
    builder.addCase(
      addOMCustomerCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.addOMCustomerCategoryStatus = asyncStatus.SUCCEEDED;
        state.addOMCustomerCategoryData = payload.data;
        // let { getOMCustomerCategoryData } = state;
        state.getOMCustomerCategoryData = [
          ...state.getOMCustomerCategoryData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addOMCustomerCategoryError = null;
      }
    );
    builder.addCase(addOMCustomerCategoryAsync.rejected, (state, actions) => {
      state.addOMCustomerCategoryData = null;
      state.addOMCustomerCategoryStatus = asyncStatus.ERROR;
      state.addOMCustomerCategoryError = actions?.error?.message;
    });

    builder.addCase(editOMCustomerCategoryAsync.pending, (state, action) => {
      state.editOMCustomerCategoryStatus = asyncStatus.LOADING;
      state.editOMCustomerCategoryError = null;
      state.getOMCustomerCategoryStatus = asyncStatus.LOADING;
      state.editOMCustomerCategoryData = null;
    });
    builder.addCase(
      editOMCustomerCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.editOMCustomerCategoryStatus = asyncStatus.SUCCEEDED;
        state.getOMCustomerCategoryStatus = asyncStatus.SUCCEEDED;
        state.editOMCustomerCategoryData = payload.data;
        const { getOMCustomerCategoryData } = state;
        let updatedArray = [];
        getOMCustomerCategoryData?.map((e) => {
          var item = e;
          if (
            item?.customer_category_id === payload?.data?.customer_category_id
          ) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMCustomerCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMCustomerCategoryError = null;
      }
    );
    builder.addCase(editOMCustomerCategoryAsync.rejected, (state, actions) => {
      state.editOMCustomerCategoryData = null;
      state.editOMCustomerCategoryStatus = asyncStatus.ERROR;
      state.editOMCustomerCategoryError = actions?.error?.message;
    });

    builder.addCase(deleteOMCustomerCategoryAsync.pending, (state, action) => {
      state.deleteOMCustomerCategoryStatus = asyncStatus.LOADING;
      state.getOMCustomerCategoryStatus = asyncStatus.LOADING;
      state.deleteOMCustomerCategoryError = null;
      state.deleteOMCustomerCategoryData = null;
    });
    builder.addCase(
      deleteOMCustomerCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMCustomerCategoryStatus = asyncStatus.SUCCEEDED;
        state.getOMCustomerCategoryStatus = asyncStatus.SUCCEEDED;
        state.deleteOMCustomerCategoryData = payload.data;
        const { getOMCustomerCategoryData } = state;
        let updatedArray = getOMCustomerCategoryData?.filter(
          (x) => x.customer_category_id !== payload.data.customer_category_id
        );
        state.getOMCustomerCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMCustomerCategoryError = null;
      }
    );
    builder.addCase(
      deleteOMCustomerCategoryAsync.rejected,
      (state, actions) => {
        state.deleteOMCustomerCategoryData = null;
        state.deleteOMCustomerCategoryStatus = asyncStatus.ERROR;
        state.deleteOMCustomerCategoryError = actions?.error?.message;
        error_toast_message(actions?.error?.message);
      }
    );

    // OM CustomerCategory

    // OM CAT

    builder.addCase(getOMCATAsync.pending, (state, action) => {
      state.getOMCATStatus = asyncStatus.LOADING;
      state.getOMCATError = null;
      state.getOMCATData = null;
    });
    builder.addCase(getOMCATAsync.fulfilled, (state, { payload }) => {
      state.getOMCATStatus = asyncStatus.SUCCEEDED;
      state.getOMCATData = payload.data;
      state.getOMCATError = null;
    });
    builder.addCase(getOMCATAsync.rejected, (state, actions) => {
      state.getOMCATData = null;
      state.getOMCATStatus = asyncStatus.ERROR;
      state.getOMCATError = actions?.error?.message;
    });

    builder.addCase(addOMCATAsync.pending, (state, action) => {
      state.addOMCATStatus = asyncStatus.LOADING;
      state.addOMCATError = null;
      state.addOMCATData = null;
    });
    builder.addCase(addOMCATAsync.fulfilled, (state, { payload }) => {
      state.addOMCATStatus = asyncStatus.SUCCEEDED;
      state.addOMCATData = payload.data;
      // let { getOMCATData } = state;
      state.getOMCATData = [...state.getOMCATData, payload.data];
      success_toast_message(payload.message);
      state.addOMCATError = null;
    });
    builder.addCase(addOMCATAsync.rejected, (state, actions) => {
      state.addOMCATData = null;
      state.addOMCATStatus = asyncStatus.ERROR;
      state.addOMCATError = actions?.error?.message;
    });

    builder.addCase(editOMCATAsync.pending, (state, action) => {
      state.editOMCATStatus = asyncStatus.LOADING;
      state.editOMCATError = null;
      state.getOMCATStatus = asyncStatus.LOADING;
      state.editOMCATData = null;
    });
    builder.addCase(editOMCATAsync.fulfilled, (state, { payload }) => {
      state.editOMCATStatus = asyncStatus.SUCCEEDED;
      state.getOMCATStatus = asyncStatus.SUCCEEDED;
      state.editOMCATData = payload.data;
      const { getOMCATData } = state;
      let updatedArray = [];
      getOMCATData?.map((e) => {
        var item = e;
        if (
          item?.sp_region_category_id === payload?.data?.sp_region_category_id
        ) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMCATData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMCATError = null;
    });
    builder.addCase(editOMCATAsync.rejected, (state, actions) => {
      state.editOMCATData = null;
      state.editOMCATStatus = asyncStatus.ERROR;
      state.editOMCATError = actions?.error?.message;
    });

    builder.addCase(deleteOMCATAsync.pending, (state, action) => {
      state.deleteOMCATStatus = asyncStatus.LOADING;
      state.getOMCATStatus = asyncStatus.LOADING;
      state.deleteOMCATError = null;
      state.deleteOMCATData = null;
    });
    builder.addCase(deleteOMCATAsync.fulfilled, (state, { payload }) => {
      state.deleteOMCATStatus = asyncStatus.SUCCEEDED;
      state.getOMCATStatus = asyncStatus.SUCCEEDED;
      state.deleteOMCATData = payload.data;
      const { getOMCATData } = state;
      let updatedArray = getOMCATData?.filter(
        (x) => x.sp_region_category_id !== payload.data.sp_region_category_id
      );
      state.getOMCATData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteOMCATError = null;
    });
    builder.addCase(deleteOMCATAsync.rejected, (state, actions) => {
      state.deleteOMCATData = null;
      state.deleteOMCATStatus = asyncStatus.ERROR;
      state.deleteOMCATError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM CAT

    // OM BergenBilling

    builder.addCase(getOMBergenBillingAsync.pending, (state, action) => {
      state.getOMBergenBillingStatus = asyncStatus.LOADING;
      state.getOMBergenBillingError = null;
      state.getOMBergenBillingData = null;
    });
    builder.addCase(getOMBergenBillingAsync.fulfilled, (state, { payload }) => {
      state.getOMBergenBillingStatus = asyncStatus.SUCCEEDED;
      state.getOMBergenBillingData = payload.data;
      state.getOMBergenBillingError = null;
    });
    builder.addCase(getOMBergenBillingAsync.rejected, (state, actions) => {
      state.getOMBergenBillingData = null;
      state.getOMBergenBillingStatus = asyncStatus.ERROR;
      state.getOMBergenBillingError = actions?.error?.message;
    });

    builder.addCase(addOMBergenBillingAsync.pending, (state, action) => {
      state.addOMBergenBillingStatus = asyncStatus.LOADING;
      state.addOMBergenBillingError = null;
      state.addOMBergenBillingData = null;
    });
    builder.addCase(addOMBergenBillingAsync.fulfilled, (state, { payload }) => {
      state.addOMBergenBillingStatus = asyncStatus.SUCCEEDED;
      state.addOMBergenBillingData = payload.data;
      // let { getOMBergenBillingData } = state;
      state.getOMBergenBillingData = [
        ...state.getOMBergenBillingData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMBergenBillingError = null;
    });
    builder.addCase(addOMBergenBillingAsync.rejected, (state, actions) => {
      state.addOMBergenBillingData = null;
      state.addOMBergenBillingStatus = asyncStatus.ERROR;
      state.addOMBergenBillingError = actions?.error?.message;
    });

    builder.addCase(editOMBergenBillingAsync.pending, (state, action) => {
      state.editOMBergenBillingStatus = asyncStatus.LOADING;
      state.editOMBergenBillingError = null;
      state.getOMBergenBillingStatus = asyncStatus.LOADING;
      state.editOMBergenBillingData = null;
    });
    builder.addCase(
      editOMBergenBillingAsync.fulfilled,
      (state, { payload }) => {
        state.editOMBergenBillingStatus = asyncStatus.SUCCEEDED;
        state.getOMBergenBillingStatus = asyncStatus.SUCCEEDED;
        state.editOMBergenBillingData = payload.data;
        const { getOMBergenBillingData } = state;
        let updatedArray = [];
        getOMBergenBillingData?.map((e) => {
          var item = e;
          if (item?.bergan_billing_id === payload?.data?.bergan_billing_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        state.getOMBergenBillingData = [...updatedArray];
        success_toast_message(payload.message);
        state.editOMBergenBillingError = null;
      }
    );
    builder.addCase(editOMBergenBillingAsync.rejected, (state, actions) => {
      state.editOMBergenBillingData = null;
      state.editOMBergenBillingStatus = asyncStatus.ERROR;
      state.editOMBergenBillingError = actions?.error?.message;
    });

    builder.addCase(deleteOMBergenBillingAsync.pending, (state, action) => {
      state.deleteOMBergenBillingStatus = asyncStatus.LOADING;
      state.getOMBergenBillingStatus = asyncStatus.LOADING;
      state.deleteOMBergenBillingError = null;
      state.deleteOMBergenBillingData = null;
    });
    builder.addCase(
      deleteOMBergenBillingAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMBergenBillingStatus = asyncStatus.SUCCEEDED;
        state.getOMBergenBillingStatus = asyncStatus.SUCCEEDED;
        state.deleteOMBergenBillingData = payload.data;
        const { getOMBergenBillingData } = state;
        let updatedArray = getOMBergenBillingData?.filter(
          (x) => x.bergan_billing_id !== payload.data.bergan_billing_id
        );
        state.getOMBergenBillingData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMBergenBillingError = null;
      }
    );
    builder.addCase(deleteOMBergenBillingAsync.rejected, (state, actions) => {
      state.deleteOMBergenBillingData = null;
      state.deleteOMBergenBillingStatus = asyncStatus.ERROR;
      state.deleteOMBergenBillingError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM BergenBilling

    // OM SalesPerson

    builder.addCase(getOMSalesPersonAsync.pending, (state, action) => {
      state.getOMSalesPersonStatus = asyncStatus.LOADING;
      state.getOMSalesPersonError = null;
      state.getOMSalesPersonData = null;
    });
    builder.addCase(getOMSalesPersonAsync.fulfilled, (state, { payload }) => {
      state.getOMSalesPersonStatus = asyncStatus.SUCCEEDED;
      state.getOMSalesPersonData = payload.data;
      state.getOMSalesPersonError = null;
    });
    builder.addCase(getOMSalesPersonAsync.rejected, (state, actions) => {
      state.getOMSalesPersonData = null;
      state.getOMSalesPersonStatus = asyncStatus.ERROR;
      state.getOMSalesPersonError = actions?.error?.message;
    });

    builder.addCase(addOMSalesPersonAsync.pending, (state, action) => {
      state.addOMSalesPersonStatus = asyncStatus.LOADING;
      state.addOMSalesPersonError = null;
      state.addOMSalesPersonData = null;
    });
    builder.addCase(addOMSalesPersonAsync.fulfilled, (state, { payload }) => {
      state.addOMSalesPersonStatus = asyncStatus.SUCCEEDED;
      state.addOMSalesPersonData = payload.data;
      // let { getOMSalesPersonData } = state;
      state.getOMSalesPersonData = [
        ...state.getOMSalesPersonData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addOMSalesPersonError = null;
    });
    builder.addCase(addOMSalesPersonAsync.rejected, (state, actions) => {
      state.addOMSalesPersonData = null;
      state.addOMSalesPersonStatus = asyncStatus.ERROR;
      state.addOMSalesPersonError = actions?.error?.message;
    });

    builder.addCase(editOMSalesPersonAsync.pending, (state, action) => {
      state.editOMSalesPersonStatus = asyncStatus.LOADING;
      state.editOMSalesPersonError = null;
      state.getOMSalesPersonStatus = asyncStatus.LOADING;
      state.editOMSalesPersonData = null;
    });
    builder.addCase(editOMSalesPersonAsync.fulfilled, (state, { payload }) => {
      state.editOMSalesPersonStatus = asyncStatus.SUCCEEDED;
      state.getOMSalesPersonStatus = asyncStatus.SUCCEEDED;
      state.editOMSalesPersonData = payload.data;
      const { getOMSalesPersonData } = state;
      let updatedArray = [];
      getOMSalesPersonData?.map((e) => {
        var item = e;
        if (item?.sales_person_id === payload?.data?.sales_person_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getOMSalesPersonData = [...updatedArray];
      success_toast_message(payload.message);
      state.editOMSalesPersonError = null;
    });
    builder.addCase(editOMSalesPersonAsync.rejected, (state, actions) => {
      state.editOMSalesPersonData = null;
      state.editOMSalesPersonStatus = asyncStatus.ERROR;
      state.editOMSalesPersonError = actions?.error?.message;
    });

    builder.addCase(deleteOMSalesPersonAsync.pending, (state, action) => {
      state.deleteOMSalesPersonStatus = asyncStatus.LOADING;
      state.getOMSalesPersonStatus = asyncStatus.LOADING;
      state.deleteOMSalesPersonError = null;
      state.deleteOMSalesPersonData = null;
    });
    builder.addCase(
      deleteOMSalesPersonAsync.fulfilled,
      (state, { payload }) => {
        state.deleteOMSalesPersonStatus = asyncStatus.SUCCEEDED;
        state.getOMSalesPersonStatus = asyncStatus.SUCCEEDED;
        state.deleteOMSalesPersonData = payload.data;
        const { getOMSalesPersonData } = state;
        let updatedArray = getOMSalesPersonData?.filter(
          (x) => x.sales_person_id !== payload.data.sales_person_id
        );
        state.getOMSalesPersonData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteOMSalesPersonError = null;
      }
    );
    builder.addCase(deleteOMSalesPersonAsync.rejected, (state, actions) => {
      state.deleteOMSalesPersonData = null;
      state.deleteOMSalesPersonStatus = asyncStatus.ERROR;
      state.deleteOMSalesPersonError = actions?.error?.message;
      error_toast_message(actions?.error?.message);
    });

    // OM SalesPerson

    // OM Months

    builder.addCase(getOMMonthsAsync.pending, (state, action) => {
      state.getOMMonthsStatus = asyncStatus.LOADING;
      state.getOMMonthsError = null;
      state.getOMMonthsData = null;
    });
    builder.addCase(getOMMonthsAsync.fulfilled, (state, { payload }) => {
      state.getOMMonthsStatus = asyncStatus.SUCCEEDED;
      state.getOMMonthsData = payload.data;
      state.getOMMonthsError = null;
    });
    builder.addCase(getOMMonthsAsync.rejected, (state, actions) => {
      state.getOMMonthsData = null;
      state.getOMMonthsStatus = asyncStatus.ERROR;
      state.getOMMonthsError = actions?.error?.message;
    });

    // OM Months

    // OM Years

    builder.addCase(getOMYearsAsync.pending, (state, action) => {
      state.getOMYearsStatus = asyncStatus.LOADING;
      state.getOMYearsError = null;
      state.getOMYearsData = null;
    });
    builder.addCase(getOMYearsAsync.fulfilled, (state, { payload }) => {
      state.getOMYearsStatus = asyncStatus.SUCCEEDED;
      state.getOMYearsData = payload.data;
      state.getOMYearsError = null;
    });
    builder.addCase(getOMYearsAsync.rejected, (state, actions) => {
      state.getOMYearsData = null;
      state.getOMYearsStatus = asyncStatus.ERROR;
      state.getOMYearsError = actions?.error?.message;
    });

    // OM Years

    // OM ShippingMethod

    builder.addCase(getOMShippingMethodAsync.pending, (state, action) => {
      state.getOMShippingMethodStatus = asyncStatus.LOADING;
      state.getOMShippingMethodError = null;
      state.getOMShippingMethodData = null;
    });
    builder.addCase(
      getOMShippingMethodAsync.fulfilled,
      (state, { payload }) => {
        state.getOMShippingMethodStatus = asyncStatus.SUCCEEDED;
        state.getOMShippingMethodData = payload.data;
        state.getOMShippingMethodError = null;
      }
    );
    builder.addCase(getOMShippingMethodAsync.rejected, (state, actions) => {
      state.getOMShippingMethodData = null;
      state.getOMShippingMethodStatus = asyncStatus.ERROR;
      state.getOMShippingMethodError = actions?.error?.message;
    });

    // OM ShippingMethod

    // OM OrderType

    builder.addCase(getOMOrderTypeAsync.pending, (state, action) => {
      state.getOMOrderTypeStatus = asyncStatus.LOADING;
      state.getOMOrderTypeError = null;
      state.getOMOrderTypeData = null;
    });
    builder.addCase(getOMOrderTypeAsync.fulfilled, (state, { payload }) => {
      state.getOMOrderTypeStatus = asyncStatus.SUCCEEDED;
      state.getOMOrderTypeData = payload.data;
      state.getOMOrderTypeError = null;
    });
    builder.addCase(getOMOrderTypeAsync.rejected, (state, actions) => {
      state.getOMOrderTypeData = null;
      state.getOMOrderTypeStatus = asyncStatus.ERROR;
      state.getOMOrderTypeError = actions?.error?.message;
    });

    // OM OrderType

    // CUSTOMER CATEGORY

    // builder.addCase(getOMCustomerCategoryAsync.pending, (state, action) => {
    //   state.getOMCustomerTypeStatus = asyncStatus.LOADING;
    //   state.getOMCustomerTypeError = null;
    //   state.getOMCustomerTypeData = null;
    // });
    // builder.addCase(getOMCustomerCategoryAsync.fulfilled, (state, { payload }) => {
    //   state.getOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
    //   state.getOMCustomerTypeData = payload.data;
    //   state.getOMCustomerTypeError = null;
    // });
    // builder.addCase(getOMCustomerCategoryAsync.rejected, (state, actions) => {
    //   state.getOMCustomerTypeData = null;
    //   state.getOMCustomerTypeStatus = asyncStatus.ERROR;
    //   state.getOMCustomerTypeError = actions?.error?.message;
    // });

    // builder.addCase(addOMCustomerCategoryAsync.pending, (state, action) => {
    //   state.addOMCustomerTypeStatus = asyncStatus.LOADING;
    //   state.addOMCustomerTypeError = null;
    //   state.addOMCustomerTypeData = null;
    // });
    // builder.addCase(addOMCustomerCategoryAsync.fulfilled, (state, { payload }) => {
    //   state.addOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
    //   state.addOMCustomerTypeData = payload.data;
    //   // let { getOMCustomerTypeData } = state;
    //   state.getOMCustomerTypeData = [
    //     ...state.getOMCustomerTypeData,
    //     payload.data,
    //   ];
    //   success_toast_message(payload.message);
    //   state.addOMCustomerTypeError = null;
    // });
    // builder.addCase(addOMCustomerCategoryAsync.rejected, (state, actions) => {
    //   state.addOMCustomerTypeData = null;
    //   state.addOMCustomerTypeStatus = asyncStatus.ERROR;
    //   state.addOMCustomerTypeError = actions?.error?.message;
    // });

    // builder.addCase(editOMCustomerCategoryAsync.pending, (state, action) => {
    //   state.editOMCustomerTypeStatus = asyncStatus.LOADING;
    //   state.editOMCustomerTypeError = null;
    //   state.getOMCustomerTypeStatus = asyncStatus.LOADING;
    //   state.editOMCustomerTypeData = null;
    // });
    // builder.addCase(editOMCustomerCategoryAsync.fulfilled, (state, { payload }) => {
    //   state.editOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
    //   state.getOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
    //   state.editOMCustomerTypeData = payload.data;
    //   const { getOMCustomerTypeData } = state;
    //   let updatedArray = [];
    //   getOMCustomerTypeData?.map((e) => {
    //     var item = e;
    //     if (item?.customer_type_id === payload?.data?.customer_type_id) {
    //       item = payload.data;
    //     }
    //     updatedArray.push(item);
    //   });
    //   state.getOMCustomerTypeData = [...updatedArray];
    //   success_toast_message(payload.message);
    //   state.editOMCustomerTypeError = null;
    // });
    // builder.addCase(editOMCustomerCategoryAsync.rejected, (state, actions) => {
    //   state.editOMCustomerTypeData = null;
    //   state.editOMCustomerTypeStatus = asyncStatus.ERROR;
    //   state.editOMCustomerTypeError = actions?.error?.message;
    // });

    // builder.addCase(deleteOMCustomerCategoryAsync.pending, (state, action) => {
    //   state.deleteOMCustomerTypeStatus = asyncStatus.LOADING;
    //   state.getOMCustomerTypeStatus = asyncStatus.LOADING;
    //   state.deleteOMCustomerTypeError = null;
    //   state.deleteOMCustomerTypeData = null;
    // });
    // builder.addCase(
    //   deleteOMCustomerCategoryAsync.fulfilled,
    //   (state, { payload }) => {
    //     state.deleteOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
    //     state.getOMCustomerTypeStatus = asyncStatus.SUCCEEDED;
    //     state.deleteOMCustomerTypeData = payload.data;
    //     const { getOMCustomerTypeData } = state;
    //     let updatedArray = getOMCustomerTypeData?.filter(
    //       (x) => x.customer_type_id !== payload.data.customer_type_id
    //     );
    //     state.getOMCustomerTypeData = [...updatedArray];
    //     success_toast_message(payload.message);
    //     state.deleteOMCustomerTypeError = null;
    //   }
    // );
    // builder.addCase(deleteOMCustomerCategoryAsync.rejected, (state, actions) => {
    //   state.deleteOMCustomerTypeData = null;
    //   state.deleteOMCustomerTypeStatus = asyncStatus.ERROR;
    //   state.deleteOMCustomerTypeError = actions?.error?.message;
    //   error_toast_message(actions?.error?.message);
    // });






    builder.addCase(getOMCustomerInformationAsync.pending, (state, action) => {
      state.getOMCustomerInformationStatus = asyncStatus.LOADING;
      state.getOMCustomerCategoryError = null;
      state.getOMCustomerCategoryData = null;
    });
    builder.addCase(
      getOMCustomerInformationAsync.fulfilled,
      (state, { payload }) => {
        state.getOMCustomerCategoryStatus = asyncStatus.SUCCEEDED;
        state.getOMCustomerCategoryData = payload.data;
        state.getOMCustomerCategoryError = null;
      }
    );
    builder.addCase(getOMCustomerInformationAsync.rejected, (state, actions) => {
      state.getOMCustomerCategoryData = null;
      state.getOMCustomerCategoryStatus = asyncStatus.ERROR;
      state.getOMCustomerCategoryError = actions?.error?.message;
    });
  },
});

export const { setEditVendorByIdEmpty } = OMSlice.actions;
export default OMSlice.reducer;
