import { Stack } from "@mui/material";

import "./CustomDrawer.css";
import { primaryColor } from "../../utils/themeColors";
import { menuItems } from "../../utils/tempObjMenu";
import CustomMenu from "../common/CustomMenu/CustomMenu";
const CustomDrawer = () => {
  return (
    <Stack
      // width={"100%"}
      flexDirection={"row"}
      sx={{
        backgroundColor: primaryColor,
        overflowX: "scroll",
        whiteSpace: "nowrap",
        "&::-webkit-scrollbar": {
          width: "5px",
          backgroundColor: "#F5F5F5",
          display: "none",
        },
      }}
      paddingY={"10px"}
      alignItems={"center"}
      paddingLeft={2}
      gap={4}
      className={"removeScroll"}
    >
      {menuItems?.map((e, i) => {
        return <CustomMenu dataObj={e} key={i} />;
      })}
    </Stack>
  );
};

export default CustomDrawer;
