import { Stack, Typography } from '@mui/material'
import React from 'react'
import { primaryColor } from '../../../utils/themeColors'
const ScreenCard = ({title="Title",customHeader,children}) => {
  return (
    <Stack
    sx={{
      borderRadius: 5,
      backgroundColor: "white",
      boxShadow: "1px 1px 20px 0px rgb(0 0 0 / 14%)",
      border:"1px solid gray",
      overflow:"hidden"
    }}
  >
   {customHeader|| <Stack sx={{ padding: 0.5,pl:4, backgroundColor: primaryColor }}>
      <Typography sx={{color:"white",fontWeight:"bold"}}>{title}</Typography>
    </Stack>}
    {children ||  <Stack padding={5}>
    <Typography>Data</Typography>
    </Stack>}
  </Stack>
  )
}
export default ScreenCard