import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
  Box,
  Card,
  CardActions,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  AppBar,
  Tabs,
  Tab,
  SwipeableViews,
  Checkbox,
  Chip,
  Button,
} from "@mui/material";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import InputComp from "../../../../components/common/InputComp/Input";
import InfoFormComp from "../../../../components/InfoForm/InfoFormComp";
import Summary from "../../../../components/Summary/summary";
import BillingShippingInformation from "../../../../components/BillingShippingInformation/BillingShippingInformation";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableComp from "../../../../components/common/TableComp/TableComp";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { themeLightGray, primaryColor } from "../../../../utils/themeColors";
import AddItems from "./AddItems";
import { useNavigate, useParams } from "react-router-dom";
import FiltersAccordionFom from "../../../../components/FiltersAccordionFom/FiltersAccordionFom";
import VirtualizedSelect from "../../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
import RestoreIcon from "@mui/icons-material/Restore";
const SaleOrder = () => {
  const [formState, setFormState] = useState("View");
  const [formData, setFormData] = useState({
    // Primary Information
    order_number: null,
    order_date: null,
    po_number: null,
    source_type: null,
    customer_name: null,
    ref_id: null,
    memo: null,
    subsidary: null,
    // Billing Information
    billing: null,
    billing_first_name: null,
    billing_last_name: null,
    billing_address1: null,
    billing_address2: null,
    billing_city: null,
    billing_state: null,
    billing_zip: null,
    billing_country: null,
    billing_tel: null,
    billing_email: null,
    // Shipping Information
    shipping: null,
    shipping_first_name: null,
    shipping_last_name: null,
    shipping_address1: null,
    shipping_address2: null,
    shipping_city: null,
    shipping_state: null,
    shipping_zip: null,
    shipping_country: null,
    shipping_tel: null,
    shipping_email: null,
    // Date
    start_date: null,
    cancel_date: null,
    // Sales Information
    gender: null,
    category: null,
    region: null,
    sale_person: null,
    location: null,
    // Classification
    // Shipping
    shipping_account_no: null,
    ship_via: null,
    shipping_method: null,
    shipping_cost: null,
    shipping_tax_code: null,
    shipping_tax_rate: null,
    frieght_term: null,
    bergan_billing_option: null,
    bergan_payment_term: null,
    ship_complete: false,
    // Billing
    term_print: null,
    payment_term: null,
    // Edi
    mark_code: null,
    mark_company: null,
    mark_address1: null,
    mark_address2: null,
    mark_city: null,
    mark_state: null,
    mark_zip: null,
    mark_country: null,
    mark_email: null,
    mark_phone: null,
    // Other
    order_status: null,
    factor: null,
    factor_decision: null,
    dc_no: null,
    resale_certificate: null,
    resale_certificate_date: null,
    store: null,
    department_desc: null,
    department_code: null,
    division_desc: null,
    division_code: null,
    ship_to_code: null,
    special_instruction: null,
    bill_to_non_us: null,
    ship_to_non_us: null,
    order_type: null,
    tax_duties: null,
    closed: false,

    // Discount
    discount: null,
    discount_code: null,
    discount_type: null,
    remarks: null,
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const params = useParams();
  let param = params["*"];
  console.log("params", param);
  const mode = "abc";

  const addHandler = () => {
    navigate(`/sale-order?mode=add`);
    setFormState("add");
    // router.push(pathname + "?" + "mode=add");
    setFormData({
      // Primary Information
      order_number: null,
      order_date: null,
      po_number: null,
      source_type: null,
      customer_name: null,
      ref_id: null,
      memo: null,
      subsidary: null,
      // Billing Information
      billing: null,
      billing_first_name: null,
      billing_last_name: null,
      billing_address1: null,
      billing_address2: null,
      billing_city: null,
      billing_state: null,
      billing_zip: null,
      billing_country: null,
      billing_tel: null,
      billing_email: null,
      // Shipping Information
      shipping: null,
      shipping_first_name: null,
      shipping_last_name: null,
      shipping_address1: null,
      shipping_address2: null,
      shipping_city: null,
      shipping_state: null,
      shipping_zip: null,
      shipping_country: null,
      shipping_tel: null,
      shipping_email: null,
      // Date
      start_date: null,
      cancel_date: null,
      // Sales Information
      gender: null,
      category: null,
      region: null,
      sale_person: null,
      location: null,
      // Classification
      // Shipping
      shipping_account_no: null,
      ship_via: null,
      shipping_method: null,
      shipping_cost: null,
      shipping_tax_code: null,
      shipping_tax_rate: null,
      frieght_term: null,
      bergan_billing_option: null,
      bergan_payment_term: null,
      ship_complete: false,
      // Billing
      term_print: null,
      payment_term: null,
      // Edi
      mark_code: null,
      mark_company: null,
      mark_address1: null,
      mark_address2: null,
      mark_city: null,
      mark_state: null,
      mark_zip: null,
      mark_country: null,
      mark_email: null,
      mark_phone: null,
      // Other
      order_status: null,
      factor: null,
      factor_decision: null,
      dc_no: null,
      resale_certificate: null,
      resale_certificate_date: null,
      store: null,
      department_desc: null,
      department_code: null,
      division_desc: null,
      division_code: null,
      ship_to_code: null,
      special_instruction: null,
      bill_to_non_us: null,
      ship_to_non_us: null,
      order_type: null,
      tax_duties: null,
      closed: false,

      // Discount
      discount: null,
      discount_code: null,
      discount_type: null,
      remarks: null,
    });
  };

  const disableHandler = () => {
    if (formState === "View") {
      return true;
    } else {
      return false;
    }
  };

  // ONSAVE FORM
  const onFinish = (event) => {
    event.preventDefault();

    if (mode === "add") {
      // ....
    } else if (mode === "edit") {
      // ....
    }
    console.log("mode===>>>", mode);
    console.log("formData", formData);
  };

  return (
    <Stack component={"form"} onSubmit={onFinish} padding={2}>
      <ScreenCard
        customHeader={
          <Stack
            sx={{
              py: 1,
              px: 2,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack flexDirection={"row"} gap={3} alignItems={"center"}>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                Sale Order
              </Typography>
              {param !== "search" && (
                <Chip
                  label={formState.toLocaleUpperCase()}
                  size="small"
                  color="success"
                  style={{ fontSize: 12, borderRadius: 2, padding: 0 }}
                />
              )}
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => {
                  setFormState("View");
                }}
              >
                <RestoreIcon sx={{ color: "#389e0d" }} />
              </IconButton>
            </Stack>

            {param !== "search" && (
              <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                <Btn
                  type="button"
                  style={{
                    border: "1px solid #fff",
                    padding: "0px 5px 0px 5px",
                    fontSize: 12,
                    textTransform: "initial",
                  }}
                >
                  Order Adjustment
                </Btn>
                <Btn
                  type="button"
                  style={{
                    border: "1px solid #fff",
                    padding: "0px 5px 0px 5px",
                    fontSize: 12,
                    textTransform: "initial",
                  }}
                >
                  FullFillment
                </Btn>
                <Typography
                  sx={{ color: "white", fontSize: 12, cursor: "pointer" }}
                >
                  View Report
                </Typography>
                {/* <Link href={"/sale-order/list"}> */}
                <IconButton
                  onClick={() => {
                    navigate("/sale-order/search");
                  }}
                >
                  <SearchIcon sx={{ color: "white" }} />
                </IconButton>
                {/* </Link> */}
                {formState === "View" ? (
                  <>
                    <IconButton onClick={addHandler}>
                      <AddIcon
                        // onClick={() => addHandler()}
                        sx={{ color: "white" }}
                      />
                    </IconButton>

                    <IconButton onClick={() => setFormState("edit")}>
                      <EditIcon sx={{ color: "white" }} />
                    </IconButton>

                    <IconButton>
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </>
                ) : null}
                {formState === "add" && (
                  <IconButton>
                    <button
                      type="submit"
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <SaveIcon sx={{ color: "white" }} />
                    </button>
                  </IconButton>
                )}
              </Stack>
            )}
          </Stack>
        }
      >
        {param === "search" ? (
          <Stack
            padding={2}
            // id="vendorSearchForm"
            // component={"form"}
            // onSubmit={(event) => {
            //   event.preventDefault();
            //   console.log(searchData);
            // }}
          >
            <FiltersAccordionFom
              allFields={[
                {
                  title: "SO NO :",
                  comp: (
                    <InputComp
                      type={"number"}
                      // required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_id: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_id}
                      // disabled={true}
                      name={"customer_code"}
                    />
                  ),
                },
                {
                  title: "From Date :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      type={"date"}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     from_date: moment(new Date(e.target.value)).format(
                      //       "MM-DD-YY"
                      //     ),
                      //   })
                      // }
                      // value={moment(new Date(searchData?.from_date)).format(
                      //   "YYYY-MM-DD"
                      // )}
                      // disabled={true}
                      name={"from_date"}
                    />
                  ),
                },
                {
                  title: "To Date :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      type={"date"}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     from_date: moment(new Date(e.target.value)).format(
                      //       "MM-DD-YY"
                      //     ),
                      //   })
                      // }
                      // value={moment(new Date(searchData?.from_date)).format(
                      //   "YYYY-MM-DD"
                      // )}
                      // disabled={true}
                      name={"from_date"}
                    />
                  ),
                },
                {
                  title: "PO # :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Customer :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Sale Person Name :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Region :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Gender :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },

                {
                  title: "Terms :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
              ]}
              headerRightComp={
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    //  history("/customerInformation");
                    navigate("/sale-order");
                  }}
                >
                  <AddIcon />
                </IconButton>
              }
              bottomChild={
                <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                  <Btn
                    // type="submit"
                    // onClick={() => {
                    //   if (!(!searchData?.from_date || !searchData?.to_date)) {
                    //     console.log(searchData);
                    //     dispatch(searchOMVendorAsync(searchData));
                    //   } else {
                    //     error_toast_message(
                    //       "Please select from data and to date first! "
                    //     );
                    //   }
                    // }}
                    style={{ width: "auto", textTransform: "none" }}
                    // loading={searchOMVendorStatus === asyncStatus.LOADING}
                  >
                    Search
                  </Btn>
                </Stack>
              }
            />

            <Stack height={500} mt={4} width={"100%"}>
              <TableComp
                onRowClick={(e) => {
                  navigate(`/customerInformation/${e?.row?.vendor_id}`);
                  // setCurrentVendorId(e?.row?.vendor_id);
                  navigate("/sale-order");
                }}
                exportToExcel={true}
                // rows={formattedData}
                rows={[]}
                columns={[
                  {
                    field: "opening_date",
                    headerName: "Order ID",
                    flex: true,
                  },
                  {
                    field: "customer_code",
                    headerName: "Order No",
                    flex: true,
                  },
                  {
                    field: "customer_name",
                    headerName: "Order Date",
                    flex: true,
                  },
                  {
                    field: "customer_type_name",
                    headerName: "PO #",
                    flex: true,
                  },
                  { field: "office", headerName: "Customer", flex: true },
                  { field: "mobile", headerName: "Term", flex: true },
                  {
                    field: "shipping_method_name",
                    headerName: "Region Name",
                    flex: true,
                  },
                  {
                    field: "release_certificate_no",
                    headerName: "SalePerson Name",
                    flex: true,
                  },
                  {
                    field: "shipping_account_no",
                    headerName: "Gender Name",
                    flex: true,
                  },
                ]}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}

            <InfoFormComp
              title="Primary Information"
              // fieldsArray={}
              customChild={
                <Grid
                  container
                  flexDirection={{xl:"row",lg:"column-reverse",md:"column-reverse",sm:"column-reverse",xs:"column-reverse",}}
                // sx={{flexDirection:{xl:"row",lg:"column-reverse",md:"column-reverse",sm:"column-reverse",xs:"column-reverse",}}}
                  justifyContent={{xl:"flex-start",lg:"flex-end",md:"flex-end",sm:"flex-end",xs:"flex-end"}}
                  alignItems={{xl:"flex-start",lg:"flex-end",md:"flex-end",sm:"flex-end",xs:"flex-end"}}
                  padding={2}
                >
                  <Grid
                    xl={10}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    padding={4}
                    spacing={2}
                    sx={{ justifyContent:"flex-start",alignItems:"flex-start" }}
                  >
                    {[
                      {
                        label: "Order # :",
                        comp: (
                          <InputComp
                            value={formData?.order_number || ""}
                            disabled={disableHandler()}
                            // required={true}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                order_number: e.target.value,
                              })
                            }
                            style={{ padding: 3 }}
                            placeholder=""
                          />
                        ),
                      },
                      {
                        label: "Order Date :",
                        comp: (
                          <InputComp
                            // required={true}
                            value={formData?.order_date || ""}
                            disabled={disableHandler()}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                order_date: e.target.value,
                              })
                            }
                            style={{ padding: 3 }}
                            type={"date"}
                            placeholder=""
                          />
                        ),
                      },
                      {
                        label: "P.O # :",
                        comp: (
                          <InputComp
                            // required={true}
                            value={formData?.po_number || ""}
                            disabled={disableHandler()}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                po_number: e.target.value,
                              })
                            }
                            style={{ padding: 3 }}
                            placeholder=""
                          />
                        ),
                      },
                      {
                        label: "Source Type :",
                        comp: (
                          <InputComp
                            value={formData?.source_type || ""}
                            disabled={disableHandler()}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                source_type: e.target.value,
                              })
                            }
                            style={{ padding: 3 }}
                            placeholder=""
                          />
                        ),
                      },
                      {
                        label: "Customer Name :",
                        comp: (
                          <Select
                            fullWidth
                            disabled={disableHandler()}
                            size="small"
                            value={"MEN"}
                            style={{ height: 30, fontSize: 14 }}
                            // onChange={handleChange}
                          >
                            <MenuItem value={"MEN"}>MEN</MenuItem>
                            <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                          </Select>
                        ),
                      },
                      {
                        label: "Ref ID :",
                        comp: (
                          <InputComp
                            value={formData?.ref_id || ""}
                            disabled={disableHandler()}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                ref_id: e.target.value,
                              })
                            }
                            style={{ padding: 3 }}
                            placeholder=""
                          />
                        ),
                      },
                      {
                        label: "Memo :",
                        comp: (
                          <InputComp
                            value={formData?.memo || ""}
                            disabled={disableHandler()}
                            onChange={(e) =>
                              setFormData({ ...formData, memo: e.target.value })
                            }
                            style={{ padding: 3 }}
                            placeholder=""
                          />
                        ),
                      },
                      {
                        label: "Subsidary :",
                        comp: (
                          <Select
                            fullWidth
                            disabled={disableHandler()}
                            size="small"
                            value={"MEN"}
                            style={{ height: 30, fontSize: 14 }}
                            // onChange={handleChange}
                          >
                            <MenuItem value={"MEN"}>MEN</MenuItem>
                            <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                          </Select>
                        ),
                      },
                      {
                        label: "Active:",
                        comp: (
                          <Checkbox
                            defaultChecked
                            defaultValue={true}
                            style={{ padding: 3 }}
                            // disabled={!(addVendorMode || editMode)}
                            // onChange={(e) =>
                            //   setAddVendorData({
                            //     ...addVendorData,
                            //     is_active: e.target.checked,
                            //   })
                            // }
                          />
                        ),
                      },
                    ]?.map((e) => {
                      const { label, comp } = e;

                      return (
                        <>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                          >
                            <Grid item xl={4} lg={6} md={6} sm={6} xs={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                {label}
                              </Typography>
                            </Grid>
                            <Grid item xl={8} lg={6} md={6} sm={6} xs={6}>
                              {comp}
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                  <Grid
                    xl={2}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    sx={{
                    //   width: "20%",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    //   padding: 2,
                    }}
                  >
                    <Summary
                      heading={"Order Summary"}
                      quantity={10}
                      subTotal={20}
                      discount={5}
                      tax={2}
                      shippingCost={4}
                    />
                  </Grid>
                </Grid>
              }
            />

            {/* </div> */}

            {/* <Stack my={2}>
            <TabComp
                tabs={[
                    {
                        name: "Companies",
                        content: (
                            <Stack height={400} width={"100%"}>
                                <TableComp
                                    columns={[
                                        {
                                            field: "companyId",
                                            headerName: "Company ID",
                                            flex: true,
                                        },
                                        {
                                            field: "companyName",
                                            headerName: "Company Name",
                                            flex: true,
                                        },
                                        {
                                            field: "action",
                                            headerName: "Action",
                                            flex: true,
                                        },
                                    ]}
                                    //   onRowClick={(e) => {
                                    //     setSelectedStyle(e?.row);
                                    //   }}
                                    rows={[]}
                                //   loading={
                                //     getProductStyleStatus === asyncStatus.IDLE ||
                                //     getProductStyleStatus === asyncStatus.LOADING
                                //   }
                                />
                            </Stack>
                        ),
                    },
                    {
                        name: "Log Info",
                        content: (
                            <Stack height={400} width={"100%"}>
                                <TableComp
                                    columns={[
                                        {
                                            field: "logId",
                                            headerName: "Log ID",
                                            flex: true,
                                        },
                                        {
                                            field: "recordId",
                                            headerName: "Record_id",
                                            flex: true,
                                        },
                                        {
                                            field: "columnName",
                                            headerName: "Column Name",
                                            flex: true,
                                        },
                                        {
                                            field: "columnValue",
                                            headerName: "Column Value",
                                            flex: true,
                                        },
                                        {
                                            field: "actionName",
                                            headerName: "Action Name",
                                            flex: true,
                                        },
                                        {
                                            field: "actionDate",
                                            headerName: "Action Date",
                                            flex: true,
                                        },
                                        {
                                            field: "userId",
                                            headerName: " User ID",
                                            flex: true,
                                        },
                                    ]}
                                    //   onRowClick={(e) => {
                                    //     setSelectedStyle(e?.row);
                                    //   }}
                                    rows={[]}
                                //   loading={
                                //     getProductStyleStatus === asyncStatus.IDLE ||
                                //     getProductStyleStatus === asyncStatus.LOADING
                                //   }
                                />
                            </Stack>
                        ),
                    },
                ]}
            />
        </Stack> */}
            {/* Billing Shipping Information */}
            {/* <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}> */}
            <Grid container spacing={2} padding={2}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <BillingShippingInformation
                  data={{
                    name: formData?.billing,
                    first_name: formData?.billing_first_name,
                    last_name: formData?.billing_last_name,
                    address1: formData?.billing_address1,
                    address2: formData?.billing_address2,
                    city: formData?.billing_city,
                    state: formData?.billing_state,
                    zip: formData?.billing_zip,
                    country: formData?.billing_country,
                    tel: formData?.billing_tel,
                    email: formData?.billing_email,
                  }}
                  heading={"Billing Information"}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <BillingShippingInformation
                  data={{
                    name: formData?.shipping,
                    first_name: formData?.shipping_first_name,
                    last_name: formData?.shipping_last_name,
                    address1: formData?.shipping_address1,
                    address2: formData?.shipping_address2,
                    city: formData?.shipping_city,
                    state: formData?.shipping_state,
                    zip: formData?.shipping_zip,
                    country: formData?.shipping_country,
                    tel: formData?.shipping_tel,
                    email: formData?.shipping_email,
                  }}
                  heading={"Shipping Information"}
                />
              </Grid>
            </Grid>
            {/* </div> */}
            {/* Date Section */}
            <InfoFormComp
              title="Date"
              fieldsArray={[
                {
                  label: "Start Date :",
                  comp: (
                    <InputComp
                      value={formData?.start_date || ""}
                      disabled={disableHandler()}
                      onChange={(e) =>
                        setFormData({ ...formData, start_date: e.target.value })
                      }
                      style={{ padding: 3 }}
                      type={"date"}
                      placeholder=""
                    />
                  ),
                },
                {
                  label: "Cancel Date :",
                  comp: (
                    <InputComp
                      value={formData?.cancel_date || ""}
                      disabled={disableHandler()}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          cancel_date: e.target.value,
                        })
                      }
                      style={{ padding: 3 }}
                      type={"date"}
                      placeholder=""
                    />
                  ),
                },
              ]}
            />
            {/* Sales Information */}
            <InfoFormComp
              title="Sales Information"
              fieldsArray={[
                {
                  label: "Gender :",
                  comp: (
                    <Select
                      fullWidth
                      disabled={disableHandler()}
                      size="small"
                      value={"MEN"}
                      style={{ height: 30, fontSize: 14 }}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"MEN"}>MEN</MenuItem>
                      <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                    </Select>
                  ),
                },
                {
                  label: "Category :",
                  comp: "test",
                },
                {
                  label: "Region :",
                  comp: (
                    <Select
                      fullWidth
                      disabled={disableHandler()}
                      size="small"
                      value={"MEN"}
                      style={{ height: 30, fontSize: 14 }}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"MEN"}>MEN</MenuItem>
                      <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                    </Select>
                  ),
                },
                {
                  label: "Sales Person :",
                  comp: (
                    <Select
                      fullWidth
                      size="small"
                      value={"MEN"}
                      disabled={disableHandler()}
                      style={{ height: 30, fontSize: 14 }}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"MEN"}>MEN</MenuItem>
                      <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                    </Select>
                  ),
                },
                {
                  label: "Location :",
                  comp: (
                    <Select
                      fullWidth
                      size="small"
                      value={"MEN"}
                      disabled={disableHandler()}
                      style={{ height: 30, fontSize: 14 }}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"MEN"}>MEN</MenuItem>
                      <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                    </Select>
                  ),
                },
              ]}
            />
            {/* Classification */}
            <Typography
              sx={{
                color: "black",
                backgroundColor: themeLightGray,
                paddingX: 2,
                paddingY: 1,
              }}
            >
              Classification
            </Typography>
            <Stack my={2}>
              <TabComp
                tabs={[
                  {
                    name: "Shipping",
                    content: (
                      <Stack width={"100%"}>
                        <InfoFormComp
                          noHeader={true}
                          //    customHeader={<p> </p>}
                          fieldsArray={[
                            {
                              label: "Shipping Account No:",
                              comp: (
                                <InputComp
                                  value={formData?.shipping_account_no || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      shipping_account_no: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Ship Via:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.ship_via || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      ship_via: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Shipping Method:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.shipping_method || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      shipping_method: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Shipping Cost:",
                              comp: (
                                <InputComp
                                  value={formData?.shipping_cost || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      shipping_cost: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Shipping Tax Code:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.shipping_tax_code || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      shipping_tax_code: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Shipping Tax Rate:",
                              comp: (
                                <InputComp
                                  value={formData?.shipping_tax_rate || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      shipping_tax_rate: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Freight Term:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.frieght_term || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      frieght_term: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Bergen Billing Option:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.bergan_billing_option || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bergan_billing_option: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Bergen Payment Term:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.bergan_payment_term || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bergan_payment_term: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Ship Complete:",
                              comp: (
                                <Checkbox
                                  value={formData?.ship_complete || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      ship_complete: e.target.checked,
                                    })
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              ),
                            },
                          ]}
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Billing",
                    content: (
                      <Stack width={"100%"}>
                        <InfoFormComp
                          noHeader={true}
                          //    customHeader={<p> </p>}
                          fieldsArray={[
                            {
                              label: "Term For Print:",
                              comp: <p>{formData?.term_print}</p>,
                            },
                            {
                              label: "Payment Term:",
                              comp: (
                                <Select
                                  fullWidth
                                  required={true}
                                  size="small"
                                  value={formData?.payment_term || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      payment_term: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                          ]}
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "EDI",
                    content: (
                      <Stack width={"100%"}>
                        <InfoFormComp
                          noHeader={true}
                          //    customHeader={<p> </p>}
                          fieldsArray={[
                            {
                              label: "Mark for Code:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_code || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_code: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for Company:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_company || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_company: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for Address 1:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_address1 || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_address1: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for Address 2:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_address2 || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_address2: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for City:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_city || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_city: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for State:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_state || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_state: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for Zip:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_zip || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_zip: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for Country:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_country || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_country: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for Email:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_email || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_email: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Mark for Phone:",
                              comp: (
                                <InputComp
                                  value={formData?.mark_phone || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      mark_phone: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                          ]}
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Other",
                    content: (
                      <Stack width={"100%"}>
                        <InfoFormComp
                          noHeader={true}
                          //    customHeader={<p> </p>}
                          fieldsArray={[
                            {
                              label: "Order Status:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.order_status || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      order_status: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Factor:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.factor || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      factor: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Factor Decision:",
                              comp: (
                                <InputComp
                                  value={formData?.factor_decision || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      factor_decision: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "DC#:",
                              comp: (
                                <InputComp
                                  value={formData?.dc_no || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      dc_no: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Resale Certificate:",
                              comp: (
                                <InputComp
                                  value={formData?.resale_certificate || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      resale_certificate: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Resale Expiry Date:",
                              comp: (
                                <InputComp
                                  value={
                                    formData?.resale_certificate_date || ""
                                  }
                                  disabled={disableHandler()}
                                  type={"date"}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      resale_certificate_date: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Store:",
                              comp: (
                                <InputComp
                                  value={formData?.store || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      store: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Department Desc:",
                              comp: (
                                <InputComp
                                  value={formData?.department_desc || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      department_desc: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Division Desc:",
                              comp: (
                                <InputComp
                                  value={formData?.division_desc || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      cancel_date: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Division Code:",
                              comp: (
                                <InputComp
                                  value={formData?.division_code || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      division_code: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Department Code:",
                              comp: (
                                <InputComp
                                  value={formData?.department_code || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      department_code: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Ship To Code:",
                              comp: (
                                <InputComp
                                  value={formData?.ship_to_code || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      ship_to_code: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Special Instructions:",
                              comp: (
                                <InputComp
                                  value={formData?.special_instruction || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      special_instruction: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Bill To Non-US:",
                              comp: (
                                <InputComp
                                  value={formData?.bill_to_non_us || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bill_to_non_us: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Ship To Non-US:",
                              comp: (
                                <InputComp
                                  value={formData?.ship_to_non_us || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      ship_to_non_us: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Order Type:",
                              comp: (
                                <Select
                                  fullWidth
                                  size="small"
                                  value={formData?.order_type || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      order_type: e.target.value,
                                    })
                                  }
                                  style={{ height: 30, fontSize: 14 }}
                                  // onChange={handleChange}
                                >
                                  <MenuItem value={"MEN"}>MEN</MenuItem>
                                  <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                                </Select>
                              ),
                            },
                            {
                              label: "Tax & Duties:",
                              comp: (
                                <InputComp
                                  value={formData?.tax_duties || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      tax_duties: e.target.value,
                                    })
                                  }
                                  style={{ padding: 3 }}
                                  placeholder=""
                                />
                              ),
                            },
                            {
                              label: "Closed:",
                              comp: (
                                <Checkbox
                                  value={formData?.closed || ""}
                                  disabled={disableHandler()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      closed: e.target.checked,
                                    })
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              ),
                            },
                          ]}
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Related Transaction",
                    content: (
                      <Stack width={"100%"}>
                        <h1>Table</h1>
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
            {/* Discount */}
            <InfoFormComp
              title="Discount"
              fieldsArray={[
                {
                  label: "Discount:",
                  comp: (
                    <Select
                      fullWidth
                      size="small"
                      value={formData?.discount || ""}
                      disabled={disableHandler()}
                      onChange={(e) =>
                        setFormData({ ...formData, discount: e.target.value })
                      }
                      style={{ height: 30, fontSize: 14 }}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"MEN"}>MEN</MenuItem>
                      <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                    </Select>
                  ),
                },
                {
                  label: "Discount Code:",
                  comp: (
                    <InputComp
                      value={formData?.discount_code || ""}
                      disabled={disableHandler()}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          discount_code: e.target.value,
                        })
                      }
                      style={{ padding: 3 }}
                      placeholder=""
                    />
                  ),
                },
                {
                  label: "Discount Type:",
                  comp: (
                    <Select
                      fullWidth
                      size="small"
                      value={formData?.discount_type || ""}
                      disabled={disableHandler()}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          discount_type: e.target.value,
                        })
                      }
                      style={{ height: 30, fontSize: 14 }}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"MEN"}>MEN</MenuItem>
                      <MenuItem value={"WOMEN"}>WOMEN</MenuItem>
                    </Select>
                  ),
                },
                {
                  label: "Remarks:",
                  comp: (
                    <InputComp
                      value={formData?.remarks || ""}
                      disabled={disableHandler()}
                      onChange={(e) =>
                        setFormData({ ...formData, remarks: e.target.value })
                      }
                      style={{ padding: 3 }}
                      placeholder=""
                    />
                  ),
                },
              ]}
            />

            <Stack my={2}>
              <TabComp
                tabs={[
                  {
                    name: "Product Information",
                    content: (
                      <Stack width={"100%"}>
                        <Btn
                          style={{
                            width: "max-content",
                            fontSize: 14,
                            margin: 2,
                          }}
                          onClick={handleClickOpen}
                        >
                          Add Item
                        </Btn>

                        <TableComp
                          columns={[
                            {
                              field: "companyId",
                              headerName: "SKU",
                              flex: true,
                              width: 100,
                            },

                            {
                              field: "styleId",
                              headerName: "Style",
                              flex: true,
                              width: 100,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "System Information",
                    content: (
                      <Stack width={"100%"}>
                        <Stack width={"100%"}>
                          <h1>System Information</h1>
                        </Stack>
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>

            {open && <AddItems open={open} setOpen={setOpen} />}
          </Stack>
        )}
      </ScreenCard>
    </Stack>
  );
};

export default SaleOrder;
