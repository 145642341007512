import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import {
  addProductStyleAsync,
  addProductFabricClassAsync,
  deleteProductFabricClassAsync,
  editProductStyleAsync,
  editProductFabricClassAsync,
  getProductStyleAsync,
  getProductFabricClassAsync,
} from "../../../../store/services/productInfoServices";
const FabricClass = () => {
  const dispatch = useDispatch();
  const {
    getProductFabricClassStatus,
    getProductFabricClassError,
    getProductFabricClassData,
    addProductFabricClassStatus,
    addProductFabricClassData,
    addProductFabricClassError,

    editProductFabricClassStatus,
    editProductFabricClassError,
    editProductFabricClassData,

    deleteProductFabricClassData,
    deleteProductFabricClassStatus,
    deleteProductFabricClassError,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductFabricClassAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductFabricClassData?.length
    ? getProductFabricClassData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedFabricClass, setSelectedFabricClass] = useState();
  const [addFabricClassMode, setAddFabricClassMode] = useState(false);
  const [addFabricClassData, setAddFabricClassData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedFabricClass", selectedFabricClass);
  // console.log("editData", editData);
  useEffect(() => {
    if (addProductFabricClassStatus === asyncStatus.SUCCEEDED) {
      setAddFabricClassMode(false);
      setSaveDisabled(true);
      setAddFabricClassData();
    }
  }, [, addProductFabricClassStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(
      deleteProductFabricClassAsync(selectedFabricClass?.fabric_class_id)
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductFabricClassStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedFabricClass();
    }
  }, [, deleteProductFabricClassStatus]);
  useEffect(() => {
    if (editProductFabricClassStatus === asyncStatus.SUCCEEDED) {
      setSelectedFabricClass();
      setEditMode(false);
    }
  }, [, editProductFabricClassStatus]);
  console.log(
    "getProductFabricClassData",
    getProductFabricClassData,
    editProductFabricClassData
  );
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this fabric class?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Fabric Class"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Fabric Class Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddFabricClassMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addFabricClassMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFabricClassMode || !selectedFabricClass}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddFabricClassMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFabricClassMode || !selectedFabricClass}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductFabricClassStatus === asyncStatus.LOADING ||
                  editProductFabricClassStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addFabricClassData) {
                    dispatch(addProductFabricClassAsync(addFabricClassData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedFabricClass, ...editData };
                    delete a.id;
                    dispatch(editProductFabricClassAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
                columns={[
                  { field: "fabric_class_id", headerName: "ID", flex: true },
                  {
                    field: "fabric_class_name",
                    headerName: "Fabric Class Name",
                    flex: true,
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedFabricClass(e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductFabricClassStatus === asyncStatus.IDLE ||
                  getProductFabricClassStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addFabricClassMode ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "FabricClass:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddFabricClassData({
                            ...addFabricClassData,
                            fabric_class_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddFabricClassData({
                            ...addFabricClassData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedFabricClass ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedFabricClass?.fabric_class_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "FabricClass:",
                    comp: (
                      <InputComp
                        key={selectedFabricClass?.fabric_class_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            fabric_class_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedFabricClass?.fabric_class_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={editData?.is_active}
                        disabled={!editMode}
                        defaultValue={
                          selectedFabricClass?.is_active ? true : false
                        }
                        defaultChecked={
                          selectedFabricClass?.is_active ? true : false
                        }
                        checked={selectedFabricClass?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Typography>Select any fabric class</Typography>
            )}

            <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductFabricClassError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductFabricClassError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductFabricClassError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductFabricClassError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  {
                    name: "Companies",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "companyId",
                              headerName: "Company ID",
                              flex: true,
                            },
                            {
                              field: "companyName",
                              headerName: "Company Name",
                              flex: true,
                            },
                            {
                              field: "action",
                              headerName: "Action",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedFabricClass(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedFabricClass(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default FabricClass;
