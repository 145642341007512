import {
    Avatar,
    Box,
    CircularProgress,
    Grid,
    Input,
    Modal,
    Stack,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };
  const CustomModal = ({ handleOpen, handleClose, open,children }) => {

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 1000,
          flexDirection:"row",
          alignItems: "center",
        justifyContent:"center",
          backgroundColor: "rgba(0,0,0,0.6)",
        }}
      >
       <Stack sx={{...style}}>
   {children}
       <Stack>
       </Stack>
       </Stack>
      </Modal>
    );
  };
  
  export default CustomModal;
  