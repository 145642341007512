import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import {
  addOMCustomerCategoryAsync,
  deleteOMCustomerCategoryAsync,
  editOMCustomerCategoryAsync,
  getOMBrandAsync,
  getOMFigureAsync,
  getOMRegionAsync,
  getOMCustomerCategoryAsync,
} from "../../../store/services/orderManagementService";
import InputWithBtnLayoutGridComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutGridComp";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
const CustomerCategory = () => {
  const dispatch = useDispatch();
  const {
    getOMCustomerCategoryStatus,
    getOMCustomerCategoryError,
    getOMCustomerCategoryData,
    addOMCustomerCategoryStatus,
    addOMCustomerCategoryData,
    addOMCustomerCategoryError,

    editOMCustomerCategoryStatus,
    editOMCustomerCategoryError,
    editOMCustomerCategoryData,

    deleteOMCustomerCategoryData,
    deleteOMCustomerCategoryStatus,
    deleteOMCustomerCategoryError,

    getOMBrandStatus,
    getOMBrandError,
    getOMBrandData,

    getOMRegionStatus,
    getOMRegionError,
    getOMRegionData,

    getOMFigureStatus,
    getOMFigureError,
    getOMFigureData,
  } = useSelector((state) => state.om);

  useEffect(() => {
    dispatch(getOMCustomerCategoryAsync());
    dispatch(getOMBrandAsync());
    dispatch(getOMRegionAsync());
    dispatch(getOMFigureAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMCustomerCategoryData?.length
    ? getOMCustomerCategoryData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedCustomerCategory, setSelectedCustomerCategory] = useState();
  const [addCustomerCategoryMode, setAddCustomerCategoryMode] = useState(false);
  const [addCustomerCategoryData, setAddCustomerCategoryData] = useState({
    customer_id: null,
    gender_id: null,
    sales_person_id: null,
    region_id: null,
    sp_region_category_id: null,
    is_active: false,
  });
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedCustomerCategory", selectedCustomerCategory);
  // console.log("editData", editData);
  useEffect(() => {
    if (addOMCustomerCategoryStatus === asyncStatus.SUCCEEDED) {
      setAddCustomerCategoryMode(false);
      setSaveDisabled(true);
      setAddCustomerCategoryData();
    }
  }, [, addOMCustomerCategoryStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(
      deleteOMCustomerCategoryAsync(selectedCustomerCategory?.sales_target_id)
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (
      deleteOMCustomerCategoryStatus === asyncStatus.SUCCEEDED ||
      deleteOMCustomerCategoryStatus === asyncStatus.ERROR
    ) {
      setOpen(false);
      setSelectedCustomerCategory();
    }
  }, [, deleteOMCustomerCategoryStatus]);
  useEffect(() => {
    if (editOMCustomerCategoryStatus === asyncStatus.SUCCEEDED) {
      setSelectedCustomerCategory();
      setEditMode(false);
      setEditData();
    }
  }, [, editOMCustomerCategoryStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this customer category?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Customer Category"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Customer Category
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddCustomerCategoryMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addCustomerCategoryMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addCustomerCategoryMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddCustomerCategoryMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addCustomerCategoryMode || !selectedCustomerCategory}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMCustomerCategoryStatus === asyncStatus.LOADING ||
                  editOMCustomerCategoryStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addCustomerCategoryData) {
                    dispatch(
                      addOMCustomerCategoryAsync(addCustomerCategoryData)
                    );
                  } else if (editMode) {
                    let a;
                    a = { ...selectedCustomerCategory, ...editData };
                    delete a.id;
                    dispatch(editOMCustomerCategoryAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={500} width={"100%"}>
              <TableComp
                columns={[
                  {
                    field: "sales_target_id",
                    headerName: "ID",
                    flex: true,
                  },
                  {
                    field: "region",
                    headerName: "Region",
                    flex: true,
                  },
                  {
                    field: "salesPerson",
                    headerName: "Sales Person",
                    flex: true,
                  },
                  { field: "gender", headerName: "Gender", flex: true },
                ]}
                onRowClick={(e) => {
                  setSelectedCustomerCategory(e?.row);
                }}
                rows={formattedData}
                loading={
                  getOMCustomerCategoryStatus === asyncStatus.IDLE ||
                  getOMCustomerCategoryStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addCustomerCategoryMode ? (
              <InputWithBtnLayoutGridComp
                // error={addOMCustomerCategoryError}
                inputArray={[
                  {
                    label: "Customer :",
                    comp: (
                      // <InputComp
                      //   style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                      //   placeholder=""
                      //   onChange={(e) =>
                      //     setAddCustomerCategoryData({
                      //       ...addCustomerCategoryData,
                      //       sales_target: e.target.value,
                      //     })
                      //   }
                      // />
                      <VirtualizedSelect
                        required={true}
                        options={getOMBrandData?.length && getOMBrandData}
                        value={
                          getOMBrandData?.find(
                            (x) =>
                              addCustomerCategoryData?.brand_id === x?.brand_id
                          ) || ""
                        }
                        // disabled={
                        //   getOMCustomerStatus === asyncStatus.IDLE ||
                        //   getOMBrandStatus === asyncStatus.LOADING ||
                        //   !(addCustomerCategoryMode || editMode)
                        // }
                        onChange={(e) => {
                          if (e?.brand_id) {
                            setAddCustomerCategoryData({
                              ...addCustomerCategoryData,
                              customer_id: e?.brand_id,
                            });
                          }
                        }}
                        name={"customer_name"}
                        id_key_name={"customer_id"}
                      />
                    ),
                  },
                  {
                    label: "Billing :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        // options={getOMBrandData?.length && getOMBrandData}
                        // value={
                        //   getOMBrandData?.find(
                        //     (x) =>
                        //       addCustomerCategoryData?.brand_id === x?.brand_id
                        //   ) || ""
                        // }
                        // disabled={
                        //   getOMCustomerStatus === asyncStatus.IDLE ||
                        //   getOMBrandStatus === asyncStatus.LOADING ||
                        //   !(addCustomerCategoryMode || editMode)
                        // }
                        // onChange={(e) => {
                        //   if (e?.brand_id) {
                        //     setAddCustomerCategoryData({
                        //       ...addCustomerCategoryData,
                        //       customer_id: e?.brand_id,
                        //     });
                        //   }

                        // }}
                        // name={"customer_name"}
                        // id_key_name={"customer_id"}
                      />
                    ),
                  },
                  {
                    label: "Gender :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        // options={getOMBrandData?.length && getOMBrandData}
                        // value={
                        //   getOMBrandData?.find(
                        //     (x) =>
                        //       addCustomerCategoryData?.brand_id === x?.brand_id
                        //   ) || ""
                        // }
                        // disabled={
                        //   getOMCustomerStatus === asyncStatus.IDLE ||
                        //   getOMBrandStatus === asyncStatus.LOADING ||
                        //   !(addCustomerCategoryMode || editMode)
                        // }
                        onChange={(e) => {
                          if (e?.brand_id) {
                            setAddCustomerCategoryData({
                              ...addCustomerCategoryData,
                              gender_id: e?.brand_id,
                            });
                          }
                        }}
                        // name={"customer_name"}
                        // id_key_name={"customer_id"}
                      />
                    ),
                  },
                  {
                    label: "Category :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        // options={getOMBrandData?.length && getOMBrandData}
                        // value={
                        //   getOMBrandData?.find(
                        //     (x) =>
                        //       addCustomerCategoryData?.brand_id === x?.brand_id
                        //   ) || ""
                        // }
                        // disabled={
                        //   getOMCustomerStatus === asyncStatus.IDLE ||
                        //   getOMBrandStatus === asyncStatus.LOADING ||
                        //   !(addCustomerCategoryMode || editMode)
                        // }
                        onChange={(e) => {
                          if (e) {
                            setAddCustomerCategoryData({
                              ...addCustomerCategoryData,
                              sp_region_category_id: e?.brand_id,
                            });
                          }
                        }}
                        // name={"customer_name"}
                        // id_key_name={"customer_id"}
                      />
                    ),
                  },
                  {
                    label: "Sales Person :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        // options={getOMBrandData?.length && getOMBrandData}
                        // value={
                        //   getOMBrandData?.find(
                        //     (x) =>
                        //       addCustomerCategoryData?.brand_id === x?.brand_id
                        //   ) || ""
                        // }
                        // disabled={
                        //   getOMCustomerStatus === asyncStatus.IDLE ||
                        //   getOMBrandStatus === asyncStatus.LOADING ||
                        //   !(addCustomerCategoryMode || editMode)
                        // }
                        onChange={(e) => {
                          if (e) {
                            setAddCustomerCategoryData({
                              ...addCustomerCategoryData,
                              sales_person_id: e?.brand_id,
                            });
                          }
                        }}
                        // name={"customer_name"}
                        // id_key_name={"customer_id"}
                      />
                    ),
                  },
                  {
                    label: "Region :",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddCustomerCategoryData({
                            ...addCustomerCategoryData,
                            region_id: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddCustomerCategoryData({
                            ...addCustomerCategoryData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                  // {
                  //   label: "Customer :",
                  //   comp: (
                  //     <VirtualizedSelect
                  //       required={true}
                  //       options={getOMBrandData?.length && getOMBrandData}
                  //       value={
                  //         getOMBrandData?.find(
                  //           (x) =>
                  //             addCustomerCategoryData?.brand_id === x?.brand_id
                  //         ) || ""
                  //       }
                  //       disabled={
                  //         getOMBrandStatus === asyncStatus.IDLE ||
                  //         getOMBrandStatus === asyncStatus.LOADING ||
                  //         !(addCustomerCategoryMode || editMode)
                  //       }
                  //       onChange={(e) => {
                  //         if (e?.brand_id) {
                  //           setAddCustomerCategoryData({
                  //             ...addCustomerCategoryData,
                  //             brand_id: e?.brand_id,
                  //           });
                  //         }
                  //         console.log("bbb", e);
                  //       }}
                  //       name={"brand_name"}
                  //       id_key_name={"brand_id"}
                  //     />
                  //   ),
                  // },
                  // {
                  //   label: "Billing :",
                  //   comp: (
                  //     <VirtualizedSelect
                  //       required={true}
                  //       options={getOMRegionData?.length && getOMRegionData}
                  //       value={
                  //         getOMRegionData?.find(
                  //           (x) =>
                  //             addCustomerCategoryData?.region_id ===
                  //             x?.region_id
                  //         ) || ""
                  //       }
                  //       disabled={
                  //         getOMRegionStatus === asyncStatus.IDLE ||
                  //         getOMRegionStatus === asyncStatus.LOADING ||
                  //         !(addCustomerCategoryMode || editMode)
                  //       }
                  //       onChange={(e) => {
                  //         if (e?.region_id) {
                  //           setAddCustomerCategoryData({
                  //             ...addCustomerCategoryData,
                  //             region_id: e?.region_id,
                  //           });
                  //         }
                  //       }}
                  //       name={"region_name"}
                  //       id_key_name={"region_id"}
                  //     />
                  //   ),
                  // },
                  // {
                  //   label: "Gender :",
                  //   comp: (
                  //     <VirtualizedSelect
                  //       required={true}
                  //       options={getOMFigureData?.length && getOMFigureData}
                  //       value={
                  //         getOMFigureData?.find(
                  //           (x) =>
                  //             addCustomerCategoryData?.figure_id ===
                  //             x?.figure_id
                  //         ) || ""
                  //       }
                  //       disabled={
                  //         getOMFigureStatus === asyncStatus.IDLE ||
                  //         getOMFigureStatus === asyncStatus.LOADING ||
                  //         !(addCustomerCategoryMode || editMode)
                  //       }
                  //       onChange={(e) => {
                  //         if (e?.figure_id) {
                  //           setAddCustomerCategoryData({
                  //             ...addCustomerCategoryData,
                  //             figure_id: e?.figure_id,
                  //           });
                  //         }
                  //       }}
                  //       name={"figure_name"}
                  //       id_key_name={"figure_id"}
                  //     />
                  //   ),
                  // },
                  {},
                  //
                  // Figure
                  // Month
                  // Year
                  // Gender
                  // Target Date
                  // Customer Category
                  // Target Amount

                  // {
                  //   label: "Active:",
                  //   comp: (
                  //     <Checkbox
                  //       defaultChecked
                  //       defaultValue={true}
                  //       onChange={(e) =>
                  //         setAddCustomerCategoryData({
                  //           ...addCustomerCategoryData,
                  //           is_active: e.target.checked,
                  //         })
                  //       }
                  //     />
                  //   ),
                  // },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedCustomerCategory ? (
              <InputWithBtnLayoutComp
                error={editOMCustomerCategoryError}
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedCustomerCategory?.sales_target_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "CustomerCategory:",
                    comp: (
                      <InputComp
                        key={selectedCustomerCategory?.sales_target_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            sales_target: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedCustomerCategory?.sales_target}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={
                          selectedCustomerCategory?.is_active ||
                          editData?.is_active
                        }
                        disabled={!editMode}
                        // defaultValue={selectedCustomerCategory?.is_active || editData?.is_active}
                        defaultChecked={
                          selectedCustomerCategory?.is_active ||
                          editData?.is_active
                        }
                        // checked={selectedCustomerCategory?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Stack>
                <Typography>Select any customer category</Typography>
                <Typography color={"red"} fontSize={12}>
                  {getOMCustomerCategoryError}
                </Typography>
              </Stack>
            )}
            <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedCustomerCategory(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedCustomerCategory(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default CustomerCategory;

// import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
// import React, { useEffect, useRef, useState } from "react";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import SaveIcon from "@mui/icons-material/Save";
// import { DataGrid } from "@mui/x-data-grid";
// import { useDispatch, useSelector } from "react-redux";
// import CancelIcon from "@mui/icons-material/Cancel";
// import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
// import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
// import TableComp from "../../../components/common/TableComp/TableComp";
// import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
// import InputComp from "../../../components/common/InputComp/Input";
// import TabComp from "../../../components/TabsComp/TabComp";
// import Btn from "../../../components/common/ButtonComp/Btn";
// import { asyncStatus } from "../../../utils/asyncStatus";
// import { primaryColor } from "../../../utils/themeColors";
// import {
//   addOMStateAsync,
//   deleteOMStateAsync,
//   editOMStateAsync,
//   getOMStateAsync,
// } from "../../../store/services/orderManagementService";
// import InfoFormComp from "../../../components/InfoForm/InfoFormComp";
// const State = () => {
//   const dispatch = useDispatch();
//   const {
//     getOMCustomerCategoryStatus,
//     getOMCustomerCategoryError,
//     getOMCustomerCategoryData,

//     addOMCustomerCategoryStatus,
//     addOMCustomerCategoryError,
//     addOMCustomerCategoryData,

//     editOMCustomerCategoryStatus,
//     editOMCustomerCategoryError,
//     editOMCustomerCategoryData,

//     deleteOMCustomerCategoryStatus,
//     deleteOMCustomerCategoryError,
//     deleteOMCustomerCategoryData,
//   } = useSelector((state) => state.om);

//   useEffect(() => {
//     dispatch(getOMStateAsync());
//   }, []);

//   // console.log("getProductStyleData", getProductStyleData);
//   const formattedData = getOMCustomerCategoryData?.length
//     ? getOMCustomerCategoryData.map((style, index) => ({
//         id: index + 1, // Assuming the index is a unique identifier for each row
//         ...style,
//       }))
//     : [];
//   const [selectedState, setSelectedState] = useState();
//   const [addStateMode, setAddStateMode] = useState(false);
//   const [addStateData, setAddStateData] = useState();
//   const [saveDisabled, setSaveDisabled] = useState(true);
//   const [editData, setEditData] = useState();
//   const [editMode, setEditMode] = useState(false);
//   // console.log("selectedState", selectedState);
//   // console.log("editData", editData);
//   useEffect(() => {
//     if (addOMCustomerCategoryStatus === asyncStatus.SUCCEEDED) {
//       setAddStateMode(false);
//       setSaveDisabled(true);
//       setAddStateData();
//     }
//   }, [addOMCustomerCategoryStatus]);

//   const [open, setOpen] = useState(false);
//   const agree = () => {
//     dispatch(deleteOMStateAsync(selectedState?.state_id));
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };
//   useEffect(() => {
//     if (
//       deleteOMCustomerCategoryStatus === asyncStatus.SUCCEEDED ||
//       deleteOMCustomerCategoryStatus === asyncStatus.ERROR
//     ) {
//       setOpen(false);
//       setSelectedState();
//     }
//   }, [deleteOMCustomerCategoryStatus]);
//   useEffect(() => {
//     if (editOMCustomerCategoryStatus === asyncStatus.SUCCEEDED) {
//       setSelectedState();
//       setEditMode(false);
//       setEditData();
//     }
//   }, [editOMCustomerCategoryStatus]);

//   //   const [tableHeight, setTableHeight] = useState("");
//   //   let elem = document.getElementById("containerHeight");
//   //   useEffect(() => {
//   //     console.log("elem?.offsetHeight",elem?.clientHeight);
//   //     setTableHeight(elem?.offsetHeight);
//   //   }, [ elem?.offsetHeight]);
//   // console.log(containerHeight)
//   return (
//     <Stack padding={2}>
//       <DialogBoxComp
//         open={open}
//         handleClose={handleClose}
//         agree={agree}
//         innerContent={"Are you sure you want to delete this customer category?"}
//         heading={"Delete request"}
//       />
//       <ScreenCard
//         title="Customer Category"
//         customHeader={
//           <Stack
//             sx={{
//               padding: 0.5,
//               px: 4,
//               backgroundColor: primaryColor,
//               flexDirection: "row",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Typography sx={{ color: "white", fontWeight: "bold" }}>
//               Customer Category
//             </Typography>
//             <Stack flexDirection={"row"} gap={1}>
//               <IconButton
//                 onClick={() => {
//                   setEditMode(false);
//                   setAddStateMode((prev) => !prev);
//                   setSaveDisabled(false);
//                 }}
//               >
//                 {!addStateMode ? (
//                   <AddIcon sx={{ color: "white" }} />
//                 ) : (
//                   <CancelIcon sx={{ color: "white" }} />
//                 )}
//               </IconButton>
//               <IconButton
//                 disabled={addStateMode}
//                 sx={{ color: "white", ":disabled": { color: "gray" } }}
//                 onClick={() => {
//                   setAddStateMode(false);
//                   setEditMode((prev) => !prev);
//                   setSaveDisabled(false);
//                 }}
//               >
//                 {!editMode ? (
//                   <EditIcon />
//                 ) : (
//                   <CancelIcon sx={{ color: "white" }} />
//                 )}
//               </IconButton>
//               <IconButton
//                 disabled={addStateMode || !selectedState}
//                 sx={{ color: "white", ":disabled": { color: "gray" } }}
//                 onClick={() => setOpen(true)}
//               >
//                 <DeleteIcon />
//               </IconButton>
//               <IconButton
//                 disabled={
//                   addOMCustomerCategoryStatus === asyncStatus.LOADING ||
//                   editOMCustomerCategoryStatus === asyncStatus.LOADING ||
//                   saveDisabled
//                 }
//                 sx={{ color: "white", ":disabled": { color: "gray" } }}
//                 onClick={() => {
//                   if (addStateData) {
//                     dispatch(addOMStateAsync(addStateData));
//                   } else if (editMode) {
//                     let a;
//                     a = { ...selectedState, ...editData };
//                     delete a.id;
//                     dispatch(editOMStateAsync(a));
//                   }
//                 }}
//               >
//                 <SaveIcon />
//               </IconButton>
//             </Stack>
//           </Stack>
//         }
//       >
//         <Grid container>
//           <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
//             <Stack height={500} width={"100%"}>
//               <TableComp
//                 columns={[
//                   { field: "cust_cat_id", headerName: "ID", flex: true },
//                   {
//                     field: "cust_cat_name",
//                     headerName: "Customer Category",
//                     flex: true,
//                   },
//                 ]}
//                 onRowClick={(e) => {
//                   setSelectedState(e?.row);
//                 }}
//                 rows={formattedData}
//                 loading={
//                   getOMCustomerCategoryStatus === asyncStatus.IDLE ||
//                   getOMCustomerCategoryStatus === asyncStatus.LOADING
//                 }
//               />
//             </Stack>
//           </Grid>
//           <Grid item xl={9} lg={9} md={15} sm={12} xs={12} padding={2}>
//             {addStateMode ? (
//               <Stack>
//                 <InfoFormComp
//                   three
//                 //   title="Primary Information"
//                   fieldsArray={[

//                     {
//                       label: "Customer :",
//                       comp: (
//                         <InputComp
//                           // type={"number"}
//                           // required={true}
//                           // validator={(value) => {
//                           //   if (!/[0-9]/.test(value.target.value))
//                           //     return "Characters not allowed";
//                           //   return "";
//                           // }}
//                           style={{ padding: 3 }}
//                           placeholder=""
//                           // onChange={(e) =>
//                           //   setAddVendorData({
//                           //     ...addVendorData,
//                           //     vendor_id: e.target.value,
//                           //   })
//                           // }
//                           // value={addVendorData?.vendor_id}
//                           disabled={true}
//                           name={"customer_code"}
//                         />
//                       ),
//                     },
//                     {
//                       label: "Billing :",
//                       comp: (
//                         <InputComp
//                           type={"text"}
//                           // required={true}
//                           // validator={(value) => {
//                           //   if (!/[0-9]/.test(value.target.value))
//                           //     return "Characters not allowed";
//                           //   return "";
//                           // }}
//                           style={{ padding: 3 }}
//                           placeholder=""
//                         //   onChange={(e) =>
//                         //     setAddCustomerInformationData({
//                         //       ...addCustomerInformationData,
//                         //       customer_name: e.target.value,
//                         //     })
//                         //   }
//                           // value={addVendorData?.vendor_id}
//                         //   disabled={!(addCustomerInformationMode || editMode)}
//                           name={"customer_name"}
//                         />
//                       ),
//                     },

//                     {
//                       label: "Gender :",
//                       comp: (
//                         <InputComp
//                           type={"text"}
//                           // required={true}
//                           // validator={(value) => {
//                           //   if (!/[0-9]/.test(value.target.value))
//                           //     return "Characters not allowed";
//                           //   return "";
//                           // }}
//                           style={{ padding: 3 }}
//                           placeholder=""
//                         //   onChange={(e) =>
//                         //     setAddCustomerInformationData({
//                         //       ...addCustomerInformationData,
//                         //       web_address: e.target.value,
//                         //     })
//                         //   }
//                           // value={addVendorData?.vendor_id}
//                         //   disabled={!(addCustomerInformationMode || editMode)}
//                           name={"web_address"}
//                         />
//                       ),
//                     },
//                     {
//                         label: "Category :",
//                         comp: (
//                           <InputComp
//                             // type={"number"}
//                             // required={true}
//                             // validator={(value) => {
//                             //   if (!/[0-9]/.test(value.target.value))
//                             //     return "Characters not allowed";
//                             //   return "";
//                             // }}
//                             style={{ padding: 3 }}
//                             placeholder=""
//                             // onChange={(e) =>
//                             //   setAddVendorData({
//                             //     ...addVendorData,
//                             //     vendor_id: e.target.value,
//                             //   })
//                             // }
//                             // value={addVendorData?.vendor_id}
//                             disabled={true}
//                             name={"customer_code"}
//                           />
//                         ),
//                       },
//                       {
//                         label: "Sales Person :",
//                         comp: (
//                           <InputComp
//                             type={"text"}
//                             // required={true}
//                             // validator={(value) => {
//                             //   if (!/[0-9]/.test(value.target.value))
//                             //     return "Characters not allowed";
//                             //   return "";
//                             // }}
//                             style={{ padding: 3 }}
//                             placeholder=""
//                           //   onChange={(e) =>
//                           //     setAddCustomerInformationData({
//                           //       ...addCustomerInformationData,
//                           //       customer_name: e.target.value,
//                           //     })
//                           //   }
//                             // value={addVendorData?.vendor_id}
//                           //   disabled={!(addCustomerInformationMode || editMode)}
//                             name={"customer_name"}
//                           />
//                         ),
//                       },

//                       {
//                         label: "Region :",
//                         comp: (
//                           <InputComp
//                             type={"text"}
//                             // required={true}
//                             // validator={(value) => {
//                             //   if (!/[0-9]/.test(value.target.value))
//                             //     return "Characters not allowed";
//                             //   return "";
//                             // }}
//                             style={{ padding: 3 }}
//                             placeholder=""
//                           //   onChange={(e) =>
//                           //     setAddCustomerInformationData({
//                           //       ...addCustomerInformationData,
//                           //       web_address: e.target.value,
//                           //     })
//                           //   }
//                             // value={addVendorData?.vendor_id}
//                           //   disabled={!(addCustomerInformationMode || editMode)}
//                             name={"web_address"}
//                           />
//                         ),
//                       },

//                   ]}
//                 />
//               </Stack>
//             ) : selectedState ? (
//               <InputWithBtnLayoutComp
//                 error={editOMCustomerCategoryError}
//                 inputArray={[
//                   {
//                     label: "ID:",
//                     comp: (
//                       <Typography sx={{ fontSize: 14 }}>
//                         {selectedState?.state_id}
//                       </Typography>
//                     ),
//                   },
//                   {
//                     label: "Customer Category:",
//                     comp: (
//                       <InputComp
//                         key={selectedState?.state_id} // Add key prop to force remount
//                         disabled={!editMode}
//                         onChange={(e) =>
//                           setEditData({
//                             ...editData,
//                             state_name: e.target.value,
//                           })
//                         }
//                         style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
//                         placeholder=""
//                         defaultValue={selectedState?.state_name}
//                       />
//                     ),
//                   },
//                   {
//                     label: "Active:",

//                     comp: (
//                       <Checkbox
//                         onChange={(e) =>
//                           setEditData({
//                             ...editData,
//                             is_active: e.target.checked,
//                           })
//                         }
//                         value={selectedState?.is_active || editData?.is_active}
//                         disabled={!editMode}
//                         // defaultValue={selectedState?.is_active || editData?.is_active}
//                         defaultChecked={
//                           selectedState?.is_active || editData?.is_active
//                         }
//                         // checked={selectedState?.is_active}
//                       />
//                     ),
//                   },
//                 ]}
//                 secondaryComp={
//                   <Btn
//                     style={{ fontSize: 10, fontWeight: "bold" }}
//                     disabled={editMode}
//                   >
//                     Assign Companies
//                   </Btn>
//                 }
//               />
//             ) : (
//               <Stack>
//                 <Typography>Select any Customer Category</Typography>
//                 <Typography color={"red"} fontSize={12}>
//                   {getOMCustomerCategoryError}
//                 </Typography>
//               </Stack>
//             )}
//             <Stack my={2}>
//               <TabComp
//                 tabs={[
//                   //   {
//                   //     name: "Companies",
//                   //     content: (
//                   //       <Stack height={400} width={"100%"}>
//                   //         <TableComp
//                   //           columns={[
//                   //             {
//                   //               field: "companyId",
//                   //               headerName: "Company ID",
//                   //               flex: true,
//                   //             },
//                   //             {
//                   //               field: "companyName",
//                   //               headerName: "Company Name",
//                   //               flex: true,
//                   //             },
//                   //             {
//                   //               field: "action",
//                   //               headerName: "Action",
//                   //               flex: true,
//                   //             },
//                   //           ]}
//                   //           //   onRowClick={(e) => {
//                   //           //     setSelectedState(e?.row);
//                   //           //   }}
//                   //           rows={[]}
//                   //           //   loading={
//                   //           //     getProductStyleStatus === asyncStatus.IDLE ||
//                   //           //     getProductStyleStatus === asyncStatus.LOADING
//                   //           //   }
//                   //         />
//                   //       </Stack>
//                   //     ),
//                   //   },
//                   {
//                     name: "Log Info",
//                     content: (
//                       <Stack height={400} width={"100%"}>
//                         <TableComp
//                           columns={[
//                             {
//                               field: "logId",
//                               headerName: "Log ID",
//                               flex: true,
//                             },
//                             {
//                               field: "recordId",
//                               headerName: "Record_id",
//                               flex: true,
//                             },
//                             {
//                               field: "columnName",
//                               headerName: "Column Name",
//                               flex: true,
//                             },
//                             {
//                               field: "columnValue",
//                               headerName: "Column Value",
//                               flex: true,
//                             },
//                             {
//                               field: "actionName",
//                               headerName: "Action Name",
//                               flex: true,
//                             },
//                             {
//                               field: "actionDate",
//                               headerName: "Action Date",
//                               flex: true,
//                             },
//                             {
//                               field: "userId",
//                               headerName: " User ID",
//                               flex: true,
//                             },
//                           ]}
//                           //   onRowClick={(e) => {
//                           //     setSelectedState(e?.row);
//                           //   }}
//                           rows={[]}
//                           //   loading={
//                           //     getProductStyleStatus === asyncStatus.IDLE ||
//                           //     getProductStyleStatus === asyncStatus.LOADING
//                           //   }
//                         />
//                       </Stack>
//                     ),
//                   },
//                 ]}
//               />
//             </Stack>
//           </Grid>
//         </Grid>

//         {/* <div style={{ height: 400, width: '100%' }}>
//       <DataGrid
//       sty
//         rows={rows}
//         columns={columns}
//         initialState={{
//           pagination: {
//             paginationModel: { page: 0, pageSize: 5 },
//           },
//         }}
//         pageSizeOptions={[5, 10]}
//         // checkboxSelection
//       />
//     </div> */}
//       </ScreenCard>
//     </Stack>
//   );
// };

// export default State;
