import { Box, Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
// import {
//   addOMUserRightsAsync,
//   deleteOMUserRightsAsync,
//   editOMUserRightsAsync,
//   getOMUserRightsAsync,
// } from "../../../store/services/orderManagementService";
const UserRights = () => {
  const dispatch = useDispatch();
  const {
    getOMUserRightsStatus,
    getOMUserRightsError,
    getOMUserRightsData,
    addOMUserRightsStatus,
    addOMUserRightsData,
    addOMUserRightsError,

    editOMUserRightsStatus,
    editOMUserRightsError,
    editOMUserRightsData,

    deleteOMUserRightsData,
    deleteOMUserRightsStatus,
    deleteOMUserRightsError,
  } = useSelector((state) => state.om);

  // useEffect(() => {
  //   dispatch(getOMUserRightsAsync());
  // }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMUserRightsData?.length
    ? getOMUserRightsData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedUserRights, setSelectedUserRights] = useState();
  const [addUserRightsMode, setAddUserRightsMode] = useState(false);
  const [addUserRightsData, setAddUserRightsData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedUserRights", selectedUserRights);
  // console.log("editData", editData);
  // useEffect(() => {
  //   if (addOMUserRightsStatus === asyncStatus.SUCCEEDED) {
  //     setAddUserRightsMode(false);
  //     setSaveDisabled(true);
  //     setAddUserRightsData();
  //   }
  // }, [, addOMUserRightsStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    // dispatch(deleteOMUserRightsAsync(selectedUserRights?.term_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   if (deleteOMUserRightsStatus === asyncStatus.SUCCEEDED || deleteOMUserRightsStatus === asyncStatus.ERROR ) {
  //     setOpen(false);
  //     setSelectedUserRights();
  //   }
  // }, [, deleteOMUserRightsStatus]);
  // useEffect(() => {
  //   if (editOMUserRightsStatus === asyncStatus.SUCCEEDED) {
  //     setSelectedUserRights();
  //     setEditMode(false);
  //     setEditData()
  //   }
  // }, [, editOMUserRightsStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this location?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="User Rights"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              User Rights
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddUserRightsMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addUserRightsMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addUserRightsMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddUserRightsMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addUserRightsMode || !selectedUserRights}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMUserRightsStatus === asyncStatus.LOADING ||
                  editOMUserRightsStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                // onClick={() => {
                //   if (addUserRightsData) {
                //     dispatch(addOMUserRightsAsync(addUserRightsData));
                //   } else if (editMode) {
                //     let a;
                //     a = { ...selectedUserRights, ...editData };
                //     delete a.id;
                //     dispatch(editOMUserRightsAsync(a));
                //   }
                // }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} padding={2}>
            {addUserRightsMode ? (
              <InputWithBtnLayoutComp
              error={addOMUserRightsError}
                inputArray={[
                  {
                    label: "User:",
                    comp: (
                      <VirtualizedSelect
                      required={true}
                      // options={
                      //   getProductGenderData?.length && getProductGenderData
                      // }
                      // value={
                      //   getProductGenderData?.find(
                      //     (x) =>
                      //       addGenderCategoryData?.gender_id === x?.gender_id
                      //   ) || ""
                      // }
                      // disabled={
                      //   getProductGenderStatus === asyncStatus.IDLE ||
                      //   getProductGenderStatus === asyncStatus.LOADING ||
                      //   !(addGenderCategoryMode || editMode)
                      // }
                      // onChange={(e) => {
                      //   if (e?.gender_id) {
                      //     setAddGenderCategoryData({
                      //       ...addGenderCategoryData,
                      //       gender_id: e?.gender_id,
                      //     });
                      //   }
                      // }}
                      name={"gender_name"}
                      id_key_name={"gender_id"}
                    />
                    ),
                  },
                  {
                    label: "Module:",
                    comp: (
                      <VirtualizedSelect
                      required={true}
                      // options={
                      //   getProductGenderData?.length && getProductGenderData
                      // }
                      // value={
                      //   getProductGenderData?.find(
                      //     (x) =>
                      //       addGenderCategoryData?.gender_id === x?.gender_id
                      //   ) || ""
                      // }
                      // disabled={
                      //   getProductGenderStatus === asyncStatus.IDLE ||
                      //   getProductGenderStatus === asyncStatus.LOADING ||
                      //   !(addGenderCategoryMode || editMode)
                      // }
                      // onChange={(e) => {
                      //   if (e?.gender_id) {
                      //     setAddGenderCategoryData({
                      //       ...addGenderCategoryData,
                      //       gender_id: e?.gender_id,
                      //     });
                      //   }
                      // }}
                      name={"gender_name"}
                      id_key_name={"gender_id"}
                    />
                    ),
                  },
                
                ]}
                secondaryComp={<></>}
              />
            ) : selectedUserRights ? (
              <InputWithBtnLayoutComp
              error={editOMUserRightsError}
                inputArray={[
                  {
                    label: "User:",
                    comp: (
                      <VirtualizedSelect
                      required={true}
                      // options={
                      //   getProductGenderData?.length && getProductGenderData
                      // }
                      // value={
                      //   getProductGenderData?.find(
                      //     (x) =>
                      //       addGenderCategoryData?.gender_id === x?.gender_id
                      //   ) || ""
                      // }
                      // disabled={
                      //   getProductGenderStatus === asyncStatus.IDLE ||
                      //   getProductGenderStatus === asyncStatus.LOADING ||
                      //   !(addGenderCategoryMode || editMode)
                      // }
                      // onChange={(e) => {
                      //   if (e?.gender_id) {
                      //     setAddGenderCategoryData({
                      //       ...addGenderCategoryData,
                      //       gender_id: e?.gender_id,
                      //     });
                      //   }
                      // }}
                      name={"gender_name"}
                      id_key_name={"gender_id"}
                    />
                    ),
                  },
                  {
                    label: "Module:",
                    comp: (
                      <VirtualizedSelect
                      required={true}
                      // options={
                      //   getProductGenderData?.length && getProductGenderData
                      // }
                      // value={
                      //   getProductGenderData?.find(
                      //     (x) =>
                      //       addGenderCategoryData?.gender_id === x?.gender_id
                      //   ) || ""
                      // }
                      // disabled={
                      //   getProductGenderStatus === asyncStatus.IDLE ||
                      //   getProductGenderStatus === asyncStatus.LOADING ||
                      //   !(addGenderCategoryMode || editMode)
                      // }
                      // onChange={(e) => {
                      //   if (e?.gender_id) {
                      //     setAddGenderCategoryData({
                      //       ...addGenderCategoryData,
                      //       gender_id: e?.gender_id,
                      //     });
                      //   }
                      // }}
                      name={"gender_name"}
                      id_key_name={"gender_id"}
                    />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (<InputWithBtnLayoutComp
              error={addOMUserRightsError}
                inputArray={[
                  {
                    label: "User:",
                    comp: (
                      <VirtualizedSelect
                      required={true}
                      // options={
                      //   getProductGenderData?.length && getProductGenderData
                      // }
                      // value={
                      //   getProductGenderData?.find(
                      //     (x) =>
                      //       addGenderCategoryData?.gender_id === x?.gender_id
                      //   ) || ""
                      // }
                      // disabled={
                      //   getProductGenderStatus === asyncStatus.IDLE ||
                      //   getProductGenderStatus === asyncStatus.LOADING ||
                      //   !(addGenderCategoryMode || editMode)
                      // }
                      // onChange={(e) => {
                      //   if (e?.gender_id) {
                      //     setAddGenderCategoryData({
                      //       ...addGenderCategoryData,
                      //       gender_id: e?.gender_id,
                      //     });
                      //   }
                      // }}
                      name={"gender_name"}
                      id_key_name={"gender_id"}
                    />
                    ),
                  },
                  {
                    label: "Module:",
                    comp: (
                      <VirtualizedSelect
                      required={true}
                      // options={
                      //   getProductGenderData?.length && getProductGenderData
                      // }
                      // value={
                      //   getProductGenderData?.find(
                      //     (x) =>
                      //       addGenderCategoryData?.gender_id === x?.gender_id
                      //   ) || ""
                      // }
                      // disabled={
                      //   getProductGenderStatus === asyncStatus.IDLE ||
                      //   getProductGenderStatus === asyncStatus.LOADING ||
                      //   !(addGenderCategoryMode || editMode)
                      // }
                      // onChange={(e) => {
                      //   if (e?.gender_id) {
                      //     setAddGenderCategoryData({
                      //       ...addGenderCategoryData,
                      //       gender_id: e?.gender_id,
                      //     });
                      //   }
                      // }}
                      name={"gender_name"}
                      id_key_name={"gender_id"}
                    />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            )}
            <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedUserRights(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "User Rights",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Form Menu Id",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Form Name",
                              flex: true,
                            },
                            {
                              field: "recordId2sad",
                              headerName: "View",
                              renderHeader: (params) => (
                                <Box display="flex" alignItems="center">
                                  <Checkbox />
                                  {params.colDef.headerName}
                                </Box>
                              ),  
                              hideSortIcons: true,
                              flex: true,
                            },
                            {
                              field: "recordIdsad3",
                              headerName: "Add",
                              renderHeader: (params) => (
                             <Box display="flex" alignItems="center">
                               <Checkbox />
                               {params.colDef.headerName}
                             </Box>
                           ),  
                              hideSortIcons: true,
                              flex: true,
                            },
                            {
                              field: "recordId4ret",
                              headerName: "Delete",
                              renderHeader: (params) => (
                             <Box display="flex" alignItems="center">
                               <Checkbox />
                               {params.colDef.headerName}
                             </Box>
                           ),  
                              hideSortIcons: true,
                              flex: true,
                            },
                            {
                              field: "recordIdfdg5",
                              headerName: "Edit",
                              renderHeader: (params) => (
                             <Box display="flex" alignItems="center">
                               <Checkbox />
                               {params.colDef.headerName}
                             </Box>
                           ),  
                              hideSortIcons: true,
                              flex: true,
                            },
                            {
                              field: "recordxvcId6",
                              headerName: "Print",
                                 renderHeader: (params) => (
                                <Box display="flex" alignItems="center">
                                  <Checkbox />
                                  {params.colDef.headerName}
                                </Box>
                              ),  
                              hideSortIcons: true,
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedUserRights(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default UserRights;
