import * as React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import dlLogo from "../../assets/images/dlLogo.svg";
import InputComp from "../common/InputComp/Input";
import { Stack } from "@mui/material";
import AppBarMenu from "./AppBarMenu";
export default function CustomAppBar() {
  return (
    <Stack sx={{ position: "sticky", top: 0, height: "70px", flexDirection: "row", justifyContent: "space-between", backgroundColor: "white", borderBottom: "1px solid black", alignItems: "center" }} px={2}>
      <img alt="logo" src={dlLogo}  style={{ objectFit: "contain",width:"150px",height:"70px" }} />
      {/* <Stack flexDirection={"row"} > 
        <InputComp />
        <SearchIcon color="white" />
      </Stack> */}
      <AppBarMenu />
    </Stack>
  );
}
