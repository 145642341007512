import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import {
  addProductStyleAsync,
  addProductGenderCategoryAsync,
  deleteProductGenderCategoryAsync,
  editProductStyleAsync,
  editProductGenderCategoryAsync,
  getProductStyleAsync,
  getProductGenderCategoryAsync,
  getProductsizeAsync,
  getProductGenderAsync,
} from "../../../../store/services/productInfoServices";
import InfoFormComp from "../../../../components/InfoForm/InfoFormComp";
import VirtualizedSelect from "../../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
const GenderCategory = () => {
  const dispatch = useDispatch();
  const {
    getProductGenderCategoryStatus,
    getProductGenderCategoryError,
    getProductGenderCategoryData,
    addProductGenderCategoryStatus,
    addProductGenderCategoryData,
    addProductGenderCategoryError,

    editProductGenderCategoryStatus,
    editProductGenderCategoryError,
    editProductGenderCategoryData,

    deleteProductGenderCategoryData,
    deleteProductGenderCategoryStatus,
    deleteProductGenderCategoryError,

    getProductsizeStatus,
    getProductsizeError,
    getProductsizeData,

    getProductGenderStatus,
    getProductGenderError,
    getProductGenderData,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductGenderCategoryAsync());
    dispatch(getProductsizeAsync());
    dispatch(getProductGenderAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductGenderCategoryData?.length
    ? getProductGenderCategoryData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedGenderCategory, setSelectedGenderCategory] = useState();
  const [addGenderCategoryMode, setAddGenderCategoryMode] = useState(false);
  const [addGenderCategoryData, setAddGenderCategoryData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedGenderCategory", selectedGenderCategory);
  // console.log("editData", editData);
  useEffect(() => {
    if (addProductGenderCategoryStatus === asyncStatus.SUCCEEDED) {
      setAddGenderCategoryMode(false);
      setSaveDisabled(true);
      setAddGenderCategoryData();
    }
  }, [, addProductGenderCategoryStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(
      deleteProductGenderCategoryAsync(
        selectedGenderCategory?.gender_category_id
      )
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductGenderCategoryStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedGenderCategory();
    }
  }, [, deleteProductGenderCategoryStatus]);
  useEffect(() => {
    if (editProductGenderCategoryStatus === asyncStatus.SUCCEEDED) {
      setSelectedGenderCategory();
      setEditMode(false);
    }
  }, [, editProductGenderCategoryStatus]);
  console.log(
    "getProductGenderCategoryData",
    getProductGenderCategoryData,
    editProductGenderCategoryData
  );

  const handleChangeSize = (event) => {
    const {
      target: { value },
    } = event;
    setAddGenderCategoryData({
      ...addGenderCategoryData,
      size_chart: typeof value === "string" ? value.split(",") : value,
    });
  };
  const handleChangeActualSize = (event) => {
    const {
      target: { value },
    } = event;
    setAddGenderCategoryData({
      ...addGenderCategoryData,
      actual_size_chart: typeof value === "string" ? value.split(",") : value,
    });
  };
  const editHandleChangeSize = (event) => {
    const {
      target: { value },
    } = event;
    setEditData({
      ...editData,
      size_chart: typeof value === "string" ? value.split(",") : value,
    });
  };
  const editHandleChangeActualSize = (event) => {
    const {
      target: { value },
    } = event;
    setEditData({
      ...editData,
      actual_size_chart: typeof value === "string" ? value.split(",") : value,
    });
  };
  console.log("selectedGenderCategory",selectedGenderCategory);
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this Gender Category?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Gender Category"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Gender Category Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddGenderCategoryMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addGenderCategoryMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addGenderCategoryMode || !selectedGenderCategory}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddGenderCategoryMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addGenderCategoryMode || !selectedGenderCategory}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductGenderCategoryStatus === asyncStatus.LOADING ||
                  editProductGenderCategoryStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addGenderCategoryData) {
                    dispatch(
                      addProductGenderCategoryAsync({
                        ...addGenderCategoryData,
                        size_chart:
                          addGenderCategoryData?.size_chart?.join(","),
                        actual_size_chart:
                          addGenderCategoryData?.actual_size_chart?.join(","),
                      })
                    );
                  } else if (editMode) {
                    let a;
                    a = { ...selectedGenderCategory, ...editData };
                    delete a.id;
                    dispatch(editProductGenderCategoryAsync({...a, size_chart:
                      editData?.size_chart?.join(","),
                    actual_size_chart:
                      editData?.actual_size_chart?.join(","),}));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
              // columnWidthArray={[30,30,100,100]}
                columns={[
                  { field: "gender_category_id", headerName: "ID", width:70 },
                  {
                    field: "gender_category_name",
                    headerName: "Gender Category Name",
                  width:190
                  },
                  {
                    field: "size_chart",
                    headerName: "Size Chart",
                    // flex: true,
                    width:240,
                    renderCell: (params) => {
                      let sizes = params?.value?.split(",");
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1,
                            maxWidth: "100%",
                          }}
                        >
                          {sizes?.map((size, index) => (
                            <Box
                              key={index}
                              sx={{
                                padding: "1px 3px",
                                border: "1px solid black",
                              }}
                            >
                              {size}
                            </Box>
                          ))}
                        </Box>
                      );
                    },
                  },
                  {
                    field: "actual_size_chart",
                    headerName: "Actual Size Chart",
                    width:240,
                    renderCell: (params) => {
                      let sizes = params?.value?.split(",");
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1,
                            maxWidth: "100%",
                          }}
                        >
                          {sizes?.map((size, index) => (
                            <Box
                              key={index}
                              sx={{
                                padding: "1px 3px",
                                border: "1px solid black",
                              }}
                            >
                              {size}
                            </Box>
                          ))}
                        </Box>
                      );
                    },
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedGenderCategory(e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductGenderCategoryStatus === asyncStatus.IDLE ||
                  getProductGenderCategoryStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12} padding={2}>
            {addGenderCategoryMode ? (
              // <InputWithBtnLayoutComp
              //   inputArray={[
              //     {
              //       label: "Gender Category:",
              //       comp: (
              //         <InputComp
              //           style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
              //           placeholder=""
              //           onChange={(e) =>
              //             setAddGenderCategoryData({
              //               ...addGenderCategoryData,
              //               gender_category_name: e.target.value,
              //             })
              //           }
              //         />
              //       ),
              //     },
              //     {
              //       label: "Gender Category:",
              //       comp: (
              //         <InputComp
              //           style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
              //           placeholder=""
              //           onChange={(e) =>
              //             setAddGenderCategoryData({
              //               ...addGenderCategoryData,
              //               gender_category_name: e.target.value,
              //             })
              //           }
              //         />
              //       ),
              //     },
              //     {
              //       label: "Active:",
              //       comp: (
              //         <Checkbox
              //           defaultChecked
              //           defaultValue={true}
              //           onChange={(e) =>
              //             setAddGenderCategoryData({
              //               ...addGenderCategoryData,
              //               is_active: e.target.checked,
              //             })
              //           }
              //         />
              //       ),
              //     },
              //   ]}
              //   secondaryComp={<></>}
              // />
              <InfoFormComp
              customColumns={6}
                noHeader={true}
                bottomChild={
                  <Stack flexDirection={"row-reverse"} width={"100%"} pt={2}>
                    <Btn
                      style={{ fontSize: 10, fontWeight: "bold" }}
                      disabled={editMode}
                    >
                      Assign Companies
                    </Btn>
                  </Stack>
                }
                fieldsArray={[
                  {
                    label: "Gender Category:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddGenderCategoryData({
                            ...addGenderCategoryData,
                            gender_category_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Gender:",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={
                          getProductGenderData?.length && getProductGenderData
                        }
                        value={
                          getProductGenderData?.find(
                            (x) =>
                              addGenderCategoryData?.gender_id === x?.gender_id
                          ) || ""
                        }
                        disabled={
                          getProductGenderStatus === asyncStatus.IDLE ||
                          getProductGenderStatus === asyncStatus.LOADING ||
                          !(addGenderCategoryMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.gender_id) {
                            setAddGenderCategoryData({
                              ...addGenderCategoryData,
                              gender_id: e?.gender_id,
                            });
                          }
                        }}
                        name={"gender_name"}
                        id_key_name={"gender_id"}
                      />
                    ),
                  },
                  {
                    label: "Size:",
                    comp: (
                      <Select
                        required={true}
                        fullWidth
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={addGenderCategoryData?.size_chart || []}
                        onChange={handleChangeSize}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              padding: "5px 5px !important",
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                sx={{
                                  padding: "0px 0px 0px 0px !important",
                                  "& .css-6od3lo-MuiChip-label": {
                                    padding: "5px",
                                    fontSize: 12,
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        sx={{
                          padding: "5px 5px !important",
                          height: "auto",
                          fontSize: 12,
                          "& .MuiSelect-select": {
                            padding: "1px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 600,
                              width: 100,
                            },
                          },
                        }}
                        disabled={!(addGenderCategoryMode || editMode)}
                        // MenuProps={MenuProps}
                      >
                        {getProductsizeData?.map((e) => (
                          <MenuItem key={e?.size_id} value={e?.size_name}>
                            {e?.size_name}
                          </MenuItem>
                        ))}
                      </Select>
                    ),
                  },
                  {
                    label: "Actual Size:",
                    comp: (
                      <Select
                        required={true}
                        fullWidth
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={addGenderCategoryData?.actual_size_chart || []}
                        onChange={handleChangeActualSize}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              padding: "5px 5px !important",
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                sx={{
                                  padding: "0px 0px 0px 0px !important",
                                  "& .css-6od3lo-MuiChip-label": {
                                    padding: "5px",
                                    fontSize: 12,
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        sx={{
                          padding: "5px 5px !important",
                          height: "auto",
                          fontSize: 12,
                          "& .MuiSelect-select": {
                            padding: "1px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 600,
                              width: 100,
                            },
                          },
                        }}
                        disabled={!(addGenderCategoryMode || editMode)}
                        // MenuProps={MenuProps}
                      >
                        {getProductsizeData?.map((e) => (
                          <MenuItem key={e?.size_id} value={e?.size_name}>
                            {e?.size_name}
                          </MenuItem>
                        ))}
                      </Select>
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddGenderCategoryData({
                            ...addGenderCategoryData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                  ,
                ]}
              />
            ) : selectedGenderCategory ? (
              // <InputWithBtnLayoutComp
              //   inputArray={[
              //     {
              //       label: "ID:",
              //       comp: (
              //         <Typography sx={{ fontSize: 14 }}>
              //           {selectedGenderCategory?.gender_category_id}
              //         </Typography>
              //       ),
              //     },
              //     {
              //       label: "Gender Category:",
              //       comp: (
              //         <InputComp
              //           key={selectedGenderCategory?.gender_category_id} // Add key prop to force remount
              //           disabled={!editMode}
              //           onChange={(e) =>
              //             setEditData({
              //               ...editData,
              //               gender_category_name: e.target.value,
              //             })
              //           }
              //           style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
              //           placeholder=""
              //           defaultValue={
              //             selectedGenderCategory?.gender_category_name
              //           }
              //         />
              //       ),
              //     },
              //     {
              //       label: "Active:",

              //       comp: (
              //         <Checkbox
              //           onChange={(e) =>
              //             setEditData({
              //               ...editData,
              //               is_active: e.target.checked,
              //             })
              //           }
              //           value={editData?.is_active}
              //           disabled={!editMode}
              //           defaultValue={
              //             selectedGenderCategory?.is_active ? true : false
              //           }
              //           defaultChecked={
              //             selectedGenderCategory?.is_active ? true : false
              //           }
              //           checked={selectedGenderCategory?.is_active}
              //         />
              //       ),
              //     },
              //   ]}
              //   secondaryComp={
              //     <Btn
              //       style={{ fontSize: 10, fontWeight: "bold" }}
              //       disabled={editMode}
              //     >
              //       Assign Companies
              //     </Btn>
              //   }
              // />
              <InfoFormComp
              customColumns={6}
                noHeader={true}
                bottomChild={
                  <Stack flexDirection={"row-reverse"} width={"100%"} pt={2}>
                    <Btn
                      style={{ fontSize: 10, fontWeight: "bold" }}
                      disabled={editMode}
                    >
                      Assign Companies
                    </Btn>
                  </Stack>
                }
                fieldsArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedGenderCategory?.gender_category_id}
                      </Typography>
                    ),
                  },
                  {
                          label: "Gender Category:",
                          comp: (
                            <InputComp
                              key={selectedGenderCategory?.gender_category_id} // Add key prop to force remount
                              disabled={!editMode}
                              onChange={(e) =>
                                setEditData({
                                  ...editData,
                                  gender_category_name: e.target.value,
                                })
                              }
                              style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                              placeholder=""
                              defaultValue={
                                selectedGenderCategory?.gender_category_name
                              }
                            />
                          ),
                        },
                      
                  {
                    label: "Gender:",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={
                          getProductGenderData?.length && getProductGenderData
                        }
                        value={
                    selectedGenderCategory?.gender_id?
                    getProductGenderData?.find(
                      (x) =>
                        selectedGenderCategory?.gender_id === x?.gender_id
                    ) || ""
                    :
                          getProductGenderData?.find(
                            (x) =>
                              editData?.gender_id === x?.gender_id
                          ) || ""
                        }
                        disabled={
                          getProductGenderStatus === asyncStatus.IDLE ||
                          getProductGenderStatus === asyncStatus.LOADING ||
                          !(addGenderCategoryMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.gender_id) {
                            setEditData({
                              ...editData,
                              gender_id: e?.gender_id,
                            });
                          }
                        }}
                        name={"gender_name"}
                        id_key_name={"gender_id"}
                      />
                    ),
                  },
                  {
                    label: "Size:",
                    comp: (
                      <Select
                        required={true}
                        fullWidth
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={editData?.size_chart?editData?.size_chart:selectedGenderCategory?.size_chart?.split(",") || []}
                        onChange={editHandleChangeSize}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              padding: "5px 5px !important",
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                sx={{
                                  padding: "0px 0px 0px 0px !important",
                                  "& .css-6od3lo-MuiChip-label": {
                                    padding: "5px",
                                    fontSize: 12,
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        sx={{
                          padding: "5px 5px !important",
                          height: "auto",
                          fontSize: 12,
                          "& .MuiSelect-select": {
                            padding: "1px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 600,
                              width: 100,
                            },
                          },
                        }}
                        disabled={!(addGenderCategoryMode || editMode)}
                        // MenuProps={MenuProps}
                      >
                        {getProductsizeData?.map((e) => (
                          <MenuItem key={e?.size_id} value={e?.size_name}>
                            {e?.size_name}
                          </MenuItem>
                        ))}
                      </Select>
                    ),
                  },
                  {
                    label: "Actual Size:",
                    comp: (
                      <Select
                        required={true}
                        fullWidth
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={editData?.actual_size_chart?editData?.actual_size_chart:selectedGenderCategory?.actual_size_chart?.split(",") || []}
                        onChange={editHandleChangeActualSize}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              padding: "5px 5px !important",
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                sx={{
                                  padding: "0px 0px 0px 0px !important",
                                  "& .css-6od3lo-MuiChip-label": {
                                    padding: "5px",
                                    fontSize: 12,
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        sx={{
                          padding: "5px 5px !important",
                          height: "auto",
                          fontSize: 12,
                          "& .MuiSelect-select": {
                            padding: "1px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 600,
                              width: 100,
                            },
                          },
                        }}
                        disabled={!(addGenderCategoryMode || editMode)}
                        // MenuProps={MenuProps}
                      >
                        {getProductsizeData?.map((e) => (
                          <MenuItem key={e?.size_id} value={e?.size_name}>
                            {e?.size_name}
                          </MenuItem>
                        ))}
                      </Select>
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={editData?.is_active}
                        disabled={!editMode}
                        defaultValue={
                          selectedGenderCategory?.is_active ? true : false
                        }
                        defaultChecked={
                          selectedGenderCategory?.is_active ? true : false
                        }
                        checked={selectedGenderCategory?.is_active}
                      />
                    ),
                  },
                  ,
                ]}
              />
            ) : (
              <Typography>Select any gender categories</Typography>
            )}

            <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductGenderCategoryError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductGenderCategoryError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductGenderCategoryError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductGenderCategoryError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  // {
                  //   name: "Companies",
                  //   content: (
                  //     <Stack height={400} width={"100%"}>
                  //       <TableComp
                  //         columns={[
                  //           {
                  //             field: "companyId",
                  //             headerName: "Company ID",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "companyName",
                  //             headerName: "Company Name",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Action",
                  //             flex: true,
                  //           },
                  //         ]}
                  //         //   onRowClick={(e) => {
                  //         //     setSelectedGenderCategory(e?.row);
                  //         //   }}
                  //         rows={[]}
                  //         //   loading={
                  //         //     getProductStyleStatus === asyncStatus.IDLE ||
                  //         //     getProductStyleStatus === asyncStatus.LOADING
                  //         //   }
                  //       />
                  //     </Stack>
                  //   ),
                  // },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedGenderCategory(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default GenderCategory;
