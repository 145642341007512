import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import {
  addProductStyleAsync,
  addProductWashAsync,
  deleteProductWashAsync,
  editProductStyleAsync,
  editProductWashAsync,
  getProductStyleAsync,
  getProductWashAsync,
} from "../../../../store/services/productInfoServices";
const Wash = () => {
  const dispatch = useDispatch();
  const {
    getProductWashStatus,
    getProductWashError,
    getProductWashData,
    addProductWashStatus,
    addProductWashData,
    addProductWashError,

    editProductWashStatus,
    editProductWashError,
    editProductWashData,

    deleteProductWashData,
    deleteProductWashStatus,
    deleteProductWashError,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductWashAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductWashData?.length
    ? getProductWashData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedWash, setSelectedWash] = useState();
  const [addWashMode, setAddWashMode] = useState(false);
  const [addWashData, setAddWashData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedWash", selectedWash);
  // console.log("editData", editData);
  useEffect(() => {
    if (addProductWashStatus === asyncStatus.SUCCEEDED) {
      setAddWashMode(false);
      setSaveDisabled(true);
      setAddWashData();
    }
  }, [, addProductWashStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteProductWashAsync(selectedWash?.wash_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductWashStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedWash();
    }
  }, [, deleteProductWashStatus]);
  useEffect(() => {
    if (editProductWashStatus === asyncStatus.SUCCEEDED) {
      setSelectedWash();
      setEditMode(false);
    }
  }, [, editProductWashStatus]);

  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this wash?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Style"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Wash Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddWashMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addWashMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addWashMode || !selectedWash}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddWashMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addWashMode || !selectedWash}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductWashStatus === asyncStatus.LOADING ||
                  editProductWashStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addWashData) {
                    dispatch(addProductWashAsync(addWashData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedWash, ...editData };
                    delete a.id;
                    dispatch(editProductWashAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
                columns={[ 
                  { field: "wash_id", headerName: "ID", flex: true },
                  { field: "wash_name", headerName: "Wash Name", flex: true },
                ]}
                onRowClick={(e) => {
                  setSelectedWash(e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductWashStatus === asyncStatus.IDLE ||
                  getProductWashStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addWashMode ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "Wash:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddWashData({
                            ...addWashData,
                            wash_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddWashData({
                            ...addWashData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedWash ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedWash?.wash_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "Wash:",
                    comp: (
                      <InputComp
                        key={selectedWash?.wash_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            wash_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedWash?.wash_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={editData?.is_active}
                        disabled={!editMode}
                        defaultValue={selectedWash?.is_active ? true : false}
                        defaultChecked={selectedWash?.is_active ? true : false}
                        checked={selectedWash?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Typography>Select any wash</Typography>
            )}
             <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductWashError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductWashError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductWashError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductWashError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  {
                    name: "Companies",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "companyId",
                              headerName: "Company ID",
                              flex: true,
                            },
                            {
                              field: "companyName",
                              headerName: "Company Name",
                              flex: true,
                            },
                            {
                              field: "action",
                              headerName: "Action",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedWash(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedWash(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default Wash;
