import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { themeLightGray } from "../../utils/themeColors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InputComp from "../common/InputComp/Input";
import Btn from "../common/ButtonComp/Btn";
const FiltersAccordionFom = ({
  headerRightComp,
  allFields = [
    {
      title: "abc",
      comp: <InputComp />,
    },
    {
      title: "abc",
      comp: <InputComp />,
    },
    {
      title: "abc",
      comp: <InputComp />,
    },
    {
      title: "abc",
      comp: <InputComp />,
    },
    {
      title: "abc",
      comp: <InputComp />,
    },
  ],
  bottomChild=        <Stack flexDirection={"row"} justifyContent={"flex-end"}>
  <Btn type="submit" style={{ width: "auto", textTransform: "none" }}>Search</Btn>
</Stack>
}) => {
  const [accordion, setAccordion] = useState(false);

  return (
    <Accordion
      expanded={accordion}
      sx={{
        "& .css-o4b71y-MuiAccordionSummary-content": {
          margin: 0,
        },
        // "& .css-o4b71y-MuiAccordionSummary-content:hover": {
        //   margin: 0,
        // },

        border: 0,
        boxShadow: "none",
      }}
      onChange={(e, a) => setAccordion(a)}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        style={{
          backgroundColor: themeLightGray,
          borderRadius: 0,
          //   margin: "0px !important",
          //   minHeight:0
        }}
      >
        <Stack
          width={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: headerRightComp ? "space-between" : "flex-start",
            alignItems: "center",
            margin: "0px !important",
          }}
        >
          <Stack flexDirection={"row"} alignItems={"center"} gap={3}>
            {accordion ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            <Typography sx={{ fontSize: 16, fontWeight: "600" }}>
              Filters
            </Typography>
          </Stack>
          {headerRightComp}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container rowSpacing={1}>
          {allFields?.map(({ title, comp }) => (
            <Grid
              container
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={6}
              sx={{ alignItems: "center" }}
            >
              <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                <Typography sx={{ fontSize: 14 }}>{title}</Typography>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                {comp}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {bottomChild}
      </AccordionDetails>
    </Accordion>
  );
};

export default FiltersAccordionFom;
