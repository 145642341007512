import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import {
  addProductStyleAsync,
  addProductFitCategoryAsync,
  deleteProductFitCategoryAsync,
  editProductStyleAsync,
  editProductFitCategoryAsync,
  getProductStyleAsync,
  getProductFitCategoryAsync,
} from "../../../../store/services/productInfoServices";
const FitCategory = () => {
  const dispatch = useDispatch();
  const {
    getProductFitCategoryStatus,
    getProductFitCategoryError,
    getProductFitCategoryData,
    addProductFitCategoryStatus,
    addProductFitCategoryData,
    addProductFitCategoryError,

    editProductFitCategoryStatus,
    editProductFitCategoryError,
    editProductFitCategoryData,

    deleteProductFitCategoryData,
    deleteProductFitCategoryStatus,
    deleteProductFitCategoryError,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductFitCategoryAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductFitCategoryData?.length
    ? getProductFitCategoryData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedFitCategory, setSelectedFitCategory] = useState();
  const [addFitCategoryMode, setAddFitCategoryMode] = useState(false);
  const [addFitCategoryData, setAddFitCategoryData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedFitCategory", selectedFitCategory);
  // console.log("editData", editData);
  useEffect(() => {
    if (addProductFitCategoryStatus === asyncStatus.SUCCEEDED) {
      setAddFitCategoryMode(false);
      setSaveDisabled(true);
      setAddFitCategoryData();
    }
  }, [, addProductFitCategoryStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteProductFitCategoryAsync(selectedFitCategory?.fit_category_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductFitCategoryStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedFitCategory();
    }
  }, [, deleteProductFitCategoryStatus]);
  useEffect(() => {
    if (editProductFitCategoryStatus === asyncStatus.SUCCEEDED) {
      setSelectedFitCategory();
      setEditMode(false);
    }
  }, [, editProductFitCategoryStatus]);
console.log("getProductFitCategoryData",getProductFitCategoryData,editProductFitCategoryData);
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this FitCategory?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Fit Category"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Fit Category Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddFitCategoryMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addFitCategoryMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFitCategoryMode || !selectedFitCategory}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddFitCategoryMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFitCategoryMode || !selectedFitCategory}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductFitCategoryStatus === asyncStatus.LOADING ||
                  editProductFitCategoryStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addFitCategoryData) {
                    dispatch(addProductFitCategoryAsync(addFitCategoryData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedFitCategory, ...editData };
                    delete a.id;
                    dispatch(editProductFitCategoryAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
                columns={[
                  { field: "fit_category_id", headerName: "ID", flex: true },
                  {
                    field: "fit_category_name",
                    headerName: "FitCategory Name",
                    flex: true,
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedFitCategory(e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductFitCategoryStatus === asyncStatus.IDLE ||
                  getProductFitCategoryStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addFitCategoryMode ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "Fit Category:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddFitCategoryData({
                            ...addFitCategoryData,
                          fit_category_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddFitCategoryData({
                            ...addFitCategoryData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedFitCategory ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedFitCategory?.fit_category_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "Fit Category:",
                    comp: (
                      <InputComp
                        key={selectedFitCategory?.fit_category_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                          fit_category_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedFitCategory?.fit_category_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={editData?.is_active}
                        disabled={!editMode}
                        defaultValue={
                          selectedFitCategory?.is_active ? true : false
                        }
                        defaultChecked={
                          selectedFitCategory?.is_active ? true : false
                        }
                        checked={selectedFitCategory?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Typography>Select any fit category</Typography>
            )}

            <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductFitCategoryError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductFitCategoryError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductFitCategoryError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductFitCategoryError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  // {
                  //   name: "Companies",
                  //   content: (
                  //     <Stack height={400} width={"100%"}>
                  //       <TableComp
                  //         columns={[
                  //           {
                  //             field: "companyId",
                  //             headerName: "Company ID",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "companyName",
                  //             headerName: "Company Name",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Action",
                  //             flex: true,
                  //           },
                  //         ]}
                  //         //   onRowClick={(e) => {
                  //         //     setSelectedFitCategory(e?.row);
                  //         //   }}
                  //         rows={[]}
                  //         //   loading={
                  //         //     getProductStyleStatus === asyncStatus.IDLE ||
                  //         //     getProductStyleStatus === asyncStatus.LOADING
                  //         //   }
                  //       />
                  //     </Stack>
                  //   ),
                  // },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedFitCategory(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default FitCategory;
