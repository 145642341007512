import { Stack } from "@mui/material";
import "./input.css";
import { useState } from "react";





function InputComp({
  value,
  placeholder=" ",
  type,
  id,
  onChange=()=>{},
  required,
  disabled,
  width,
  label,
  style,
  maxLength,
  minLength,
  defaultValue,
  name,
  validator=()=>{}
}) {
 const [error, setError] = useState("")
  return (
    <Stack>
      <label
        htmlFor={id}
        style={{
          color: "lightgray",
          display: "block",
          fontSize: "18px",
          width: "100%",
          fontWeight: 500,
        }}
      >
        {label}
      </label>
      <input
        defaultValue={defaultValue}
        type={type}
        name={name}
        width={width}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e)=>{onChange(e);setError(validator(e))}}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        style={{ ...style, }}
        className={"hoveringInp"}
      />
      <p style={{color:"red",fontSize:14,textAlign:"end"}}>{error}</p>
    </Stack>
  );
}
export default InputComp;
