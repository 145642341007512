import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor, themeLightGray } from "../../../utils/themeColors";
import InfoFormComp from "../../../components/InfoForm/InfoFormComp";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
import { getProductCompanyAsync } from "../../../store/services/productInfoServices";
import CustomModal from "../../../components/common/Modal/CustomModal";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FiltersAccordionFom from "../../../components/FiltersAccordionFom/FiltersAccordionFom";
import {
  addOMVendorAsync,
  deleteVendorByIdAsync,
  editVendorByIdAsync,
  getOMVendorByIdAsync,
  getOMVendorCategoryAsync,
  searchOMVendorAsync,
} from "../../../store/services/orderManagementService";
import moment from "moment";
import {
  error_toast_message,
  success_toast_message,
} from "../../../utils/toast_notification";
import { setEditVendorByIdEmpty } from "../../../store/slices/orderManagementSlice";
// import {
//   addOMVendorAsync,
//   deleteOMVendorAsync,
//   editOMVendorAsync,
//   getOMVendorAsync,
// } from "../../../store/services/orderManagementService";
const Vendor = () => {
  const dispatch = useDispatch();
  const { getProductCompanyStatus, getProductCompanyData } = useSelector(
    (state) => state.product
  );
  const {
    getOMVendorCategoryStatus,
    getOMVendorCategoryError,
    getOMVendorCategoryData,
    addOMVendorStatus,
    addOMVendorError,
    addOMVendorData,
    searchOMVendorStatus,
    searchOMVendorError,
    searchOMVendorData,

    getVendorByIdStatus,
    getVendorByIdError,
    getVendorByIdData,

    editVendorByIdStatus,
    editVendorByIdError,
    editVendorByIdData,

    deleteVendorByIdStatus,
    deleteVendorByIdError,
    deleteVendorByIdData,
  } = useSelector((state) => state.om);

  // useEffect(() => {
  //   dispatch(getOMVendorAsync());
  // }, []);

  // console.log("getProductStyleData", getProductStyleData);
  // const formattedData = getOMVendorData?.length
  //   ? getOMVendorData.map((style, index) => ({
  //       id: index + 1, // Assuming the index is a unique identifier for each row
  //       ...style,
  //     }))
  //   : [];
  const [selectedVendor, setSelectedVendor] = useState();
  const [addVendorMode, setAddVendorMode] = useState(false);
  const [addVendorData, setAddVendorData] = useState({
    vendor_type: "I",
  });
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  const history = useNavigate();
  // console.log("selectedVendor", selectedVendor);
  // console.log("editData", editData);
  // useEffect(() => {
  //   if (addOMVendorStatus === asyncStatus.SUCCEEDED) {
  //     setAddVendorMode(false);
  //     setSaveDisabled(true);
  //     setAddVendorData();
  //   }
  // }, [, addOMVendorStatus]);

  const currentDate = new Date(); // Get the current date
  const oneMonthAgo = new Date(currentDate); // Create a new date object with the current date
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  const [open, setOpen] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [searchData, setSearchData] = useState({
    from_date: oneMonthAgo,
    to_date: new Date(),
  });
  const agree = () => {
    dispatch(deleteVendorByIdAsync(currentVendorId));
  };
  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   if (deleteOMVendorStatus === asyncStatus.SUCCEEDED || deleteOMVendorStatus === asyncStatus.ERROR ) {
  //     setOpen(false);
  //     setSelectedVendor();
  //   }
  // }, [, deleteOMVendorStatus]);
  // useEffect(() => {
  //   if (editOMVendorStatus === asyncStatus.SUCCEEDED) {
  //     setSelectedVendor();
  //     setEditMode(false);
  //     setEditData()
  //   }
  // }, [, editOMVendorStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  useEffect(() => {
    dispatch(getProductCompanyAsync());
    dispatch(getOMVendorCategoryAsync());
  }, []);
  const location = useLocation();

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  useLayoutEffect(() => {
    let { pathname } = location;
    setSearchMode(
      pathname === "/vendor"
        ? false
        : pathname === "/vendor/search"
        ? true
        : false
    );
  }, [location]);
  console.log(searchMode,'5555')
  const formattedData = searchOMVendorData?.length
    ? searchOMVendorData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  console.log(searchOMVendorData);
  const [currentVendorId, setCurrentVendorId] = useState("");

  let VendorId = useParams();
  // console.log("productInfoData", productInfoData);
  useEffect(() => {
    if (VendorId["*"]) {
      setCurrentVendorId(VendorId["*"]);
      dispatch(getOMVendorByIdAsync(VendorId["*"]));
      // dispatch(getCombinationsByVendorIdAsync(VendorId["*"]));
    }
  }, [, VendorId]);
  useEffect(() => {
    if (getVendorByIdStatus === asyncStatus.SUCCEEDED) {
      setAddVendorData(getVendorByIdData);
    }
  }, [getVendorByIdStatus]);
  useEffect(() => {
    if (editVendorByIdStatus === asyncStatus.SUCCEEDED) {
      setEditMode(false);
    }
  }, [editVendorByIdStatus]);

  useEffect(() => {
    if (deleteVendorByIdStatus === asyncStatus.SUCCEEDED) {
      setCurrentVendorId("");
      setOpen(false);
      setAddVendorData({ vendor_type: "I" });
      document.getElementById("vendorForm").reset();
      history("/vendor");
    }
  }, [deleteVendorByIdStatus]);

  return (
    <Stack
      component={"form"}
      id="vendorForm"
      onSubmit={(event) => {
        event.preventDefault();
        console.log("currentVendorId", { currentVendorId });
        if (editMode) {
          let obj = addVendorData;
          delete obj.vendor_id;
          dispatch(editVendorByIdAsync({ id: currentVendorId, obj }));
        } else {
          // dispatch(
          //   postItemAsync({
          //     ...productInfoData,
          //     random: combinations,
          //     is_active: true,
          //     inseam: inseam?.join(","),
          //     size: sizes?.join(","),
          //     image_url: null,
          //   })
          // );
          dispatch(addOMVendorAsync(addVendorData));
          console.log(addVendorData);
        }
      }}
      padding={2}
    >
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this vendor?"}
        heading={"Delete request"}
      />
      <CustomModal
        handleClose={handleOpenModal}
        open={openModal}
        children={
          <Stack
            width={"100%"}
            minWidth={{ xl: 700, lg: 600, md: 500, sm: 300, xs: 200 }}
            alignItems={"center"}
            padding={1}
          >
            <Stack width={1} spacing={2}>
              <Stack
                width={"100%"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography fontSize={14} fontWeight={"bold"}>
                  Billing Form
                </Typography>
                <IconButton onClick={handleCloseModal}>
                  <RxCross1 color="red" size={20} />
                </IconButton>
              </Stack>
              <Divider />
              <Stack>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Addressee
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Address
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Address
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Address
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Country
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      City
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      State
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Email
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Fax
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Phone
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Zip
                    </Typography>
                    <InputComp style={{ padding: 3 }} placeholder="" />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                    >
                      Active
                    </Typography>
                    <Checkbox
                      defaultChecked
                      defaultValue={true}
                      sx={{ padding: 0, margin: 0 }}
                      // onChange={(e) =>
                      //   setAddVendorData({
                      //     ...addVendorData,
                      //     is_active: e.target.checked,
                      //   })
                      // }
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Divider />
              <Stack
                width={"100%"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                gap={2}
              >
                <Btn
                  type="button"
                  style={{ textTransform: "none", minWidth: "100px" }}
                  variant="outlined"
                  // disabled={!(addVendorMode || editMode)}
                  onClick={handleCloseModal}
                >
                  Back
                </Btn>
                <Btn
                  type="button"
                  style={{ textTransform: "none", minWidth: "100px" }}
                  // disabled={!(addVendorMode || editMode)}
                  // onClick={generateCombinations}
                >
                  Submit
                </Btn>
              </Stack>
            </Stack>
          </Stack>
        }
      />
      <ScreenCard
        title="Vendor"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Vendor
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setSearchMode((prev) => !prev);
                  setAddVendorMode(false);
                  setEditMode(false);
                  history(searchMode ? "/vendor" : "/vendor/search");
                }}
              >
                {!searchMode ? (
                  <SearchIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                // onClick={() => {
                //   setEditMode(false);
                //   setAddVendorMode((prev) => !prev);
                //   setSaveDisabled(false);
                // }}

                onClick={() => {
                  setEditMode(false);
                  setAddVendorMode((prev) => !prev);
                  dispatch(setEditVendorByIdEmpty());
                  setCurrentVendorId("");
                  setAddVendorData({ vendor_type: "I" });
                  document.getElementById("vendorForm").reset();
                  history("/vendor");
                  // setAddProductInformationMode((prev) => !prev);
                  // setSaveDisabled(false);
                }}
              >
                {!addVendorMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addVendorMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  // setAddProductInformationMode(false);
                  setEditMode((prev) => !prev);
                  setAddVendorMode(false);
                  // setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addVendorMode || !currentVendorId}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMVendorStatus === asyncStatus.LOADING
                  // ||  editOMVendorStatus === asyncStatus.LOADING ||
                  // saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                type="submit"

                // onClick={() => {
                //   if (addVendorData) {
                //     dispatch(addOMVendorAsync(addVendorData));
                //   } else if (editMode) {
                //     let a;
                //     a = { ...selectedVendor, ...editData };
                //     delete a.id;
                //     dispatch(editOMVendorAsync(a));
                //   }
                // }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        {" "}
        {!searchMode ? (
          <Stack>
            <InfoFormComp
              customColumns={4}
              title="Primary Information"
              fieldsArray={[
                {
                  label: "Company :",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getProductCompanyData?.length && getProductCompanyData
                      }
                      value={
                        getProductCompanyData?.find(
                          (x) => addVendorData?.company_id === x?.company_id
                        ) || ""
                      }
                      disabled={
                        getProductCompanyStatus === asyncStatus.IDLE ||
                        getProductCompanyStatus === asyncStatus.LOADING ||
                        !(addVendorMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.company_id) {
                          setAddVendorData({
                            ...addVendorData,
                            company_id: e?.company_id,
                          });
                        }
                      }}
                      name={"company_name"}
                      id_key_name={"company_id"}
                    />
                  ),
                },
                {
                  label: "Vendor Id :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          vendor_id: e.target.value,
                        })
                      }
                      value={addVendorData?.vendor_id}
                      disabled={true}
                      name={"vendor_id"}
                    />
                  ),
                },

                {
                  label: "Vendor Code :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          vendor_code: e.target.value,
                        })
                      }
                      value={addVendorData?.vendor_code}
                      disabled={true}
                      name={"vendor_code"}
                    />
                  ),
                },
                {
                  label: "Vendor Name :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          vendor_name: e.target.value,
                        })
                      }
                      value={addVendorData?.vendor_name}
                      disabled={!(addVendorMode || editMode)}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  label: "Vendor Type :",
                  comp: (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="I"
                      name="radio-buttons-group"
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                      value={addVendorData?.vendor_type}
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          vendor_type: e.target.value,
                        })
                      }
                      disabled={!(addVendorMode || editMode)}
                    >
                      <FormControlLabel
                        value="I"
                        control={<Radio size="20px" />}
                        sx={{
                          "& .css-ahj2mt-MuiTypography-root": {
                            fontSize: 14,
                            padding: 0,
                          },
                        }}
                        label="Individual"
                      />
                      <FormControlLabel
                        value="C"
                        control={<Radio size="20px" />}
                        sx={{
                          "& .css-ahj2mt-MuiTypography-root": {
                            fontSize: 14,
                            padding: 0,
                          },
                        }}
                        label="Company"
                      />
                    </RadioGroup>
                  ),
                },
                {
                  label: "Vendor Category :",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getOMVendorCategoryData?.length &&
                        getOMVendorCategoryData
                      }
                      value={
                        getOMVendorCategoryData?.find(
                          (x) =>
                            addVendorData?.vendor_category_id ===
                            x?.vendor_category_id
                        ) || ""
                      }
                      disabled={
                        getOMVendorCategoryStatus === asyncStatus.IDLE ||
                        getOMVendorCategoryStatus === asyncStatus.LOADING ||
                        !(addVendorMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.vendor_category_id) {
                          setAddVendorData({
                            ...addVendorData,
                            vendor_category_id: e?.vendor_category_id,
                          });
                        }
                      }}
                      name={"category_name"}
                      id_key_name={"vendor_category_id"}
                    />
                  ),
                },
                {
                  label: "Web Address :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          web_address: e.target.value,
                        })
                      }
                      value={addVendorData?.web_address}
                      disabled={!(addVendorMode || editMode)}
                      name={"web_address"}
                    />
                  ),
                },
                {
                  label: "Active:",
                  comp: (
                    <Checkbox
                      defaultChecked
                      defaultValue={true}
                      disabled={!(addVendorMode || editMode)}
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          is_active: e.target.checked,
                        })
                      }
                    />
                  ),
                },
              ]}
            />
            <InfoFormComp
              customColumns={4}
              title="Email | Phone | Address"
              fieldsArray={[
                {
                  label: "Email :",
                  comp: (
                    <InputComp
                      type={"email"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          email_address: e.target.value,
                        })
                      }
                      value={addVendorData?.email_address}
                      disabled={!(addVendorMode || editMode)}
                      name={"email_address"}
                    />
                  ),
                },
                {
                  label: "Office Phone :",
                  comp: (
                    <InputComp
                      type={"number"}
                      required={true}
                      validator={(value) => {
                        if (
                          !/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/.test(
                            value.target.value
                          )
                        )
                          return "Must Be a Number.";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          office: e.target.value,
                        })
                      }
                      value={addVendorData?.office}
                      disabled={!(addVendorMode || editMode)}
                      name={"office"}
                    />
                  ),
                },
                {
                  label: "Mobile Phone :",
                  comp: (
                    <InputComp
                      type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          mobile: e.target.value,
                        })
                      }
                      value={addVendorData?.mobile}
                      disabled={!(addVendorMode || editMode)}
                      name={"mobile"}
                    />
                  ),
                },
                {
                  label: "Fax :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddVendorData({
                          ...addVendorData,
                          fax: e.target.value,
                        })
                      }
                      value={addVendorData?.fax}
                      disabled={!(addVendorMode || editMode)}
                      name={"fax"}
                    />
                  ),
                },
              ]}
            />
            <Stack padding={2}>
              <TabComp
                tabs={[
                  // {
                  //   name: "Billing",
                  //   content: (
                  //     <Stack height={300} width={"100%"}>
                  //       <TableComp
                  //         columns={[
                  //           {
                  //             field: "item_code",
                  //             headerName: "Item Code",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "inseam",
                  //             headerName: "Inseam",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "size",
                  //             headerName: "Size",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "upc_code",
                  //             headerName: "UPC Code",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "nrf_color_code",
                  //             headerName: "NRF Color Code",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "nrf_size_code",
                  //             headerName: "NRF Size Code",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "is_active",
                  //             headerName: "Status",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "operations",
                  //             headerName: "Operation",
                  //             flex: true,
                  //           },
                  //         ]}
                  //         //   onRowClick={(e) => {
                  //         //     setSelectedStyle(e?.row);
                  //         //   }}
                  //         rows={[]}
                  //         //   loading={
                  //         //     getProductStyleStatus === asyncStatus.IDLE ||
                  //         //     getProductStyleStatus === asyncStatus.LOADING
                  //         //   }
                  //       />
                  //     </Stack>
                  //   ),
                  //   header: (
                  //     <IconButton onClick={handleOpenModal} paddingTop={2}>
                  //       <IoIosAddCircleOutline size={30} />
                  //     </IconButton>
                  //   ),
                  // },
                  // {
                  //   name: "Shipping",
                  //   content: (
                  //     <Stack height={300} width={"100%"}>
                  //       <TableComp
                  //         columns={[
                  //           {
                  //             field: "companyId",
                  //             headerName: "Transaction No",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "companyName",
                  //             headerName: "Transaction Type",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Transaction Date",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Customer",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Term",
                  //             flex: true,
                  //           },
                  //         ]}
                  //         //   onRowClick={(e) => {
                  //         //     setSelectedStyle(e?.row);
                  //         //   }}
                  //         rows={[]}
                  //         //   loading={
                  //         //     getProductStyleStatus === asyncStatus.IDLE ||
                  //         //     getProductStyleStatus === asyncStatus.LOADING
                  //         //   }
                  //       />
                  //     </Stack>
                  //   ),
                  //   header: (
                  //     <IconButton onClick={handleOpenModal} paddingTop={2}>
                  //       <IoIosAddCircleOutline size={30} />
                  //     </IconButton>
                  //   ),
                  // },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={300} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack
            padding={2}
            // id="vendorSearchForm"
            // component={"form"}
            // onSubmit={(event) => {
            //   event.preventDefault();
            //   console.log(searchData);
            // }}
          >
            <FiltersAccordionFom
              allFields={[
                {
                  title: "Vendor Id",
                  comp: (
                    <InputComp
                      type={"number"}
                      // required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          vendor_id: e.target.value,
                        })
                      }
                      value={searchData?.vendor_id}
                      // disabled={true}
                      name={"vendor_id"}
                    />
                  ),
                },
                {
                  title: "Mobile Phone",
                  comp: (
                    <InputComp
                      type={"number"}
                      // required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          mobile: e.target.value,
                        })
                      }
                      value={searchData?.mobile}
                      // disabled={true}
                      name={"mobile"}
                    />
                  ),
                },
                {
                  title: "Fax",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          fax: e.target.value,
                        })
                      }
                      value={searchData?.fax}
                      // disabled={true}
                      name={"fax"}
                    />
                  ),
                },
                {
                  title: "Vendor Code",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          vendor_code: e.target.value,
                        })
                      }
                      value={searchData?.vendor_code}
                      // disabled={true}
                      name={"vendor_code"}
                    />
                  ),
                },
                {
                  title: "Vendor Name",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          vendor_name: e.target.value,
                        })
                      }
                      value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Vendor Category :",
                  comp: (
                    <VirtualizedSelect
                      // required={true}
                      options={
                        getOMVendorCategoryData?.length &&
                        getOMVendorCategoryData
                      }
                      value={
                        getOMVendorCategoryData?.find(
                          (x) =>
                            searchData?.vendor_category_id ===
                            x?.vendor_category_id
                        ) || ""
                      }
                      // disabled={
                      //   getOMVendorCategoryStatus === asyncStatus.IDLE ||
                      //   getOMVendorCategoryStatus === asyncStatus.LOADING ||
                      //   !(addVendorMode || editMode)
                      // }
                      onChange={(e) => {
                        if (e?.vendor_category_id) {
                          setSearchData({
                            ...searchData,
                            vendor_category_id: e?.vendor_category_id,
                          });
                        }
                      }}
                      name={"category_name"}
                      id_key_name={"vendor_category_id"}
                    />
                  ),
                },
                {
                  title: "web Address",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          web_address: e.target.value,
                        })
                      }
                      value={searchData?.web_address}
                      // disabled={true}
                      name={"web_address"}
                    />
                  ),
                },
                {
                  title: "Email",
                  comp: (
                    <InputComp
                      type={"email"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          email: e.target.value,
                        })
                      }
                      value={searchData?.email}
                      // disabled={true}
                      name={"email"}
                    />
                  ),
                },
                {
                  title: "Office Phone",
                  comp: (
                    <InputComp
                      type={"number"}
                      // required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          office: e.target.value,
                        })
                      }
                      value={searchData?.office}
                      // disabled={true}
                      name={"office"}
                    />
                  ),
                },
                {
                  title: "From Date",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      type={"date"}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          from_date: moment(new Date(e.target.value)).format(
                            "MM-DD-YY"
                          ),
                        })
                      }
                      value={moment(new Date(searchData?.from_date)).format(
                        "YYYY-MM-DD"
                      )}
                      // disabled={true}
                      name={"from_date"}
                    />
                  ),
                },
                {
                  title: "To Date",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      type={"date"}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          to_date: moment(new Date(e.target.value)).format(
                            "MM-DD-YY"
                          ),
                        })
                      }
                      value={moment(new Date(searchData?.to_date)).format(
                        "YYYY-MM-DD"
                      )}
                      // disabled={true}
                      name={"to_date"}
                    />
                  ),
                },
              ]}
              headerRightComp={
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    history("/vendor");
                  }}
                >
                  <AddIcon />
                </IconButton>
              }
              bottomChild={
                <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                  <Btn
                    // type="submit"
                    onClick={() => {
                      if (!(!searchData?.from_date || !searchData?.to_date)) {
                        console.log(searchData);
                        dispatch(searchOMVendorAsync(searchData));
                      } else {
                        error_toast_message(
                          "Please select from data and to date first! "
                        );
                      }
                    }}
                    style={{ width: "auto", textTransform: "none" }}
                    loading={searchOMVendorStatus === asyncStatus.LOADING}
                  >
                    Search
                  </Btn>
                </Stack>
              }
            />

            <Stack height={500} mt={4} width={"100%"}>
              <TableComp
                onRowClick={(e) => {
                  console.log("aaaaaaaaaaaaaaa", e.row);
                  history(`/vendor/${e?.row?.vendor_id}`);
                  setCurrentVendorId(e?.row?.vendor_id);
                  setSearchMode(false);
                }}
                exportToExcel={true}
                rows={formattedData}
                columns={[
                  { field: "vendor_id", headerName: "Vendor Id", flex: true },
                  {
                    field: "vendor_name",
                    headerName: "Vendor Name",
                    flex: true,
                  },
                  {
                    field: "vendor_code",
                    headerName: "Vendor Code",
                    flex: true,
                  },
                  {
                    field: "web_address",
                    headerName: "Web Address",
                    flex: true,
                  },
                  { field: "office", headerName: "Office", flex: true },
                  { field: "mobile", headerName: "Mobile", flex: true },
                  { field: "fax", headerName: "Fax", flex: true },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </ScreenCard>
    </Stack>
  );
};

export default Vendor;
