import { createSlice } from "@reduxjs/toolkit";
import {
  addProductChargeBackTypeAsync,
  addProductCutAsync,
  addProductFabricAsync,
  addProductFabricClassAsync,
  addProductFabricClassTwoAsync,
  addProductFabricCompositionAsync,
  addProductFabricTypeAsync,
  addProductFabricUsableAsync,
  addProductFitCategoryAsync,
  addProductGenderAsync,
  addProductGenderCategoryAsync,
  addProductInseamLabelAsync,
  addProductItemTypeAsync,
  addProductProductClassAsync,
  addProductProductStatusAsync,
  addProductProductTypeAsync,
  addProductRiseAsync,
  addProductRiseLabelAsync,
  addProductSeasonAsync,
  addProductSeasonYearAsync,
  addProductStyleAsync,
  addProductWashAsync,
  addProductWashTypeAsync,
  addProductinseamAsync,
  addProductsizeAsync,
  deleteItemByIdAsync,
  deleteProductChargeBackTypeAsync,
  deleteProductCutAsync,
  deleteProductFabricAsync,
  deleteProductFabricClassAsync,
  deleteProductFabricClassTwoAsync,
  deleteProductFabricCompositionAsync,
  deleteProductFabricTypeAsync,
  deleteProductFabricUsableAsync,
  deleteProductFitCategoryAsync,
  deleteProductGenderAsync,
  deleteProductGenderCategoryAsync,
  deleteProductInseamLabelAsync,
  deleteProductItemTypeAsync,
  deleteProductProductClassAsync,
  deleteProductProductStatusAsync,
  deleteProductProductTypeAsync,
  deleteProductRiseAsync,
  deleteProductRiseLabelAsync,
  deleteProductSeasonAsync,
  deleteProductSeasonYearAsync,
  deleteProductStyleAsync,
  deleteProductWashAsync,
  deleteProductWashTypeAsync,
  deleteProductinseamAsync,
  deleteProductsizeAsync,
  editProductChargeBackTypeAsync,
  editProductCutAsync,
  editProductFabricAsync,
  editProductFabricClassAsync,
  editProductFabricClassTwoAsync,
  editProductFabricCompositionAsync,
  editProductFabricTypeAsync,
  editProductFabricUsableAsync,
  editProductFitCategoryAsync,
  editProductGenderAsync,
  editProductGenderCategoryAsync,
  editProductInseamLabelAsync,
  editProductItemTypeAsync,
  editProductProductClassAsync,
  editProductProductStatusAsync,
  editProductProductTypeAsync,
  editProductRiseAsync,
  editProductRiseLabelAsync,
  editProductSeasonAsync,
  editProductSeasonYearAsync,
  editProductStyleAsync,
  editProductWashAsync,
  editProductWashTypeAsync,
  editProductinseamAsync,
  editProductsizeAsync,
  getCombinationsByItemIdAsync,
  getItemAsync,
  getItemByIdAsync,
  getProductChargeBackTypeAsync,
  getProductCompanyAsync,
  getProductCutAsync,
  getProductFabricAsync,
  getProductFabricClassAsync,
  getProductFabricClassTwoAsync,
  getProductFabricCompositionAsync,
  getProductFabricTypeAsync,
  getProductFabricUsableAsync,
  getProductFitCategoryAsync,
  getProductGenderAsync,
  getProductGenderCategoryAsync,
  getProductGenderCategoryByIdAsync,
  getProductInseamLabelAsync,
  getProductItemTypeAsync,
  getProductProductClassAsync,
  getProductProductStatusAsync,
  getProductProductTypeAsync,
  getProductRiseAsync,
  getProductRiseLabelAsync,
  getProductSeasonAsync,
  getProductSeasonYearAsync,
  getProductSizeChartByGenderCategoryIdAsync,
  getProductStyleAsync,
  getProductWashAsync,
  getProductWashTypeAsync,
  getProductinseamAsync,
  getProductsizeAsync,
  postItemAsync,
  putItemByIdAsync,
  getLocationAsync,
} from "../services/productInfoServices";
import { asyncStatus } from "../../utils/asyncStatus";
import { success_toast_message } from "../../utils/toast_notification";
const productsInfoSlice = createSlice({
  name: "products",
  initialState: {
    getProductStyleStatus: asyncStatus.IDLE,
    getProductStyleError: null,
    getProductStyleData: [],

    addProductStyleStatus: asyncStatus.IDLE,
    addProductStyleError: null,
    addProductStyleData: null,

    editProductStyleStatus: asyncStatus.IDLE,
    editProductStyleError: null,
    editProductStyleData: null,

    deleteProductStyleStatus: asyncStatus.IDLE,
    deleteProductStyleData: null,
    deleteProductStyleError: null,

    // wash work
    getProductWashStatus: asyncStatus.IDLE,
    getProductWashError: null,
    getProductWashData: [],

    addProductWashStatus: asyncStatus.IDLE,
    addProductWashData: null,
    addProductWashError: null,

    editProductWashStatus: asyncStatus.IDLE,
    editProductWashError: null,
    editProductWashData: null,

    deleteProductWashStatus: asyncStatus.IDLE,
    deleteProductWashError: null,
    deleteProductWashData: null,
    // wash work

    // cut work
    getProductCutStatus: asyncStatus.IDLE,
    getProductCutError: null,
    getProductCutData: [],

    addProductCutStatus: asyncStatus.IDLE,
    addProductCutData: null,
    addProductCutError: null,

    editProductCutStatus: asyncStatus.IDLE,
    editProductCutError: null,
    editProductCutData: null,

    deleteProductCutStatus: asyncStatus.IDLE,
    deleteProductCutError: null,
    deleteProductCutData: null,
    // cut work

    // FabricClass work
    getProductFabricClassStatus: asyncStatus.IDLE,
    getProductFabricClassError: null,
    getProductFabricClassData: [],

    addProductFabricClassStatus: asyncStatus.IDLE,
    addProductFabricClassData: null,
    addProductFabricClassError: null,

    editProductFabricClassStatus: asyncStatus.IDLE,
    editProductFabricClassError: null,
    editProductFabricClassData: null,

    deleteProductFabricClassStatus: asyncStatus.IDLE,
    deleteProductFabricClassError: null,
    deleteProductFabricClassData: null,
    // FabricClass work
    // FabricType work
    getProductFabricTypeStatus: asyncStatus.IDLE,
    getProductFabricTypeError: null,
    getProductFabricTypeData: [],

    addProductFabricTypeStatus: asyncStatus.IDLE,
    addProductFabricTypeData: null,
    addProductFabricTypeError: null,

    editProductFabricTypeStatus: asyncStatus.IDLE,
    editProductFabricTypeError: null,
    editProductFabricTypeData: null,

    deleteProductFabricTypeStatus: asyncStatus.IDLE,
    deleteProductFabricTypeError: null,
    deleteProductFabricTypeData: null,
    // FabricType work
    // FabricUsable work
    getProductFabricUsableStatus: asyncStatus.IDLE,
    getProductFabricUsableError: null,
    getProductFabricUsableData: [],

    addProductFabricUsableStatus: asyncStatus.IDLE,
    addProductFabricUsableData: null,
    addProductFabricUsableError: null,

    editProductFabricUsableStatus: asyncStatus.IDLE,
    editProductFabricUsableError: null,
    editProductFabricUsableData: null,

    deleteProductFabricUsableStatus: asyncStatus.IDLE,
    deleteProductFabricUsableError: null,
    deleteProductFabricUsableData: null,
    // FabricUsable work
    // FabricClassTwo work
    getProductFabricClassTwoStatus: asyncStatus.IDLE,
    getProductFabricClassTwoError: null,
    getProductFabricClassTwoData: [],

    addProductFabricClassTwoStatus: asyncStatus.IDLE,
    addProductFabricClassTwoData: null,
    addProductFabricClassTwoError: null,

    editProductFabricClassTwoStatus: asyncStatus.IDLE,
    editProductFabricClassTwoError: null,
    editProductFabricClassTwoData: null,

    deleteProductFabricClassTwoStatus: asyncStatus.IDLE,
    deleteProductFabricClassTwoError: null,
    deleteProductFabricClassTwoData: null,
    // FabricClassTwo work
    // FabricComposition work
    getProductFabricCompositionStatus: asyncStatus.IDLE,
    getProductFabricCompositionError: null,
    getProductFabricCompositionData: [],

    addProductFabricCompositionStatus: asyncStatus.IDLE,
    addProductFabricCompositionData: null,
    addProductFabricCompositionError: null,

    editProductFabricCompositionStatus: asyncStatus.IDLE,
    editProductFabricCompositionError: null,
    editProductFabricCompositionData: null,

    deleteProductFabricCompositionStatus: asyncStatus.IDLE,
    deleteProductFabricCompositionError: null,
    deleteProductFabricCompositionData: null,
    // FabricComposition work
    // Fabric work
    getProductFabricStatus: asyncStatus.IDLE,
    getProductFabricError: null,
    getProductFabricData: [],

    addProductFabricStatus: asyncStatus.IDLE,
    addProductFabricData: null,
    addProductFabricError: null,

    editProductFabricStatus: asyncStatus.IDLE,
    editProductFabricError: null,
    editProductFabricData: null,

    deleteProductFabricStatus: asyncStatus.IDLE,
    deleteProductFabricError: null,
    deleteProductFabricData: null,
    // Fabric work
    // Gender work
    getProductGenderStatus: asyncStatus.IDLE,
    getProductGenderError: null,
    getProductGenderData: [],

    addProductGenderStatus: asyncStatus.IDLE,
    addProductGenderData: null,
    addProductGenderError: null,

    editProductGenderStatus: asyncStatus.IDLE,
    editProductGenderError: null,
    editProductGenderData: null,

    deleteProductGenderStatus: asyncStatus.IDLE,
    deleteProductGenderError: null,
    deleteProductGenderData: null,
    // Gender work
    // Gender work
    getProductWashTypeStatus: asyncStatus.IDLE,
    getProductWashTypeError: null,
    getProductWashTypeData: [],

    addProductWashTypeStatus: asyncStatus.IDLE,
    addProductWashTypeData: null,
    addProductWashTypeError: null,

    editProductWashTypeStatus: asyncStatus.IDLE,
    editProductWashTypeError: null,
    editProductWashTypeData: null,

    deleteProductWashTypeStatus: asyncStatus.IDLE,
    deleteProductWashTypeError: null,
    deleteProductWashTypeData: null,
    // Gender work
    // Season work
    getProductSeasonStatus: asyncStatus.IDLE,
    getProductSeasonError: null,
    getProductSeasonData: [],

    addProductSeasonStatus: asyncStatus.IDLE,
    addProductSeasonData: null,
    addProductSeasonError: null,

    editProductSeasonStatus: asyncStatus.IDLE,
    editProductSeasonError: null,
    editProductSeasonData: null,

    deleteProductSeasonStatus: asyncStatus.IDLE,
    deleteProductSeasonError: null,
    deleteProductSeasonData: null,
    // Season work
    // Gender Category work
    getProductGenderCategoryStatus: asyncStatus.IDLE,
    getProductGenderCategoryError: null,
    getProductGenderCategoryData: [],

    addProductGenderCategoryStatus: asyncStatus.IDLE,
    addProductGenderCategoryData: null,
    addProductGenderCategoryError: null,

    editProductGenderCategoryStatus: asyncStatus.IDLE,
    editProductGenderCategoryError: null,
    editProductGenderCategoryData: null,

    deleteProductGenderCategoryStatus: asyncStatus.IDLE,
    deleteProductGenderCategoryError: null,
    deleteProductGenderCategoryData: null,
    // Gender Category work

    // inseam work
    getProductinseamStatus: asyncStatus.IDLE,
    getProductinseamError: null,
    getProductinseamData: [],

    addProductinseamStatus: asyncStatus.IDLE,
    addProductinseamData: null,
    addProductinseamError: null,

    editProductinseamStatus: asyncStatus.IDLE,
    editProductinseamError: null,
    editProductinseamData: null,

    deleteProductinseamStatus: asyncStatus.IDLE,
    deleteProductinseamError: null,
    deleteProductinseamData: null,
    // inseam work

    // Size work
    getProductsizeStatus: asyncStatus.IDLE,
    getProductsizeError: null,
    getProductsizeData: [],

    addProductsizeStatus: asyncStatus.IDLE,
    addProductsizeData: null,
    addProductsizeError: null,

    editProductsizeStatus: asyncStatus.IDLE,
    editProductsizeError: null,
    editProductsizeData: null,

    deleteProductsizeStatus: asyncStatus.IDLE,
    deleteProductsizeError: null,
    deleteProductsizeData: null,
    // Size work

    // Fit Category work
    getProductFitCategoryStatus: asyncStatus.IDLE,
    getProductFitCategoryError: null,
    getProductFitCategoryData: [],

    addProductFitCategoryStatus: asyncStatus.IDLE,
    addProductFitCategoryData: null,
    addProductFitCategoryError: null,

    editProductFitCategoryStatus: asyncStatus.IDLE,
    editProductFitCategoryError: null,
    editProductFitCategoryData: null,

    deleteProductFitCategoryStatus: asyncStatus.IDLE,
    deleteProductFitCategoryError: null,
    deleteProductFitCategoryData: null,
    // Fit Category work
    // Rise work
    getProductRiseStatus: asyncStatus.IDLE,
    getProductRiseError: null,
    getProductRiseData: [],

    addProductRiseStatus: asyncStatus.IDLE,
    addProductRiseData: null,
    addProductRiseError: null,

    editProductRiseStatus: asyncStatus.IDLE,
    editProductRiseError: null,
    editProductRiseData: null,

    deleteProductRiseStatus: asyncStatus.IDLE,
    deleteProductRiseError: null,
    deleteProductRiseData: null,
    // Rise work
    // ChargeBackType work
    getProductChargeBackTypeStatus: asyncStatus.IDLE,
    getProductChargeBackTypeError: null,
    getProductChargeBackTypeData: [],

    addProductChargeBackTypeStatus: asyncStatus.IDLE,
    addProductChargeBackTypeData: null,
    addProductChargeBackTypeError: null,

    editProductChargeBackTypeStatus: asyncStatus.IDLE,
    editProductChargeBackTypeError: null,
    editProductChargeBackTypeData: null,

    deleteProductChargeBackTypeStatus: asyncStatus.IDLE,
    deleteProductChargeBackTypeError: null,
    deleteProductChargeBackTypeData: null,
    // ChargeBackType work
    // RiseLabel work
    getProductRiseLabelStatus: asyncStatus.IDLE,
    getProductRiseLabelError: null,
    getProductRiseLabelData: [],

    addProductRiseLabelStatus: asyncStatus.IDLE,
    addProductRiseLabelData: null,
    addProductRiseLabelError: null,

    editProductRiseLabelStatus: asyncStatus.IDLE,
    editProductRiseLabelError: null,
    editProductRiseLabelData: null,

    deleteProductRiseLabelStatus: asyncStatus.IDLE,
    deleteProductRiseLabelError: null,
    deleteProductRiseLabelData: null,
    // RiseLabel work
    // ProductType work
    getProductProductTypeStatus: asyncStatus.IDLE,
    getProductProductTypeError: null,
    getProductProductTypeData: [],

    addProductProductTypeStatus: asyncStatus.IDLE,
    addProductProductTypeData: null,
    addProductProductTypeError: null,

    editProductProductTypeStatus: asyncStatus.IDLE,
    editProductProductTypeError: null,
    editProductProductTypeData: null,

    deleteProductProductTypeStatus: asyncStatus.IDLE,
    deleteProductProductTypeError: null,
    deleteProductProductTypeData: null,
    // ProductType work
    // SeasonYear work
    getProductSeasonYearStatus: asyncStatus.IDLE,
    getProductSeasonYearError: null,
    getProductSeasonYearData: [],

    addProductSeasonYearStatus: asyncStatus.IDLE,
    addProductSeasonYearData: null,
    addProductSeasonYearError: null,

    editProductSeasonYearStatus: asyncStatus.IDLE,
    editProductSeasonYearError: null,
    editProductSeasonYearData: null,

    deleteProductSeasonYearStatus: asyncStatus.IDLE,
    deleteProductSeasonYearError: null,
    deleteProductSeasonYearData: null,
    // SeasonYear work
    // ProductClass work
    getProductProductClassStatus: asyncStatus.IDLE,
    getProductProductClassError: null,
    getProductProductClassData: [],

    addProductProductClassStatus: asyncStatus.IDLE,
    addProductProductClassData: null,
    addProductProductClassError: null,

    editProductProductClassStatus: asyncStatus.IDLE,
    editProductProductClassError: null,
    editProductProductClassData: null,

    deleteProductProductClassStatus: asyncStatus.IDLE,
    deleteProductProductClassError: null,
    deleteProductProductClassData: null,
    // ProductClass work
    // ProductStatus work
    getProductProductStatusStatus: asyncStatus.IDLE,
    getProductProductStatusError: null,
    getProductProductStatusData: [],

    addProductProductStatusStatus: asyncStatus.IDLE,
    addProductProductStatusData: null,
    addProductProductStatusError: null,

    editProductProductStatusStatus: asyncStatus.IDLE,
    editProductProductStatusError: null,
    editProductProductStatusData: null,

    deleteProductProductStatusStatus: asyncStatus.IDLE,
    deleteProductProductStatusError: null,
    deleteProductProductStatusData: null,
    // ProductStatus work

    // ItemType work
    getProductItemTypeStatus: asyncStatus.IDLE,
    getProductItemTypeError: null,
    getProductItemTypeData: [],

    addProductItemTypeStatus: asyncStatus.IDLE,
    addProductItemTypeData: null,
    addProductItemTypeError: null,

    editProductItemTypeStatus: asyncStatus.IDLE,
    editProductItemTypeError: null,
    editProductItemTypeData: null,

    deleteProductItemTypeStatus: asyncStatus.IDLE,
    deleteProductItemTypeError: null,
    deleteProductItemTypeData: null,
    // ItemType work

    // Inseam Label work
    getProductInseamLabelStatus: asyncStatus.IDLE,
    getProductInseamLabelError: null,
    getProductInseamLabelData: [],

    addProductInseamLabelStatus: asyncStatus.IDLE,
    addProductInseamLabelData: null,
    addProductInseamLabelError: null,

    editProductInseamLabelStatus: asyncStatus.IDLE,
    editProductInseamLabelError: null,
    editProductInseamLabelData: null,

    deleteProductInseamLabelStatus: asyncStatus.IDLE,
    deleteProductInseamLabelError: null,
    deleteProductInseamLabelData: null,
    // Inseam Label work

    // Product Info
    //get gender category by id
    getGenderCategoryByIdStatus: asyncStatus.IDLE,
    getGenderCategoryByIdError: null,
    getGenderCategoryByIdData: null,
    //get gender category by id

    // get company
    getProductCompanyStatus: asyncStatus.IDLE,
    getProductCompanyError: null,
    getProductCompanyData: null,
    // get company

    // get sizechart by gender category id
    getProductSizeChartByGenderCategoryIdStatus: null,
    getProductSizeChartByGenderCategoryIdError: null,
    getProductSizeChartByGenderCategoryIdData: null,
    // get sizechart by gender category id
    // post item

    postItemStatus: asyncStatus.IDLE,
    postItemError: null,
    postItemData: null,
    // post item
    // get item

    getItemStatus: asyncStatus.IDLE,
    getItemError: null,
    getItemData: null,
    // get item
    // get item By Id

    getItemByIdStatus: asyncStatus.IDLE,
    getItemByIdError: null,
    getItemByIdData: null,
    // get item By Id
    // put item By Id

    putItemByIdStatus: asyncStatus.IDLE,
    putItemByIdError: null,
    putItemByIdData: null,
    // put item By Id
    // delete item By Id

    deleteItemByIdStatus: asyncStatus.IDLE,
    deleteItemByIdError: null,
    deleteItemByIdData: null,
    // delete item By Id
    // get combinations by item Id

    getCombinationsByItemIdStatus: asyncStatus.IDLE,
    getCombinationsByItemIdError: null,
    getCombinationsByItemIdData: null,
    // get combinations by item Id

    // Product Info
  },
  reducers: {
    fabricStatusEdit(state, { payload }) {
      state.addProductFabricClassStatus = payload;
      state.addProductFabricTypeStatus = payload;
      state.addProductFabricUsableStatus = payload;
      state.addProductFabricClassTwoStatus = payload;
      state.addProductFabricCompositionStatus = payload;
    },
    productInfoStatusEdit(state, { payload }) {
      state.addProductStyleStatus = payload;
      state.addProductWashTypeStatus = payload;
      state.addProductWashStatus = payload;
      state.addProductFitCategoryStatus = payload;
      state.addProductRiseLabelStatus = payload;
      state.addProductRiseStatus = payload;
      state.addProductCutStatus = payload;
      state.addProductGenderCategoryStatus = payload;
      state.addProductGenderStatus = payload;
      state.addProductProductTypeStatus = payload;
      state.addProductProductClassStatus = payload;
      state.addProductInseamLabelStatus = payload;
      state.addProductSeasonYearStatus = payload;
      state.addProductSeasonStatus = payload;
      state.addProductFabricCompositionStatus = payload;
      state.addProductProductStatusStatus = payload;
      state.addProductItemTypeStatus = payload;
    },
    setItemByIdEmpty(state, { payload }) {
      state.getItemByIdStatus = asyncStatus.IDLE;
      state.getItemByIdError = null;
      state.getItemByIdData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductStyleAsync.pending, (state, action) => {
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.getProductStyleError = null;
      state.getProductStyleData = null;
    });
    builder.addCase(getProductStyleAsync.fulfilled, (state, { payload }) => {
      state.getProductStyleStatus = asyncStatus.SUCCEEDED;
      state.getProductStyleData = payload.data;
      state.getProductStyleError = null;
    });
    builder.addCase(getProductStyleAsync.rejected, (state, actions) => {
      state.getProductStyleData = null;
      state.getProductStyleStatus = asyncStatus.ERROR;
      state.getProductStyleError = actions?.error?.message;
    });

    builder.addCase(addProductStyleAsync.pending, (state, action) => {
      state.addProductStyleStatus = asyncStatus.LOADING;
      state.addProductStyleError = null;
      state.addProductStyleData = null;
    });
    builder.addCase(addProductStyleAsync.fulfilled, (state, { payload }) => {
      state.addProductStyleStatus = asyncStatus.SUCCEEDED;
      state.addProductStyleData = payload.data;
      state.getProductStyleData = [...state.getProductStyleData, payload.data];
      success_toast_message(payload.message);
      state.addProductStyleError = null;
    });
    builder.addCase(addProductStyleAsync.rejected, (state, actions) => {
      state.addProductStyleData = null;
      state.addProductStyleStatus = asyncStatus.ERROR;
      state.addProductStyleError = actions?.error?.message;
    });

    builder.addCase(editProductStyleAsync.pending, (state, action) => {
      state.editProductStyleStatus = asyncStatus.LOADING;
      state.editProductStyleError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductStyleData = null;
    });
    builder.addCase(editProductStyleAsync.fulfilled, (state, { payload }) => {
      state.editProductStyleStatus = asyncStatus.SUCCEEDED;
      state.getProductStyleStatus = asyncStatus.SUCCEEDED;
      state.editProductStyleData = payload.data;
      const { getProductStyleData } = state;
      let updatedArray = [];
      getProductStyleData?.map((e) => {
        var item = e;
        if (item?.style_id === payload?.data?.style_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getProductStyleData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductStyleError = null;
    });
    builder.addCase(editProductStyleAsync.rejected, (state, actions) => {
      state.editProductStyleData = null;
      state.editProductStyleStatus = asyncStatus.ERROR;
      state.editProductStyleError = actions?.error?.message;
    });

    builder.addCase(deleteProductStyleAsync.pending, (state, action) => {
      state.deleteProductStyleStatus = asyncStatus.LOADING;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.deleteProductStyleError = null;
      state.deleteProductStyleData = null;
    });
    builder.addCase(deleteProductStyleAsync.fulfilled, (state, { payload }) => {
      state.deleteProductStyleStatus = asyncStatus.SUCCEEDED;
      state.getProductStyleStatus = asyncStatus.SUCCEEDED;
      state.deleteProductStyleData = payload;
      const { getProductStyleData } = state;
      let updatedArray = getProductStyleData?.filter(
        (x) => x.style_id !== payload.data.style_id
      );
      state.getProductStyleData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteProductStyleError = null;
    });
    builder.addCase(deleteProductStyleAsync.rejected, (state, actions) => {
      state.deleteProductStyleData = null;
      state.deleteProductStyleStatus = asyncStatus.ERROR;
      state.deleteProductStyleError = actions?.error?.message;
    });

    // WASH WORK

    builder.addCase(getProductWashAsync.pending, (state, action) => {
      state.getProductWashStatus = asyncStatus.LOADING;
      state.getProductWashError = null;
      state.getProductWashData = null;
    });
    builder.addCase(getProductWashAsync.fulfilled, (state, { payload }) => {
      state.getProductWashStatus = asyncStatus.SUCCEEDED;
      state.getProductWashData = payload.data;
      state.getProductWashError = null;
    });
    builder.addCase(getProductWashAsync.rejected, (state, actions) => {
      state.getProductWashData = null;
      state.getProductWashStatus = asyncStatus.ERROR;
      state.getProductWashError = actions?.error?.message;
    });

    builder.addCase(addProductWashAsync.pending, (state, action) => {
      state.addProductWashStatus = asyncStatus.LOADING;
      state.addProductWashError = null;
      state.addProductWashData = null;
    });
    builder.addCase(addProductWashAsync.fulfilled, (state, { payload }) => {
      state.addProductWashStatus = asyncStatus.SUCCEEDED;
      state.addProductWashData = payload.data;
      // let { getProductWashData } = state;
      state.getProductWashData = [...state.getProductWashData, payload.data];
      success_toast_message(payload.message);
      state.addProductWashError = null;
    });
    builder.addCase(addProductWashAsync.rejected, (state, actions) => {
      state.addProductWashData = null;
      state.addProductWashStatus = asyncStatus.ERROR;
      state.addProductWashError = actions?.error?.message;
    });

    builder.addCase(editProductWashAsync.pending, (state, action) => {
      state.editProductWashStatus = asyncStatus.LOADING;
      state.editProductWashError = null;
      state.getProductWashStatus = asyncStatus.LOADING;
      state.editProductWashData = null;
    });
    builder.addCase(editProductWashAsync.fulfilled, (state, { payload }) => {
      state.editProductWashStatus = asyncStatus.SUCCEEDED;
      state.getProductWashStatus = asyncStatus.SUCCEEDED;
      state.editProductWashData = payload.data;
      const { getProductWashData } = state;
      let updatedArray = [];
      getProductWashData?.map((e) => {
        var item = e;
        if (item?.wash_id === payload?.data?.wash_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getProductWashData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductWashError = null;
    });
    builder.addCase(editProductWashAsync.rejected, (state, actions) => {
      state.editProductWashData = null;
      state.getProductWashStatus = asyncStatus.SUCCEEDED;
      state.editProductWashStatus = asyncStatus.ERROR;
      state.editProductWashError = actions?.error?.message;
    });

    builder.addCase(deleteProductWashAsync.pending, (state, action) => {
      state.deleteProductWashStatus = asyncStatus.LOADING;
      state.getProductWashStatus = asyncStatus.LOADING;
      state.deleteProductWashError = null;
      state.deleteProductWashData = null;
    });
    builder.addCase(deleteProductWashAsync.fulfilled, (state, { payload }) => {
      state.deleteProductWashStatus = asyncStatus.SUCCEEDED;
      state.getProductWashStatus = asyncStatus.SUCCEEDED;
      state.deleteProductWashData = payload.data;
      const { getProductWashData } = state;
      console.log("aaaaa", getProductWashData);
      let updatedArray = getProductWashData?.filter(
        (x) => x.wash_id !== payload.data.wash_id
      );
      state.getProductWashData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteProductWashError = null;
    });
    builder.addCase(deleteProductWashAsync.rejected, (state, actions) => {
      state.deleteProductWashData = null;
      state.deleteProductWashStatus = asyncStatus.ERROR;
      state.getProductWashStatus = asyncStatus.SUCCEEDED;
      state.deleteProductWashError = actions?.error?.message;
    });

    // WASH WORK

    // CUT WORK

    builder.addCase(getProductCutAsync.pending, (state, action) => {
      state.getProductCutStatus = asyncStatus.LOADING;
      state.getProductCutError = null;
      state.getProductCutData = null;
    });
    builder.addCase(getProductCutAsync.fulfilled, (state, { payload }) => {
      state.getProductCutStatus = asyncStatus.SUCCEEDED;
      state.getProductCutData = payload.data;
      state.getProductCutError = null;
    });
    builder.addCase(getProductCutAsync.rejected, (state, actions) => {
      state.getProductCutData = null;
      state.getProductCutStatus = asyncStatus.ERROR;
      state.getProductCutError = actions?.error?.message;
    });

    builder.addCase(addProductCutAsync.pending, (state, action) => {
      state.addProductCutStatus = asyncStatus.LOADING;
      state.addProductCutError = null;
      state.addProductCutData = null;
    });
    builder.addCase(addProductCutAsync.fulfilled, (state, { payload }) => {
      state.addProductCutStatus = asyncStatus.SUCCEEDED;
      state.addProductCutData = payload.data;
      // let { getProductCutData } = state;
      state.getProductCutData = [...state.getProductCutData, payload.data];
      success_toast_message(payload.message);
      state.addProductCutError = null;
    });
    builder.addCase(addProductCutAsync.rejected, (state, actions) => {
      state.addProductCutData = null;
      state.addProductCutStatus = asyncStatus.ERROR;
      state.addProductCutError = actions?.error?.message;
    });

    builder.addCase(editProductCutAsync.pending, (state, action) => {
      state.editProductCutStatus = asyncStatus.LOADING;
      state.editProductCutError = null;
      state.getProductCutStatus = asyncStatus.LOADING;
      state.editProductCutData = null;
    });
    builder.addCase(editProductCutAsync.fulfilled, (state, { payload }) => {
      state.editProductCutStatus = asyncStatus.SUCCEEDED;
      state.getProductCutStatus = asyncStatus.SUCCEEDED;
      state.editProductCutData = payload.data;
      const { getProductCutData } = state;
      let updatedArray = [];
      getProductCutData?.map((e) => {
        var item = e;
        if (item?.cut_id === payload?.data?.cut_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      state.getProductCutData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductCutError = null;
    });
    builder.addCase(editProductCutAsync.rejected, (state, actions) => {
      state.editProductCutData = null;
      state.editProductCutStatus = asyncStatus.ERROR;
      state.editProductCutError = actions?.error?.message;
    });

    builder.addCase(deleteProductCutAsync.pending, (state, action) => {
      state.deleteProductCutStatus = asyncStatus.LOADING;
      state.getProductCutStatus = asyncStatus.LOADING;
      state.deleteProductCutError = null;
      state.deleteProductCutData = null;
    });
    builder.addCase(deleteProductCutAsync.fulfilled, (state, { payload }) => {
      state.deleteProductCutStatus = asyncStatus.SUCCEEDED;
      state.getProductCutStatus = asyncStatus.SUCCEEDED;
      state.deleteProductCutData = payload.data;
      const { getProductCutData } = state;
      console.log("aaaaa", getProductCutData);
      let updatedArray = getProductCutData?.filter(
        (x) => x.cut_id !== payload.data.cut_id
      );
      state.getProductCutData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteProductCutError = null;
    });
    builder.addCase(deleteProductCutAsync.rejected, (state, actions) => {
      state.deleteProductCutData = null;
      state.deleteProductCutStatus = asyncStatus.ERROR;
      state.deleteProductCutError = actions?.error?.message;
    });

    // CUT WORK

    // FABRIC CLASS WORK

    builder.addCase(getProductFabricClassAsync.pending, (state, action) => {
      state.getProductFabricClassStatus = asyncStatus.LOADING;
      state.getProductFabricClassError = null;
      state.getProductFabricClassData = null;
    });
    builder.addCase(
      getProductFabricClassAsync.fulfilled,
      (state, { payload }) => {
        state.getProductFabricClassStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricClassData = payload.data;
        state.getProductFabricClassError = null;
      }
    );
    builder.addCase(getProductFabricClassAsync.rejected, (state, actions) => {
      state.getProductFabricClassData = null;
      state.getProductFabricClassStatus = asyncStatus.ERROR;
      state.getProductFabricClassError = actions?.error?.message;
    });

    builder.addCase(addProductFabricClassAsync.pending, (state, action) => {
      state.addProductFabricClassStatus = asyncStatus.LOADING;
      state.addProductFabricClassError = null;
      state.addProductFabricClassData = null;
    });
    builder.addCase(
      addProductFabricClassAsync.fulfilled,
      (state, { payload }) => {
        state.addProductFabricClassStatus = asyncStatus.SUCCEEDED;
        state.addProductFabricClassData = payload.data;
        // let { getProductFabricClassData } = state;
        state.getProductFabricClassData = [
          ...state.getProductFabricClassData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductFabricClassError = null;
      }
    );
    builder.addCase(addProductFabricClassAsync.rejected, (state, actions) => {
      state.addProductFabricClassData = null;
      state.addProductFabricClassStatus = asyncStatus.ERROR;
      state.addProductFabricClassError = actions?.error?.message;
    });

    builder.addCase(editProductFabricClassAsync.pending, (state, action) => {
      state.editProductFabricClassStatus = asyncStatus.LOADING;
      state.editProductFabricClassError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductFabricClassData = null;
    });
    builder.addCase(
      editProductFabricClassAsync.fulfilled,
      (state, { payload }) => {
        state.editProductFabricClassStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricClassStatus = asyncStatus.SUCCEEDED;
        state.editProductFabricClassData = payload.data;
        const { getProductFabricClassData } = state;
        let updatedArray = [];
        getProductFabricClassData?.map((e) => {
          var item = e;
          if (item?.fabric_class_id === payload?.data?.fabric_class_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductFabricClassData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductFabricClassError = null;
      }
    );
    builder.addCase(editProductFabricClassAsync.rejected, (state, actions) => {
      state.editProductFabricClassData = null;
      state.editProductFabricClassStatus = asyncStatus.ERROR;
      state.editProductFabricClassError = actions?.error?.message;
    });

    builder.addCase(deleteProductFabricClassAsync.pending, (state, action) => {
      state.deleteProductFabricClassStatus = asyncStatus.LOADING;
      state.getProductFabricClassStatus = asyncStatus.LOADING;
      state.deleteProductFabricClassError = null;
      state.deleteProductFabricClassData = null;
    });
    builder.addCase(
      deleteProductFabricClassAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductFabricClassStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricClassStatus = asyncStatus.SUCCEEDED;
        state.deleteProductFabricClassData = payload.data;
        const { getProductFabricClassData } = state;
        console.log("aaaaa", getProductFabricClassData);
        let updatedArray = getProductFabricClassData?.filter(
          (x) => x.fabric_class_id !== payload.data.fabric_class_id
        );
        state.getProductFabricClassData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductFabricClassError = null;
      }
    );
    builder.addCase(
      deleteProductFabricClassAsync.rejected,
      (state, actions) => {
        state.deleteProductFabricClassData = null;
        state.deleteProductFabricClassStatus = asyncStatus.ERROR;
        state.deleteProductFabricClassError = actions?.error?.message;
      }
    );

    // FABRIC CLASS WORK

    // FABRIC TYPE WORK

    builder.addCase(getProductFabricTypeAsync.pending, (state, action) => {
      state.getProductFabricTypeStatus = asyncStatus.LOADING;
      state.getProductFabricTypeError = null;
      state.getProductFabricTypeData = null;
    });
    builder.addCase(
      getProductFabricTypeAsync.fulfilled,
      (state, { payload }) => {
        state.getProductFabricTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricTypeData = payload.data;
        state.getProductFabricTypeError = null;
      }
    );
    builder.addCase(getProductFabricTypeAsync.rejected, (state, actions) => {
      state.getProductFabricTypeData = null;
      state.getProductFabricTypeStatus = asyncStatus.ERROR;
      state.getProductFabricTypeError = actions?.error?.message;
    });

    builder.addCase(addProductFabricTypeAsync.pending, (state, action) => {
      state.addProductFabricTypeStatus = asyncStatus.LOADING;
      state.addProductFabricTypeError = null;
      state.addProductFabricTypeData = null;
    });
    builder.addCase(
      addProductFabricTypeAsync.fulfilled,
      (state, { payload }) => {
        state.addProductFabricTypeStatus = asyncStatus.SUCCEEDED;
        state.addProductFabricTypeData = payload.data;
        // let { getProductFabricTypeData } = state;
        state.getProductFabricTypeData = [
          ...state.getProductFabricTypeData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductFabricTypeError = null;
      }
    );
    builder.addCase(addProductFabricTypeAsync.rejected, (state, actions) => {
      state.addProductFabricTypeData = null;
      state.addProductFabricTypeStatus = asyncStatus.ERROR;
      state.addProductFabricTypeError = actions?.error?.message;
    });

    builder.addCase(editProductFabricTypeAsync.pending, (state, action) => {
      state.editProductFabricTypeStatus = asyncStatus.LOADING;
      state.editProductFabricTypeError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductFabricTypeData = null;
    });
    builder.addCase(
      editProductFabricTypeAsync.fulfilled,
      (state, { payload }) => {
        state.editProductFabricTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricTypeStatus = asyncStatus.SUCCEEDED;
        state.editProductFabricTypeData = payload.data;
        const { getProductFabricTypeData } = state;
        let updatedArray = [];
        getProductFabricTypeData?.map((e) => {
          var item = e;
          if (item?.fabric_type_id === payload?.data?.fabric_type_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductFabricTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductFabricTypeError = null;
      }
    );
    builder.addCase(editProductFabricTypeAsync.rejected, (state, actions) => {
      state.editProductFabricTypeData = null;
      state.editProductFabricTypeStatus = asyncStatus.ERROR;
      state.editProductFabricTypeError = actions?.error?.message;
    });

    builder.addCase(deleteProductFabricTypeAsync.pending, (state, action) => {
      state.deleteProductFabricTypeStatus = asyncStatus.LOADING;
      state.getProductFabricTypeStatus = asyncStatus.LOADING;
      state.deleteProductFabricTypeError = null;
      state.deleteProductFabricTypeData = null;
    });
    builder.addCase(
      deleteProductFabricTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductFabricTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteProductFabricTypeData = payload.data;
        const { getProductFabricTypeData } = state;
        console.log("aaaaa", getProductFabricTypeData);
        let updatedArray = getProductFabricTypeData?.filter(
          (x) => x.fabric_type_id !== payload.data.fabric_type_id
        );
        state.getProductFabricTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductFabricTypeError = null;
      }
    );
    builder.addCase(deleteProductFabricTypeAsync.rejected, (state, actions) => {
      state.deleteProductFabricTypeData = null;
      state.deleteProductFabricTypeStatus = asyncStatus.ERROR;
      state.deleteProductFabricTypeError = actions?.error?.message;
    });

    // FABRIC TYPE WORK

    // FABRIC USABLE WORK

    builder.addCase(getProductFabricUsableAsync.pending, (state, action) => {
      state.getProductFabricUsableStatus = asyncStatus.LOADING;
      state.getProductFabricUsableError = null;
      state.getProductFabricUsableData = null;
    });
    builder.addCase(
      getProductFabricUsableAsync.fulfilled,
      (state, { payload }) => {
        state.getProductFabricUsableStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricUsableData = payload.data;
        state.getProductFabricUsableError = null;
      }
    );
    builder.addCase(getProductFabricUsableAsync.rejected, (state, actions) => {
      state.getProductFabricUsableData = null;
      state.getProductFabricUsableStatus = asyncStatus.ERROR;
      state.getProductFabricUsableError = actions?.error?.message;
    });

    builder.addCase(addProductFabricUsableAsync.pending, (state, action) => {
      state.addProductFabricUsableStatus = asyncStatus.LOADING;
      state.addProductFabricUsableError = null;
      state.addProductFabricUsableData = null;
    });
    builder.addCase(
      addProductFabricUsableAsync.fulfilled,
      (state, { payload }) => {
        state.addProductFabricUsableStatus = asyncStatus.SUCCEEDED;
        state.addProductFabricUsableData = payload.data;
        // let { getProductFabricUsableData } = state;
        state.getProductFabricUsableData = [
          ...state.getProductFabricUsableData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductFabricUsableError = null;
      }
    );
    builder.addCase(addProductFabricUsableAsync.rejected, (state, actions) => {
      state.addProductFabricUsableData = null;
      state.addProductFabricUsableStatus = asyncStatus.ERROR;
      state.addProductFabricUsableError = actions?.error?.message;
    });

    builder.addCase(editProductFabricUsableAsync.pending, (state, action) => {
      state.editProductFabricUsableStatus = asyncStatus.LOADING;
      state.editProductFabricUsableError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductFabricUsableData = null;
    });
    builder.addCase(
      editProductFabricUsableAsync.fulfilled,
      (state, { payload }) => {
        state.editProductFabricUsableStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricUsableStatus = asyncStatus.SUCCEEDED;
        state.editProductFabricUsableData = payload.data;
        const { getProductFabricUsableData } = state;
        let updatedArray = [];
        getProductFabricUsableData?.map((e) => {
          var item = e;
          if (item?.fabric_useable_id === payload?.data?.fabric_useable_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductFabricUsableData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductFabricUsableError = null;
      }
    );
    builder.addCase(editProductFabricUsableAsync.rejected, (state, actions) => {
      state.editProductFabricUsableData = null;
      state.editProductFabricUsableStatus = asyncStatus.ERROR;
      state.editProductFabricUsableError = actions?.error?.message;
    });

    builder.addCase(deleteProductFabricUsableAsync.pending, (state, action) => {
      state.deleteProductFabricUsableStatus = asyncStatus.LOADING;
      state.getProductFabricUsableStatus = asyncStatus.LOADING;
      state.deleteProductFabricUsableError = null;
      state.deleteProductFabricUsableData = null;
    });
    builder.addCase(
      deleteProductFabricUsableAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductFabricUsableStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricUsableStatus = asyncStatus.SUCCEEDED;
        state.deleteProductFabricUsableData = payload.data;
        const { getProductFabricUsableData } = state;
        console.log("aaaaa", getProductFabricUsableData);
        let updatedArray = getProductFabricUsableData?.filter(
          (x) => x.fabric_useable_id !== payload.data.fabric_useable_id
        );
        state.getProductFabricUsableData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductFabricUsableError = null;
      }
    );
    builder.addCase(
      deleteProductFabricUsableAsync.rejected,
      (state, actions) => {
        state.deleteProductFabricUsableData = null;
        state.deleteProductFabricUsableStatus = asyncStatus.ERROR;
        state.deleteProductFabricUsableError = actions?.error?.message;
      }
    );

    // FABRIC USABLE WORK

    // FABRIC CLASS2 WORK

    builder.addCase(getProductFabricClassTwoAsync.pending, (state, action) => {
      state.getProductFabricClassTwoStatus = asyncStatus.LOADING;
      state.getProductFabricClassTwoError = null;
      state.getProductFabricClassTwoData = null;
    });
    builder.addCase(
      getProductFabricClassTwoAsync.fulfilled,
      (state, { payload }) => {
        state.getProductFabricClassTwoStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricClassTwoData = payload.data;
        state.getProductFabricClassTwoError = null;
      }
    );
    builder.addCase(
      getProductFabricClassTwoAsync.rejected,
      (state, actions) => {
        state.getProductFabricClassTwoData = null;
        state.getProductFabricClassTwoStatus = asyncStatus.ERROR;
        state.getProductFabricClassTwoError = actions?.error?.message;
      }
    );

    builder.addCase(addProductFabricClassTwoAsync.pending, (state, action) => {
      state.addProductFabricClassTwoStatus = asyncStatus.LOADING;
      state.addProductFabricClassTwoError = null;
      state.addProductFabricClassTwoData = null;
    });
    builder.addCase(
      addProductFabricClassTwoAsync.fulfilled,
      (state, { payload }) => {
        state.addProductFabricClassTwoStatus = asyncStatus.SUCCEEDED;
        state.addProductFabricClassTwoData = payload.data;
        // let { getProductFabricClassTwoData } = state;
        state.getProductFabricClassTwoData = [
          ...state.getProductFabricClassTwoData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductFabricClassTwoError = null;
      }
    );
    builder.addCase(
      addProductFabricClassTwoAsync.rejected,
      (state, actions) => {
        state.addProductFabricClassTwoData = null;
        state.addProductFabricClassTwoStatus = asyncStatus.ERROR;
        state.addProductFabricClassTwoError = actions?.error?.message;
      }
    );

    builder.addCase(editProductFabricClassTwoAsync.pending, (state, action) => {
      state.editProductFabricClassTwoStatus = asyncStatus.LOADING;
      state.editProductFabricClassTwoError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductFabricClassTwoData = null;
    });
    builder.addCase(
      editProductFabricClassTwoAsync.fulfilled,
      (state, { payload }) => {
        state.editProductFabricClassTwoStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricClassTwoStatus = asyncStatus.SUCCEEDED;
        state.editProductFabricClassTwoData = payload.data;
        const { getProductFabricClassTwoData } = state;
        let updatedArray = [];
        getProductFabricClassTwoData?.map((e) => {
          var item = e;
          if (item?.fabric_class_id2 === payload?.data?.fabric_class_id2) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductFabricClassTwoData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductFabricClassTwoError = null;
      }
    );
    builder.addCase(
      editProductFabricClassTwoAsync.rejected,
      (state, actions) => {
        state.editProductFabricClassTwoData = null;
        state.editProductFabricClassTwoStatus = asyncStatus.ERROR;
        state.editProductFabricClassTwoError = actions?.error?.message;
      }
    );

    builder.addCase(
      deleteProductFabricClassTwoAsync.pending,
      (state, action) => {
        state.deleteProductFabricClassTwoStatus = asyncStatus.LOADING;
        state.getProductFabricClassTwoStatus = asyncStatus.LOADING;
        state.deleteProductFabricClassTwoError = null;
        state.deleteProductFabricClassTwoData = null;
      }
    );
    builder.addCase(
      deleteProductFabricClassTwoAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductFabricClassTwoStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricClassTwoStatus = asyncStatus.SUCCEEDED;
        state.deleteProductFabricClassTwoData = payload.data;
        const { getProductFabricClassTwoData } = state;
        console.log("aaaaa", getProductFabricClassTwoData);
        let updatedArray = getProductFabricClassTwoData?.filter(
          (x) => x.fabric_class_id2 !== payload.data.fabric_class_id2
        );
        state.getProductFabricClassTwoData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductFabricClassTwoError = null;
      }
    );
    builder.addCase(
      deleteProductFabricClassTwoAsync.rejected,
      (state, actions) => {
        state.deleteProductFabricClassTwoData = null;
        state.deleteProductFabricClassTwoStatus = asyncStatus.ERROR;
        state.deleteProductFabricClassTwoError = actions?.error?.message;
      }
    );

    // FABRIC CLASS2 WORK

    // FABRIC COMPOSITION WORK

    builder.addCase(
      getProductFabricCompositionAsync.pending,
      (state, action) => {
        state.getProductFabricCompositionStatus = asyncStatus.LOADING;
        state.getProductFabricCompositionError = null;
        state.getProductFabricCompositionData = null;
      }
    );
    builder.addCase(
      getProductFabricCompositionAsync.fulfilled,
      (state, { payload }) => {
        state.getProductFabricCompositionStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricCompositionData = payload.data;
        state.getProductFabricCompositionError = null;
      }
    );
    builder.addCase(
      getProductFabricCompositionAsync.rejected,
      (state, actions) => {
        state.getProductFabricCompositionData = null;
        state.getProductFabricCompositionStatus = asyncStatus.ERROR;
        state.getProductFabricCompositionError = actions?.error?.message;
      }
    );

    builder.addCase(
      addProductFabricCompositionAsync.pending,
      (state, action) => {
        state.addProductFabricCompositionStatus = asyncStatus.LOADING;
        state.addProductFabricCompositionError = null;
        state.addProductFabricCompositionData = null;
      }
    );
    builder.addCase(
      addProductFabricCompositionAsync.fulfilled,
      (state, { payload }) => {
        state.addProductFabricCompositionStatus = asyncStatus.SUCCEEDED;
        state.addProductFabricCompositionData = payload.data;
        // let { getProductFabricCompositionData } = state;
        state.getProductFabricCompositionData = [
          ...state.getProductFabricCompositionData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductFabricCompositionError = null;
      }
    );
    builder.addCase(
      addProductFabricCompositionAsync.rejected,
      (state, actions) => {
        state.addProductFabricCompositionData = null;
        state.addProductFabricCompositionStatus = asyncStatus.ERROR;
        state.addProductFabricCompositionError = actions?.error?.message;
      }
    );

    builder.addCase(
      editProductFabricCompositionAsync.pending,
      (state, action) => {
        state.editProductFabricCompositionStatus = asyncStatus.LOADING;
        state.editProductFabricCompositionError = null;
        state.getProductStyleStatus = asyncStatus.LOADING;
        state.editProductFabricCompositionData = null;
      }
    );
    builder.addCase(
      editProductFabricCompositionAsync.fulfilled,
      (state, { payload }) => {
        state.editProductFabricCompositionStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricCompositionStatus = asyncStatus.SUCCEEDED;
        state.editProductFabricCompositionData = payload.data;
        const { getProductFabricCompositionData } = state;
        let updatedArray = [];
        getProductFabricCompositionData?.map((e) => {
          var item = e;
          if (
            item?.fabric_composition_id === payload?.data?.fabric_composition_id
          ) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductFabricCompositionData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductFabricCompositionError = null;
      }
    );
    builder.addCase(
      editProductFabricCompositionAsync.rejected,
      (state, actions) => {
        state.editProductFabricCompositionData = null;
        state.editProductFabricCompositionStatus = asyncStatus.ERROR;
        state.editProductFabricCompositionError = actions?.error?.message;
      }
    );

    builder.addCase(
      deleteProductFabricCompositionAsync.pending,
      (state, action) => {
        state.deleteProductFabricCompositionStatus = asyncStatus.LOADING;
        state.getProductFabricCompositionStatus = asyncStatus.LOADING;
        state.deleteProductFabricCompositionError = null;
        state.deleteProductFabricCompositionData = null;
      }
    );
    builder.addCase(
      deleteProductFabricCompositionAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductFabricCompositionStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricCompositionStatus = asyncStatus.SUCCEEDED;
        state.deleteProductFabricCompositionData = payload.data;
        const { getProductFabricCompositionData } = state;
        console.log("aaaaa", getProductFabricCompositionData);
        let updatedArray = getProductFabricCompositionData?.filter(
          (x) => x.fabric_composition_id !== payload.data.fabric_composition_id
        );
        state.getProductFabricCompositionData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductFabricCompositionError = null;
      }
    );
    builder.addCase(
      deleteProductFabricCompositionAsync.rejected,
      (state, actions) => {
        state.deleteProductFabricCompositionData = null;
        state.deleteProductFabricCompositionStatus = asyncStatus.ERROR;
        state.deleteProductFabricCompositionError = actions?.error?.message;
      }
    );

    // FABRIC COMPOSITION WORK

    // FABRIC WORK

    builder.addCase(getProductFabricAsync.pending, (state, action) => {
      state.getProductFabricStatus = asyncStatus.LOADING;
      state.getProductFabricError = null;
      state.getProductFabricData = null;
    });
    builder.addCase(getProductFabricAsync.fulfilled, (state, { payload }) => {
      state.getProductFabricStatus = asyncStatus.SUCCEEDED;
      state.getProductFabricData = payload.data;
      state.getProductFabricError = null;
    });
    builder.addCase(getProductFabricAsync.rejected, (state, actions) => {
      state.getProductFabricData = null;
      state.getProductFabricStatus = asyncStatus.ERROR;
      state.getProductFabricError = actions?.error?.message;
    });

    builder.addCase(addProductFabricAsync.pending, (state, action) => {
      state.addProductFabricStatus = asyncStatus.LOADING;
      state.addProductFabricError = null;
      state.addProductFabricData = null;
    });
    builder.addCase(addProductFabricAsync.fulfilled, (state, { payload }) => {
      state.addProductFabricStatus = asyncStatus.SUCCEEDED;
      state.addProductFabricData = payload.data;
      // let { getProductFabricData } = state;
      state.getProductFabricData = [
        ...state.getProductFabricData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addProductFabricError = null;
    });
    builder.addCase(addProductFabricAsync.rejected, (state, actions) => {
      state.addProductFabricData = null;
      state.addProductFabricStatus = asyncStatus.ERROR;
      state.addProductFabricError = actions?.error?.message;
    });

    builder.addCase(editProductFabricAsync.pending, (state, action) => {
      state.editProductFabricStatus = asyncStatus.LOADING;
      state.editProductFabricError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductFabricData = null;
    });
    builder.addCase(editProductFabricAsync.fulfilled, (state, { payload }) => {
      state.editProductFabricStatus = asyncStatus.SUCCEEDED;
      state.getProductFabricStatus = asyncStatus.SUCCEEDED;
      state.editProductFabricData = payload.data;
      const { getProductFabricData } = state;
      let updatedArray = [];
      getProductFabricData?.map((e) => {
        var item = e;
        if (item?.fabric_id === payload?.data?.fabric_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      console.log("updatedArray", updatedArray);
      state.getProductFabricData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductFabricError = null;
    });
    builder.addCase(editProductFabricAsync.rejected, (state, actions) => {
      state.editProductFabricData = null;
      state.editProductFabricStatus = asyncStatus.ERROR;
      state.editProductFabricError = actions?.error?.message;
    });

    builder.addCase(deleteProductFabricAsync.pending, (state, action) => {
      state.deleteProductFabricStatus = asyncStatus.LOADING;
      state.getProductFabricStatus = asyncStatus.LOADING;
      state.deleteProductFabricError = null;
      state.deleteProductFabricData = null;
    });
    builder.addCase(
      deleteProductFabricAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductFabricStatus = asyncStatus.SUCCEEDED;
        state.getProductFabricStatus = asyncStatus.SUCCEEDED;
        state.deleteProductFabricData = payload.data;
        const { getProductFabricData } = state;
        console.log("aaaaa", getProductFabricData);
        let updatedArray = getProductFabricData?.filter(
          (x) => x.fabric_id !== payload.data.fabric_id
        );
        state.getProductFabricData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductFabricError = null;
      }
    );
    builder.addCase(deleteProductFabricAsync.rejected, (state, actions) => {
      state.deleteProductFabricData = null;
      state.deleteProductFabricStatus = asyncStatus.ERROR;
      state.deleteProductFabricError = actions?.error?.message;
    });

    // FABRIC WORK

    // GENDER WORK

    builder.addCase(getProductGenderAsync.pending, (state, action) => {
      state.getProductGenderStatus = asyncStatus.LOADING;
      state.getProductGenderError = null;
      state.getProductGenderData = null;
    });
    builder.addCase(getProductGenderAsync.fulfilled, (state, { payload }) => {
      state.getProductGenderStatus = asyncStatus.SUCCEEDED;
      state.getProductGenderData = payload.data;
      state.getProductGenderError = null;
    });
    builder.addCase(getProductGenderAsync.rejected, (state, actions) => {
      state.getProductGenderData = null;
      state.getProductGenderStatus = asyncStatus.ERROR;
      state.getProductGenderError = actions?.error?.message;
    });

    builder.addCase(addProductGenderAsync.pending, (state, action) => {
      state.addProductGenderStatus = asyncStatus.LOADING;
      state.addProductGenderError = null;
      state.addProductGenderData = null;
    });
    builder.addCase(addProductGenderAsync.fulfilled, (state, { payload }) => {
      state.addProductGenderStatus = asyncStatus.SUCCEEDED;
      state.addProductGenderData = payload.data;
      // let { getProductGenderData } = state;
      state.getProductGenderData = [
        ...state.getProductGenderData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addProductGenderError = null;
    });
    builder.addCase(addProductGenderAsync.rejected, (state, actions) => {
      state.addProductGenderData = null;
      state.addProductGenderStatus = asyncStatus.ERROR;
      state.addProductGenderError = actions?.error?.message;
    });

    builder.addCase(editProductGenderAsync.pending, (state, action) => {
      state.editProductGenderStatus = asyncStatus.LOADING;
      state.editProductGenderError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductGenderData = null;
    });
    builder.addCase(editProductGenderAsync.fulfilled, (state, { payload }) => {
      state.editProductGenderStatus = asyncStatus.SUCCEEDED;
      state.getProductGenderStatus = asyncStatus.SUCCEEDED;
      state.editProductGenderData = payload.data;
      const { getProductGenderData } = state;
      let updatedArray = [];
      getProductGenderData?.map((e) => {
        var item = e;
        if (item?.gender_id === payload?.data?.gender_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      console.log("updatedArray", updatedArray);
      state.getProductGenderData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductGenderError = null;
    });
    builder.addCase(editProductGenderAsync.rejected, (state, actions) => {
      state.editProductGenderData = null;
      state.editProductGenderStatus = asyncStatus.ERROR;
      state.editProductGenderError = actions?.error?.message;
    });

    builder.addCase(deleteProductGenderAsync.pending, (state, action) => {
      state.deleteProductGenderStatus = asyncStatus.LOADING;
      state.getProductGenderStatus = asyncStatus.LOADING;
      state.deleteProductGenderError = null;
      state.deleteProductGenderData = null;
    });
    builder.addCase(
      deleteProductGenderAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductGenderStatus = asyncStatus.SUCCEEDED;
        state.getProductGenderStatus = asyncStatus.SUCCEEDED;
        state.deleteProductGenderData = payload.data;
        const { getProductGenderData } = state;
        console.log("aaaaa", getProductGenderData);
        let updatedArray = getProductGenderData?.filter(
          (x) => x.gender_id !== payload.data.gender_id
        );
        state.getProductGenderData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductGenderError = null;
      }
    );
    builder.addCase(deleteProductGenderAsync.rejected, (state, actions) => {
      state.deleteProductGenderData = null;
      state.deleteProductGenderStatus = asyncStatus.ERROR;
      state.deleteProductGenderError = actions?.error?.message;
    });

    // GENDER WORK

    // WASH TYPE WORK

    builder.addCase(getProductWashTypeAsync.pending, (state, action) => {
      state.getProductWashTypeStatus = asyncStatus.LOADING;
      state.getProductWashTypeError = null;
      state.getProductWashTypeData = null;
    });
    builder.addCase(getProductWashTypeAsync.fulfilled, (state, { payload }) => {
      state.getProductWashTypeStatus = asyncStatus.SUCCEEDED;
      state.getProductWashTypeData = payload.data;
      state.getProductWashTypeError = null;
    });
    builder.addCase(getProductWashTypeAsync.rejected, (state, actions) => {
      state.getProductWashTypeData = null;
      state.getProductWashTypeStatus = asyncStatus.ERROR;
      state.getProductWashTypeError = actions?.error?.message;
    });

    builder.addCase(addProductWashTypeAsync.pending, (state, action) => {
      state.addProductWashTypeStatus = asyncStatus.LOADING;
      state.addProductWashTypeError = null;
      state.addProductWashTypeData = null;
    });
    builder.addCase(addProductWashTypeAsync.fulfilled, (state, { payload }) => {
      state.addProductWashTypeStatus = asyncStatus.SUCCEEDED;
      state.addProductWashTypeData = payload.data;
      // let { getProductWashTypeData } = state;
      state.getProductWashTypeData = [
        ...state.getProductWashTypeData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addProductWashTypeError = null;
    });
    builder.addCase(addProductWashTypeAsync.rejected, (state, actions) => {
      state.addProductWashTypeData = null;
      state.addProductWashTypeStatus = asyncStatus.ERROR;
      state.addProductWashTypeError = actions?.error?.message;
    });

    builder.addCase(editProductWashTypeAsync.pending, (state, action) => {
      state.editProductWashTypeStatus = asyncStatus.LOADING;
      state.editProductWashTypeError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductWashTypeData = null;
    });
    builder.addCase(
      editProductWashTypeAsync.fulfilled,
      (state, { payload }) => {
        state.editProductWashTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductWashTypeStatus = asyncStatus.SUCCEEDED;
        state.editProductWashTypeData = payload.data;
        const { getProductWashTypeData } = state;
        let updatedArray = [];
        getProductWashTypeData?.map((e) => {
          var item = e;
          if (item?.wash_type_id === payload?.data?.wash_type_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductWashTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductWashTypeError = null;
      }
    );
    builder.addCase(editProductWashTypeAsync.rejected, (state, actions) => {
      state.editProductWashTypeData = null;
      state.editProductWashTypeStatus = asyncStatus.ERROR;
      state.editProductWashTypeError = actions?.error?.message;
    });

    builder.addCase(deleteProductWashTypeAsync.pending, (state, action) => {
      state.deleteProductWashTypeStatus = asyncStatus.LOADING;
      state.getProductWashTypeStatus = asyncStatus.LOADING;
      state.deleteProductWashTypeError = null;
      state.deleteProductWashTypeData = null;
    });
    builder.addCase(
      deleteProductWashTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductWashTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductWashTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteProductWashTypeData = payload.data;
        const { getProductWashTypeData } = state;
        console.log("aaaaa", getProductWashTypeData);
        let updatedArray = getProductWashTypeData?.filter(
          (x) => x.wash_type_id !== payload.data.wash_type_id
        );
        state.getProductWashTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductWashTypeError = null;
      }
    );
    builder.addCase(deleteProductWashTypeAsync.rejected, (state, actions) => {
      state.deleteProductWashTypeData = null;
      state.deleteProductWashTypeStatus = asyncStatus.ERROR;
      state.deleteProductWashTypeError = actions?.error?.message;
    });

    // WASH TYPE WORK

    // SEASON WORK

    builder.addCase(getProductSeasonAsync.pending, (state, action) => {
      state.getProductSeasonStatus = asyncStatus.LOADING;
      state.getProductSeasonError = null;
      state.getProductSeasonData = null;
    });
    builder.addCase(getProductSeasonAsync.fulfilled, (state, { payload }) => {
      state.getProductSeasonStatus = asyncStatus.SUCCEEDED;
      state.getProductSeasonData = payload.data;
      state.getProductSeasonError = null;
    });
    builder.addCase(getProductSeasonAsync.rejected, (state, actions) => {
      state.getProductSeasonData = null;
      state.getProductSeasonStatus = asyncStatus.ERROR;
      state.getProductSeasonError = actions?.error?.message;
    });

    builder.addCase(addProductSeasonAsync.pending, (state, action) => {
      state.addProductSeasonStatus = asyncStatus.LOADING;
      state.addProductSeasonError = null;
      state.addProductSeasonData = null;
    });
    builder.addCase(addProductSeasonAsync.fulfilled, (state, { payload }) => {
      state.addProductSeasonStatus = asyncStatus.SUCCEEDED;
      state.addProductSeasonData = payload.data;
      // let { getProductSeasonData } = state;
      state.getProductSeasonData = [
        ...state.getProductSeasonData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addProductSeasonError = null;
    });
    builder.addCase(addProductSeasonAsync.rejected, (state, actions) => {
      state.addProductSeasonData = null;
      state.addProductSeasonStatus = asyncStatus.ERROR;
      state.addProductSeasonError = actions?.error?.message;
    });

    builder.addCase(editProductSeasonAsync.pending, (state, action) => {
      state.editProductSeasonStatus = asyncStatus.LOADING;
      state.editProductSeasonError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductSeasonData = null;
    });
    builder.addCase(editProductSeasonAsync.fulfilled, (state, { payload }) => {
      state.editProductSeasonStatus = asyncStatus.SUCCEEDED;
      state.getProductSeasonStatus = asyncStatus.SUCCEEDED;
      state.editProductSeasonData = payload.data;
      const { getProductSeasonData } = state;
      let updatedArray = [];
      getProductSeasonData?.map((e) => {
        var item = e;
        if (item?.season_id === payload?.data?.season_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      console.log("updatedArray", updatedArray);
      state.getProductSeasonData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductSeasonError = null;
    });
    builder.addCase(editProductSeasonAsync.rejected, (state, actions) => {
      state.editProductSeasonData = null;
      state.editProductSeasonStatus = asyncStatus.ERROR;
      state.editProductSeasonError = actions?.error?.message;
    });

    builder.addCase(deleteProductSeasonAsync.pending, (state, action) => {
      state.deleteProductSeasonStatus = asyncStatus.LOADING;
      state.getProductSeasonStatus = asyncStatus.LOADING;
      state.deleteProductSeasonError = null;
      state.deleteProductSeasonData = null;
    });
    builder.addCase(
      deleteProductSeasonAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductSeasonStatus = asyncStatus.SUCCEEDED;
        state.getProductSeasonStatus = asyncStatus.SUCCEEDED;
        state.deleteProductSeasonData = payload.data;
        const { getProductSeasonData } = state;
        console.log("aaaaa", getProductSeasonData);
        let updatedArray = getProductSeasonData?.filter(
          (x) => x.season_id !== payload.data.season_id
        );
        state.getProductSeasonData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductSeasonError = null;
      }
    );
    builder.addCase(deleteProductSeasonAsync.rejected, (state, actions) => {
      state.deleteProductSeasonData = null;
      state.deleteProductSeasonStatus = asyncStatus.ERROR;
      state.deleteProductSeasonError = actions?.error?.message;
    });

    // SEASON WORK

    // GENDER CATEGORY WORK

    builder.addCase(getProductGenderCategoryAsync.pending, (state, action) => {
      state.getProductGenderCategoryStatus = asyncStatus.LOADING;
      state.getProductGenderCategoryError = null;
      state.getProductGenderCategoryData = null;
    });
    builder.addCase(
      getProductGenderCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.getProductGenderCategoryStatus = asyncStatus.SUCCEEDED;
        state.getProductGenderCategoryData = payload.data;
        state.getProductGenderCategoryError = null;
      }
    );
    builder.addCase(
      getProductGenderCategoryAsync.rejected,
      (state, actions) => {
        state.getProductGenderCategoryData = null;
        state.getProductGenderCategoryStatus = asyncStatus.ERROR;
        state.getProductGenderCategoryError = actions?.error?.message;
      }
    );

    builder.addCase(addProductGenderCategoryAsync.pending, (state, action) => {
      state.addProductGenderCategoryStatus = asyncStatus.LOADING;
      state.addProductGenderCategoryError = null;
      state.addProductGenderCategoryData = null;
    });
    builder.addCase(
      addProductGenderCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.addProductGenderCategoryStatus = asyncStatus.SUCCEEDED;
        state.addProductGenderCategoryData = payload.data;
        // let { getProductGenderCategoryData } = state;
        state.getProductGenderCategoryData = [
          ...state.getProductGenderCategoryData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductGenderCategoryError = null;
      }
    );
    builder.addCase(
      addProductGenderCategoryAsync.rejected,
      (state, actions) => {
        state.addProductGenderCategoryData = null;
        state.addProductGenderCategoryStatus = asyncStatus.ERROR;
        state.addProductGenderCategoryError = actions?.error?.message;
      }
    );

    builder.addCase(editProductGenderCategoryAsync.pending, (state, action) => {
      state.editProductGenderCategoryStatus = asyncStatus.LOADING;
      state.editProductGenderCategoryError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductGenderCategoryData = null;
    });
    builder.addCase(
      editProductGenderCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.editProductGenderCategoryStatus = asyncStatus.SUCCEEDED;
        state.getProductGenderCategoryStatus = asyncStatus.SUCCEEDED;
        state.editProductGenderCategoryData = payload.data;
        const { getProductGenderCategoryData } = state;
        let updatedArray = [];
        getProductGenderCategoryData?.map((e) => {
          var item = e;
          if (item?.gender_category_id === payload?.data?.gender_category_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductGenderCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductGenderCategoryError = null;
      }
    );
    builder.addCase(
      editProductGenderCategoryAsync.rejected,
      (state, actions) => {
        state.editProductGenderCategoryData = null;
        state.editProductGenderCategoryStatus = asyncStatus.ERROR;
        state.editProductGenderCategoryError = actions?.error?.message;
      }
    );

    builder.addCase(
      deleteProductGenderCategoryAsync.pending,
      (state, action) => {
        state.deleteProductGenderCategoryStatus = asyncStatus.LOADING;
        state.getProductGenderCategoryStatus = asyncStatus.LOADING;
        state.deleteProductGenderCategoryError = null;
        state.deleteProductGenderCategoryData = null;
      }
    );
    builder.addCase(
      deleteProductGenderCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductGenderCategoryStatus = asyncStatus.SUCCEEDED;
        state.getProductGenderCategoryStatus = asyncStatus.SUCCEEDED;
        state.deleteProductGenderCategoryData = payload.data;
        const { getProductGenderCategoryData } = state;
        console.log("aaaaa", getProductGenderCategoryData);
        let updatedArray = getProductGenderCategoryData?.filter(
          (x) => x.gender_category_id !== payload.data.gender_category_id
        );
        state.getProductGenderCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductGenderCategoryError = null;
      }
    );
    builder.addCase(
      deleteProductGenderCategoryAsync.rejected,
      (state, actions) => {
        state.deleteProductGenderCategoryData = null;
        state.deleteProductGenderCategoryStatus = asyncStatus.ERROR;
        state.deleteProductGenderCategoryError = actions?.error?.message;
      }
    );

    // GENDER CATEGORY WORK
    // Fit category WORK

    builder.addCase(getProductFitCategoryAsync.pending, (state, action) => {
      state.getProductFitCategoryStatus = asyncStatus.LOADING;
      state.getProductFitCategoryError = null;
      state.getProductFitCategoryData = null;
    });
    builder.addCase(
      getProductFitCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.getProductFitCategoryStatus = asyncStatus.SUCCEEDED;
        state.getProductFitCategoryData = payload.data;
        state.getProductFitCategoryError = null;
      }
    );
    builder.addCase(getProductFitCategoryAsync.rejected, (state, actions) => {
      state.getProductFitCategoryData = null;
      state.getProductFitCategoryStatus = asyncStatus.ERROR;
      state.getProductFitCategoryError = actions?.error?.message;
    });

    builder.addCase(addProductFitCategoryAsync.pending, (state, action) => {
      state.addProductFitCategoryStatus = asyncStatus.LOADING;
      state.addProductFitCategoryError = null;
      state.addProductFitCategoryData = null;
    });
    builder.addCase(
      addProductFitCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.addProductFitCategoryStatus = asyncStatus.SUCCEEDED;
        state.addProductFitCategoryData = payload.data;
        // let { getProductFitCategoryData } = state;
        state.getProductFitCategoryData = [
          ...state.getProductFitCategoryData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductFitCategoryError = null;
      }
    );
    builder.addCase(addProductFitCategoryAsync.rejected, (state, actions) => {
      state.addProductFitCategoryData = null;
      state.addProductFitCategoryStatus = asyncStatus.ERROR;
      state.addProductFitCategoryError = actions?.error?.message;
    });

    builder.addCase(editProductFitCategoryAsync.pending, (state, action) => {
      state.editProductFitCategoryStatus = asyncStatus.LOADING;
      state.editProductFitCategoryError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductFitCategoryData = null;
    });
    builder.addCase(
      editProductFitCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.editProductFitCategoryStatus = asyncStatus.SUCCEEDED;
        state.getProductFitCategoryStatus = asyncStatus.SUCCEEDED;
        state.editProductFitCategoryData = payload.data;
        const { getProductFitCategoryData } = state;
        let updatedArray = [];
        getProductFitCategoryData?.map((e) => {
          var item = e;
          if (item?.fit_category_id === payload?.data?.fit_category_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductFitCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductFitCategoryError = null;
      }
    );
    builder.addCase(editProductFitCategoryAsync.rejected, (state, actions) => {
      state.editProductFitCategoryData = null;
      state.editProductFitCategoryStatus = asyncStatus.ERROR;
      state.editProductFitCategoryError = actions?.error?.message;
    });

    builder.addCase(deleteProductFitCategoryAsync.pending, (state, action) => {
      state.deleteProductFitCategoryStatus = asyncStatus.LOADING;
      state.getProductFitCategoryStatus = asyncStatus.LOADING;
      state.deleteProductFitCategoryError = null;
      state.deleteProductFitCategoryData = null;
    });
    builder.addCase(
      deleteProductFitCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductFitCategoryStatus = asyncStatus.SUCCEEDED;
        state.getProductFitCategoryStatus = asyncStatus.SUCCEEDED;
        state.deleteProductFitCategoryData = payload.data;
        const { getProductFitCategoryData } = state;
        console.log("aaaaa", getProductFitCategoryData);
        let updatedArray = getProductFitCategoryData?.filter(
          (x) => x.fit_category_id !== payload.data.fit_category_id
        );
        state.getProductFitCategoryData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductFitCategoryError = null;
      }
    );
    builder.addCase(
      deleteProductFitCategoryAsync.rejected,
      (state, actions) => {
        state.deleteProductFitCategoryData = null;
        state.deleteProductFitCategoryStatus = asyncStatus.ERROR;
        state.deleteProductFitCategoryError = actions?.error?.message;
      }
    );

    // Fit category WORK
    // Rise WORK

    builder.addCase(getProductRiseAsync.pending, (state, action) => {
      state.getProductRiseStatus = asyncStatus.LOADING;
      state.getProductRiseError = null;
      state.getProductRiseData = null;
    });
    builder.addCase(getProductRiseAsync.fulfilled, (state, { payload }) => {
      state.getProductRiseStatus = asyncStatus.SUCCEEDED;
      state.getProductRiseData = payload.data;
      state.getProductRiseError = null;
    });
    builder.addCase(getProductRiseAsync.rejected, (state, actions) => {
      state.getProductRiseData = null;
      state.getProductRiseStatus = asyncStatus.ERROR;
      state.getProductRiseError = actions?.error?.message;
    });

    builder.addCase(addProductRiseAsync.pending, (state, action) => {
      state.addProductRiseStatus = asyncStatus.LOADING;
      state.addProductRiseError = null;
      state.addProductRiseData = null;
    });
    builder.addCase(addProductRiseAsync.fulfilled, (state, { payload }) => {
      state.addProductRiseStatus = asyncStatus.SUCCEEDED;
      state.addProductRiseData = payload.data;
      // let { getProductRiseData } = state;
      state.getProductRiseData = [...state.getProductRiseData, payload.data];
      success_toast_message(payload.message);
      state.addProductRiseError = null;
    });
    builder.addCase(addProductRiseAsync.rejected, (state, actions) => {
      state.addProductRiseData = null;
      state.addProductRiseStatus = asyncStatus.ERROR;
      state.addProductRiseError = actions?.error?.message;
    });

    builder.addCase(editProductRiseAsync.pending, (state, action) => {
      state.editProductRiseStatus = asyncStatus.LOADING;
      state.editProductRiseError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductRiseData = null;
    });
    builder.addCase(editProductRiseAsync.fulfilled, (state, { payload }) => {
      state.editProductRiseStatus = asyncStatus.SUCCEEDED;
      state.getProductRiseStatus = asyncStatus.SUCCEEDED;
      state.editProductRiseData = payload.data;
      const { getProductRiseData } = state;
      let updatedArray = [];
      getProductRiseData?.map((e) => {
        var item = e;
        if (item?.rise_id === payload?.data?.rise_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      console.log("updatedArray", updatedArray);
      state.getProductRiseData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductRiseError = null;
    });
    builder.addCase(editProductRiseAsync.rejected, (state, actions) => {
      state.editProductRiseData = null;
      state.editProductRiseStatus = asyncStatus.ERROR;
      state.editProductRiseError = actions?.error?.message;
    });

    builder.addCase(deleteProductRiseAsync.pending, (state, action) => {
      state.deleteProductRiseStatus = asyncStatus.LOADING;
      state.getProductRiseStatus = asyncStatus.LOADING;
      state.deleteProductRiseError = null;
      state.deleteProductRiseData = null;
    });
    builder.addCase(deleteProductRiseAsync.fulfilled, (state, { payload }) => {
      state.deleteProductRiseStatus = asyncStatus.SUCCEEDED;
      state.getProductRiseStatus = asyncStatus.SUCCEEDED;
      state.deleteProductRiseData = payload.data;
      const { getProductRiseData } = state;
      console.log("aaaaa", getProductRiseData);
      let updatedArray = getProductRiseData?.filter(
        (x) => x.rise_id !== payload.data.rise_id
      );
      state.getProductRiseData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteProductRiseError = null;
    });
    builder.addCase(deleteProductRiseAsync.rejected, (state, actions) => {
      state.deleteProductRiseData = null;
      state.deleteProductRiseStatus = asyncStatus.ERROR;
      state.deleteProductRiseError = actions?.error?.message;
    });

    // Rise WORK
    // Charge Back Type WORK

    builder.addCase(getProductChargeBackTypeAsync.pending, (state, action) => {
      state.getProductChargeBackTypeStatus = asyncStatus.LOADING;
      state.getProductChargeBackTypeError = null;
      state.getProductChargeBackTypeData = null;
    });
    builder.addCase(
      getProductChargeBackTypeAsync.fulfilled,
      (state, { payload }) => {
        state.getProductChargeBackTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductChargeBackTypeData = payload.data;
        state.getProductChargeBackTypeError = null;
      }
    );
    builder.addCase(
      getProductChargeBackTypeAsync.rejected,
      (state, actions) => {
        state.getProductChargeBackTypeData = null;
        state.getProductChargeBackTypeStatus = asyncStatus.ERROR;
        state.getProductChargeBackTypeError = actions?.error?.message;
      }
    );

    builder.addCase(addProductChargeBackTypeAsync.pending, (state, action) => {
      state.addProductChargeBackTypeStatus = asyncStatus.LOADING;
      state.addProductChargeBackTypeError = null;
      state.addProductChargeBackTypeData = null;
    });
    builder.addCase(
      addProductChargeBackTypeAsync.fulfilled,
      (state, { payload }) => {
        state.addProductChargeBackTypeStatus = asyncStatus.SUCCEEDED;
        state.addProductChargeBackTypeData = payload.data;
        // let { getProductChargeBackTypeData } = state;
        state.getProductChargeBackTypeData = [
          ...state.getProductChargeBackTypeData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductChargeBackTypeError = null;
      }
    );
    builder.addCase(
      addProductChargeBackTypeAsync.rejected,
      (state, actions) => {
        state.addProductChargeBackTypeData = null;
        state.addProductChargeBackTypeStatus = asyncStatus.ERROR;
        state.addProductChargeBackTypeError = actions?.error?.message;
      }
    );

    builder.addCase(editProductChargeBackTypeAsync.pending, (state, action) => {
      state.editProductChargeBackTypeStatus = asyncStatus.LOADING;
      state.editProductChargeBackTypeError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductChargeBackTypeData = null;
    });
    builder.addCase(
      editProductChargeBackTypeAsync.fulfilled,
      (state, { payload }) => {
        state.editProductChargeBackTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductChargeBackTypeStatus = asyncStatus.SUCCEEDED;
        state.editProductChargeBackTypeData = payload.data;
        const { getProductChargeBackTypeData } = state;
        let updatedArray = [];
        getProductChargeBackTypeData?.map((e) => {
          var item = e;
          if (
            item?.charge_back_type_id === payload?.data?.charge_back_type_id
          ) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductChargeBackTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductChargeBackTypeError = null;
      }
    );
    builder.addCase(
      editProductChargeBackTypeAsync.rejected,
      (state, actions) => {
        state.editProductChargeBackTypeData = null;
        state.editProductChargeBackTypeStatus = asyncStatus.ERROR;
        state.editProductChargeBackTypeError = actions?.error?.message;
      }
    );

    builder.addCase(
      deleteProductChargeBackTypeAsync.pending,
      (state, action) => {
        state.deleteProductChargeBackTypeStatus = asyncStatus.LOADING;
        state.getProductChargeBackTypeStatus = asyncStatus.LOADING;
        state.deleteProductChargeBackTypeError = null;
        state.deleteProductChargeBackTypeData = null;
      }
    );
    builder.addCase(
      deleteProductChargeBackTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductChargeBackTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductChargeBackTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteProductChargeBackTypeData = payload.data;
        const { getProductChargeBackTypeData } = state;
        console.log("aaaaa", getProductChargeBackTypeData);
        let updatedArray = getProductChargeBackTypeData?.filter(
          (x) => x.charge_back_type_id !== payload.data.charge_back_type_id
        );
        state.getProductChargeBackTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductChargeBackTypeError = null;
      }
    );
    builder.addCase(
      deleteProductChargeBackTypeAsync.rejected,
      (state, actions) => {
        state.deleteProductChargeBackTypeData = null;
        state.deleteProductChargeBackTypeStatus = asyncStatus.ERROR;
        state.deleteProductChargeBackTypeError = actions?.error?.message;
      }
    );

    // Charge Back Type WORK

    // Inseam WORK

    builder.addCase(getProductinseamAsync.pending, (state, action) => {
      state.getProductinseamStatus = asyncStatus.LOADING;
      state.getProductinseamError = null;
      state.getProductinseamData = null;
    });
    builder.addCase(getProductinseamAsync.fulfilled, (state, { payload }) => {
      state.getProductinseamStatus = asyncStatus.SUCCEEDED;
      state.getProductinseamData = payload.data;
      state.getProductinseamError = null;
    });
    builder.addCase(getProductinseamAsync.rejected, (state, actions) => {
      state.getProductinseamData = null;
      state.getProductinseamStatus = asyncStatus.ERROR;
      state.getProductinseamError = actions?.error?.message;
    });

    builder.addCase(addProductinseamAsync.pending, (state, action) => {
      state.addProductinseamStatus = asyncStatus.LOADING;
      state.addProductinseamError = null;
      state.addProductinseamData = null;
    });
    builder.addCase(addProductinseamAsync.fulfilled, (state, { payload }) => {
      state.addProductinseamStatus = asyncStatus.SUCCEEDED;
      state.addProductinseamData = payload.data;
      // let { getProductinseamData } = state;
      state.getProductinseamData = [
        ...state.getProductinseamData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addProductinseamError = null;
    });
    builder.addCase(addProductinseamAsync.rejected, (state, actions) => {
      state.addProductinseamData = null;
      state.addProductinseamStatus = asyncStatus.ERROR;
      state.addProductinseamError = actions?.error?.message;
    });

    builder.addCase(editProductinseamAsync.pending, (state, action) => {
      state.editProductinseamStatus = asyncStatus.LOADING;
      state.editProductinseamError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductinseamData = null;
    });
    builder.addCase(editProductinseamAsync.fulfilled, (state, { payload }) => {
      state.editProductinseamStatus = asyncStatus.SUCCEEDED;
      state.getProductinseamStatus = asyncStatus.SUCCEEDED;
      state.editProductinseamData = payload.data;
      const { getProductinseamData } = state;
      let updatedArray = [];
      getProductinseamData?.map((e) => {
        var item = e;
        if (item?.inseam_id === payload?.data?.inseam_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      console.log("updatedArray", updatedArray);
      state.getProductinseamData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductinseamError = null;
    });
    builder.addCase(editProductinseamAsync.rejected, (state, actions) => {
      state.editProductinseamData = null;
      state.editProductinseamStatus = asyncStatus.ERROR;
      state.editProductinseamError = actions?.error?.message;
    });

    builder.addCase(deleteProductinseamAsync.pending, (state, action) => {
      state.deleteProductinseamStatus = asyncStatus.LOADING;
      state.getProductinseamStatus = asyncStatus.LOADING;
      state.deleteProductinseamError = null;
      state.deleteProductinseamData = null;
    });
    builder.addCase(
      deleteProductinseamAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductinseamStatus = asyncStatus.SUCCEEDED;
        state.getProductinseamStatus = asyncStatus.SUCCEEDED;
        state.deleteProductinseamData = payload.data;
        const { getProductinseamData } = state;
        console.log("aaaaa", getProductinseamData);
        let updatedArray = getProductinseamData?.filter(
          (x) => x.inseam_id !== payload.data.inseam_id
        );
        state.getProductinseamData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductinseamError = null;
      }
    );
    builder.addCase(deleteProductinseamAsync.rejected, (state, actions) => {
      state.deleteProductinseamData = null;
      state.deleteProductinseamStatus = asyncStatus.ERROR;
      state.deleteProductinseamError = actions?.error?.message;
    });

    // Inseam WORK

    // Size WORK

    builder.addCase(getProductsizeAsync.pending, (state, action) => {
      state.getProductsizeStatus = asyncStatus.LOADING;
      state.getProductsizeError = null;
      state.getProductsizeData = null;
    });
    builder.addCase(getProductsizeAsync.fulfilled, (state, { payload }) => {
      state.getProductsizeStatus = asyncStatus.SUCCEEDED;
      state.getProductsizeData = payload.data;
      state.getProductsizeError = null;
    });
    builder.addCase(getProductsizeAsync.rejected, (state, actions) => {
      state.getProductsizeData = null;
      state.getProductsizeStatus = asyncStatus.ERROR;
      state.getProductsizeError = actions?.error?.message;
    });

    builder.addCase(addProductsizeAsync.pending, (state, action) => {
      state.addProductsizeStatus = asyncStatus.LOADING;
      state.addProductsizeError = null;
      state.addProductsizeData = null;
    });
    builder.addCase(addProductsizeAsync.fulfilled, (state, { payload }) => {
      state.addProductsizeStatus = asyncStatus.SUCCEEDED;
      state.addProductsizeData = payload.data;
      // let { getProductsizeData } = state;
      state.getProductsizeData = [...state.getProductsizeData, payload.data];
      success_toast_message(payload.message);
      state.addProductsizeError = null;
    });
    builder.addCase(addProductsizeAsync.rejected, (state, actions) => {
      state.addProductsizeData = null;
      state.addProductsizeStatus = asyncStatus.ERROR;
      state.addProductsizeError = actions?.error?.message;
    });

    builder.addCase(editProductsizeAsync.pending, (state, action) => {
      state.editProductsizeStatus = asyncStatus.LOADING;
      state.editProductsizeError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductsizeData = null;
    });
    builder.addCase(editProductsizeAsync.fulfilled, (state, { payload }) => {
      state.editProductsizeStatus = asyncStatus.SUCCEEDED;
      state.getProductsizeStatus = asyncStatus.SUCCEEDED;
      state.editProductsizeData = payload.data;
      const { getProductsizeData } = state;
      let updatedArray = [];
      getProductsizeData?.map((e) => {
        var item = e;
        if (item?.size_id === payload?.data?.size_id) {
          item = payload.data;
        }
        updatedArray.push(item);
      });
      console.log("updatedArray", updatedArray);
      state.getProductsizeData = [...updatedArray];
      success_toast_message(payload.message);
      state.editProductsizeError = null;
    });
    builder.addCase(editProductsizeAsync.rejected, (state, actions) => {
      state.editProductsizeData = null;
      state.editProductsizeStatus = asyncStatus.ERROR;
      state.editProductsizeError = actions?.error?.message;
    });

    builder.addCase(deleteProductsizeAsync.pending, (state, action) => {
      state.deleteProductsizeStatus = asyncStatus.LOADING;
      state.getProductsizeStatus = asyncStatus.LOADING;
      state.deleteProductsizeError = null;
      state.deleteProductsizeData = null;
    });
    builder.addCase(deleteProductsizeAsync.fulfilled, (state, { payload }) => {
      state.deleteProductsizeStatus = asyncStatus.SUCCEEDED;
      state.getProductsizeStatus = asyncStatus.SUCCEEDED;
      state.deleteProductsizeData = payload.data;
      const { getProductsizeData } = state;
      console.log("aaaaa", getProductsizeData);
      let updatedArray = getProductsizeData?.filter(
        (x) => x.size_id !== payload.data.size_id
      );
      state.getProductsizeData = [...updatedArray];
      success_toast_message(payload.message);
      state.deleteProductsizeError = null;
    });
    builder.addCase(deleteProductsizeAsync.rejected, (state, actions) => {
      state.deleteProductsizeData = null;
      state.deleteProductsizeStatus = asyncStatus.ERROR;
      state.deleteProductsizeError = actions?.error?.message;
    });

    // Size WORK
    // ItemType WORK

    builder.addCase(getProductItemTypeAsync.pending, (state, action) => {
      state.getProductItemTypeStatus = asyncStatus.LOADING;
      state.getProductItemTypeError = null;
      state.getProductItemTypeData = null;
    });
    builder.addCase(getProductItemTypeAsync.fulfilled, (state, { payload }) => {
      state.getProductItemTypeStatus = asyncStatus.SUCCEEDED;
      state.getProductItemTypeData = payload.data;
      state.getProductItemTypeError = null;
    });
    builder.addCase(getProductItemTypeAsync.rejected, (state, actions) => {
      state.getProductItemTypeData = null;
      state.getProductItemTypeStatus = asyncStatus.ERROR;
      state.getProductItemTypeError = actions?.error?.message;
    });

    builder.addCase(addProductItemTypeAsync.pending, (state, action) => {
      state.addProductItemTypeStatus = asyncStatus.LOADING;
      state.addProductItemTypeError = null;
      state.addProductItemTypeData = null;
    });
    builder.addCase(addProductItemTypeAsync.fulfilled, (state, { payload }) => {
      state.addProductItemTypeStatus = asyncStatus.SUCCEEDED;
      state.addProductItemTypeData = payload.data;
      // let { getProductItemTypeData } = state;
      state.getProductItemTypeData = [
        ...state.getProductItemTypeData,
        payload.data,
      ];
      success_toast_message(payload.message);
      state.addProductItemTypeError = null;
    });
    builder.addCase(addProductItemTypeAsync.rejected, (state, actions) => {
      state.addProductItemTypeData = null;
      state.addProductItemTypeStatus = asyncStatus.ERROR;
      state.addProductItemTypeError = actions?.error?.message;
    });

    builder.addCase(editProductItemTypeAsync.pending, (state, action) => {
      state.editProductItemTypeStatus = asyncStatus.LOADING;
      state.editProductItemTypeError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductItemTypeData = null;
    });
    builder.addCase(
      editProductItemTypeAsync.fulfilled,
      (state, { payload }) => {
        state.editProductItemTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductItemTypeStatus = asyncStatus.SUCCEEDED;
        state.editProductItemTypeData = payload.data;
        const { getProductItemTypeData } = state;
        let updatedArray = [];
        getProductItemTypeData?.map((e) => {
          var item = e;
          if (item?.item_type_id === payload?.data?.item_type_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductItemTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductItemTypeError = null;
      }
    );
    builder.addCase(editProductItemTypeAsync.rejected, (state, actions) => {
      state.editProductItemTypeData = null;
      state.editProductItemTypeStatus = asyncStatus.ERROR;
      state.editProductItemTypeError = actions?.error?.message;
    });

    builder.addCase(deleteProductItemTypeAsync.pending, (state, action) => {
      state.deleteProductItemTypeStatus = asyncStatus.LOADING;
      state.getProductItemTypeStatus = asyncStatus.LOADING;
      state.deleteProductItemTypeError = null;
      state.deleteProductItemTypeData = null;
    });
    builder.addCase(
      deleteProductItemTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductItemTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductItemTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteProductItemTypeData = payload.data;
        const { getProductItemTypeData } = state;
        console.log("aaaaa", getProductItemTypeData);
        let updatedArray = getProductItemTypeData?.filter(
          (x) => x.item_type_id !== payload.data.item_type_id
        );
        state.getProductItemTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductItemTypeError = null;
      }
    );
    builder.addCase(deleteProductItemTypeAsync.rejected, (state, actions) => {
      state.deleteProductItemTypeData = null;
      state.deleteProductItemTypeStatus = asyncStatus.ERROR;
      state.deleteProductItemTypeError = actions?.error?.message;
    });

    // ItemType WORK

    // InseamLabel WORK

    builder.addCase(getProductInseamLabelAsync.pending, (state, action) => {
      state.getProductInseamLabelStatus = asyncStatus.LOADING;
      state.getProductInseamLabelError = null;
      state.getProductInseamLabelData = null;
    });
    builder.addCase(
      getProductInseamLabelAsync.fulfilled,
      (state, { payload }) => {
        state.getProductInseamLabelStatus = asyncStatus.SUCCEEDED;
        state.getProductInseamLabelData = payload.data;
        state.getProductInseamLabelError = null;
      }
    );
    builder.addCase(getProductInseamLabelAsync.rejected, (state, actions) => {
      state.getProductInseamLabelData = null;
      state.getProductInseamLabelStatus = asyncStatus.ERROR;
      state.getProductInseamLabelError = actions?.error?.message;
    });

    builder.addCase(addProductInseamLabelAsync.pending, (state, action) => {
      state.addProductInseamLabelStatus = asyncStatus.LOADING;
      state.addProductInseamLabelError = null;
      state.addProductInseamLabelData = null;
    });
    builder.addCase(
      addProductInseamLabelAsync.fulfilled,
      (state, { payload }) => {
        state.addProductInseamLabelStatus = asyncStatus.SUCCEEDED;
        state.addProductInseamLabelData = payload.data;
        // let { getProductInseamLabelData } = state;
        state.getProductInseamLabelData = [
          ...state.getProductInseamLabelData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductInseamLabelError = null;
      }
    );
    builder.addCase(addProductInseamLabelAsync.rejected, (state, actions) => {
      state.addProductInseamLabelData = null;
      state.addProductInseamLabelStatus = asyncStatus.ERROR;
      state.addProductInseamLabelError = actions?.error?.message;
    });

    builder.addCase(editProductInseamLabelAsync.pending, (state, action) => {
      state.editProductInseamLabelStatus = asyncStatus.LOADING;
      state.editProductInseamLabelError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductInseamLabelData = null;
    });
    builder.addCase(
      editProductInseamLabelAsync.fulfilled,
      (state, { payload }) => {
        state.editProductInseamLabelStatus = asyncStatus.SUCCEEDED;
        state.getProductInseamLabelStatus = asyncStatus.SUCCEEDED;
        state.editProductInseamLabelData = payload.data;
        const { getProductInseamLabelData } = state;
        let updatedArray = [];
        getProductInseamLabelData?.map((e) => {
          var item = e;
          if (item?.inseam_label_id === payload?.data?.inseam_label_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductInseamLabelData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductInseamLabelError = null;
      }
    );
    builder.addCase(editProductInseamLabelAsync.rejected, (state, actions) => {
      state.editProductInseamLabelData = null;
      state.editProductInseamLabelStatus = asyncStatus.ERROR;
      state.editProductInseamLabelError = actions?.error?.message;
    });

    builder.addCase(deleteProductInseamLabelAsync.pending, (state, action) => {
      state.deleteProductInseamLabelStatus = asyncStatus.LOADING;
      state.getProductInseamLabelStatus = asyncStatus.LOADING;
      state.deleteProductInseamLabelError = null;
      state.deleteProductInseamLabelData = null;
    });
    builder.addCase(
      deleteProductInseamLabelAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductInseamLabelStatus = asyncStatus.SUCCEEDED;
        state.getProductInseamLabelStatus = asyncStatus.SUCCEEDED;
        state.deleteProductInseamLabelData = payload.data;
        const { getProductInseamLabelData } = state;
        console.log("aaaaa", getProductInseamLabelData);
        let updatedArray = getProductInseamLabelData?.filter(
          (x) => x.inseam_label_id !== payload.data.inseam_label_id
        );
        state.getProductInseamLabelData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductInseamLabelError = null;
      }
    );
    builder.addCase(
      deleteProductInseamLabelAsync.rejected,
      (state, actions) => {
        state.deleteProductInseamLabelData = null;
        state.deleteProductInseamLabelStatus = asyncStatus.ERROR;
        state.deleteProductInseamLabelError = actions?.error?.message;
      }
    );

    // ItemType WORK
    // Rise Label WORK

    builder.addCase(getProductRiseLabelAsync.pending, (state, action) => {
      state.getProductRiseLabelStatus = asyncStatus.LOADING;
      state.getProductRiseLabelError = null;
      state.getProductRiseLabelData = null;
    });
    builder.addCase(
      getProductRiseLabelAsync.fulfilled,
      (state, { payload }) => {
        state.getProductRiseLabelStatus = asyncStatus.SUCCEEDED;
        state.getProductRiseLabelData = payload.data;
        state.getProductRiseLabelError = null;
      }
    );
    builder.addCase(getProductRiseLabelAsync.rejected, (state, actions) => {
      state.getProductRiseLabelData = null;
      state.getProductRiseLabelStatus = asyncStatus.ERROR;
      state.getProductRiseLabelError = actions?.error?.message;
    });

    builder.addCase(addProductRiseLabelAsync.pending, (state, action) => {
      state.addProductRiseLabelStatus = asyncStatus.LOADING;
      state.addProductRiseLabelError = null;
      state.addProductRiseLabelData = null;
    });
    builder.addCase(
      addProductRiseLabelAsync.fulfilled,
      (state, { payload }) => {
        state.addProductRiseLabelStatus = asyncStatus.SUCCEEDED;
        state.addProductRiseLabelData = payload.data;
        // let { getProductRiseLabelData } = state;
        state.getProductRiseLabelData = [
          ...state.getProductRiseLabelData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductRiseLabelError = null;
      }
    );
    builder.addCase(addProductRiseLabelAsync.rejected, (state, actions) => {
      state.addProductRiseLabelData = null;
      state.addProductRiseLabelStatus = asyncStatus.ERROR;
      state.addProductRiseLabelError = actions?.error?.message;
    });

    builder.addCase(editProductRiseLabelAsync.pending, (state, action) => {
      state.editProductRiseLabelStatus = asyncStatus.LOADING;
      state.editProductRiseLabelError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductRiseLabelData = null;
    });
    builder.addCase(
      editProductRiseLabelAsync.fulfilled,
      (state, { payload }) => {
        state.editProductRiseLabelStatus = asyncStatus.SUCCEEDED;
        state.getProductRiseLabelStatus = asyncStatus.SUCCEEDED;
        state.editProductRiseLabelData = payload.data;
        const { getProductRiseLabelData } = state;
        let updatedArray = [];
        getProductRiseLabelData?.map((e) => {
          var item = e;
          if (item?.rise_label_id === payload?.data?.rise_label_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductRiseLabelData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductRiseLabelError = null;
      }
    );
    builder.addCase(editProductRiseLabelAsync.rejected, (state, actions) => {
      state.editProductRiseLabelData = null;
      state.editProductRiseLabelStatus = asyncStatus.ERROR;
      state.editProductRiseLabelError = actions?.error?.message;
    });

    builder.addCase(deleteProductRiseLabelAsync.pending, (state, action) => {
      state.deleteProductRiseLabelStatus = asyncStatus.LOADING;
      state.getProductRiseLabelStatus = asyncStatus.LOADING;
      state.deleteProductRiseLabelError = null;
      state.deleteProductRiseLabelData = null;
    });
    builder.addCase(
      deleteProductRiseLabelAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductRiseLabelStatus = asyncStatus.SUCCEEDED;
        state.getProductRiseLabelStatus = asyncStatus.SUCCEEDED;
        state.deleteProductRiseLabelData = payload.data;
        const { getProductRiseLabelData } = state;
        console.log("aaaaa", getProductRiseLabelData);
        let updatedArray = getProductRiseLabelData?.filter(
          (x) => x.rise_label_id !== payload.data.rise_label_id
        );
        state.getProductRiseLabelData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductRiseLabelError = null;
      }
    );
    builder.addCase(deleteProductRiseLabelAsync.rejected, (state, actions) => {
      state.deleteProductRiseLabelData = null;
      state.deleteProductRiseLabelStatus = asyncStatus.ERROR;
      state.deleteProductRiseLabelError = actions?.error?.message;
    });

    // Rise Label WORK
    // PRODUCT TYPES WORK

    builder.addCase(getProductProductTypeAsync.pending, (state, action) => {
      state.getProductProductTypeStatus = asyncStatus.LOADING;
      state.getProductProductTypeError = null;
      state.getProductProductTypeData = null;
    });
    builder.addCase(
      getProductProductTypeAsync.fulfilled,
      (state, { payload }) => {
        state.getProductProductTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductProductTypeData = payload.data;
        state.getProductProductTypeError = null;
      }
    );
    builder.addCase(getProductProductTypeAsync.rejected, (state, actions) => {
      state.getProductProductTypeData = null;
      state.getProductProductTypeStatus = asyncStatus.ERROR;
      state.getProductProductTypeError = actions?.error?.message;
    });

    builder.addCase(addProductProductTypeAsync.pending, (state, action) => {
      state.addProductProductTypeStatus = asyncStatus.LOADING;
      state.addProductProductTypeError = null;
      state.addProductProductTypeData = null;
    });
    builder.addCase(
      addProductProductTypeAsync.fulfilled,
      (state, { payload }) => {
        state.addProductProductTypeStatus = asyncStatus.SUCCEEDED;
        state.addProductProductTypeData = payload.data;
        // let { getProductProductTypeData } = state;
        state.getProductProductTypeData = [
          ...state.getProductProductTypeData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductProductTypeError = null;
      }
    );
    builder.addCase(addProductProductTypeAsync.rejected, (state, actions) => {
      state.addProductProductTypeData = null;
      state.addProductProductTypeStatus = asyncStatus.ERROR;
      state.addProductProductTypeError = actions?.error?.message;
    });

    builder.addCase(editProductProductTypeAsync.pending, (state, action) => {
      state.editProductProductTypeStatus = asyncStatus.LOADING;
      state.editProductProductTypeError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductProductTypeData = null;
    });
    builder.addCase(
      editProductProductTypeAsync.fulfilled,
      (state, { payload }) => {
        state.editProductProductTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductProductTypeStatus = asyncStatus.SUCCEEDED;
        state.editProductProductTypeData = payload.data;
        const { getProductProductTypeData } = state;
        let updatedArray = [];
        getProductProductTypeData?.map((e) => {
          var item = e;
          if (item?.product_type_id === payload?.data?.product_type_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductProductTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductProductTypeError = null;
      }
    );
    builder.addCase(editProductProductTypeAsync.rejected, (state, actions) => {
      state.editProductProductTypeData = null;
      state.editProductProductTypeStatus = asyncStatus.ERROR;
      state.editProductProductTypeError = actions?.error?.message;
    });

    builder.addCase(deleteProductProductTypeAsync.pending, (state, action) => {
      state.deleteProductProductTypeStatus = asyncStatus.LOADING;
      state.getProductProductTypeStatus = asyncStatus.LOADING;
      state.deleteProductProductTypeError = null;
      state.deleteProductProductTypeData = null;
    });
    builder.addCase(
      deleteProductProductTypeAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductProductTypeStatus = asyncStatus.SUCCEEDED;
        state.getProductProductTypeStatus = asyncStatus.SUCCEEDED;
        state.deleteProductProductTypeData = payload.data;
        const { getProductProductTypeData } = state;
        console.log("aaaaa", getProductProductTypeData);
        let updatedArray = getProductProductTypeData?.filter(
          (x) => x.product_type_id !== payload.data.product_type_id
        );
        state.getProductProductTypeData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductProductTypeError = null;
      }
    );
    builder.addCase(
      deleteProductProductTypeAsync.rejected,
      (state, actions) => {
        state.deleteProductProductTypeData = null;
        state.deleteProductProductTypeStatus = asyncStatus.ERROR;
        state.deleteProductProductTypeError = actions?.error?.message;
      }
    );

    // PRODUCT TYPES WORK
    // SEASON YEAR WORK

    builder.addCase(getProductSeasonYearAsync.pending, (state, action) => {
      state.getProductSeasonYearStatus = asyncStatus.LOADING;
      state.getProductSeasonYearError = null;
      state.getProductSeasonYearData = null;
    });
    builder.addCase(
      getProductSeasonYearAsync.fulfilled,
      (state, { payload }) => {
        state.getProductSeasonYearStatus = asyncStatus.SUCCEEDED;
        state.getProductSeasonYearData = payload.data;
        state.getProductSeasonYearError = null;
      }
    );
    builder.addCase(getProductSeasonYearAsync.rejected, (state, actions) => {
      state.getProductSeasonYearData = null;
      state.getProductSeasonYearStatus = asyncStatus.ERROR;
      state.getProductSeasonYearError = actions?.error?.message;
    });

    builder.addCase(addProductSeasonYearAsync.pending, (state, action) => {
      state.addProductSeasonYearStatus = asyncStatus.LOADING;
      state.addProductSeasonYearError = null;
      state.addProductSeasonYearData = null;
    });
    builder.addCase(
      addProductSeasonYearAsync.fulfilled,
      (state, { payload }) => {
        state.addProductSeasonYearStatus = asyncStatus.SUCCEEDED;
        state.addProductSeasonYearData = payload.data;
        // let { getProductSeasonYearData } = state;
        state.getProductSeasonYearData = [
          ...state.getProductSeasonYearData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductSeasonYearError = null;
      }
    );
    builder.addCase(addProductSeasonYearAsync.rejected, (state, actions) => {
      state.addProductSeasonYearData = null;
      state.addProductSeasonYearStatus = asyncStatus.ERROR;
      state.addProductSeasonYearError = actions?.error?.message;
    });

    builder.addCase(editProductSeasonYearAsync.pending, (state, action) => {
      state.editProductSeasonYearStatus = asyncStatus.LOADING;
      state.editProductSeasonYearError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductSeasonYearData = null;
    });
    builder.addCase(
      editProductSeasonYearAsync.fulfilled,
      (state, { payload }) => {
        state.editProductSeasonYearStatus = asyncStatus.SUCCEEDED;
        state.getProductSeasonYearStatus = asyncStatus.SUCCEEDED;
        state.editProductSeasonYearData = payload.data;
        const { getProductSeasonYearData } = state;
        let updatedArray = [];
        getProductSeasonYearData?.map((e) => {
          var item = e;
          if (item?.season_year_id === payload?.data?.season_year_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductSeasonYearData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductSeasonYearError = null;
      }
    );
    builder.addCase(editProductSeasonYearAsync.rejected, (state, actions) => {
      state.editProductSeasonYearData = null;
      state.editProductSeasonYearStatus = asyncStatus.ERROR;
      state.editProductSeasonYearError = actions?.error?.message;
    });

    builder.addCase(deleteProductSeasonYearAsync.pending, (state, action) => {
      state.deleteProductSeasonYearStatus = asyncStatus.LOADING;
      state.getProductSeasonYearStatus = asyncStatus.LOADING;
      state.deleteProductSeasonYearError = null;
      state.deleteProductSeasonYearData = null;
    });
    builder.addCase(
      deleteProductSeasonYearAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductSeasonYearStatus = asyncStatus.SUCCEEDED;
        state.getProductSeasonYearStatus = asyncStatus.SUCCEEDED;
        state.deleteProductSeasonYearData = payload.data;
        const { getProductSeasonYearData } = state;
        console.log("aaaaa", getProductSeasonYearData);
        let updatedArray = getProductSeasonYearData?.filter(
          (x) => x.season_year_id !== payload.data.season_year_id
        );
        state.getProductSeasonYearData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductSeasonYearError = null;
      }
    );
    builder.addCase(deleteProductSeasonYearAsync.rejected, (state, actions) => {
      state.deleteProductSeasonYearData = null;
      state.deleteProductSeasonYearStatus = asyncStatus.ERROR;
      state.deleteProductSeasonYearError = actions?.error?.message;
    });

    // SEASON YEAR WORK
    // PRODUCT CLASS WORK

    builder.addCase(getProductProductClassAsync.pending, (state, action) => {
      state.getProductProductClassStatus = asyncStatus.LOADING;
      state.getProductProductClassError = null;
      state.getProductProductClassData = null;
    });
    builder.addCase(
      getProductProductClassAsync.fulfilled,
      (state, { payload }) => {
        state.getProductProductClassStatus = asyncStatus.SUCCEEDED;
        state.getProductProductClassData = payload.data;
        state.getProductProductClassError = null;
      }
    );
    builder.addCase(getProductProductClassAsync.rejected, (state, actions) => {
      state.getProductProductClassData = null;
      state.getProductProductClassStatus = asyncStatus.ERROR;
      state.getProductProductClassError = actions?.error?.message;
    });

    builder.addCase(addProductProductClassAsync.pending, (state, action) => {
      state.addProductProductClassStatus = asyncStatus.LOADING;
      state.addProductProductClassError = null;
      state.addProductProductClassData = null;
    });
    builder.addCase(
      addProductProductClassAsync.fulfilled,
      (state, { payload }) => {
        state.addProductProductClassStatus = asyncStatus.SUCCEEDED;
        state.addProductProductClassData = payload.data;
        // let { getProductProductClassData } = state;
        state.getProductProductClassData = [
          ...state.getProductProductClassData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductProductClassError = null;
      }
    );
    builder.addCase(addProductProductClassAsync.rejected, (state, actions) => {
      state.addProductProductClassData = null;
      state.addProductProductClassStatus = asyncStatus.ERROR;
      state.addProductProductClassError = actions?.error?.message;
    });

    builder.addCase(editProductProductClassAsync.pending, (state, action) => {
      state.editProductProductClassStatus = asyncStatus.LOADING;
      state.editProductProductClassError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductProductClassData = null;
    });
    builder.addCase(
      editProductProductClassAsync.fulfilled,
      (state, { payload }) => {
        state.editProductProductClassStatus = asyncStatus.SUCCEEDED;
        state.getProductProductClassStatus = asyncStatus.SUCCEEDED;
        state.editProductProductClassData = payload.data;
        const { getProductProductClassData } = state;
        let updatedArray = [];
        getProductProductClassData?.map((e) => {
          var item = e;
          if (item?.product_class_id === payload?.data?.product_class_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductProductClassData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductProductClassError = null;
      }
    );
    builder.addCase(editProductProductClassAsync.rejected, (state, actions) => {
      state.editProductProductClassData = null;
      state.editProductProductClassStatus = asyncStatus.ERROR;
      state.editProductProductClassError = actions?.error?.message;
    });

    builder.addCase(deleteProductProductClassAsync.pending, (state, action) => {
      state.deleteProductProductClassStatus = asyncStatus.LOADING;
      state.getProductProductClassStatus = asyncStatus.LOADING;
      state.deleteProductProductClassError = null;
      state.deleteProductProductClassData = null;
    });
    builder.addCase(
      deleteProductProductClassAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductProductClassStatus = asyncStatus.SUCCEEDED;
        state.getProductProductClassStatus = asyncStatus.SUCCEEDED;
        state.deleteProductProductClassData = payload.data;
        const { getProductProductClassData } = state;
        console.log("aaaaa", getProductProductClassData);
        let updatedArray = getProductProductClassData?.filter(
          (x) => x.product_class_id !== payload.data.product_class_id
        );
        state.getProductProductClassData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductProductClassError = null;
      }
    );
    builder.addCase(
      deleteProductProductClassAsync.rejected,
      (state, actions) => {
        state.deleteProductProductClassData = null;
        state.deleteProductProductClassStatus = asyncStatus.ERROR;
        state.deleteProductProductClassError = actions?.error?.message;
      }
    );

    // PRODUCT CLASS WORK
    // PRODUCT STATUS

    builder.addCase(getProductProductStatusAsync.pending, (state, action) => {
      state.getProductProductStatusStatus = asyncStatus.LOADING;
      state.getProductProductStatusError = null;
      state.getProductProductStatusData = null;
    });
    builder.addCase(
      getProductProductStatusAsync.fulfilled,
      (state, { payload }) => {
        state.getProductProductStatusStatus = asyncStatus.SUCCEEDED;
        state.getProductProductStatusData = payload.data;
        state.getProductProductStatusError = null;
      }
    );
    builder.addCase(getProductProductStatusAsync.rejected, (state, actions) => {
      state.getProductProductStatusData = null;
      state.getProductProductStatusStatus = asyncStatus.ERROR;
      state.getProductProductStatusError = actions?.error?.message;
    });

    builder.addCase(addProductProductStatusAsync.pending, (state, action) => {
      state.addProductProductStatusStatus = asyncStatus.LOADING;
      state.addProductProductStatusError = null;
      state.addProductProductStatusData = null;
    });
    builder.addCase(
      addProductProductStatusAsync.fulfilled,
      (state, { payload }) => {
        state.addProductProductStatusStatus = asyncStatus.SUCCEEDED;
        state.addProductProductStatusData = payload.data;
        // let { getProductProductStatusData } = state;
        state.getProductProductStatusData = [
          ...state.getProductProductStatusData,
          payload.data,
        ];
        success_toast_message(payload.message);
        state.addProductProductStatusError = null;
      }
    );
    builder.addCase(addProductProductStatusAsync.rejected, (state, actions) => {
      state.addProductProductStatusData = null;
      state.addProductProductStatusStatus = asyncStatus.ERROR;
      state.addProductProductStatusError = actions?.error?.message;
    });

    builder.addCase(editProductProductStatusAsync.pending, (state, action) => {
      state.editProductProductStatusStatus = asyncStatus.LOADING;
      state.editProductProductStatusError = null;
      state.getProductStyleStatus = asyncStatus.LOADING;
      state.editProductProductStatusData = null;
    });
    builder.addCase(
      editProductProductStatusAsync.fulfilled,
      (state, { payload }) => {
        state.editProductProductStatusStatus = asyncStatus.SUCCEEDED;
        state.getProductProductStatusStatus = asyncStatus.SUCCEEDED;
        state.editProductProductStatusData = payload.data;
        const { getProductProductStatusData } = state;
        let updatedArray = [];
        getProductProductStatusData?.map((e) => {
          var item = e;
          if (item?.product_status_id === payload?.data?.product_status_id) {
            item = payload.data;
          }
          updatedArray.push(item);
        });
        console.log("updatedArray", updatedArray);
        state.getProductProductStatusData = [...updatedArray];
        success_toast_message(payload.message);
        state.editProductProductStatusError = null;
      }
    );
    builder.addCase(
      editProductProductStatusAsync.rejected,
      (state, actions) => {
        state.editProductProductStatusData = null;
        state.editProductProductStatusStatus = asyncStatus.ERROR;
        state.editProductProductStatusError = actions?.error?.message;
      }
    );

    builder.addCase(
      deleteProductProductStatusAsync.pending,
      (state, action) => {
        state.deleteProductProductStatusStatus = asyncStatus.LOADING;
        state.getProductProductStatusStatus = asyncStatus.LOADING;
        state.deleteProductProductStatusError = null;
        state.deleteProductProductStatusData = null;
      }
    );
    builder.addCase(
      deleteProductProductStatusAsync.fulfilled,
      (state, { payload }) => {
        state.deleteProductProductStatusStatus = asyncStatus.SUCCEEDED;
        state.getProductProductStatusStatus = asyncStatus.SUCCEEDED;
        state.deleteProductProductStatusData = payload.data;
        const { getProductProductStatusData } = state;
        console.log("aaaaa", getProductProductStatusData);
        let updatedArray = getProductProductStatusData?.filter(
          (x) => x.product_status_id !== payload.data.product_status_id
        );
        state.getProductProductStatusData = [...updatedArray];
        success_toast_message(payload.message);
        state.deleteProductProductStatusError = null;
      }
    );
    builder.addCase(
      deleteProductProductStatusAsync.rejected,
      (state, actions) => {
        state.deleteProductProductStatusData = null;
        state.deleteProductProductStatusStatus = asyncStatus.ERROR;
        state.deleteProductProductStatusError = actions?.error?.message;
      }
    );

    // PRODUCT STATUS

    // PRODUCT INFO

    // get gender category by id
    builder.addCase(
      getProductGenderCategoryByIdAsync.pending,
      (state, action) => {
        state.getGenderCategoryByIdStatus = asyncStatus.LOADING;
        state.getGenderCategoryByIdError = null;
        state.getGenderCategoryByIdData = null;
      }
    );
    builder.addCase(
      getProductGenderCategoryByIdAsync.fulfilled,
      (state, { payload }) => {
        state.getGenderCategoryByIdStatus = asyncStatus.SUCCEEDED;
        state.getGenderCategoryByIdData = payload.data;
        state.getGenderCategoryByIdError = null;
      }
    );
    builder.addCase(
      getProductGenderCategoryByIdAsync.rejected,
      (state, actions) => {
        state.getGenderCategoryByIdData = null;
        state.getGenderCategoryByIdStatus = asyncStatus.ERROR;
        state.getGenderCategoryByIdError = actions?.error?.message;
      }
    );

    // get gender category by id

    // get company

    builder.addCase(getProductCompanyAsync.pending, (state, action) => {
      state.getProductCompanyStatus = asyncStatus.LOADING;
      state.getProductCompanyError = null;
      state.getProductCompanyData = null;
    });
    builder.addCase(getProductCompanyAsync.fulfilled, (state, { payload }) => {
      state.getProductCompanyStatus = asyncStatus.SUCCEEDED;
      state.getProductCompanyData = payload.data;
      state.getProductCompanyError = null;
    });
    builder.addCase(getProductCompanyAsync.rejected, (state, actions) => {
      state.getProductCompanyData = null;
      state.getProductCompanyStatus = asyncStatus.ERROR;
      state.getProductCompanyError = actions?.error?.message;
    });

    // get company

    // get sizechart by gender category id

    builder.addCase(
      getProductSizeChartByGenderCategoryIdAsync.pending,
      (state, action) => {
        state.getProductSizeChartByGenderCategoryIdStatus = asyncStatus.LOADING;
        state.getProductSizeChartByGenderCategoryIdError = null;
        state.getProductSizeChartByGenderCategoryIdData = null;
      }
    );
    builder.addCase(
      getProductSizeChartByGenderCategoryIdAsync.fulfilled,
      (state, { payload }) => {
        state.getProductSizeChartByGenderCategoryIdStatus =
          asyncStatus.SUCCEEDED;
        state.getProductSizeChartByGenderCategoryIdData = payload.data;
        state.getProductSizeChartByGenderCategoryIdError = null;
      }
    );
    builder.addCase(
      getProductSizeChartByGenderCategoryIdAsync.rejected,
      (state, actions) => {
        state.getProductSizeChartByGenderCategoryIdData = null;
        state.getProductSizeChartByGenderCategoryIdStatus = asyncStatus.ERROR;
        state.getProductSizeChartByGenderCategoryIdError =
          actions?.error?.message;
      }
    );

    // get sizechart by gender category id

    // post Item

    builder.addCase(postItemAsync.pending, (state, action) => {
      state.postItemStatus = asyncStatus.LOADING;
      state.postItemError = null;
      state.postItemData = null;
    });
    builder.addCase(postItemAsync.fulfilled, (state, { payload }) => {
      state.postItemStatus = asyncStatus.SUCCEEDED;
      state.postItemData = payload.data;
      state.postItemError = null;
      success_toast_message(payload.message);
    });
    builder.addCase(postItemAsync.rejected, (state, actions) => {
      state.postItemData = null;
      state.postItemStatus = asyncStatus.ERROR;
      state.postItemError = actions?.error?.message;
    });

    // post Item

    // get Item

    builder.addCase(getItemAsync.pending, (state, action) => {
      state.getItemStatus = asyncStatus.LOADING;
      state.getItemError = null;
      state.getItemData = null;
    });
    builder.addCase(getItemAsync.fulfilled, (state, { payload }) => {
      state.getItemStatus = asyncStatus.SUCCEEDED;
      state.getItemData = payload.data;
      state.getItemError = null;
      // success_toast_message(payload.message)
    });
    builder.addCase(getItemAsync.rejected, (state, actions) => {
      state.getItemData = null;
      state.getItemStatus = asyncStatus.ERROR;
      state.getItemError = actions?.error?.message;
    });

    // get Item

    // get Item By Id

    builder.addCase(getItemByIdAsync.pending, (state, action) => {
      state.getItemByIdStatus = asyncStatus.LOADING;
      state.getItemByIdError = null;
      state.getItemByIdData = null;
    });
    builder.addCase(getItemByIdAsync.fulfilled, (state, { payload }) => {
      state.getItemByIdStatus = asyncStatus.SUCCEEDED;
      state.getItemByIdData = payload.data;
      state.getItemByIdError = null;
      // success_toast_message(payload.message)
    });
    builder.addCase(getItemByIdAsync.rejected, (state, actions) => {
      state.getItemByIdData = null;
      state.getItemByIdStatus = asyncStatus.ERROR;
      state.getItemByIdError = actions?.error?.message;
    });

    // get Item By Id

    // put Item By Id

    builder.addCase(putItemByIdAsync.pending, (state, action) => {
      state.putItemByIdStatus = asyncStatus.LOADING;
      state.putItemByIdError = null;
      state.putItemByIdData = null;
    });
    builder.addCase(putItemByIdAsync.fulfilled, (state, { payload }) => {
      state.putItemByIdStatus = asyncStatus.SUCCEEDED;
      state.putItemByIdData = payload.data;
      state.putItemByIdError = null;
      success_toast_message(payload.message);
    });
    builder.addCase(putItemByIdAsync.rejected, (state, actions) => {
      state.putItemByIdData = null;
      state.putItemByIdStatus = asyncStatus.ERROR;
      state.putItemByIdError = actions?.error?.message;
    });

    // put Item By Id
    // delete Item By Id

    builder.addCase(deleteItemByIdAsync.pending, (state, action) => {
      state.deleteItemByIdStatus = asyncStatus.LOADING;
      state.deleteItemByIdError = null;
      state.deleteItemByIdData = null;
    });
    builder.addCase(deleteItemByIdAsync.fulfilled, (state, { payload }) => {
      state.deleteItemByIdStatus = asyncStatus.SUCCEEDED;
      state.deleteItemByIdData = payload.data;
      state.deleteItemByIdError = null;
      success_toast_message(payload.message);
    });
    builder.addCase(deleteItemByIdAsync.rejected, (state, actions) => {
      state.deleteItemByIdData = null;
      state.deleteItemByIdStatus = asyncStatus.ERROR;
      state.deleteItemByIdError = actions?.error?.message;
    });

    // delete Item By Id
    // Get Combinations By Item Id

    builder.addCase(getCombinationsByItemIdAsync.pending, (state, action) => {
      state.getCombinationsByItemIdStatus = asyncStatus.LOADING;
      state.getCombinationsByItemIdError = null;
      state.getCombinationsByItemIdData = null;
    });
    builder.addCase(
      getCombinationsByItemIdAsync.fulfilled,
      (state, { payload }) => {
        state.getCombinationsByItemIdStatus = asyncStatus.SUCCEEDED;
        state.getCombinationsByItemIdData = payload.data;
        state.getCombinationsByItemIdError = null;
        // success_toast_message(payload.message)
      }
    );
    builder.addCase(getCombinationsByItemIdAsync.rejected, (state, actions) => {
      state.getCombinationsByItemIdData = null;
      state.getCombinationsByItemIdStatus = asyncStatus.ERROR;
      state.getCombinationsByItemIdError = actions?.error?.message;
    });

    // Get Combinations By Item Id

    // PRODUCT INFO

    ////LOCATION

    builder.addCase(getLocationAsync.pending, (state, action) => {
      state.getLocationStatus = asyncStatus.LOADING;
      state.getLocationError = null;
      state.getLocationData = null;
    });
    builder.addCase(getLocationAsync.fulfilled, (state, { payload }) => {
      state.getLocationStatus = asyncStatus.SUCCEEDED;
      state.getLocationData = payload.data;
      state.getLocationError = null;
      // success_toast_message(payload.message)
    });
    builder.addCase(getLocationAsync.rejected, (state, actions) => {
      state.getLocationData = null;
      state.getLocationStatus = asyncStatus.ERROR;
      state.getLocationError = actions?.error?.message;
    });
  },
});

export const { fabricStatusEdit, setItemByIdEmpty ,productInfoStatusEdit} = productsInfoSlice.actions;
export default productsInfoSlice.reducer;
