import { Grid, Stack, Typography, keyframes } from "@mui/material";
import React from "react";
import InputComp from "../common/InputComp/Input";
import Btn from "../common/ButtonComp/Btn";

const InputWithBtnLayoutComp = ({
  inputArray = [
    {
      label: "abc",
      comp: <InputComp />,
    },
    {
      label: "sads",
      comp: <InputComp />,
    },
  ],
  secondaryComp = <Btn />,
  error
}) => {
  return (
    <Stack>
      <Grid container rowGap={2}>
        <Grid
        item
          xl={6}
          lg={6}
          md={9}
          sm={12}
          xs={12}
          sx={{ display: "flex", gap: 2, flexDirection: "column" }}
        >
          {inputArray?.length &&
            inputArray?.map((e,i) => (
              <Grid
                item
                container
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                alignItems={"center"
              }
              key={i}
              >
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Typography
                    sx={{ color: "gray", fontSize: 14 ,wordWrap:"break-word"}}
                  >
                    {e?.label}
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  {e?.comp}
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid
        item
          xl={6}
          lg={6}
          md={3}
          sm={12}
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
        >
          {secondaryComp}
        </Grid>
      </Grid>
      <Typography color={"red"} fontSize={12}>{error}</Typography>
    </Stack>
  );
};

export default InputWithBtnLayoutComp;
