import React, { useEffect, useState } from "react";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import {
  Box,
  Checkbox,
  Chip,
  // CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  // Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { primaryColor } from "../../../../utils/themeColors";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoFormComp from "../../../../components/InfoForm/InfoFormComp";
import InputComp from "../../../../components/common/InputComp/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductCutAsync,
  addProductFabricClassAsync,
  addProductFabricCompositionAsync,
  addProductFitCategoryAsync,
  addProductGenderAsync,
  addProductGenderCategoryAsync,
  addProductInseamLabelAsync,
  addProductItemTypeAsync,
  addProductProductClassAsync,
  addProductProductStatusAsync,
  addProductProductTypeAsync,
  addProductRiseAsync,
  addProductRiseLabelAsync,
  addProductSeasonAsync,
  addProductSeasonYearAsync,
  addProductStyleAsync,
  addProductWashAsync,
  addProductWashTypeAsync,
  deleteItemByIdAsync,
  getCombinationsByItemIdAsync,
  getItemAsync,
  getItemByIdAsync,
  getProductCompanyAsync,
  getProductCutAsync,
  getProductFabricAsync,
  getProductFabricCompositionAsync,
  getProductFitCategoryAsync,
  getProductGenderAsync,
  getProductGenderCategoryAsync,
  getProductGenderCategoryByIdAsync,
  getProductInseamLabelAsync,
  getProductItemTypeAsync,
  getProductProductClassAsync,
  getProductProductStatusAsync,
  getProductProductTypeAsync,
  getProductRiseAsync,
  getProductRiseLabelAsync,
  getProductSeasonAsync,
  getProductSeasonYearAsync,
  getProductSizeChartByGenderCategoryIdAsync,
  getProductStyleAsync,
  getProductWashAsync,
  getProductWashTypeAsync,
  getProductinseamAsync,
  getProductsizeAsync,
  postItemAsync,
  putItemByIdAsync,
} from "../../../../store/services/productInfoServices";
import { asyncStatus } from "../../../../utils/asyncStatus";
import Btn from "../../../../components/common/ButtonComp/Btn";
import TabComp from "../../../../components/TabsComp/TabComp";
import TableComp from "../../../../components/common/TableComp/TableComp";
import VirtualizedSelect from "../../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
import { productInfoStatusEdit, setItemByIdEmpty } from "../../../../store/slices/productInfoSlice";
import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../../components/common/Modal/CustomModal";

const ProductInformation = () => {
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [addProductInformationMode, setAddProductInformationMode] =
    useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [open, setOpen] = useState(false);
  const {
    getProductStyleStatus,
    getProductStyleError,
    getProductStyleData,
    getProductGenderStatus,
    getProductGenderData,
    getProductGenderError,
    getProductGenderCategoryStatus,
    getProductGenderCategoryError,
    getProductGenderCategoryData,
    getProductCutStatus,
    getProductCutError,
    getProductCutData,
    getProductRiseStatus,
    getProductRiseError,
    getProductRiseData,
    getProductRiseLabelStatus,
    getProductRiseLabelError,
    getProductRiseLabelData,
    getProductFitCategoryStatus,
    getProductFitCategoryError,
    getProductFitCategoryData,
    getProductWashStatus,
    getProductWashError,
    getProductWashData,
    getProductWashTypeStatus,
    getProductWashTypeError,
    getProductWashTypeData,
    getProductFabricStatus,
    getProductFabricError,
    getProductFabricData,
    getProductFabricCompositionStatus,
    getProductFabricCompositionError,
    getProductFabricCompositionData,
    getProductSeasonStatus,
    getProductSeasonError,
    getProductSeasonData,
    getProductSeasonYearStatus,
    getProductSeasonYearError,
    getProductSeasonYearData,
    getProductInseamLabelStatus,
    getProductInseamLabelError,
    getProductInseamLabelData,
    getProductProductClassStatus,
    getProductProductClassError,
    getProductProductClassData,
    getGenderCategoryByIdStatus,
    getGenderCategoryByIdError,
    getGenderCategoryByIdData,
    getProductItemTypeStatus,
    getProductItemTypeError,
    getProductItemTypeData,
    getProductProductStatusStatus,
    getProductProductStatusError,
    getProductProductStatusData,
    getProductCompanyStatus,
    getProductCompanyData,
    getProductSizeChartByGenderCategoryIdStatus,
    getProductSizeChartByGenderCategoryIdError,
    getProductSizeChartByGenderCategoryIdData,
    getProductinseamStatus,
    getProductinseamError,
    getProductinseamData,
    getProductsizeData,
    getProductProductTypeStatus,
    getProductProductTypeError,
    getProductProductTypeData,
    postItemStatus,
    postItemError,
    postItemData,
    getItemStatus,
    getItemError,
    getItemData,
    getItemByIdStatus,
    getItemByIdError,
    getItemByIdData,
    putItemByIdStatus,
    deleteItemByIdStatus,
    getCombinationsByItemIdStatus,
    getCombinationsByItemIdError,
    getCombinationsByItemIdData,




    addProductStyleStatus,
    addProductWashTypeStatus,
    addProductWashStatus,
    addProductFitCategoryStatus,
    addProductRiseLabelStatus,
    addProductRiseStatus,
    addProductCutStatus,
    addProductGenderCategoryStatus,
    addProductGenderStatus,
    addProductProductTypeStatus,
    addProductProductClassStatus,
    addProductInseamLabelStatus,
    addProductSeasonYearStatus,
    addProductSeasonStatus,
    addProductFabricCompositionStatus,
    addProductProductStatusStatus,
    addProductItemTypeStatus

  } = useSelector((state) => state.product);

  const [productInfoData, setProductInfoData] = useState({});
  const [combinations, setCombinations] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductStyleAsync());
    dispatch(getProductGenderAsync());
    dispatch(getProductGenderCategoryAsync());
    dispatch(getProductCutAsync());
    dispatch(getProductRiseAsync());
    dispatch(getProductRiseLabelAsync());
    dispatch(getProductFitCategoryAsync());
    dispatch(getProductWashAsync());
    dispatch(getProductWashTypeAsync());
    dispatch(getProductFabricAsync());
    dispatch(getProductFabricCompositionAsync());
    dispatch(getProductSeasonAsync());
    dispatch(getProductSeasonYearAsync());
    dispatch(getProductInseamLabelAsync());
    dispatch(getProductProductClassAsync());
    dispatch(getProductProductTypeAsync());
    dispatch(getProductItemTypeAsync());
    dispatch(getProductProductStatusAsync());
    dispatch(getProductCompanyAsync());
    dispatch(getProductinseamAsync());
    dispatch(getProductsizeAsync());
  }, []);
  const [sizes, setSizes] = useState([]);
  const [inseam, setInseam] = useState([]);
  const [createItem, setCreateItem] = useState();
  const handleChangeSize = (event) => {
    const {
      target: { value },
    } = event;
    setSizes(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeInseam = (event) => {
    const {
      target: { value },
    } = event;
    setInseam(typeof value === "string" ? value.split(",") : value);
  };
  const generateCombinations = () => {
    console.log(getProductinseamData);
    console.log(getProductsizeData);
    console.log(inseam);
    console.log(sizes);
    // Filter inseams and sizes based on conditions
    const filteredInseams = getProductinseamData?.filter((singleInseam) =>
      inseam?.includes(singleInseam.inseam_name)
    );
    const filteredSizes = getProductsizeData?.filter((singleSize) =>
      sizes?.includes(singleSize.size_name)
    );

    // Function to generate combinations
    const generate = (inseams, sizes) => {
      const combinations = [];

      // Iterate through each inseam using for loop
      for (let i = 0; i < inseams.length; i++) {
        const inseam = inseams[i];

        // Iterate through each size using for loop
        for (let j = 0; j < sizes.length; j++) {
          const size = sizes[j];

          // Generate item code
          const itemCode = `${productInfoData?.item_code}-${inseam?.inseam_name}-${size?.size_name}`;

          // Create combination object
          const combination = {
            item_code: itemCode,
            inseam_id: inseam?.inseam_id,
            inseam: inseam?.inseam_name,
            size_id: size?.size_id,
            size: size?.size_name,
            upc_code: null,
            nrf_color_code: null,
            nrf_size_code: null,
            is_deleted: 0,
            company_id: productInfoData?.company_id,
            fabric_composition_id: productInfoData?.fabric_composition_id,
            is_active: false,
          };

          // Push combination to combinations array
          combinations.push(combination);
        }
      }

      return combinations;
    };

    // Generate combinations
    let combinations = generate(filteredInseams, filteredSizes);

    // Add an 'id' property to each combination
    combinations = combinations.map((combination, index) => ({
      ...combination,
      id: index + 1,
    }));

    // Set combinations state
    setCombinations(combinations);
  };

  const [searchMode, setSearchMode] = useState(false);
  const [currentItemId, setCurrentItemId] = useState("");

  useEffect(() => {
    if (searchMode) {
      dispatch(getItemAsync());
    }
  }, [searchMode]);
  const formattedData = getItemData?.length
    ? getItemData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  useEffect(() => {
    if (getItemByIdStatus === asyncStatus.SUCCEEDED) {
      setProductInfoData(getItemByIdData);
      dispatch(getProductGenderCategoryByIdAsync(getItemByIdData?.gender_id));

      if (getItemByIdData?.size?.includes(",")) {
        setSizes(getItemByIdData?.size?.split(","));
      } else {
        setSizes([getItemByIdData?.size]);
      }
      if (getItemByIdData?.inseam?.includes(",")) {
        setInseam(getItemByIdData?.inseam?.split(","));
      } else {
        setInseam([getItemByIdData?.inseam]);
      }
      // generateCombinations();
    }
  }, [getItemByIdStatus]);

  useEffect(() => {
    if (productInfoData?.gender_category_id) {
      dispatch(
        getProductSizeChartByGenderCategoryIdAsync(
          productInfoData?.gender_category_id
        )
      );
    }
  }, [productInfoData?.gender_category_id]);
  useEffect(() => {
    if (putItemByIdStatus === asyncStatus.SUCCEEDED) {
      setEditMode(false);
    }
  }, [putItemByIdStatus]);

  useEffect(() => {
    if (deleteItemByIdStatus === asyncStatus.SUCCEEDED) {
      setCurrentItemId("");
      setProductInfoData({});
      setSizes([]);
      setInseam([]);
      document.getElementById("productForm").reset();
    }
  }, [deleteItemByIdStatus]);
  useEffect(() => {
    if (postItemStatus === asyncStatus.SUCCEEDED) {
      setCurrentItemId("");
      setProductInfoData({});
      setSizes([]);
      setInseam([]);
      document.getElementById("productForm").reset();
    }
  }, [postItemStatus]);
  const history = useNavigate();
  let itemId = useParams();
  // console.log("productInfoData", productInfoData);
  useEffect(() => {
    if (itemId["*"]) {
      dispatch(getItemByIdAsync(itemId["*"]));
      dispatch(getCombinationsByItemIdAsync(itemId["*"]));
    }
  }, [itemId]);
  useEffect(() => {
    if (getCombinationsByItemIdStatus === asyncStatus.SUCCEEDED) {
      let combination = getCombinationsByItemIdData?.map((e) => {
        return {
          item_code: e?.item_code,
          inseam_id: e?.e_id,
          inseam: e?.inseam_name,
          size_id: e?.size_id,
          size: e?.size,
          upc_code: e?.upc_code,
          nrf_color_code: e?.nrf_color_code,
          nrf_color_code: e?.nrf_color_code,
          is_deleted: e?.is_deleted,
          company_id: e?.company_id,
          fabric_composition_id: e?.fabric_composition_id,
          is_active: e?.is_active,
        };
      });
      combination = combination?.map((e, ind) => ({ ...e, id: ind + 1 }));
      console.log("combine", combination);
      setCombinations(combination);
      setProductInfoData({ ...productInfoData, random: combination });
    }
  }, [getCombinationsByItemIdStatus]);

  console.log(productInfoData);
  const [selectedAddMoreFieldData, setSelectedAddMoreFieldData] = useState({
    is_active: true,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAddMoreFieldName, setSelectedAddMoreFieldName] = useState("");

  const handleModalOpen = (e) => {
    console.log("e", e);
    setSelectedAddMoreFieldName(e);
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);
  const addOnChangeHandleForFields = (e) => {
    const { name, value } = e.target;
    // style_name
    // fabric_name
    // wash_type_name
    // wash_name
    // fit_category_name
    // rise_label_name
    // rise_name
    // cut_name
    // gender_category_name
    // gender_name
    // product_type_name
    // product_class_name
    // inseam_label_name
    // season_year
    // season_name
    // fabric_composition_name
    // product_status_name
    // item_type_name
    //
    //

    let obj;
    switch (name) {
      case "style_name":
        obj = {
          style_name: value,
        };
        break;
      case "fabric_name":
        obj = {
          fabric_name: value,
        };
        break;
      case "wash_type_name":
        obj = {
          wash_type_name: value,
        };
        break;
      case "wash_name":
        obj = {
          wash_name: value,
        };
        break;
      case "fit_category_name":
        obj = {
          fit_category_name: value,
        };
        break;
      case "rise_label_name":
        obj = {
          rise_label_name: value,
        };
        break;
      case "rise_name":
        obj = {
          rise_name: value,
        };
        break;
      case "cut_name":
        obj = {
          cut_name: value,
        };
        break;
      case "gender_category_name":
        obj = {
          gender_category_name: value,
        };
        break;
      case "gender_name":
        obj = {
          gender_name: value,
        };
        break;
      case "product_type_name":
        obj = {
          product_type_name: value,
        };
        break;
      case "product_class_name":
        obj = {
          product_class_name: value,
        };
        break;
      case "inseam_label_name":
        obj = {
          inseam_label_name: value,
        };
        break;
      case "season_year":
        obj = {
          season_year: value,
        };
        break;
      case "season_name":
        obj = {
          season_name: value,
        };
        break;
      case "fabric_composition_name":
        obj = {
          composition_name: value,
        };
        break;
      case "product_status_name":
        obj = {
          product_status_name: value,
        };
        break;
      case "item_type_name":
        obj = {
          item_type_name: value,
        };
        break;
    }
    setSelectedAddMoreFieldData((prev) => ({
      is_active: prev?.is_active,
      ...obj
    }));
  };
console.log("current",selectedAddMoreFieldData);
  const addField = () => {

    switch (selectedAddMoreFieldName) {
      case "style_name":
      dispatch(addProductStyleAsync(selectedAddMoreFieldData))
        break;
      case "fabric_name":
      // dispatch(addfabric)
        break;
      case "wash_type_name":
      dispatch(addProductWashTypeAsync(selectedAddMoreFieldData))
        break;
      case "wash_name":
      dispatch(addProductWashAsync(selectedAddMoreFieldData))
        break;
      case "fit_category_name":
      dispatch(addProductFitCategoryAsync(selectedAddMoreFieldData))
        break;
      case "rise_label_name":
      dispatch(addProductRiseLabelAsync(selectedAddMoreFieldData))
        break;
      case "rise_name":
      dispatch(addProductRiseAsync(selectedAddMoreFieldData))
        break;
      case "cut_name":
      dispatch(addProductCutAsync(selectedAddMoreFieldData))
        break;
      case "gender_category_name":
      dispatch(addProductGenderCategoryAsync(selectedAddMoreFieldData))
        break;
      case "gender_name":
      dispatch(addProductGenderAsync(selectedAddMoreFieldData))
        break;
      case "product_type_name":
      dispatch(addProductProductTypeAsync(selectedAddMoreFieldData))
        break;
      case "product_class_name":
      dispatch(addProductProductClassAsync(selectedAddMoreFieldData))
        break;
      case "inseam_label_name":
      dispatch(addProductInseamLabelAsync(selectedAddMoreFieldData))
        break;
      case "season_year":
      dispatch(addProductSeasonYearAsync(selectedAddMoreFieldData))
        break;
      case "season_name":
      dispatch(addProductSeasonAsync(selectedAddMoreFieldData))
        break;
      case "fabric_composition_name":
      dispatch(addProductFabricCompositionAsync(selectedAddMoreFieldData))
        break;
      case "product_status_name":
      dispatch(addProductProductStatusAsync(selectedAddMoreFieldData))
        break;
      case "item_type_name":
      dispatch(addProductItemTypeAsync(selectedAddMoreFieldData))
        break;
      }
    // if (selectedAddMoreFieldName === "fabric_class_id1") {
    //   dispatch(addProductFabricClassAsync(selectedAddMoreFieldData));
    // } else if (selectedAddMoreFieldName === "fabric_class_id2") {
    //   dispatch(addProductFabricClassTwoAsync(selectedAddMoreFieldData));
    // } else if (selectedAddMoreFieldName === "fabric_type_id") {
    //   dispatch(addProductFabricTypeAsync(selectedAddMoreFieldData));
    // } else if (selectedAddMoreFieldName === "fabric_useable_id") {
    //   dispatch(addProductFabricUsableAsync(selectedAddMoreFieldData));
    // } else if (selectedAddMoreFieldName === "fabric_composition_id") {
    //   dispatch(addProductFabricCompositionAsync(selectedAddMoreFieldData));
    // }
  };


  useEffect(() => {
    if (
      addProductStyleStatus===asyncStatus.SUCCEEDED||
      addProductWashTypeStatus===asyncStatus.SUCCEEDED||
      addProductWashStatus===asyncStatus.SUCCEEDED||
      addProductFitCategoryStatus===asyncStatus.SUCCEEDED||
      addProductRiseLabelStatus===asyncStatus.SUCCEEDED||
      addProductRiseStatus===asyncStatus.SUCCEEDED||
      addProductCutStatus===asyncStatus.SUCCEEDED||
      addProductGenderCategoryStatus===asyncStatus.SUCCEEDED||
      addProductGenderStatus===asyncStatus.SUCCEEDED||
      addProductProductTypeStatus===asyncStatus.SUCCEEDED||
      addProductProductClassStatus===asyncStatus.SUCCEEDED||
      addProductInseamLabelStatus===asyncStatus.SUCCEEDED||
      addProductSeasonYearStatus===asyncStatus.SUCCEEDED||
      addProductSeasonStatus===asyncStatus.SUCCEEDED||
      addProductFabricCompositionStatus===asyncStatus.SUCCEEDED||
      addProductProductStatusStatus===asyncStatus.SUCCEEDED||
      addProductItemTypeStatus===asyncStatus.SUCCEEDED
    ) {
      dispatch(productInfoStatusEdit(asyncStatus.IDLE));
      setModalOpen(false);
    }
  }, [
    addProductStyleStatus,
    addProductWashTypeStatus,
    addProductWashStatus,
    addProductFitCategoryStatus,
    addProductRiseLabelStatus,
    addProductRiseStatus,
    addProductCutStatus,
    addProductGenderCategoryStatus,
    addProductGenderStatus,
    addProductProductTypeStatus,
    addProductProductClassStatus,
    addProductInseamLabelStatus,
    addProductSeasonYearStatus,
    addProductSeasonStatus,
    addProductFabricCompositionStatus,
    addProductProductStatusStatus,
    addProductItemTypeStatus
  ]);


console.log("=============================",sizes);
  return (
    <Stack
      padding={2}
      component={"form"}
      id="productForm"
      onSubmit={(event) => {
        event.preventDefault();

        if (editMode) {
          let obj = productInfoData;
          delete obj.item_id;
          dispatch(putItemByIdAsync({ id: currentItemId, obj }));
        } else {
          dispatch(
            postItemAsync({
              ...productInfoData,
              random: combinations,
              is_active: true,
              inseam: inseam?.join(","),
              size: sizes?.join(","),
              image_url: null,
            })
          );
        }
      }}
    >
      <CustomModal
        handleOpen={handleModalOpen}
        handleClose={handleModalClose}
        open={modalOpen}
      >
        <Stack>
          <Stack padding={2}>
            <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
              Add Field
            </Typography>
            <InputComp
              style={{
                borderRadius: 5,
                fontSize: 12,
                padding: 4,
                fontSize: 16,
              }}
              placeholder="Field Name"
              name={selectedAddMoreFieldName}
              onChange={addOnChangeHandleForFields}
            />
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={14}>Is Active</Typography>
              <Checkbox
                defaultChecked
                defaultValue={true}
                onChange={(e) =>
                  setSelectedAddMoreFieldData({
                    ...selectedAddMoreFieldData,
                    is_active: e.target.checked,
                  })
                }
              />
            </Stack>
            <Btn
              loading={
                addProductStyleStatus===asyncStatus.LOADING||
    addProductWashTypeStatus===asyncStatus.LOADING||
    addProductWashStatus===asyncStatus.LOADING||
    addProductFitCategoryStatus===asyncStatus.LOADING||
    addProductRiseLabelStatus===asyncStatus.LOADING||
    addProductRiseStatus===asyncStatus.LOADING||
    addProductCutStatus===asyncStatus.LOADING||
    addProductGenderCategoryStatus===asyncStatus.LOADING||
    addProductGenderStatus===asyncStatus.LOADING||
    addProductProductTypeStatus===asyncStatus.LOADING||
    addProductProductClassStatus===asyncStatus.LOADING||
    addProductInseamLabelStatus===asyncStatus.LOADING||
    addProductSeasonYearStatus===asyncStatus.LOADING||
    addProductSeasonStatus===asyncStatus.LOADING||
    addProductFabricCompositionStatus===asyncStatus.LOADING||
    addProductProductStatusStatus===asyncStatus.LOADING||
    addProductItemTypeStatus===asyncStatus.LOADING
              }
              onClick={addField}
            >
              Add
            </Btn>
          </Stack>
        </Stack>
      </CustomModal>
      <ScreenCard
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              paddingBottom: 0,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Product Information
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setSearchMode((prev) => !prev);
                  setAddMode(false);
                  setEditMode(false);
                  history("/productInfo");
                }}
              >
                {!searchMode ? (
                  <SearchIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setEditMode(false);
                  setAddMode((prev) => !prev);
                  dispatch(setItemByIdEmpty());
                  setCurrentItemId("");
                  setProductInfoData({});
                  setSizes([]);
                  setInseam([]);
                  setCombinations([]);
                  document.getElementById("productForm").reset();
                  history("/productInfo");
                  // setAddProductInformationMode((prev) => !prev);
                  // setSaveDisabled(false);
                }}
                disabled={searchMode}
              >
                {!addMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={!getItemByIdData || searchMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  // setAddProductInformationMode(false);
                  setEditMode((prev) => !prev);
                  setAddMode(false);
                  // setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={
                  addProductInformationMode || searchMode || !currentItemId
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  dispatch(deleteItemByIdAsync(currentItemId));
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                // disabled={
                //   addProductStyleStatus === asyncStatus.LOADING ||
                //   editProductStyleStatus === asyncStatus.LOADING ||
                //   saveDisabled
                // }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                // onClick={() => {
                //   if (addStyleData) {
                //     dispatch(addProductStyleAsync(addStyleData));
                //   } else if (editMode) {
                //     let a;
                //     a = { ...selectedStyle, ...editData };
                //     delete a.id;
                //     dispatch(editProductStyleAsync(a));
                //   }
                // }}
                // onClick={()=>{
                //   console.log("submit", { ...productInfoData, random: combinations });
                // }}
                type="submit"
                disabled={postItemStatus === asyncStatus.LOADING || searchMode}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        {searchMode ? (
          <Stack>
            <InfoFormComp
              // fourColumns
              title="Product List"
              customChild={
                <Stack padding={2}>
                  <Stack height={600} width={"100%"}>
                    <TableComp
                      onRowClick={(e) => {
                        console.log("aaaaaaaaaaaaaaa", e.row);
                        history(`/productInfo/${e?.row?.item_id}`);
                        setCurrentItemId(e?.row?.item_id);
                        setSearchMode(false);
                      }}
                      columns={[
                        {
                          field: "item_code",
                          headerName: "SKU",
                          flex: true,
                        },
                        {
                          field: "style_name",
                          headerName: "Style/Fit",
                          flex: true,
                        },
                        {
                          field: "cut_name",
                          headerName: "Cut",
                          flex: true,
                        },
                        {
                          field: "wash_name",
                          headerName: "Wash",
                          flex: true,
                        },
                        {
                          field: "wash_type_name",
                          headerName: "Wash Type",
                          flex: true,
                        },
                        {
                          field: "fabric_name",
                          headerName: "Fabric",
                          flex: true,
                        },
                        {
                          field: "gender_name",
                          headerName: "Gender",
                          flex: true,
                        },
                        {
                          field: "product_status_name",
                          headerName: "Product Status",
                          flex: true,
                        },
                        {
                          field: "is_active",
                          headerName: "Active",
                          flex: true,
                        },
                        {
                          field: "rate",
                          headerName: "Retail Price",
                          flex: true,
                        },
                        {
                          field: "cost_price",
                          headerName: "Cost Price",
                          flex: true,
                        },
                        {
                          field: "item_id",
                          headerName: "Item Id",
                          flex: true,
                        },
                      ]}
                      rows={formattedData}
                      loading={
                        getItemStatus === asyncStatus.IDLE ||
                        getItemStatus === asyncStatus.LOADING
                      }
                    />
                  </Stack>
                </Stack>
              }
            />
          </Stack>
        ) : (
          <Stack>
            <InfoFormComp
              customColumns={4}
              title="Primary Information"
              fieldsArray={[
                {
                  label: "Company :",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getProductCompanyData?.length && getProductCompanyData
                      }
                      value={
                        getProductCompanyData?.find(
                          (x) => productInfoData?.company_id === x?.company_id
                        ) || ""
                      }
                      disabled={
                        getProductCompanyStatus === asyncStatus.IDLE ||
                        getProductCompanyStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.company_id) {
                          setProductInfoData({
                            ...productInfoData,
                            company_id: e?.company_id,
                          });
                        }
                      }}
                      name={"company_name"}
                      id_key_name={"company_id"}
                    />
                  ),
                },
                {
                  label: "SKU :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setProductInfoData({
                          ...productInfoData,
                          item_code: e.target.value,
                        })
                      }
                      value={productInfoData?.item_code}
                      disabled={!(addMode || editMode)}
                      name={"item_code"}
                    />
                  ),
                },
                {
                  label: "Style :",
                  comp: (
                    <VirtualizedSelect
                      addOption
                      addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("style_name");
                      }}
                      required={true}
                      options={
                        getProductStyleData?.length && getProductStyleData
                      }
                      value={
                        getProductStyleData?.find(
                          (x) => productInfoData?.style_id === x?.style_id
                        ) || ""
                      }
                      disabled={
                        getProductStyleStatus === asyncStatus.IDLE ||
                        getProductStyleStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.style_id) {
                          setProductInfoData({
                            ...productInfoData,
                            style_id: e?.style_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"style_name"}
                      id_key_name={"style_id"}
                    />
                  ),
                },
                {
                  label: "Gender :",
                  comp: (
                    <VirtualizedSelect
                    addOption
                    addOptionLoading={addMode || editMode}
                    onAddClick={() => {
                      handleModalOpen("gender_name");
                    }}
                      required={true}
                      options={
                        getProductGenderData?.length && getProductGenderData
                      }
                      value={
                        getProductGenderData?.find(
                          (x) => productInfoData?.gender_id === x?.gender_id
                        ) || ""
                      }
                      disabled={
                        getProductGenderStatus === asyncStatus.IDLE ||
                        getProductGenderStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      // sx={{
                      //   padding: "0px 0px !important",
                      //   height: "auto",
                      //   fontSize: 14,
                      //   "& .MuiSelect-select": {
                      //     padding: "1px",
                      //   },
                      // }}
                      onChange={(e) => {
                        if (e?.gender_id) {
                          dispatch(
                            getProductGenderCategoryByIdAsync(e?.gender_id)
                          );
                          setProductInfoData({
                            ...productInfoData,
                            gender_id: e?.gender_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"gender_name"}
                      id_key_name={"gender_id"}
                    />
                  ),
                },
                {
                  label: "Gender Category :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("gender_category_name");
                      }}
                      required={true}
                      options={
                        getGenderCategoryByIdData?.length &&
                        getGenderCategoryByIdData
                      }
                      value={
                        getGenderCategoryByIdData?.find(
                          (x) =>
                            productInfoData?.gender_category_id ===
                            x?.gender_category_id
                        ) || ""
                      }
                      disabled={
                        getGenderCategoryByIdStatus === asyncStatus.IDLE ||
                        getGenderCategoryByIdStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.gender_category_id) {
                          setProductInfoData({
                            ...productInfoData,
                            gender_category_id: e?.gender_category_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"gender_category_name"}
                      id_key_name={"gender_category_id"}
                    />
                  ),
                },
                {
                  label: "Fit/Cut :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("cut_name");
                      }}
                      required={true}
                      options={getProductCutData?.length && getProductCutData}
                      value={
                        getProductCutData?.find(
                          (x) => productInfoData?.cut_id === x?.cut_id
                        ) || ""
                      }
                      disabled={
                        getProductCutStatus === asyncStatus.IDLE ||
                        getProductCutStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.cut_id) {
                          setProductInfoData({
                            ...productInfoData,
                            cut_id: e?.cut_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"cut_name"}
                      id_key_name={"cut_id"}
                    />
                  ),
                },
                {
                  label: "Rise :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("rise_name");
                      }}
                      required={true}
                      options={getProductRiseData?.length && getProductRiseData}
                      value={
                        getProductRiseData?.find(
                          (x) => productInfoData?.rise_id === x?.rise_id
                        ) || ""
                      }
                      disabled={
                        getProductRiseStatus === asyncStatus.IDLE ||
                        getProductRiseStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.rise_id) {
                          setProductInfoData({
                            ...productInfoData,
                            rise_id: e?.rise_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"rise_name"}
                      id_key_name={"rise_id"}
                    />
                  ),
                },
                {
                  label: "Rise Label :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("rise_label_name");
                      }}
                      required={true}
                      options={
                        getProductRiseLabelData?.length &&
                        getProductRiseLabelData
                      }
                      value={
                        getProductRiseLabelData?.find(
                          (x) =>
                            productInfoData?.rise_label_id === x?.rise_label_id
                        ) || ""
                      }
                      disabled={
                        getProductRiseLabelStatus === asyncStatus.IDLE ||
                        getProductRiseLabelStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.rise_label_id) {
                          setProductInfoData({
                            ...productInfoData,
                            rise_label_id: e?.rise_label_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"rise_label_name"}
                      id_key_name={"rise_label_id"}
                    />
                  ),
                },
                {
                  label: "Fit Category :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("fit_category_name");
                      }}
                      required={true}
                      options={
                        getProductFitCategoryData?.length &&
                        getProductFitCategoryData
                      }
                      value={
                        getProductFitCategoryData?.find(
                          (x) =>
                            productInfoData?.fit_category_id ===
                            x?.fit_category_id
                        ) || ""
                      }
                      disabled={
                        getProductFitCategoryStatus === asyncStatus.IDLE ||
                        getProductFitCategoryStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.fit_category_id) {
                          setProductInfoData({
                            ...productInfoData,
                            fit_category_id: e?.fit_category_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"fit_category_name"}
                      id_key_name={"fit_category_id"}
                    />
                  ),
                },
                {
                  label: "Wash :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("wash_name");
                      }}
                      required={true}
                      options={getProductWashData?.length && getProductWashData}
                      value={
                        getProductWashData?.find(
                          (x) => productInfoData?.wash_id === x?.wash_id
                        ) || ""
                      }
                      disabled={
                        getProductWashStatus === asyncStatus.IDLE ||
                        getProductWashStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.wash_id) {
                          setProductInfoData({
                            ...productInfoData,
                            wash_id: e?.wash_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"wash_name"}
                      id_key_name={"wash_id"}
                    />
                  ),
                },
                {
                  label: "Wash Type :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("wash_type_name");
                      }}
                      required={true}
                      options={
                        getProductWashTypeData?.length && getProductWashTypeData
                      }
                      value={
                        getProductWashTypeData?.find(
                          (x) =>
                            productInfoData?.wash_type_id === x?.wash_type_id
                        ) || ""
                      }
                      disabled={
                        getProductWashTypeStatus === asyncStatus.IDLE ||
                        getProductWashTypeStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.wash_type_id) {
                          setProductInfoData({
                            ...productInfoData,
                            wash_type_id: e?.wash_type_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"wash_type_name"}
                      id_key_name={"wash_type_id"}
                    />
                  ),
                },
                {
                  label: "Fabric :",
                  comp: (
                    <VirtualizedSelect
                    //  addOption
                    addOptionLoading={addMode || editMode}
                    //   onAddClick={() => {
                    //     handleModalOpen("fabric_name");
                    //   }}
                      required={true}
                      options={
                        getProductFabricData?.length && getProductFabricData
                      }
                      value={
                        getProductFabricData?.find(
                          (x) => productInfoData?.fabric_id === x?.fabric_id
                        ) || ""
                      }
                      disabled={
                        getProductFabricStatus === asyncStatus.IDLE ||
                        getProductFabricStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.fabric_id) {
                          setProductInfoData({
                            ...productInfoData,
                            fabric_id: e?.fabric_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"fabric_name"}
                      id_key_name={"fabric_id"}
                    />
                  ),
                },
                {
                  label: "Fabric Composition :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("fabric_composition_name");
                      }}
                      required={true}
                      options={
                        getProductFabricCompositionData?.length &&
                        getProductFabricCompositionData
                      }
                      value={
                        getProductFabricCompositionData?.find(
                          (x) =>
                            productInfoData?.fabric_composition_id ===
                            x?.fabric_composition_id
                        ) || ""
                      }
                      disabled={
                        getProductFabricCompositionStatus ===
                          asyncStatus.IDLE ||
                        getProductFabricCompositionStatus ===
                          asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.fabric_composition_id) {
                          setProductInfoData({
                            ...productInfoData,
                            fabric_composition_id: e?.fabric_composition_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"fabric_composition_name"}
                      id_key_name={"fabric_composition_id"}
                    />
                  ),
                },
                {
                  label: "Season :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("season_name");
                      }}
                      required={true}
                      options={
                        getProductSeasonData?.length && getProductSeasonData
                      }
                      value={
                        getProductSeasonData?.find(
                          (x) => productInfoData?.season_id === x?.season_id
                        ) || ""
                      }
                      disabled={
                        getProductSeasonStatus === asyncStatus.IDLE ||
                        getProductSeasonStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.season_id) {
                          setProductInfoData({
                            ...productInfoData,
                            season_id: e?.season_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"season_name"}
                      id_key_name={"season_id"}
                    />
                  ),
                },
                {
                  label: "Season Year :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("season_year");
                      }}
                      required={true}
                      options={
                        getProductSeasonYearData?.length &&
                        getProductSeasonYearData
                      }
                      value={
                        getProductSeasonYearData?.find(
                          (x) =>
                            productInfoData?.season_year === x?.season_year_id
                        ) || ""
                      }
                      disabled={
                        getProductSeasonYearStatus === asyncStatus.IDLE ||
                        getProductSeasonYearStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.season_year_id) {
                          setProductInfoData({
                            ...productInfoData,
                            season_year: e?.season_year_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"season_year"}
                      id_key_name={"season_year_id"}
                    />
                  ),
                },
                {
                  label: "New Season :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("season_name");
                      }}
                      required={true}
                      options={
                        getProductSeasonData?.length && getProductSeasonData
                      }
                      value={
                        getProductSeasonData?.find(
                          (x) => productInfoData?.new_season_id === x?.season_id
                        ) || ""
                      }
                      disabled={
                        getProductSeasonStatus === asyncStatus.IDLE ||
                        getProductSeasonStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.season_id) {
                          setProductInfoData({
                            ...productInfoData,
                            new_season_id: e?.season_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"season_name"}
                      id_key_name={"season_id"}
                    />
                  ),
                },
                {
                  label: "New Season Year",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("season_year");
                      }}
                      required={true}
                      options={
                        getProductSeasonYearData?.length &&
                        getProductSeasonYearData
                      }
                      value={
                        getProductSeasonYearData?.find(
                          (x) =>
                            productInfoData?.new_season_year ===
                            x?.season_year_id
                        ) || ""
                      }
                      disabled={
                        getProductSeasonYearStatus === asyncStatus.IDLE ||
                        getProductSeasonYearStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.season_year_id) {
                          setProductInfoData({
                            ...productInfoData,
                            new_season_year: e?.season_year_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"season_year"}
                      id_key_name={"season_year_id"}
                    />
                  ),
                },
                {
                  label: "Inseam Label :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("inseam_label_name");
                      }}
                      required={true}
                      options={
                        getProductInseamLabelData?.length &&
                        getProductInseamLabelData
                      }
                      value={
                        getProductInseamLabelData?.find(
                          (x) =>
                            productInfoData?.inseam_label_id ===
                            x?.inseam_label_id
                        ) || ""
                      }
                      disabled={
                        getProductInseamLabelStatus === asyncStatus.IDLE ||
                        getProductInseamLabelStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.inseam_label_id) {
                          setProductInfoData({
                            ...productInfoData,
                            inseam_label_id: e?.inseam_label_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"inseam_label_name"}
                      id_key_name={"inseam_label_id"}
                    />
                  ),
                },
                {
                  label: "Product Class :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("product_class_name");
                      }}
                      required={true}
                      options={
                        getProductProductClassData?.length &&
                        getProductProductClassData
                      }
                      value={
                        getProductProductClassData?.find(
                          (x) =>
                            productInfoData?.product_class_id ===
                            x?.product_class_id
                        ) || ""
                      }
                      disabled={
                        getProductProductClassStatus === asyncStatus.IDLE ||
                        getProductProductClassStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.product_class_id) {
                          setProductInfoData({
                            ...productInfoData,
                            product_class_id: e?.product_class_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"product_class_name"}
                      id_key_name={"product_class_id"}
                    />
                  ),
                },
                {
                  label: "Product Type :",
                  comp: (
                    <VirtualizedSelect
                     addOption
                     addOptionLoading={addMode || editMode}
                      onAddClick={() => {
                        handleModalOpen("product_type_name");
                      }}
                      required={true}
                      options={
                        getProductProductTypeData?.length &&
                        getProductProductTypeData
                      }
                      value={
                        getProductProductTypeData?.find(
                          (x) =>
                            productInfoData?.product_type_id ===
                            x?.product_type_id
                        ) || ""
                      }
                      disabled={
                        getProductProductTypeStatus === asyncStatus.IDLE ||
                        getProductProductTypeStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.product_type_id) {
                          setProductInfoData({
                            ...productInfoData,
                            product_type_id: e?.product_type_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"product_type_name"}
                      id_key_name={"product_type_id"}
                    />
                  ),
                },

                {
                  label: "Cost :",
                  comp: (
                    <InputComp
                      disabled={!(addMode || editMode)}
                      required={true}
                      type={"number"}
                      validator={(e) => {
                        if (
                          !/^(?![.]$|[.]$|$)\d+[.]?\d{0,6}$/.test(
                            e.target.value
                          )
                        )
                          return "Please enter correct amount.";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setProductInfoData({
                          ...productInfoData,
                          cost_price: e.target.value,
                        })
                      }
                      value={productInfoData?.cost_price}
                      name={"cost_price"}
                    />
                  ),
                },
                {
                  label: "Retail Price :",
                  comp: (
                    <InputComp
                      disabled={!(addMode || editMode)}
                      type={"number"}
                      required={true}
                      validator={(value) => {
                        if (
                          !/^(?![.,]$|[.,]$|$)\d+[,.]?\d{0,6}$/.test(
                            value.target.value
                          )
                        )
                          return "Please enter correct amount.";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setProductInfoData({
                          ...productInfoData,
                          rate: e.target.value,
                        })
                      }
                      value={productInfoData?.rate}
                      name={"rate"}
                    />
                  ),
                },
                {
                  label: "LDP Rate :",
                  comp: (
                    <InputComp
                      disabled={!(addMode || editMode)}
                      type={"number"}
                      required={true}
                      validator={(value) => {
                        if (
                          !/^(?![.,]$|[.,]$|$)\d+[,.]?\d{0,6}$/.test(
                            value.target.value
                          )
                        )
                          return "Please enter correct amount.";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setProductInfoData({
                          ...productInfoData,
                          ldp_rate: e.target.value,
                        })
                      }
                      value={productInfoData?.ldp_rate}
                      name={"ldp_rate"}
                    />
                  ),
                },
                {
                  label: "Status :",
                  comp: (
                    <VirtualizedSelect
                    addOption
                    addOptionLoading={addMode || editMode}
                    onAddClick={() => {
                      handleModalOpen("product_status_name");
                    }}
                      required={true}
                      options={
                        getProductProductStatusData?.length &&
                        getProductProductStatusData
                      }
                      value={
                        getProductProductStatusData?.find(
                          (x) =>
                            productInfoData?.product_status_id ===
                            x?.product_status_id
                        ) || ""
                      }
                      disabled={
                        getProductProductStatusStatus === asyncStatus.IDLE ||
                        getProductProductStatusStatus === asyncStatus.LOADING ||
                        !(addMode || editMode)
                      }
                      // sx={{
                      //   padding: "0px 0px !important",
                      //   height: "auto",
                      //   fontSize: 14,
                      //   "& .MuiSelect-select": {
                      //     padding: "1px",
                      //   },
                      // }}
                      onChange={(e) => {
                        if (e?.product_status_id) {
                          setProductInfoData({
                            ...productInfoData,
                            product_status_id: e?.product_status_id,
                          });
                        }
                        console.log("bbb", e);
                      }}
                      name={"product_status_name"}
                      id_key_name={"product_status_id"}
                    />
                  ),
                },
              ]}
              bottomChild={
                <>
                  <Grid
                    item
                    container
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ alignItems: "center" }}
                  >
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                      <Typography sx={{ fontSize: 14 }}>Description</Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} sm={6} xs={6}>
                      <InputComp
                        required={true}
                        onChange={(e) =>
                          setProductInfoData({
                            ...productInfoData,
                            description: e.target.value,
                          })
                        }
                        value={productInfoData?.description}
                        name={"description"}
                        style={{ padding: 3 }}
                        placeholder=""
                        disabled={!(addMode || editMode)}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xl={3}
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    sx={{ alignItems: "center" }}
                  >
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography sx={{ fontSize: 14 }}>Item Type</Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <VirtualizedSelect
                         addOption
                         addOptionLoading={addMode || editMode}
                         onAddClick={() => {
                           handleModalOpen("item_type_name");
                         }}
                        required={true}
                        options={
                          getProductItemTypeData?.length &&
                          getProductItemTypeData
                        }
                        value={
                          getProductItemTypeData?.find(
                            (x) =>
                              productInfoData?.item_type_id === x?.item_type_id
                          ) || ""
                        }
                        disabled={
                          getProductItemTypeStatus === asyncStatus.IDLE ||
                          getProductItemTypeStatus === asyncStatus.LOADING ||
                          !(addMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.item_type_id) {
                            setProductInfoData({
                              ...productInfoData,
                              item_type_id: e?.item_type_id,
                            });
                          }
                          console.log("bbb", e);
                        }}
                        name={"item_type_name"}
                        id_key_name={"item_type_id"}
                      />
                    </Grid>
                  </Grid>
                </>
              }
            />
            <Stack sx={{ px: 4, paddingBottom: 3 }}>
              <InputComp
                type={"file"}
                style={{ border: "none", padding: 0, width: "300px" }}
              />
            </Stack>

            <InfoFormComp
              title="Inseam & Size"
              customChild={
                <Grid
                  container
                  padding={4}
                  spacing={2}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Grid
                    // key={i}
                    item
                    container
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography sx={{ fontSize: 14 }}>Inseam:</Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Select
                        required={true}
                        fullWidth
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={inseam}
                        onChange={handleChangeInseam}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              padding: "5px 5px !important",
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                sx={{
                                  padding: "0px 0px 0px 0px !important",
                                  "& .css-6od3lo-MuiChip-label": {
                                    padding: "5px",
                                    fontSize: 12,
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        sx={{
                          padding: "5px 5px !important",
                          height: "auto",
                          fontSize: 12,
                          "& .MuiSelect-select": {
                            padding: "1px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 600,
                              width: 100,
                            },
                          },
                        }}
                        disabled={
                          getProductinseamStatus === asyncStatus.IDLE ||
                          getProductinseamStatus === asyncStatus.LOADING ||
                          !(addMode || editMode)
                        }
                        // MenuProps={MenuProps}
                      >
                        {getProductinseamData?.map((e) => (
                          <MenuItem key={e?.inseam_id} value={e?.inseam_name}>
                            {e.inseam_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    // key={i}
                    item
                    container
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography sx={{ fontSize: 14 }}>Size:</Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Select
                        required={true}
                        fullWidth
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={sizes}
                        onChange={handleChangeSize}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              padding: "5px 5px !important",
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                sx={{
                                  padding: "0px 0px 0px 0px !important",
                                  "& .css-6od3lo-MuiChip-label": {
                                    padding: "5px",
                                    fontSize: 12,
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        sx={{
                          padding: "5px 5px !important",
                          height: "auto",
                          fontSize: 14,
                          "& .MuiSelect-select": {
                            padding: "1px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 600,
                              width: 100,
                            },
                          },
                        }}
                        disabled={
                          getProductSizeChartByGenderCategoryIdStatus ===
                            asyncStatus.LOADING || !(addMode || editMode)
                        }
                        // MenuProps={MenuProps}
                      >
                        {getProductSizeChartByGenderCategoryIdData?.actual_size_chart
                          ?.split(",")
                          .map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              // style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    // key={i}
                    item
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Btn
                      type="button"
                      style={{ textTransform: "none", minWidth: "200px" }}
                      disabled={!(addMode || editMode)}
                      onClick={generateCombinations}
                    >
                      Generate
                    </Btn>
                  </Grid>
                  {/* </>
                  );
                })}
              {bottomChild} */}
                </Grid>
              }
            />
            <InfoFormComp
              title="Combination & Logs"
              customChild={
                <Stack padding={2}>
                  <TabComp
                    tabs={[
                      {
                        name: "Combinaton",
                        content: (
                          <Stack height={300} width={"100%"}>
                            <TableComp
                              columns={[
                                {
                                  field: "item_code",
                                  headerName: "Item Code",
                                  flex: true,
                                },
                                {
                                  field: "inseam",
                                  headerName: "Inseam",
                                  flex: true,
                                },
                                {
                                  field: "size",
                                  headerName: "Size",
                                  flex: true,
                                },
                                {
                                  field: "upc_code",
                                  headerName: "UPC Code",
                                  flex: true,
                                },
                                {
                                  field: "nrf_color_code",
                                  headerName: "NRF Color Code",
                                  flex: true,
                                },
                                {
                                  field: "nrf_size_code",
                                  headerName: "NRF Size Code",
                                  flex: true,
                                },
                                {
                                  field: "is_active",
                                  headerName: "Status",
                                  flex: true,
                                },
                                {
                                  field: "operations",
                                  headerName: "Operation",
                                  flex: true,
                                },
                              ]}
                              //   onRowClick={(e) => {
                              //     setSelectedStyle(e?.row);
                              //   }}
                              rows={combinations}
                              //   loading={
                              //     getProductStyleStatus === asyncStatus.IDLE ||
                              //     getProductStyleStatus === asyncStatus.LOADING
                              //   }
                            />
                          </Stack>
                        ),
                      },
                      {
                        name: "Related Transactions",
                        content: (
                          <Stack height={300} width={"100%"}>
                            <TableComp
                              columns={[
                                {
                                  field: "companyId",
                                  headerName: "Transaction No",
                                  flex: true,
                                },
                                {
                                  field: "companyName",
                                  headerName: "Transaction Type",
                                  flex: true,
                                },
                                {
                                  field: "action",
                                  headerName: "Transaction Date",
                                  flex: true,
                                },
                                {
                                  field: "action",
                                  headerName: "Customer",
                                  flex: true,
                                },
                                {
                                  field: "action",
                                  headerName: "Term",
                                  flex: true,
                                },
                              ]}
                              //   onRowClick={(e) => {
                              //     setSelectedStyle(e?.row);
                              //   }}
                              rows={[]}
                              //   loading={
                              //     getProductStyleStatus === asyncStatus.IDLE ||
                              //     getProductStyleStatus === asyncStatus.LOADING
                              //   }
                            />
                          </Stack>
                        ),
                      },
                      {
                        name: "Log Info",
                        content: (
                          <Stack height={300} width={"100%"}>
                            <TableComp
                              columns={[
                                {
                                  field: "logId",
                                  headerName: "Log ID",
                                  flex: true,
                                },
                                {
                                  field: "recordId",
                                  headerName: "Record_id",
                                  flex: true,
                                },
                                {
                                  field: "columnName",
                                  headerName: "Column Name",
                                  flex: true,
                                },
                                {
                                  field: "columnValue",
                                  headerName: "Column Value",
                                  flex: true,
                                },
                                {
                                  field: "actionName",
                                  headerName: "Action Name",
                                  flex: true,
                                },
                                {
                                  field: "actionDate",
                                  headerName: "Action Date",
                                  flex: true,
                                },
                                {
                                  field: "userId",
                                  headerName: " User ID",
                                  flex: true,
                                },
                              ]}
                              //   onRowClick={(e) => {
                              //     setSelectedStyle(e?.row);
                              //   }}
                              rows={[]}
                              //   loading={
                              //     getProductStyleStatus === asyncStatus.IDLE ||
                              //     getProductStyleStatus === asyncStatus.LOADING
                              //   }
                            />
                          </Stack>
                        ),
                      },
                    ]}
                  />
                </Stack>
              }
            />
          </Stack>
        )}
      </ScreenCard>
    </Stack>
  );
};

export default ProductInformation;
