import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { primaryColor } from "../../../utils/themeColors";

const Btn = ({ children = "Submit", type = "submit", variant = "contained", style, loading, disabled, onClick }) => {
  return <Button type={type} variant={variant} disabled={disabled || loading} sx={variant==="contained"?{
    fontWeight: "bold", backgroundColor: primaryColor, "&:hover": {
      backgroundColor: "#555e65"
    }, ...style
  }:
  {color:primaryColor,borderColor:primaryColor,
    fontWeight: "bold",  "&:hover": {
      backgroundColor: primaryColor,color:"white",borderColor:"white"
    }, ...style
  }


} onClick={onClick}>{loading ? <CircularProgress size={20} /> : children}</Button>;
};

export default Btn;
