import axios from "axios"
import { save_tokens_constant, session_expired } from "../../store/constant.js"
import dayjs from "dayjs"
import jwt_decode, { jwtDecode } from "jwt-decode"
import { deleteTokenCookie, getTokenFromCookie } from "../../helpers/cookieFunctions.js"

export const exit_session = () => {
deleteTokenCookie()
  window.location.reload()
}
// export const baseURL = `http://172.16.1.87:3333`
export const baseURL = `https://backendv2.dl1961.com`
// export const baseURL = `http://localhost:3333`
// export const baseURL = `http://172.16.2.31:3333`
// export const baseURL = `http://172.16.2.18:3333`

export const apiHandle = axios.create({
  baseURL: `${baseURL}`,

})


axios.defaults.timeout = 15000;
apiHandle.interceptors.request.use(async (req) => {

  // const authToken = localStorage.getItem(save_tokens_constant) || null
  const authToken = getTokenFromCookie()
  if (authToken) {
    const user = jwtDecode(authToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (isExpired) {
      exit_session();
      return req;
    } else req.headers.Authorization = `Bearer ${authToken}`;
  }

  return req;

  // req.headers.Authorization = `Bearer ${authToken}`;
});
apiHandle.interceptors.response.use(async(res)=>{
  console.log(res.status);
  if(res?.data?.statusCode===401){
    exit_session();
    return res;
  }
  return res
})
// apiHandle.interceptors.request.use(async (req) => {
//   const authToken = localStorage.getItem(save_tokens_constant) || null

//   if (authToken) {
//     const user = jwt_decode(authToken)
//     const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1
//     if (isExpired) {
//       exit_session()
//       return req
//     } else req.headers.Authorization = `Bearer ${authToken}`
//   }

//   return req

//   // req.headers.Authorization = `Bearer ${authToken}`;
// })
