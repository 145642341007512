import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import {
  addProductStyleAsync,
  addProductFabricCompositionAsync,
  deleteProductFabricCompositionAsync,
  editProductStyleAsync,
  editProductFabricCompositionAsync,
  getProductStyleAsync,
  getProductFabricCompositionAsync,
} from "../../../../store/services/productInfoServices";
const FabricComposition = () => {
  const dispatch = useDispatch();
  const {
    getProductFabricCompositionStatus,
    getProductFabricCompositionError,
    getProductFabricCompositionData,
    addProductFabricCompositionStatus,
    addProductFabricCompositionData,
    addProductFabricCompositionError,

    editProductFabricCompositionStatus,
    editProductFabricCompositionError,
    editProductFabricCompositionData,

    deleteProductFabricCompositionData,
    deleteProductFabricCompositionStatus,
    deleteProductFabricCompositionError,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductFabricCompositionAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductFabricCompositionData?.length
    ? getProductFabricCompositionData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedFabricComposition, setSelectedFabricComposition] = useState();
  const [addFabricCompositionMode, setAddFabricCompositionMode] = useState(false);
  const [addFabricCompositionData, setAddFabricCompositionData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedFabricComposition", selectedFabricComposition);
  // console.log("editData", editData);
  useEffect(() => {
    if (addProductFabricCompositionStatus === asyncStatus.SUCCEEDED) {
      setAddFabricCompositionMode(false);
      setSaveDisabled(true);
      setAddFabricCompositionData();
    }
  }, [, addProductFabricCompositionStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteProductFabricCompositionAsync(selectedFabricComposition?.fabric_composition_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductFabricCompositionStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedFabricComposition();
    }
  }, [, deleteProductFabricCompositionStatus]);
  useEffect(() => {
    if (editProductFabricCompositionStatus === asyncStatus.SUCCEEDED) {
      setSelectedFabricComposition();
      setEditMode(false);
    }
  }, [, editProductFabricCompositionStatus]);
console.log("getProductFabricCompositionData",getProductFabricCompositionData,editProductFabricCompositionData);
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this fabric composition?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Fabric Composition"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Fabric Composition Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddFabricCompositionMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addFabricCompositionMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFabricCompositionMode || !selectedFabricComposition}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddFabricCompositionMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFabricCompositionMode || !selectedFabricComposition}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductFabricCompositionStatus === asyncStatus.LOADING ||
                  editProductFabricCompositionStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addFabricCompositionData) {
                    dispatch(addProductFabricCompositionAsync(addFabricCompositionData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedFabricComposition, ...editData };
                    delete a.id;
                    dispatch(editProductFabricCompositionAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
                columns={[
                  { field: "fabric_composition_id", headerName: "ID", flex: true },
                  {
                    field: "composition_name",
                    headerName: "Fabric Composition Name",
                    flex: true,
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedFabricComposition(e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductFabricCompositionStatus === asyncStatus.IDLE ||
                  getProductFabricCompositionStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addFabricCompositionMode ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "FabricComposition:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddFabricCompositionData({
                            ...addFabricCompositionData,
                            composition_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddFabricCompositionData({
                            ...addFabricCompositionData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedFabricComposition ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedFabricComposition?.fabric_composition_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "FabricComposition:",
                    comp: (
                      <InputComp
                        key={selectedFabricComposition?.fabric_composition_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            composition_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedFabricComposition?.composition_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={editData?.is_active}
                        disabled={!editMode}
                        defaultValue={
                          selectedFabricComposition?.is_active ? true : false
                        }
                        defaultChecked={
                          selectedFabricComposition?.is_active ? true : false
                        }
                        checked={selectedFabricComposition?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Typography>Select any fabric composition</Typography>
            )}

            <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductFabricCompositionError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductFabricCompositionError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductFabricCompositionError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductFabricCompositionError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  // {
                  //   name: "Companies",
                  //   content: (
                  //     <Stack height={400} width={"100%"}>
                  //       <TableComp
                  //         columns={[
                  //           {
                  //             field: "companyId",
                  //             headerName: "Company ID",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "companyName",
                  //             headerName: "Company Name",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Action",
                  //             flex: true,
                  //           },
                  //         ]}
                  //         //   onRowClick={(e) => {
                  //         //     setSelectedFabricComposition(e?.row);
                  //         //   }}
                  //         rows={[]}
                  //         //   loading={
                  //         //     getProductStyleStatus === asyncStatus.IDLE ||
                  //         //     getProductStyleStatus === asyncStatus.LOADING
                  //         //   }
                  //       />
                  //     </Stack>
                  //   ),
                  // },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedFabricComposition(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default FabricComposition;
