import React, { useState } from "react";
import InputComp from "../../../../components/common/InputComp/Input";
import Btn from "../../../../components/common/ButtonComp/Btn";
import FiltersAccordionFom from "../../../../components/FiltersAccordionFom/FiltersAccordionFom";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import { IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { primaryColor } from "../../../../utils/themeColors";
import TableComp from "../../../../components/common/TableComp/TableComp";

const SaleOrderAdjustmentList = () => {
  const [filterFields, setFilterFields] = useState({});

  const handleChange = (fieldName, e) => {
    setFilterFields({
      ...filterFields,
      [fieldName]: e.target.value,
    });
  };

  return (
    <Stack padding={2}>
      <ScreenCard
        title="Sales Order Adjustment List"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Sales Order Adjustment List
            </Typography>
            <Stack flexDirection={"row"} gap={1}></Stack>
          </Stack>
        }
      >
        {" "}
        <Stack
          padding={2}
          // id="vendorSearchForm"
          // component={"form"}
          // onSubmit={(event) => {
          //   event.preventDefault();
          //   console.log(searchData);
          // }}
        >
          <FiltersAccordionFom
            allFields={[
              {
                title: "Order # :",
                comp: (
                  <InputComp
                    type={"number"}
                    // required={true}
                    validator={(value) => {
                      if (!/[0-9]/.test(value.target.value))
                        return "Characters not allowed";
                      return "";
                    }}
                    style={{ padding: 3 }}
                    placeholder=""
                    onChange={(e) => handleChange("orderNo", e)}
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_id: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_id}
                    // disabled={true}
                    name={"orderNo"}
                  />
                ),
              },
              {
                title: "Order Adj :",
                comp: (
                  <InputComp
                    placeholder=""
                    style={{ padding: 3 }}
                    onChange={(e) => handleChange("orderAdj", e)}
                    name={"orderAdj"}
                  />
                ),
              },
              {
                title: "From Date :",
                comp: (
                  <InputComp
                    type={"date"}
                    style={{ padding: 3 }}
                    onChange={(e) => handleChange("fromDate", e)}
                    name={"fromDate"}
                  />
                ),
              },
              {
                title: "To Date:",
                comp: (
                  <InputComp
                    type={"date"}
                    // required={true}
                    // validator={(value) => {
                    //   if (!/[0-9]/.test(value.target.value))
                    //     return "Characters not allowed";
                    //   return "";
                    // }}
                    style={{ padding: 3 }}
                    placeholder=""
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_code: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_code}
                    // disabled={true}
                    onChange={(e) => handleChange("toDate", e)}
                    name={"toDate"}
                  />
                ),
              },
              {
                title: "PO NO:",
                comp: (
                  <InputComp
                    type={"text"}
                    placeholder=""
                    onChange={(e) => handleChange("poNo", e)}
                    style={{ padding: 3 }}
                  />
                ),
              },
              {
                title: "Customer:",
                comp: (
                  <InputComp
                    placeholder=""
                    style={{ padding: 3 }}
                    onChange={(e) => handleChange("customer", e)}
                  />
                ),
              },
              {
                title: "Sale Person Name:",
                comp: (
                  <InputComp
                    // type={"number"}
                    // required={true}
                    // validator={(value) => {
                    //   if (!/[0-9]/.test(value.target.value))
                    //     return "Characters not allowed";
                    //   return "";
                    // }}
                    style={{ padding: 3 }}
                    placeholder=""
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_name: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_name}
                    // disabled={true}
                    onChange={e=>handleChange('salesPersonName',e)}
                    name={"salesPersonName"}
                  />
                ),
              },
              {
                title: "Region :",
                comp: <InputComp style={{ padding: 3 }} placeholder="" onChange={e=>handleChange('region',e)} />,
              },
              {
                title: "Gender :",
                comp: <InputComp style={{ padding: 3 }} placeholder="" onChange={e=>handleChange('gender_name',e)} name={'gender_name'}/>,
              },
              {
                title: "Terms :",
                comp: (
                  <InputComp
                    // type={"number"}
                    // required={true}
                    // validator={(value) => {
                    //   if (!/[0-9]/.test(value.target.value))
                    //     return "Characters not allowed";
                    //   return "";
                    // }}
                    style={{ padding: 3 }}
                    placeholder=""
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_name: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_name}
                    // disabled={true}
                    onChange={e=>handleChange('term',e)}
                    name={"term"}
                  />
                ),
              },
            ]}
            headerRightComp={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // history("/customerInformation");
                }}
              >
                <AddIcon />
              </IconButton>
            }
            bottomChild={
              <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                <Btn
                  // type="submit"
                  // onClick={() => {
                  //   if (!(!searchData?.from_date || !searchData?.to_date)) {
                  //     console.log(searchData);
                  //     dispatch(searchOMVendorAsync(searchData));
                  //   } else {
                  //     error_toast_message(
                  //       "Please select from data and to date first! "
                  //     );
                  //   }
                  // }}
                  style={{ width: "auto", textTransform: "none" }}
                  // loading={searchOMVendorStatus === asyncStatus.LOADING}
                >
                  Search
                </Btn>
              </Stack>
            }
          />

          <Stack height={500} mt={4} width={"100%"}>
            <TableComp
              onRowClick={(e) => {
                //   history(`/customerInformation/${e?.row?.vendor_id}`);
                // setCurrentVendorId(e?.row?.vendor_id);
                //   setSearchMode(false);
              }}
              exportToExcel={true}
              // rows={formattedData}
              rows={[]}
              columns={[
                {
                  field: "adjNo",
                  headerName: "Adj No",
                  flex: true,
                },
                {
                  field: "orderId",
                  headerName: "Order ID",
                  flex: true,
                },
                {
                  field: "orderNo",
                  headerName: "Order No",
                  flex: true,
                },
                {
                  field: "adjDate",
                  headerName: "Adj Date",
                  flex: true,
                },
                { field: "poNo", headerName: "PO #", flex: true },
                { field: "customer", headerName: "Customer", flex: true },
                {
                  field: "term",
                  headerName: "Term",
                  flex: true,
                },
                {
                  field: "regionName",
                  headerName: "Region Name",
                  flex: true,
                },
                {
                  field: "salesPersonName",
                  headerName: "SalesPerson Name",
                  flex: true,
                },
                {
                  field: "genderName",
                  headerName: "Gender Name",
                  flex: true,
                },
              ]}
            />
          </Stack>
        </Stack>
      </ScreenCard>
    </Stack>
  );
};

export default SaleOrderAdjustmentList;
