import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import { addProductStyleAsync, deleteProductStyleAsync, editProductStyleAsync, getProductStyleAsync } from "../../../../store/services/productInfoServices";
const Style = () => {
  const dispatch = useDispatch();
  const {
    getProductStyleStatus,
    getProductStyleError,
    getProductStyleData,
    addProductStyleStatus,
    addProductStyleError,
    addProductStyleData,

    editProductStyleStatus,
    editProductStyleError,
    editProductStyleData,

    deleteProductStyleStatus,
    deleteProductStyleError,
    deleteProductStyleData,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductStyleAsync());
  }, []);

  console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductStyleData?.length
    ? getProductStyleData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedStyle, setSelectedStyle] = useState();
  const [addStyleMode, setAddStyleMode] = useState(false);
  const [addStyleData, setAddStyleData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  console.log("selectedStyle", selectedStyle);
  console.log("editData", editData);
  useEffect(() => {
    if (addProductStyleStatus === asyncStatus.SUCCEEDED) {
      setAddStyleMode(false);
      setSaveDisabled(true);
      setAddStyleData();
    }
  }, [, addProductStyleStatus]);

  const [open, setOpen] = React.useState(false);
  const agree = () => {
    dispatch(deleteProductStyleAsync(selectedStyle?.style_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductStyleStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedStyle();
    }
  }, [, deleteProductStyleStatus]);
  useEffect(() => {
    if (editProductStyleStatus === asyncStatus.SUCCEEDED) {
      setSelectedStyle();
      setEditMode(false)
    }
  }, [, deleteProductStyleStatus]);

  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this style?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Style"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Style
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddStyleMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addStyleMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addStyleMode || !selectedStyle}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddStyleMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addStyleMode || !selectedStyle}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductStyleStatus === asyncStatus.LOADING ||
                  editProductStyleStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addStyleData) {
                    dispatch(addProductStyleAsync(addStyleData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedStyle, ...editData };
                    delete a.id;
                    dispatch(editProductStyleAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
                columns={[
                  { field: "style_id", headerName: "Style Id", flex: true },
                  { field: "style_name", headerName: "Style Name", flex: true },
                ]}
                onRowClick={(e) => {
                  setSelectedStyle((prev) => e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductStyleStatus === asyncStatus.IDLE ||
                  getProductStyleStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addStyleMode ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "Style:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddStyleData({
                            ...addStyleData,
                            style_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddStyleData({
                            ...addStyleData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedStyle ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedStyle?.style_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "Style:",
                    comp: (
                      <InputComp
                        disabled={!editMode}
                        key={selectedStyle?.style_id} 
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            style_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedStyle?.style_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={editData?.is_active}
                        disabled={!editMode}
                        // defaultValue={selectedStyle?.is_active ? true : false}
                        defaultChecked={selectedStyle?.is_active ? true : false}
                        // checked={selectedStyle?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Typography>Select any style</Typography>
            )}
             <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductStyleError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductStyleError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductStyleError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductStyleError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  {
                    name: "Style Combination",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "style_id",
                              headerName: "Style Combination Id",
                              flex: true,
                            },
                            {
                              field: "gender",
                              headerName: "Gender",
                              flex: true,
                            },
                            {
                              field: "category",
                              headerName: "Category",
                              flex: true,
                            },
                            {
                              field: "cut",
                              headerName: "Cut",
                              flex: true,
                            },
                            {
                              field: "fitCategory",
                              headerName: "Fit Category",
                              flex: true,
                            },
                            {
                              field: "rise",
                              headerName: "Rise",
                              flex: true,
                            },
                            {
                              field: "riseLabel",
                              headerName: "Rise Label",
                              flex: true,
                            },
                            {
                              field: "default",
                              headerName: "Default",
                              flex: true,
                            },
                            {
                              field: "actions",
                              headerName: "Actions",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Companies",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "companyId",
                              headerName: "Company ID",
                              flex: true,
                            },
                            {
                              field: "companyName",
                              headerName: "Company Name",
                              flex: true,
                            },
                            {
                              field: "action",
                              headerName: "Action",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default Style;
