import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid, GridSearchIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import {
  addOMCustomerInformationAsync,
  deleteOMCustomerInformationAsync,
  editOMCustomerInformationAsync,
  getOMBergenBillingAsync,
  getOMCATAsync,
  getOMCountryAsync,
  getOMCustomerInformationAsync,
  getOMCustomerTypeAsync,
  getOMFreightTermAsync,
  getOMOrderStatusAsync,
  getOMOrderTypeAsync,
  getOMPaymentTermAsync,
  getOMShipViaAsync,
  getOMShippingMethodAsync,
  getOMCityAsync,
  getOMPtStatusAsync,
  getOMStateAsync,
  getOMSalesPersonAsync,
} from "../../../store/services/orderManagementService";
import FiltersAccordionFom from "../../../components/FiltersAccordionFom/FiltersAccordionFom";
import InfoFormComp from "../../../components/InfoForm/InfoFormComp";
import { useLocation, useNavigate } from "react-router-dom";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
import {
  getProductCompanyAsync,
  getProductGenderAsync,
} from "../../../store/services/productInfoServices";
import SearchIcon from "@mui/icons-material/Search";
import CustomModal from "../../../components/common/Modal/CustomModal";
import { RxCross1 } from "react-icons/rx";
import dayjs from "dayjs";

const communicationColumns = [
  {
    field: "logId",
    headerName: "Log ID",
    flex: true,
  },
  {
    field: "recordId",
    headerName: "Record_id",
    flex: true,
  },
  {
    field: "columnName",
    headerName: "Column Name",
    flex: true,
  },
  {
    field: "columnValue",
    headerName: "Column Value",
    flex: true,
  },
  {
    field: "actionName",
    headerName: "Action Name",
    flex: true,
  },
  {
    field: "actionDate",
    headerName: "Action Date",
    flex: true,
  },
  {
    field: "userId",
    headerName: " User ID",
    flex: true,
  },
];

const transictionColumns = [
  {
    field: "transaction_id",
    headerName: "Transaction ID",
    flex: true,
  },
  {
    field: "recordId",
    headerName: "Transaction No",
    flex: true,
  },
  {
    field: "columnName",
    headerName: "Transaction Type",
    flex: true,
  },
  {
    field: "columnValue",
    headerName: "Transaction Date",
    flex: true,
  },
  {
    field: "actionName",
    headerName: "Customer Name",
    flex: true,
  },
  {
    field: "actionDate",
    headerName: "PO No",
    flex: true,
  },
];

const categoryColumns = [
  {
    field: "gender_id",
    headerName: "Gender",
    flex: true,
  },
  {
    field: "category_id",
    headerName: "Category Id",
    flex: true,
  },
  {
    field: "region",
    headerName: "Region",
    flex: true,
  },
  {
    field: "sales_person",
    headerName: "Sales person",
    flex: true,
  },
  {
    field: "is_active",
    headerName: "Active",
    flex: true,
  },
  {
    field: "action",
    headerName: "Action",
    flex: true,
  },
];

const addressColumns = [
  {
    field: "first_name",
    headerName: "First Name",
    flex: true,
  },
  {
    field: "last_name",
    headerName: "Last Name",
    flex: true,
  },
  {
    field: "customer_addressee",
    headerName: "Addressee",
    flex: true,
  },
  {
    field: "adddress_1",
    headerName: "Address 1",
    flex: true,
  },
  {
    field: "address_2",
    headerName: "Address 2",
    flex: true,
  },
  {
    field: "city",
    headerName: "City",
    flex: true,
  },
  {
    field: "state",
    headerName: "State",
    flex: true,
  },
  {
    field: "zip",
    headerName: "Zip",
    flex: true,
  },
  {
    field: "country",
    headerName: "Country",
    flex: true,
  },
  {
    field: "is_active",
    headerName: "Active",
    flex: true,
  },
  {
    field: "default_billing",
    headerName: "Default Billing",
    flex: true,
  },
  {
    field: "default_shipping",
    headerName: "Default Shipping",
    flex: true,
  },
  {
    field: "userId",
    headerName: "Action",
    flex: true,
  },
];

const CustomerInformation = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const typographyStyle = { fontSize: 12, fontWeight: "bold", color: "gray" };

  const {
    getProductCompanyStatus,
    getProductCompanyData,

    getProductGenderStatus,
    getProductGenderError,
    getProductGenderData,
  } = useSelector((state) => state.product);
  const {
    // Get
    getOMCustomerInformationStatus,
    getOMCustomerInformationError,
    getOMCustomerInformationData,
    // Add
    addOMCustomerInformationStatus,
    addOMCustomerInformationData,
    addOMCustomerInformationError,

    // Edit
    editOMCustomerInformationStatusById,
    editOMCustomerInformationErrorById,
    editOMCustomerInformationDataById,
    // Delete
    deleteOMCustomerInformationData,
    deleteOMCustomerInformationStatus,
    deleteOMCustomerInformationError,

    getOMCustomerTypeStatus,
    getOMCustomerTypeError,
    getOMCustomerTypeData,

    getOMOrderStatusStatus,
    getOMOrderStatusError,
    getOMOrderStatusData,

    getOMPaymentTermStatus,
    getOMPaymentTermError,
    getOMPaymentTermData,

    getOMCATStatus,
    getOMCATError,
    getOMCATData,

    getOMShippingMethodData,
    getOMShippingMethodStatus,
    getOMShippingMethodError,

    getOMFreightTermStatus,
    getOMFreightTermError,
    getOMFreightTermData,

    getOMBergenBillingStatus,
    getOMBergenBillingError,
    getOMBergenBillingData,

    getOMShipViaStatus,
    getOMShipViaError,
    getOMShipViaData,

    getOMOrderTypeStatus,
    getOMOrderTypeError,
    getOMOrderTypeData,

    getOMCountryStatus,
    getOMCountryError,
    getOMCountryData,

    getOMStateStatus,
    getOMStateError,
    getOMStateData,

    getOMCityStatus,
    getOMCityError,
    getOMCityData,

    getOMSalesPersonStatus,
    getOMSalesPersonError,
    getOMSalesPersonData,
  } = useSelector((state) => state.om);

  const [customerAddInfoData, setCustomerAddInfoData] = useState({}); //obj for address
  const [addressDataSource, setAddressDataSource] = useState([]); //for lines

  const [customerCatInfoData, setCustomerCatInfoData] = useState({}); //obj for address
  const [catDataSource, setCatDataSource] = useState([]); //for lines
  const [selectedCustomerInformation, setSelectedCustomerInformation] =
    useState();
  const [addCustomerInformationData, setAddCustomerInformationData] = useState(
    {}
  );
  const [openModal, setOpenModal] = useState(false);
  const [modalName, setModalName] = useState("");

  const [addCustomerInformationMode, setAddCustomerInformationMode] =
    useState(false);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [searchMode, setSearchMode] = useState(false);

  const [addMode, setAddMode] = useState(false);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log(customerAddInfoData, "customerAddInfoData");
  });
  useEffect(() => {
    console.log(addCustomerInformationData, "addCustomerInformationData");
  }, [addCustomerInformationData]);

  useLayoutEffect(() => {
    let { pathname } = location;
    setSearchMode(
      pathname === "/customerInformation"
        ? false
        : pathname === "/customerInformation/search"
        ? true
        : false
    );
  }, [location]);

  useEffect(() => {
    if (addOMCustomerInformationStatus === asyncStatus.SUCCEEDED) {
      setAddCustomerInformationMode(false);
      setSaveDisabled(true);
      setAddCustomerInformationData();
    }
  }, [addOMCustomerInformationStatus]);

  useEffect(() => {
    dispatch(getProductCompanyAsync());
    dispatch(getOMCustomerTypeAsync());
    dispatch(getOMOrderStatusAsync());
    dispatch(getOMPaymentTermAsync());
    dispatch(getProductGenderAsync());
    dispatch(getOMCATAsync());
    dispatch(getOMShippingMethodAsync());
    dispatch(getOMFreightTermAsync());
    dispatch(getOMBergenBillingAsync());
    dispatch(getOMShipViaAsync());
    dispatch(getOMOrderTypeAsync());
    dispatch(getOMCountryAsync());
    dispatch(getOMCityAsync());
    dispatch(getOMStateAsync());
    dispatch(getOMSalesPersonAsync());
  }, []);

  useEffect(() => {
    if (deleteOMCustomerInformationStatus === asyncStatus.SUCCEEDED) {
      // setCurrentVendorId("");
      setOpen(false);
      // setAddVendorData({ vendor_type: "I" });
      document.getElementById("customerInformation").reset();
      history("/customerInformation");
    }
  }, [deleteOMCustomerInformationStatus]);

  const agree = () => {
    dispatch(
      deleteOMCustomerInformationAsync(selectedCustomerInformation?.term_id)
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  const generateCombinations = (inputCase) => {
    if (inputCase === "address") {
      let copyArr = [...addressDataSource];
      copyArr.push(customerAddInfoData);
      setAddressDataSource(copyArr);
    } else if (inputCase === "cat") {
      let copyArr = [...catDataSource];
      copyArr.push(customerCatInfoData);
      setCatDataSource(copyArr);
    }
  };

  const handleOpenModal = (name) => {
    setOpenModal(true);
    setModalName(name);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setModalName("");
  };

  const handleChange = (fieldName, e, select, value) => {
    setAddCustomerInformationData({
      ...addCustomerInformationData,
      [fieldName]: select ? value : e.target.value,
    });
  };
  const handleChangeAddress = (fieldName, e, select, value) => {
    setCustomerAddInfoData({
      ...addCustomerInformationData,
      [fieldName]: select ? value : e.target.value,
    });
  };
  const formattedDataAddress = addressDataSource?.length
    ? addressDataSource.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];

  const formattedDataCategory = catDataSource?.length
    ? catDataSource.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];

  const payload = {
    customer_code: null,
    opening_date: new Date(),
    first_name: addCustomerInformationData?.first_name,
    middle_name: null,
    last_name: addCustomerInformationData?.last_name,
    customer_name:
      addCustomerInformationData?.first_name +
      " " +
      addCustomerInformationData?.last_name,
    email_address: addCustomerInformationData?.email_address,
    alternate_email_address: addCustomerInformationData?.other_email_address,
    phone_no: addCustomerInformationData?.phone_no,
    mobile_no: addCustomerInformationData?.phone_no,
    fax_no: addCustomerInformationData?.fax_no,
    website: null,
    inactive_date: null,
    old_customer_code: addCustomerInformationData?.old_id,
    resale_certificate_no: addCustomerInformationData?.resale_certificate,
    // resale_certificate_date: dayjs(addCustomerInformationData?.resale_expiry_date).format('DD-MM-YYYYTHH:mm:ss.SSS[Z]'),
    resale_certificate_date: null,
    remarks: addCustomerInformationData?.comments,
    alcohol_recipient_type: addCustomerInformationData?.alcohol_recipient_type,
    shipping_account_no: addCustomerInformationData?.ship_account,
    is_hold: false,
    is_ship_complete: true,
    deleted_date: null,
    deleted_by: null,
    credit_hold: false,
    date_closed: null,
    is_approved: true,
    date_of_last_order: null,
    order_type_id: 1,
    active: true,
    federal_tax_id: addCustomerInformationData?.federal_tax_id,
    source_type: null,
    bill_to_non_us: addCustomerInformationData?.bill_to_non_us,
    ship_to_non_us: addCustomerInformationData?.ship_to_non_us,
    tax_duties: addCustomerInformationData?.tax_duties,
    secondary_email: addCustomerInformationData?.sec_email_address,
    customer_type_id: 2,
    customer_category_id: 1,
    primary_subsidiary_id: null,
    order_status_id: addCustomerInformationData?.order_status_id,
    term_id: null,
    company_id: addCustomerInformationData?.company_id,
    freight_term_id: addCustomerInformationData?.freight_term_id,
    bergan_ship_via_id: addCustomerInformationData?.ship_via_id,
    shipping_method_id: addCustomerInformationData?.shipping_method_id,
    bergan_billing_id: addCustomerInformationData?.bergan_billing_id,
    currency_id: null,
    is_active: true,
    is_deleted: false,
    addresses: addressDataSource,
    salesCategories: catDataSource,
  };
  console.log(payload);

  const [searchCustomerInfoData, setSearchCustomerInfoData] = useState({});

  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={
          "Are you sure you want to delete this customer information?"
        }
        heading={"Delete request"}
      />
      <CustomModal
        handleClose={handleClose}
        open={openModal}
        children={
          <Stack
            width={"100%"}
            minWidth={{ xl: 700, lg: 600, md: 500, sm: 300, xs: 200 }}
            alignItems={"center"}
            padding={1}
          >
            {modalName === "Address" ? (
              <Stack width={1} spacing={2}>
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={14} fontWeight={"bold"}>
                    Address Form
                  </Typography>
                  <IconButton onClick={handleCloseModal}>
                    <RxCross1 color="red" size={20} />
                  </IconButton>
                </Stack>
                <Divider />
                <Stack>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>First Name</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("first_name", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Last Name</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("last_name", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Addressee</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress(
                            "customer_addressee",
                            e,
                            false,
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Address 1</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("adddress_1", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Address 2</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("address_2", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Country</Typography>

                      <VirtualizedSelect
                        required={true}
                        options={getOMCountryData?.length && getOMCountryData}
                        value={
                          getOMCountryData?.find(
                            (x) =>
                              customerAddInfoData?.country_id === x?.country_id
                          ) || ""
                        }
                        // disabled={
                        //   getOMCountryStatus === asyncStatus.IDLE ||
                        //   getOMCountryStatus === asyncStatus.LOADING ||
                        //   !(addMode || editMode)
                        // }
                        // onChange={(e) => {
                        onChange={(e) =>
                          e?.country_id
                            ? handleChangeAddress(
                                "country_id",
                                e,
                                true,
                                e?.country_id
                              )
                            : null
                        }
                        name={"country_name"}
                        id_key_name={"country_id"}
                      />

                      {/* <InputComp style={{ padding: 3 }} placeholder="" /> */}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>City</Typography>
                      {/* <InputComp style={{ padding: 3 }} placeholder="" /> */}
                      <VirtualizedSelect
                        required={true}
                        options={getOMCityData?.length && getOMCityData}
                        value={
                          getOMCityData?.find(
                            (x) => customerAddInfoData?.city_id === x?.city_id
                          ) || ""
                        }
                        // disabled={
                        //   getOMCountryStatus === asyncStatus.IDLE ||
                        //   getOMCountryStatus === asyncStatus.LOADING ||
                        //   !(addMode || editMode)
                        // }
                        onChange={(e) =>
                          e?.city_id
                            ? handleChangeAddress(
                                "city_id",
                                e,
                                true,
                                e?.city_id
                              )
                            : null
                        }
                        name={"city_name"}
                        id_key_name={"city_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>State</Typography>
                      {/* <InputComp style={{ padding: 3 }} placeholder="" /> */}
                      <VirtualizedSelect
                        required={true}
                        options={getOMStateData?.length && getOMStateData}
                        value={
                          getOMStateData?.find(
                            (x) => customerAddInfoData?.state_id === x?.state_id
                          ) || ""
                        }
                        // disabled={
                        //   getOMCountryStatus === asyncStatus.IDLE ||
                        //   getOMCountryStatus === asyncStatus.LOADING ||
                        //   !(addMode || editMode)
                        // }
                        onChange={(e) =>
                          e?.state_id
                            ? handleChangeAddress(
                                "state_id",
                                e,
                                true,
                                e?.state_id
                              )
                            : null
                        }
                        name={"state_name"}
                        id_key_name={"state_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Email</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("customer_email", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Fax</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("fax", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Phone</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("phone", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Zip</Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          handleChangeAddress("zip_code", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                      <Typography sx={typographyStyle}>Active</Typography>
                      {/* <Checkbox
                        defaultChecked
                        defaultValue={true}
                        sx={{ padding: 0, margin: 0 }}
                        // onChange={(e) =>
                        //   setAddVendorData({
                        //     ...addVendorData,
                        //     is_active: e.target.checked,
                        //   })
                        // }
                      /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) =>
                          handleChangeAddress("is_active", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                      <Typography sx={typographyStyle}>
                        Default Billing
                      </Typography>
                      {/* <Checkbox
                        defaultChecked
                        defaultValue={true}
                        sx={{ padding: 0, margin: 0 }}
                        // onChange={(e) =>
                        //   setAddVendorData({
                        //     ...addVendorData,
                        //     is_active: e.target.checked,
                        //   })
                        // }
                      /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) =>
                          handleChangeAddress("default_billing", e, false, "")
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      xs={6}
                      flexDirection={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Typography sx={typographyStyle}>
                        Default shipping
                      </Typography>
                      {/* <Checkbox
                        defaultChecked
                        defaultValue={true}
                        sx={{ padding: 0, margin: 0 }}
                        // onChange={(e) =>
                        //   setAddVendorData({
                        //     ...addVendorData,
                        //     is_active: e.target.checked,
                        //   })
                        // }
                      /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) =>
                          handleChangeAddress("default_shipping", e, false, "")
                        }
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={2}
                >
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    variant="outlined"
                    // disabled={!(addVendorMode || editMode)}
                    onClick={handleCloseModal}
                  >
                    Back
                  </Btn>
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    // disabled={!(addVendorMode || editMode)}
                    onClick={() => {
                      generateCombinations("address");
                      handleCloseModal();
                    }}
                  >
                    Submit
                  </Btn>
                </Stack>
              </Stack>
            ) : modalName === "Category" ? (
              <Stack width={1} spacing={2}>
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={14} fontWeight={"bold"}>
                    Category Form
                  </Typography>
                  <IconButton onClick={handleCloseModal}>
                    <RxCross1 color="red" size={20} />
                  </IconButton>
                </Stack>
                <Divider />
                <Stack>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Gender</Typography>
                      <VirtualizedSelect
                        required={true}
                        options={
                          getProductGenderData?.length && getProductGenderData
                        }
                        value={
                          getProductGenderData?.find(
                            (x) =>
                              customerCatInfoData?.gender_id === x?.gender_id
                          ) || ""
                        }
                        disabled={
                          getProductGenderStatus === asyncStatus.IDLE ||
                          getProductGenderStatus === asyncStatus.LOADING
                        }
                        onChange={(e) =>
                          e?.gender_id
                            ? handleChange("gender_id", e, true, e?.gender_id)
                            : null
                        }
                        name={"gender_name"}
                        id_key_name={"gender_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography sx={typographyStyle}>
                        Region Category
                      </Typography>
                      <VirtualizedSelect
                        required={true}
                        options={getOMCATData?.length && getOMCATData}
                        value={
                          getOMCATData?.find(
                            (x) =>
                              addCustomerInformationData?.sp_region_category_id ===
                              x?.sp_region_category_id
                          ) || ""
                        }
                        disabled={
                          getOMCATStatus === asyncStatus.IDLE ||
                          getOMCATStatus === asyncStatus.LOADING
                        }
                        onChange={(e) =>
                          e?.sp_region_category_id
                            ? handleChange(
                                "category_id",
                                e,
                                true,
                                e?.sp_region_category_id
                              )
                            : null
                        }
                        name={"description"}
                        id_key_name={"sp_region_category_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={typographyStyle}>Active</Typography>
                      {/* <Checkbox
                        defaultChecked
                        defaultValue={true}
                        sx={{ padding: 0, margin: 0 }}
                        // onChange={(e) =>
                        //   setAddVendorData({
                        //     ...addVendorData,
                        //     is_active: e.target.checked,
                        //   })
                        // }
                      /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) =>
                          handleChange("is_active", e, false, "")
                        }
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={2}
                >
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    variant="outlined"
                    // disabled={!(addVendorMode || editMode)}
                    onClick={handleCloseModal}
                  >
                    Back
                  </Btn>
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    // disabled={!(addVendorMode || editMode)}
                    onClick={() => {
                      generateCombinations("cat");
                      handleCloseModal();
                    }}
                  >
                    Submit
                  </Btn>
                </Stack>
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        }
      />
      <ScreenCard
        title="Customer Info"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Customer Info
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setSearchMode((prev) => !prev);
                  setAddMode(false);
                  setEditMode(false);
                  history(
                    searchMode
                      ? "/customerInformation"
                      : "/customerInformation/search"
                  );
                }}
              >
                {!searchMode ? (
                  <SearchIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddMode(false);
                  setAddCustomerInformationMode((prev) => !prev);
                  setSaveDisabled(false);
                  history("/customerInformation");
                }}
              >
                {!addCustomerInformationMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addCustomerInformationMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddCustomerInformationMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={
                  addCustomerInformationMode || !selectedCustomerInformation
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMCustomerInformationStatus === asyncStatus.LOADING ||
                  editOMCustomerInformationStatusById === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addCustomerInformationData) {
                    dispatch(addOMCustomerInformationAsync(payload));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedCustomerInformation, ...editData };
                    delete a.id;
                    dispatch(editOMCustomerInformationAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        {" "}
        {!searchMode ? (
          <Stack>
            <InfoFormComp
              customColumns={4}
              title="Primary Information"
              fieldsArray={[
                {
                  label: "Company :",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getProductCompanyData?.length && getProductCompanyData
                      }
                      value={
                        getProductCompanyData?.find(
                          (x) =>
                            addCustomerInformationData?.company_id ===
                            x?.company_id
                        ) || ""
                      }
                      disabled={
                        getProductCompanyStatus === asyncStatus.IDLE ||
                        getProductCompanyStatus === asyncStatus.LOADING ||
                        !(addCustomerInformationMode || editMode)
                      }
                      onChange={(e) =>
                        e?.company_id
                          ? handleChange("company_id", e, true, e?.company_id)
                          : null
                      }
                      name={"company_name"}
                      id_key_name={"company_id"}
                    />
                  ),
                },
                {
                  label: "Customer Code:",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setAddVendorData({
                      //     ...addVendorData,
                      //     vendor_id: e.target.value,
                      //   })
                      // }
                      // value={addVendorData?.vendor_id}
                      disabled={true}
                      name={"customer_code"}
                    />
                  ),
                },
                {
                  label: "Customer Name :",
                  comp: (
                    <InputComp
                      type={"text"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("customer_name", e, false, "")
                      }
                      // value={addVendorData?.vendor_id}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"customer_name"}
                    />
                  ),
                },
                {
                  label: "Customer Type:",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getOMCustomerTypeData?.length && getOMCustomerTypeData
                      }
                      value={
                        getOMCustomerTypeData?.find(
                          (x) =>
                            addCustomerInformationData?.customer_type_id ===
                            x?.customer_type_id
                        ) || ""
                      }
                      disabled={
                        getOMCustomerTypeStatus === asyncStatus.IDLE ||
                        getOMCustomerTypeStatus === asyncStatus.LOADING ||
                        !(addCustomerInformationMode || editMode)
                      }
                      // onChange={(e) => {
                      //   if (e?.customer_type_id) {
                      //     setAddCustomerInformationData({
                      //       ...addCustomerInformationData,
                      //       customer_type_id: e?.customer_type_id,
                      //     });
                      //   }
                      // }}
                      onChange={(e) =>
                        e?.customer_type_id
                          ? handleChange(
                              "customer_addressee",
                              e,
                              true,
                              e?.customer_type_id
                            )
                          : null
                      }
                      name={"customer_type_name"}
                      id_key_name={"customer_type_id"}
                    />
                  ),
                },
                {
                  label: "Web Address :",
                  comp: (
                    <InputComp
                      type={"text"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("web_address", e, false, "")
                      }
                      // onChange={(e) =>
                      //   setAddCustomerInformationData({
                      //     ...addCustomerInformationData,
                      //     web_address: e.target.value,
                      //   })
                      // }
                      // value={addVendorData?.vendor_id}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"web_address"}
                    />
                  ),
                },
                {
                  label: "Comments :",
                  comp: (
                    <InputComp
                      type={"text"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) => handleChange("comments", e, false, "")}
                      value={addCustomerInformationData?.comments || ""}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"comments"}
                    />
                  ),
                },
              ]}
            />
            <InfoFormComp
              customColumns={4}
              title="Email | Phone | Address"
              fieldsArray={[
                {
                  label: "AP First Name :",
                  comp: (
                    <InputComp
                      type={"text"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) => handleChange("first_name", e, false, "")}
                      value={addCustomerInformationData?.first_name}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"first_name"}
                    />
                  ),
                },
                {
                  label: "AP Last Name :",
                  comp: (
                    <InputComp
                      type={"text"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) => handleChange("last_name", e, false, "")}
                      value={addCustomerInformationData?.last_name}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"last_name"}
                    />
                  ),
                },
                {
                  label: "Other Emails :",
                  comp: (
                    <InputComp
                      type={"email"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("other_email_address", e, false, "")
                      }
                      value={addCustomerInformationData?.other_email_address}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"other_email_address"}
                    />
                  ),
                },
                {
                  label: "Email :",
                  comp: (
                    <InputComp
                      type={"email"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("email_address", e, false, "")
                      }
                      value={addCustomerInformationData?.email_address}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"email_address"}
                    />
                  ),
                },
                {
                  label: "Secondary Email :",
                  comp: (
                    <InputComp
                      type={"email"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("sec_email_address", e, false, "")
                      }
                      value={addCustomerInformationData?.sec_email_address}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"sec_email_address"}
                    />
                  ),
                },
                {
                  label: "Phone :",
                  comp: (
                    <InputComp
                      type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) => handleChange("phone_no", e, false, "")}
                      value={addCustomerInformationData?.phone}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"phone_no"}
                    />
                  ),
                },
                {
                  label: "Fax :",
                  comp: (
                    <InputComp
                      type={"text"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) => handleChange("fax_no", e, false, "")}
                      value={addCustomerInformationData?.fax_no}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"fax_no"}
                    />
                  ),
                },
              ]}
            />
            <Stack flexDirection={"row"} sx={{ paddingX: 4, paddingBottom: 2 }}>
              <Typography sx={{ fontSize: 14 }}>
                Default Billing Address :
              </Typography>
              <Typography sx={{ fontSize: 14 }}></Typography>
            </Stack>
            <InfoFormComp
              customColumns={4}
              title="Classification"
              fieldsArray={[
                {
                  label: "Order Status :",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getOMOrderStatusData?.length && getOMOrderStatusData
                      }
                      value={
                        getOMOrderStatusData?.find(
                          (x) =>
                            addCustomerInformationData?.order_status_id ===
                            x?.order_status_id
                        ) || ""
                      }
                      disabled={
                        getOMOrderStatusStatus === asyncStatus.IDLE ||
                        getOMOrderStatusStatus === asyncStatus.LOADING ||
                        !(addCustomerInformationMode || editMode)
                      }
                      onChange={(e) =>
                        e?.order_status_id
                          ? handleChange(
                              "order_status_id",
                              e,
                              true,
                              e?.order_status_id
                            )
                          : null
                      }
                      name={"status_name"}
                      id_key_name={"order_status_id"}
                    />
                  ),
                },
                {
                  label: "Old ID :",
                  comp: (
                    <InputComp
                      type={"text"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) => handleChange("old_id", e, false, "")}
                      value={addCustomerInformationData?.old_id}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"old_id"}
                    />
                  ),
                },
                {
                  label: "Resale Certificate :",
                  comp: (
                    <InputComp
                      type={"text"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("resale_certificate", e, false, "")
                      }
                      value={addCustomerInformationData?.resale_certificate}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"resale_certificate"}
                    />
                  ),
                },
                {
                  label: "Resale Expiry Date :",
                  comp: (
                    <InputComp
                      type={"date"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("resale_expiry_date", e, false, "")
                      }
                      value={addCustomerInformationData?.resale_expiry_date}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"resale_expiry_date"}
                    />
                  ),
                },
                {
                  label: "Payment Term :",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getOMPaymentTermData?.length && getOMPaymentTermData
                      }
                      value={
                        getOMPaymentTermData?.find(
                          (x) =>
                            addCustomerInformationData?.term_id === x?.term_id
                        ) || ""
                      }
                      disabled={
                        getOMPaymentTermStatus === asyncStatus.IDLE ||
                        getOMPaymentTermStatus === asyncStatus.LOADING ||
                        !(addCustomerInformationMode || editMode)
                      }
                      onChange={(e) =>
                        e?.term_id
                          ? handleChange("term_id", e, true, e?.term_id)
                          : null
                      }
                      name={"term_name"}
                      id_key_name={"term_id"}
                    />
                  ),
                },
                {
                  label: "Federal Tax ID :",
                  comp: (
                    <InputComp
                      type={"text"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        handleChange("federal_tax_id", e, false, "")
                      }
                      value={addCustomerInformationData?.federal_tax_id}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"federal_tax_id"}
                    />
                  ),
                },
              ]}
            />
            <Divider />
            <Stack padding={2}>
              <TabComp
                tabs={[
                  {
                    name: "Address",
                    content: (
                      <Stack height={300} width={"100%"}>
                        <TableComp
                          columns={addressColumns}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={formattedDataAddress}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                    header: (
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          paddingX: 2,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setOpenModal(true);
                            setModalName("Address");
                          }}
                        >
                          <AddIcon sx={{ color: "black", fontSize: 22 }} />
                        </IconButton>
                      </Stack>
                    ),
                  },
                  {
                    name: "Category",
                    content: (
                      <Stack height={300} width={"100%"}>
                        <TableComp
                          columns={categoryColumns}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={formattedDataCategory}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                    header: (
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          paddingX: 2,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setOpenModal(true);
                            setModalName("Category");
                          }}
                        >
                          <AddIcon sx={{ color: "black", fontSize: 22 }} />
                        </IconButton>
                      </Stack>
                    ),
                  },
                  {
                    name: "Preferences",
                    content: (
                      <Stack height={300} width={"100%"} marginTop={3}>
                        <Grid container rowGap={2} columnSpacing={2}>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Shipping Method
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <VirtualizedSelect
                                required={true}
                                options={
                                  getOMShippingMethodData?.length &&
                                  getOMShippingMethodData
                                }
                                value={
                                  getOMShippingMethodData?.find(
                                    (x) =>
                                      addCustomerInformationData?.shipping_method_id ===
                                      x?.shipping_method_id
                                  ) || ""
                                }
                                disabled={
                                  getOMShippingMethodStatus ===
                                    asyncStatus.IDLE ||
                                  getOMShippingMethodStatus ===
                                    asyncStatus.LOADING ||
                                  !(addCustomerInformationMode || editMode)
                                }
                                onChange={(e) =>
                                  e?.shipping_method_id
                                    ? handleChange(
                                        "shipping_method_id",
                                        e,
                                        true,
                                        e?.shipping_method_id
                                      )
                                    : null
                                }
                                name={"shipping_method_name"}
                                id_key_name={"shipping_method_id"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Freight Term
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <VirtualizedSelect
                                required={true}
                                options={
                                  getOMFreightTermData?.length &&
                                  getOMFreightTermData
                                }
                                value={
                                  getOMFreightTermData?.find(
                                    (x) =>
                                      addCustomerInformationData?.freight_term_id ===
                                      x?.freight_term_id
                                  ) || ""
                                }
                                disabled={
                                  getOMFreightTermStatus === asyncStatus.IDLE ||
                                  getOMFreightTermStatus ===
                                    asyncStatus.LOADING ||
                                  !(addCustomerInformationMode || editMode)
                                }
                                onChange={(e) =>
                                  e?.freight_term_id
                                    ? handleChange(
                                        "freight_term_id",
                                        e,
                                        true,
                                        e?.freight_term_id
                                      )
                                    : null
                                }
                                name={"freight_term_name"}
                                id_key_name={"freight_term_id"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Bergen Billing
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <VirtualizedSelect
                                required={true}
                                options={
                                  getOMBergenBillingData?.length &&
                                  getOMBergenBillingData
                                }
                                value={
                                  getOMBergenBillingData?.find(
                                    (x) =>
                                      addCustomerInformationData?.bergan_billing_id ===
                                      x?.bergan_billing_id
                                  ) || ""
                                }
                                disabled={
                                  getOMBergenBillingStatus ===
                                    asyncStatus.IDLE ||
                                  getOMBergenBillingStatus ===
                                    asyncStatus.LOADING ||
                                  !(addCustomerInformationMode || editMode)
                                }
                                onChange={(e) =>
                                  e?.bergan_billing_id
                                    ? handleChange(
                                        "bergan_billing_id",
                                        e,
                                        true,
                                        e?.bergan_billing_id
                                      )
                                    : null
                                }
                                name={"bergan_billing_name"}
                                id_key_name={"bergan_billing_id"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Ship Via
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <VirtualizedSelect
                                required={true}
                                options={
                                  getOMShipViaData?.length && getOMShipViaData
                                }
                                value={
                                  getOMShipViaData?.find(
                                    (x) =>
                                      addCustomerInformationData?.ship_via_id ===
                                      x?.ship_via_id
                                  ) || ""
                                }
                                disabled={
                                  getOMShipViaStatus === asyncStatus.IDLE ||
                                  getOMShipViaStatus === asyncStatus.LOADING ||
                                  !(addCustomerInformationMode || editMode)
                                }
                                onChange={(e) =>
                                  e?.ship_via_id
                                    ? handleChange(
                                        "ship_via_id",
                                        e,
                                        true,
                                        e?.ship_via_id
                                      )
                                    : null
                                }
                                name={"ship_via_description"}
                                id_key_name={"ship_via_id"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Ship Account
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <InputComp
                                // type={"number"}
                                // required={true}
                                // validator={(value) => {
                                //   if (!/[0-9]/.test(value.target.value))
                                //     return "Characters not allowed";
                                //   return "";
                                // }}
                                style={{ padding: 3 }}
                                placeholder=""
                                onChange={(e) =>
                                  handleChange("ship_account", e, false, "")
                                }
                                value={addCustomerInformationData?.ship_account}
                                disabled={
                                  !(addCustomerInformationMode || editMode)
                                }
                                name={"ship_account"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Alcohol Recepient
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <VirtualizedSelect
                                required={true}
                                options={
                                  getOMShippingMethodData?.length &&
                                  getOMShippingMethodData
                                }
                                value={
                                  getOMShippingMethodData?.find(
                                    (x) =>
                                      addCustomerInformationData?.shipping_method_id ===
                                      x?.shipping_method_id
                                  ) || ""
                                }
                                disabled={
                                  getOMShippingMethodStatus ===
                                    asyncStatus.IDLE ||
                                  getOMShippingMethodStatus ===
                                    asyncStatus.LOADING ||
                                  !(addCustomerInformationMode || editMode)
                                }
                                onChange={(e) =>
                                  e?.shipping_method_id
                                    ? handleChange(
                                        "shipping_method_id",
                                        e,
                                        true,
                                        e?.shipping_method_id
                                      )
                                    : null
                                }
                                name={"shipping_method_name"}
                                id_key_name={"shipping_method_id"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Tax Duties
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <InputComp
                                // type={"number"}
                                // required={true}
                                // validator={(value) => {
                                //   if (!/[0-9]/.test(value.target.value))
                                //     return "Characters not allowed";
                                //   return "";
                                // }}
                                style={{ padding: 3 }}
                                placeholder=""
                                onChange={(e) =>
                                  handleChange("tax_duties", e, false, "")
                                }
                                value={addCustomerInformationData?.tax_duties}
                                disabled={
                                  !(addCustomerInformationMode || editMode)
                                }
                                name={"tax_duties"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Ship To Non Us
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <InputComp
                                // type={"number"}
                                // required={true}
                                // validator={(value) => {
                                //   if (!/[0-9]/.test(value.target.value))
                                //     return "Characters not allowed";
                                //   return "";
                                // }}
                                style={{ padding: 3 }}
                                placeholder=""
                                onChange={(e) =>
                                  handleChange(
                                    "e?.ship_to_non_us",
                                    e,
                                    false,
                                    ""
                                  )
                                }
                                value={
                                  addCustomerInformationData?.ship_to_non_us
                                }
                                disabled={
                                  !(addCustomerInformationMode || editMode)
                                }
                                name={"ship_to_non_us"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Bill To Non Us
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <InputComp
                                // type={"number"}
                                // required={true}
                                // validator={(value) => {
                                //   if (!/[0-9]/.test(value.target.value))
                                //     return "Characters not allowed";
                                //   return "";
                                // }}
                                style={{ padding: 3 }}
                                placeholder=""
                                onChange={(e) =>
                                  handleChange("bill_to_non_us", e, false, "")
                                }
                                value={
                                  addCustomerInformationData?.bill_to_non_us
                                }
                                disabled={
                                  !(addCustomerInformationMode || editMode)
                                }
                                name={"bill_to_non_us"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xl={6}>
                              <Typography sx={{ fontSize: 14 }}>
                                Order Type
                              </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <VirtualizedSelect
                                required={true}
                                options={
                                  getOMOrderTypeData?.length &&
                                  getOMOrderTypeData
                                }
                                value={
                                  getOMOrderTypeData?.find(
                                    (x) =>
                                      addCustomerInformationData?.order_type_id ===
                                      x?.order_type_id
                                  ) || ""
                                }
                                disabled={
                                  getOMOrderTypeStatus === asyncStatus.IDLE ||
                                  getOMOrderTypeStatus ===
                                    asyncStatus.LOADING ||
                                  !(addCustomerInformationMode || editMode)
                                }
                                onChange={(e) =>
                                  e?.order_type_id
                                    ? handleChange(
                                        "order_type_id",
                                        e,
                                        true,
                                        e?.order_type_id
                                      )
                                    : null
                                }
                                name={"order_type_name"}
                                id_key_name={"order_type_id"}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "start",
                            }}
                          >
                            {/* <Grid item xl={6}> */}
                            <Checkbox
                              defaultChecked
                              defaultValue={true}
                              // onChange={(e) =>
                              //   setAddBergenBillingData({
                              //     ...addBergenBillingData,
                              //     is_active: e.target.checked,
                              //   })
                              // }
                            />
                            <Typography sx={{ fontSize: 14 }}>
                              Ship Complete
                            </Typography>
                            {/* </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            </Grid> */}
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "start",
                            }}
                          >
                            <Checkbox
                              defaultChecked
                              defaultValue={true}
                              // onChange={(e) =>
                              //   setAddBergenBillingData({
                              //     ...addBergenBillingData,
                              //     is_active: e.target.checked,
                              //   })
                              // }
                            />
                            <Typography sx={{ fontSize: 14 }}>
                              Approved
                            </Typography>
                            {/* <Grid item xl={6}>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            </Grid> */}
                          </Grid>
                          <Grid
                            item
                            container
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                              alignItems: "center",
                              justifyContent: "start",
                            }}
                          >
                            <Checkbox
                              defaultChecked
                              defaultValue={true}
                              // onChange={(e) =>
                              //   setAddBergenBillingData({
                              //     ...addBergenBillingData,
                              //     is_active: e.target.checked,
                              //   })
                              // }
                            />
                            <Typography sx={{ fontSize: 14 }}>
                              Active
                            </Typography>
                            {/* <Grid item xl={6}>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Stack>
                    ),
                  },
                  {
                    name: "Related Transactions",
                    content: (
                      <Stack height={300} width={"100%"}>
                        <TableComp
                          columns={transictionColumns}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                  {
                    name: "Communication",
                    content: (
                      <Stack height={300} width={"100%"}>
                        <TableComp
                          columns={communicationColumns}
                          //   onRowClick={(e) => {
                          //     setSelectedStyle(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack
            padding={2}
            // id="vendorSearchForm"
            // component={"form"}
            // onSubmit={(event) => {
            //   event.preventDefault();
            //   console.log(searchData);
            // }}
          >
            <FiltersAccordionFom
              allFields={[
                {
                  title: "Customer Code :",
                  comp: (
                    <InputComp
                      type={"number"}
                      // required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setSearchCustomerInfoData({
                          ...searchCustomerInfoData,
                          customer_code: e.target.value,
                        })
                      }
                      value={searchCustomerInfoData?.customer_code}
                      // disabled={true}
                      name={"customer_code"}
                    />
                  ),
                },
                {
                  title: "Sale Person :",
                  comp:       <VirtualizedSelect
                  // required={true}
                  options={
                    getOMSalesPersonData?.length &&
                    getOMSalesPersonData
                  }
                  value={
                    getOMSalesPersonData?.find(
                      (x) =>
                        searchCustomerInfoData?.sales_person_id ===
                        x?.sales_person_id
                    ) || ""
                  }
                  // disabled={
                  //   getOMVendorCategoryStatus === asyncStatus.IDLE ||
                  //   getOMVendorCategoryStatus === asyncStatus.LOADING ||
                  //   !(addVendorMode || editMode)
                  // }
                  onChange={(e) => {
                    if (e?.salesperson_name) {
                      setSearchCustomerInfoData({
                        ...searchCustomerInfoData,
                        sales_person: e?.salesperson_name,
                      });
                    }
                  }}
                  name={"category_name"}
                  id_key_name={"sales_person_id"}
                />,
                },
                {
                  title: "Region :",
                  comp: <VirtualizedSelect />,
                },
                {
                  title: "Customer :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_code: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_code}
                      // disabled={true}
                      name={"vendor_code"}
                    />
                  ),
                },
                {
                  title: "Customer Type :",
                  comp: <VirtualizedSelect />,
                },
                {
                  title: "Term :",
                  comp: <VirtualizedSelect />,
                },
                {
                  title: "Shipping Account :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Shipping Method :",
                  comp: <VirtualizedSelect />,
                },
                {
                  title: "Freight Term :",
                  comp: <VirtualizedSelect />,
                },
                {
                  title: "Resale Certificate :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Order Status :",
                  comp: <VirtualizedSelect />,
                },
                {
                  title: "Email :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "BillAddress :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "AP First Name :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "AP Last Name :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Active :",
                  comp: <VirtualizedSelect />,
                },
                {
                  title: "Is Hold :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Customer Id :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Phone # :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
                {
                  title: "Is Approved :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
              ]}
              headerRightComp={
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    history("/customerInformation");
                  }}
                >
                  <AddIcon />
                </IconButton>
              }
              bottomChild={
                <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                  <Btn
                    // type="submit"
                    // onClick={() => {
                    //   if (!(!searchData?.from_date || !searchData?.to_date)) {
                    //     console.log(searchData);
                    //     dispatch(searchOMVendorAsync(searchData));
                    //   } else {
                    //     error_toast_message(
                    //       "Please select from data and to date first! "
                    //     );
                    //   }
                    // }}
                    style={{ width: "auto", textTransform: "none" }}
                    // loading={searchOMVendorStatus === asyncStatus.LOADING}
                  >
                    Search
                  </Btn>
                </Stack>
              }
            />

            <Stack height={500} mt={4} width={"100%"}>
              <TableComp
                onRowClick={(e) => {
                  history(`/customerInformation/${e?.row?.vendor_id}`);
                  // setCurrentVendorId(e?.row?.vendor_id);
                  setSearchMode(false);
                }}
                exportToExcel={true}
                // rows={formattedData}
                rows={[]}
                columns={[
                  {
                    field: "opening_date",
                    headerName: "Opening Date",
                    flex: true,
                  },
                  {
                    field: "customer_code",
                    headerName: "Customer Code",
                    flex: true,
                  },
                  {
                    field: "customer_name",
                    headerName: "Customer Name",
                    flex: true,
                  },
                  {
                    field: "customer_type_name",
                    headerName: "Customer Type Name",
                    flex: true,
                  },
                  { field: "office", headerName: "Email Address", flex: true },
                  { field: "mobile", headerName: "Term Name", flex: true },
                  {
                    field: "shipping_method_name",
                    headerName: "Shipping Method Name",
                    flex: true,
                  },
                  {
                    field: "release_certificate_no",
                    headerName: "Resale Certificate No",
                    flex: true,
                  },
                  {
                    field: "shipping_account_no",
                    headerName: "Shipping Account No",
                    flex: true,
                  },
                  {
                    field: "order_status",
                    headerName: "Order Status",
                    flex: true,
                  },
                  { field: "is_hold", headerName: "Is Hold", flex: true },
                  {
                    field: "is_approved",
                    headerName: "Is Approved",
                    flex: true,
                  },
                  {
                    field: "freight_term",
                    headerName: "Freight Term",
                    flex: true,
                  },
                  {
                    field: "bill_address",
                    headerName: "Bill Address",
                    flex: true,
                  },
                  { field: "is_active", headerName: "Active", flex: true },
                  {
                    field: "first_name",
                    headerName: "AP First Name",
                    flex: true,
                  },
                  {
                    field: "last_name",
                    headerName: "AP Last Name",
                    flex: true,
                  },
                  {
                    field: "sales_person",
                    headerName: "Sales Person",
                    flex: true,
                  },
                  { field: "region", headerName: "Region", flex: true },
                  { field: "gender", headerName: "Gender", flex: true },
                  { field: "phone_no", headerName: "Phone #", flex: true },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </ScreenCard>
    </Stack>
  );
};

export default CustomerInformation;
