import React from 'react'
import { Typography, Box, Card, CardActions, CardContent, Select, MenuItem } from "@mui/material";


// ##### CSS Styles #####
const styles = {
    Container: {
        backgroundColor: "#3b4a57",
        // textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // padding: 2,
        color: "#fff",
        // borderRadius: "5px",
        height: 30
    },
    summaryHeading: {
        fontSize: 14
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: 2
    },
    contentkey: {
        fontSize: 12,
        fontWeight: "bold"
    }

}


const BillingShippingInformation = ({ heading = "Billing Information", data }) => {

    const { name, first_name, last_name, address1, address2, city, state, zip, country, tel, email } = data;
    // console.log("data", data)

    const content = [
        {
            key: "Name:",
            value: <Select
                fullWidth
                size="small"
                value={"MEN"}
                style={{ height: 30, width: "100%", fontSize: 14 }}
            // onChange={handleChange}
            >
                <MenuItem
                    value={"MEN"}
                >
                    MEN
                </MenuItem>
                <MenuItem
                    value={"WOMEN"}
                >
                    WOMEN
                </MenuItem>

            </Select>
        },
        {
            key: "First Name:",
            value: first_name
        },
        {
            key: "Last Name:",
            value: last_name
        },
        {
            key: "Address 1:",
            value: address1
        },
        {
            key: "Address 2:",
            value: address2
        },
        {
            key: "City:",
            value: city
        },
        {
            key: "State:",
            value: state
        },
        {
            key: "Zip:",
            value: zip
        },
        {
            key: "Country:",
            value: country
        },
        {
            key: "Tel:",
            value: tel
        },
        {
            key: "Email:",
            value: email
        },
    ];




    return (
        // <div style={{ width: "48%" }}>
            <Box sx={{ margin: "10px 0px 10px 0px", border: "1px solid black", }}>
                <Card >
                    <CardContent>
                        <div style={styles.Container}>
                            {heading}
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                            {
                                content.map((v, i) => {
                                    return (
                                        <div key={i} style={{ width: "48%", padding: 3, display: "flex", gap: 10, fontSize: 14, alignItems: "center", lineHeight: 2, flexWrap: "wrap" }}>{v?.key} <div style={{ width: "80%" }}>{v?.value}</div></div>
                                    )
                                })
                            }
                        </div>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Box>
        // </div>
    )
}

export default BillingShippingInformation;