import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import {
  addOMAdminJobAsync,
  deleteOMAdminJobAsync,
  editOMAdminJobAsync,
  getOMAdminJobAsync,
} from "../../../store/services/orderManagementService";
const AdminJob = () => {
  const dispatch = useDispatch();
  const {
    getOMAdminJobStatus,
    getOMAdminJobError,
    getOMAdminJobData,
    addOMAdminJobStatus,
    addOMAdminJobData,
    addOMAdminJobError,

    editOMAdminJobStatus,
    editOMAdminJobError,
    editOMAdminJobData,

    deleteOMAdminJobData,
    deleteOMAdminJobStatus,
    deleteOMAdminJobError,
  } = useSelector((state) => state.om);

  useEffect(() => {
    dispatch(getOMAdminJobAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMAdminJobData?.length
    ? getOMAdminJobData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedAdminJob, setSelectedAdminJob] = useState();
  const [addAdminJobMode, setAddAdminJobMode] = useState(false);
  const [addAdminJobData, setAddAdminJobData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedAdminJob", selectedAdminJob);
  // console.log("editData", editData);
  useEffect(() => {
    if (addOMAdminJobStatus === asyncStatus.SUCCEEDED) {
      setAddAdminJobMode(false);
      setSaveDisabled(true);
      setAddAdminJobData();
    }
  }, [, addOMAdminJobStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteOMAdminJobAsync(selectedAdminJob?.job_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteOMAdminJobStatus === asyncStatus.SUCCEEDED||deleteOMAdminJobStatus === asyncStatus.ERROR) {
      setOpen(false);
      setSelectedAdminJob();
    }
  }, [, deleteOMAdminJobStatus]);
  useEffect(() => {
    if (editOMAdminJobStatus === asyncStatus.SUCCEEDED) {
      setSelectedAdminJob();
      setEditMode(false);
      setEditData()
    }
  }, [, editOMAdminJobStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this admin job?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Admin Job"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Admin Job Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddAdminJobMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addAdminJobMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addAdminJobMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddAdminJobMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addAdminJobMode || !selectedAdminJob}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMAdminJobStatus === asyncStatus.LOADING ||
                  editOMAdminJobStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addAdminJobData) {
                    dispatch(addOMAdminJobAsync(addAdminJobData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedAdminJob, ...editData };
                    delete a.id;
                    dispatch(editOMAdminJobAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={500} width={"100%"}>
              <TableComp
                columns={[
                  { field: "job_id", headerName: "ID", flex: true },
                  { field: "job_title", headerName: "Admin Job", flex: true },
                ]}
                onRowClick={(e) => {
                  setSelectedAdminJob(e?.row);
                }}
                rows={formattedData}
                loading={
                  getOMAdminJobStatus === asyncStatus.IDLE ||
                  getOMAdminJobStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addAdminJobMode ? (
              <InputWithBtnLayoutComp
              error={addOMAdminJobError}
                inputArray={[
                  {
                    label: "Admin Job:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddAdminJobData({
                            ...addAdminJobData,
                            job_title: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddAdminJobData({
                            ...addAdminJobData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedAdminJob ? (
              <InputWithBtnLayoutComp
              error={editOMAdminJobError}
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedAdminJob?.job_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "AdminJob:",
                    comp: (
                      <InputComp
                        key={selectedAdminJob?.job_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            job_title: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedAdminJob?.job_title}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={selectedAdminJob?.is_active|| editData?.is_active}
                        disabled={!editMode}
                        // defaultValue={selectedAdminJob?.is_active || editData?.is_active}
                        defaultChecked={selectedAdminJob?.is_active || editData?.is_active}
                        // checked={selectedAdminJob?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Stack>
              <Typography>Select any admin job</Typography>
              <Typography color={"red"} fontSize={12}>{getOMAdminJobError}</Typography>
              </Stack>
            )}
            <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedAdminJob(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedAdminJob(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default AdminJob;
