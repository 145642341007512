import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import {
  addOMCATAsync,
  deleteOMCATAsync,
  editOMCATAsync,
  getOMCATAsync,
  getOMRegionAsync,
  getOMSalesPersonAsync,
} from "../../../store/services/orderManagementService";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
const CAT = () => {
  const dispatch = useDispatch();
  const {
    getOMCATStatus,
    getOMCATError,
    getOMCATData,
    addOMCATStatus,
    addOMCATData,
    addOMCATError,

    editOMCATStatus,
    editOMCATError,
    editOMCATData,

    deleteOMCATData,
    deleteOMCATStatus,
    deleteOMCATError,

    getOMRegionStatus,
    getOMRegionError,
    getOMRegionData,

    getOMSalesPersonStatus,
    getOMSalesPersonError,
    getOMSalesPersonData,
  } = useSelector((state) => state.om);

  useEffect(() => {
    dispatch(getOMCATAsync());
    dispatch(getOMSalesPersonAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMCATData?.length
    ? getOMCATData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        salesperson_name:getOMSalesPersonData?.find(x=>x?.sales_person_id===style?.sales_person_id)?.salesperson_name,
        region_name:getOMRegionData?.find(x=>x?.region_id===style?.region_id)?.region_name,
        ...style,
      }))
    : [];
  const [selectedCAT, setSelectedCAT] = useState();
  const [addCATMode, setAddCATMode] = useState(false);
  const [addCATData, setAddCATData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedCAT", selectedCAT);
  // console.log("editData", editData);
  useEffect(() => {
    if (addOMCATStatus === asyncStatus.SUCCEEDED) {
      setAddCATMode(false);
      setSaveDisabled(true);
      setAddCATData();
    }
  }, [, addOMCATStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteOMCATAsync(selectedCAT?.sp_region_category_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (
      deleteOMCATStatus === asyncStatus.SUCCEEDED ||
      deleteOMCATStatus === asyncStatus.ERROR
    ) {
      setOpen(false);
      setSelectedCAT();
    }
  }, [, deleteOMCATStatus]);
  useEffect(() => {
    if (editOMCATStatus === asyncStatus.SUCCEEDED) {
      setSelectedCAT();
      setEditMode(false);
      setEditData();
    }
  }, [, editOMCATStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)

  useEffect(() => {
    dispatch(getOMRegionAsync());
  }, []);
console.log(selectedCAT);
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this category?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Category"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Category
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddCATMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addCATMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addCATMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddCATMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addCATMode || !selectedCAT}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMCATStatus === asyncStatus.LOADING ||
                  editOMCATStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addCATData) {
                    dispatch(addOMCATAsync(addCATData));
                  } else if (editMode) {
                    let a= { ...selectedCAT, ...editData };
                    // delete a.sp_region_category_id;
                    console.log(a);
                    dispatch(editOMCATAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={500} width={"100%"}>
              <TableComp
                columns={[
                  { field: "sp_region_category_id", headerName: "Category ID", flex: true },
                  { field: "region_name", headerName: "Region Name", flex: true },
                  { field: "salesperson_name", headerName: "Sales Person", flex: true },
                ]}
                onRowClick={(e) => {
                  setSelectedCAT(e?.row);
                }}
                rows={formattedData}
                loading={
                  getOMCATStatus === asyncStatus.IDLE ||
                  getOMCATStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addCATMode ? (
              <InputWithBtnLayoutComp
                error={addOMCATError}
                inputArray={[
                  {
                    label: "Region:",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMRegionData?.length && getOMRegionData}
                        value={
                          getOMRegionData?.find(
                            (x) => addCATData?.region_id === x?.region_id
                          ) || ""
                        }
                        disabled={
                          getOMRegionStatus === asyncStatus.IDLE ||
                          getOMRegionStatus === asyncStatus.LOADING ||
                          !(addCATMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.region_id) {
                            setAddCATData({
                              ...addCATData,
                              region_id: e?.region_id,
                            });
                          }
                        }}
                        name={"region_name"}
                        id_key_name={"region_id"}
                      />
                    ),
                  },
                  {
                    label: "Sale Person:",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMSalesPersonData?.length && getOMSalesPersonData}
                        value={
                          getOMSalesPersonData?.find(
                            (x) => addCATData?.sales_person_id === x?.sales_person_id
                          ) || ""
                        }
                        disabled={
                          getOMSalesPersonStatus === asyncStatus.IDLE ||
                          getOMSalesPersonStatus === asyncStatus.LOADING ||
                          !(addCATMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.sales_person_id) {
                            setAddCATData({
                              ...addCATData,
                              sales_person_id: e?.sales_person_id,
                            });
                          }
                        }}
                        name={"salesperson_name"}
                        id_key_name={"sales_person_id"}
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddCATData({
                            ...addCATData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedCAT ? (
              <InputWithBtnLayoutComp
                error={editOMCATError}
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedCAT?.sp_region_category_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "Region:",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMRegionData?.length && getOMRegionData}
                        value={
                          editData?.region_id?  getOMRegionData?.find(
                            (x) => editData?.region_id === x?.region_id
                          ) : getOMRegionData?.find(
                            (x) => selectedCAT?.region_id === x?.region_id
                          ) 
                        }
                        disabled={
                          getOMRegionStatus === asyncStatus.IDLE ||
                          getOMRegionStatus === asyncStatus.LOADING ||
                          !(addCATMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.region_id) {
                            setEditData({
                              ...editData,
                              region_id: e?.region_id,
                            });
                          }
                        }}
                        name={"region_name"}
                        id_key_name={"region_id"}
                      />
                    ),
                  },
                  {
                    label: "Sale Person:",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMSalesPersonData?.length && getOMSalesPersonData}
                        value={
                          editData?.sales_person_id?  getOMSalesPersonData?.find(
                            (x) => editData?.sales_person_id === x?.sales_person_id
                          ) :
                         getOMSalesPersonData?.find(
                            (x) => selectedCAT?.sales_person_id === x?.sales_person_id
                          ) 
                        }
                        disabled={
                          getOMSalesPersonStatus === asyncStatus.IDLE ||
                          getOMSalesPersonStatus === asyncStatus.LOADING ||
                          !(addCATMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.sales_person_id) {
                            setEditData({
                              ...editData,
                              sales_person_id: e?.sales_person_id,
                            });
                          }
                        }}
                        name={"salesperson_name"}
                        id_key_name={"sales_person_id"}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={selectedCAT?.is_active || editData?.is_active}
                        disabled={!editMode}
                        // defaultValue={selectedCAT?.is_active || editData?.is_active}
                        defaultChecked={
                          selectedCAT?.is_active || editData?.is_active
                        }
                        // checked={selectedCAT?.is_active}
                      />
                    ),
                  },
                  
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Stack>
                <Typography>Select any category</Typography>
                <Typography color={"red"} fontSize={12}>
                  {getOMCATError}
                </Typography>
              </Stack>
            )}
            <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedCAT(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedCAT(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default CAT;
