import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../../components/common/InputComp/Input";
import TabComp from "../../../../components/TabsComp/TabComp";
import Btn from "../../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../../utils/asyncStatus";
import { primaryColor } from "../../../../utils/themeColors";
import {
  addProductStyleAsync,
  addProductSeasonAsync,
  deleteProductSeasonAsync,
  editProductStyleAsync,
  editProductSeasonAsync,
  getProductStyleAsync,
  getProductSeasonAsync,
} from "../../../../store/services/productInfoServices";
const Season = () => {
  const dispatch = useDispatch();
  const {
    getProductSeasonStatus,
    getProductSeasonError,
    getProductSeasonData,
    addProductSeasonStatus,
    addProductSeasonData,
    addProductSeasonError,

    editProductSeasonStatus,
    editProductSeasonError,
    editProductSeasonData,

    deleteProductSeasonData,
    deleteProductSeasonStatus,
    deleteProductSeasonError,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProductSeasonAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getProductSeasonData?.length
    ? getProductSeasonData.map((style, index) => ({
      id: index + 1, // Assuming the index is a unique identifier for each row
      ...style,
    }))
    : [];
  const [selectedSeason, setSelectedSeason] = useState();
  const [addSeasonMode, setAddSeasonMode] = useState(false);
  const [addSeasonData, setAddSeasonData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedSeason", selectedSeason);
  // console.log("editData", editData);
  useEffect(() => {
    if (addProductSeasonStatus === asyncStatus.SUCCEEDED) {
      setAddSeasonMode(false);
      setSaveDisabled(true);
      setAddSeasonData();
    }
  }, [, addProductSeasonStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteProductSeasonAsync(selectedSeason?.season_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteProductSeasonStatus === asyncStatus.SUCCEEDED) {
      setOpen(false);
      setSelectedSeason();
    }
  }, [, deleteProductSeasonStatus]);
  useEffect(() => {
    if (editProductSeasonStatus === asyncStatus.SUCCEEDED) {
      setSelectedSeason();
      setEditMode(false);
    }
  }, [, editProductSeasonStatus]);
  console.log("getProductSeasonData", getProductSeasonData, editProductSeasonData);
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this Season?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Season"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Season Name
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddSeasonMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addSeasonMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addSeasonMode || !selectedSeason}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddSeasonMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addSeasonMode || !selectedSeason}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addProductSeasonStatus === asyncStatus.LOADING ||
                  editProductSeasonStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addSeasonData) {
                    dispatch(addProductSeasonAsync(addSeasonData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedSeason, ...editData };
                    delete a.id;
                    dispatch(editProductSeasonAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={"500px"} width={"100%"}>
              <TableComp
                columns={[
                  { field: "season_id", headerName: "ID", flex: true },
                  {
                    field: "season_name",
                    headerName: "Season Name",
                    flex: true,
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedSeason(e?.row);
                }}
                rows={formattedData}
                loading={
                  getProductSeasonStatus === asyncStatus.IDLE ||
                  getProductSeasonStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addSeasonMode ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "Season:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddSeasonData({
                            ...addSeasonData,
                            season_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddSeasonData({
                            ...addSeasonData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedSeason ? (
              <InputWithBtnLayoutComp
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedSeason?.season_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "Season:",
                    comp: (
                      <InputComp
                        key={selectedSeason?.season_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            season_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedSeason?.season_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={editData?.is_active}
                        disabled={!editMode}
                        defaultValue={
                          selectedSeason?.is_active ? true : false
                        }
                        defaultChecked={
                          selectedSeason?.is_active ? true : false
                        }
                        checked={selectedSeason?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Typography>Select any season</Typography>
            )}

            <Stack>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {getProductSeasonError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {addProductSeasonError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {editProductSeasonError}
              </Typography>
              <Typography sx={{ fontSize: 14, color: "red" }}>
                {deleteProductSeasonError}
              </Typography>
            </Stack>
            <Stack my={2}>
              <TabComp
                tabs={[
                  // {
                  //   name: "Companies",
                  //   content: (
                  //     <Stack height={400} width={"100%"}>
                  //       <TableComp
                  //         columns={[
                  //           {
                  //             field: "companyId",
                  //             headerName: "Company ID",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "companyName",
                  //             headerName: "Company Name",
                  //             flex: true,
                  //           },
                  //           {
                  //             field: "action",
                  //             headerName: "Action",
                  //             flex: true,
                  //           },
                  //         ]}
                  //         //   onRowClick={(e) => {
                  //         //     setSelectedSeason(e?.row);
                  //         //   }}
                  //         rows={[]}
                  //         //   loading={
                  //         //     getProductStyleStatus === asyncStatus.IDLE ||
                  //         //     getProductStyleStatus === asyncStatus.LOADING
                  //         //   }
                  //       />
                  //     </Stack>
                  //   ),
                  // },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedSeason(e?.row);
                          //   }}
                          rows={[]}
                        //   loading={
                        //     getProductStyleStatus === asyncStatus.IDLE ||
                        //     getProductStyleStatus === asyncStatus.LOADING
                        //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default Season;
