import { Grid, Stack, Typography, Chip } from "@mui/material";
import React from "react";
import InputComp from "../common/InputComp/Input";
import { themeLightGray } from "../../utils/themeColors";

const InfoFormComp = ({
  title = "Information",
  fieldsArray = [
    {
      label: "",
      comp: <InputComp />,
    },
    {
      label: "b:",
      comp: <InputComp />,
    },
    {
      label: "c:",
      comp: <InputComp />,
    },
    {
      label: "d:",
      comp: <InputComp />,
    },
  ],
  customHeader,
  noHeader,
  customColumns,
  customChild,
  bottomChild,
}) => {
  return (
    <>
      <Stack>
        {!noHeader &&
          (customHeader || (
            <Typography
              sx={{
                color: "black",
                backgroundColor: themeLightGray,
                paddingX: 2,
                paddingY: 1,
              }}
            >
              {title}
            </Typography>
          ))}
        {customChild || (
          <Grid container padding={4} spacing={2}>
            {fieldsArray?.length &&
              fieldsArray?.map((e,i) => {
                const { label, comp } = e;

                return (
                  <>
                    <Grid
                    key={i}
                      item
                      container
                      xl={customColumns || 4}
                      lg={customColumns || 4}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xl={6}>
                        <Typography sx={{ fontSize: 14 }}>{label}</Typography>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        {comp}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            {bottomChild}
          </Grid>
        )}
      </Stack>
    </>
  );
};

export default InfoFormComp;
