import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { mainRoutes } from "../../utils/routeList.js";
import { PrivateRoutes } from "./PrivateRoutes/PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes/PublicRoutes";
import { getTokenFromCookie } from "../../helpers/cookieFunctions.js";
import { useDispatch, useSelector } from "react-redux";
import { setUserAuth } from "../../store/slices/auth_slice.js";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { exit_session } from "../apiHandle/apiHandle.js";
import { CircularProgress, Stack } from "@mui/material";
import AppBarMenu from "../../components/CustomAppBar/AppBarMenu.js";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar.js";
import CustomDrawer from "../../components/CustomDrawer/CustomDrawer.js";

function AppRouter() {
  const dispatch = useDispatch();
  const { userAuth } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let token = getTokenFromCookie();
    if (token) {
      const user = jwtDecode(token);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (isExpired) {
        exit_session();
        setLoading(false);
      } else {
        dispatch(setUserAuth(true));
        setLoading(false);
      }
    }
    setLoading(false);
  }, []);
  // const titles = {
  //   "/city": "Home",
  //   "/about": "About",
  // };

  // useLayoutEffect(() => {
  //   console.log("location");
  //   if (window) {
  //     let dynamicTitle = mainRoutes.find(x=>x?.linkTo===window?.location.pathname)?.name
  //     console.log(dynamicTitle);
  //     document.title = mainRoutes.find(x=>x?.linkTo===window?.location.pathname)?.name || "ERP V2";
  //   }
  // }, [, window]);

  if (loading) {
    return (
      <Stack
        width={1}
        height={"100vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Router>
      {/* {userAuth && <Navbar />} */}

      {userAuth && (
        <div style={{ position: "sticky", top: 1, zIndex: 101 }}>
          <CustomAppBar />
          <CustomDrawer />
        </div>
      )}
      <Routes>
        <Route element={<PublicRoutes />}>
          {React.Children.toArray(
            mainRoutes.map((route, i) => {
              const { linkTo, element, authRequired, check_cond } = route;
              return (
                !authRequired && (
                  <Route key={i} element={element} path={linkTo} />
                )
              );
            })
          )}
        </Route>
        <Route element={<PrivateRoutes />}>
          {React.Children.toArray(
            mainRoutes.map((route, i) => {
              const { linkTo, element, authRequired, home } = route;
              return (
                authRequired && (
                  <Route key={i} element={element} path={linkTo} />
                )
              );
            })
          )}
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;
