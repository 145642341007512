import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import {
  addOMCustomerInformationAsync,
  deleteOMCustomerInformationAsync,
  editOMCustomerInformationAsync,
  getOMBergenBillingAsync,
  getOMCATAsync,
  getOMCountryAsync,
  getOMCustomerInformationAsync,
  getOMCustomerTypeAsync,
  getOMFreightTermAsync,
  getOMOrderStatusAsync,
  getOMOrderTypeAsync,
  getOMPaymentTermAsync,
  getOMShipViaAsync,
  getOMShippingMethodAsync,
  getOMCityAsync,
  getOMPtStatusAsync,
  getOMStateAsync,
} from "../../../store/services/orderManagementService";
import FiltersAccordionFom from "../../../components/FiltersAccordionFom/FiltersAccordionFom";
import InfoFormComp from "../../../components/InfoForm/InfoFormComp";
import { useNavigate } from "react-router-dom";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
import {
  getProductCompanyAsync,
  getProductGenderAsync,
} from "../../../store/services/productInfoServices";
import CustomModal from "../../../components/common/Modal/CustomModal";
import { RxCross1 } from "react-icons/rx";
const SalesPerson = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const {
    getProductCompanyStatus,
    getProductCompanyData,

    getProductGenderStatus,
    getProductGenderError,
    getProductGenderData,
  } = useSelector((state) => state.product);
  const {
    getOMCustomerInformationStatus,
    getOMCustomerInformationError,
    getOMCustomerInformationData,
    addOMCustomerInformationStatus,
    addOMCustomerInformationData,
    addOMCustomerInformationError,

    editOMCustomerInformationStatus,
    editOMCustomerInformationError,
    editOMCustomerInformationData,

    deleteOMCustomerInformationData,
    deleteOMCustomerInformationStatus,
    deleteOMCustomerInformationError,

    getOMCustomerTypeStatus,
    getOMCustomerTypeError,
    getOMCustomerTypeData,

    getOMOrderStatusStatus,
    getOMOrderStatusError,
    getOMOrderStatusData,

    getOMPaymentTermStatus,
    getOMPaymentTermError,
    getOMPaymentTermData,

    getOMCATStatus,
    getOMCATError,
    getOMCATData,

    getOMShippingMethodData,
    getOMShippingMethodStatus,
    getOMShippingMethodError,

    getOMFreightTermStatus,
    getOMFreightTermError,
    getOMFreightTermData,

    getOMBergenBillingStatus,
    getOMBergenBillingError,
    getOMBergenBillingData,

    getOMShipViaStatus,
    getOMShipViaError,
    getOMShipViaData,

    getOMOrderTypeStatus,
    getOMOrderTypeError,
    getOMOrderTypeData,

    getOMCountryStatus,
    getOMCountryError,
    getOMCountryData,

    getOMStateStatus,
    getOMStateError,
    getOMStateData,

    getOMCityStatus,
    getOMCityError,
    getOMCityData,
  } = useSelector((state) => state.om);

  const [customerAddInfoData, setCustomerAddInfoData] = useState({}); //obj for address
  const [addressDataSource, setAddressDataSource] = useState([]); //for lines

  const [customerCatInfoData, setCustomerCatInfoData] = useState({}); //obj for address
  const [catDataSource, setCatDataSource] = useState([]); //for lines

  // useEffect(() => {
  //   dispatch(getOMCustomerInformationAsync());
  // }, []);
  const [selectedCustomerInformation, setSelectedCustomerInformation] =
    useState();
  const [addCustomerInformationData, setAddCustomerInformationData] = useState(
    {}
  );
  // console.log("getProductStyleData", getProductStyleData);
  const formattedDataAddress = addressDataSource?.length
    ? addressDataSource.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];

  const formattedDataCategory = catDataSource?.length
    ? catDataSource.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  useEffect(() => {
    console.log(catDataSource, "catDataSource");
  }, [catDataSource]);
  const [addCustomerInformationMode, setAddCustomerInformationMode] =
    useState(false);

  useEffect(() => {
    // console.log(addCustomerInformationData, "111");
  }, [addCustomerInformationData]);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [searchMode, setSearchMode] = useState(false);

  const [addMode, setAddMode] = useState(false);

  // console.log("selectedCustomerInformation", selectedCustomerInformation);
  // console.log("editData", editData);
  useEffect(() => {
    if (addOMCustomerInformationStatus === asyncStatus.SUCCEEDED) {
      setAddCustomerInformationMode(false);
      setSaveDisabled(true);
      setAddCustomerInformationData();
    }
  }, [addOMCustomerInformationStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(
      deleteOMCustomerInformationAsync(selectedCustomerInformation?.term_id)
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(()=>{
  //   console.log(customerInfoData,'customerInfoData')
  // },[customerInfoData])

  const generateCombinations = (inputCase) => {
    if (inputCase === "address") {
      let copyArr = [...addressDataSource];
      copyArr.push(customerAddInfoData);
      setAddressDataSource(copyArr);
    } else if (inputCase === "cat") {
      let copyArr = [...catDataSource];
      copyArr.push(customerCatInfoData);
      setCatDataSource(copyArr);
    }
  };

  // console.log("getOMCountryAsync", getOMCountryAsync)
  // useEffect(() => {
  //   if (deleteOMCustomerInformationStatus === asyncStatus.SUCCEEDED || deleteOMCustomerInformationStatus === asyncStatus.ERROR ) {
  //     setOpen(false);
  //     setSelectedCustomerInformation();
  //   }
  // }, [, deleteOMCustomerInformationStatus]);
  // useEffect(() => {
  //   if (editOMCustomerInformationStatus === asyncStatus.SUCCEEDED) {
  //     setSelectedCustomerInformation();
  //     setEditMode(false);
  //     setEditData()
  //   }
  // }, [, editOMCustomerInformationStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)

  useEffect(() => {
    console.log(addCustomerInformationData, "5555");
  }, [addCustomerInformationData]);

  useEffect(() => {
    dispatch(getProductCompanyAsync());
    dispatch(getOMCustomerTypeAsync());
    dispatch(getOMOrderStatusAsync());
    dispatch(getOMPaymentTermAsync());
    dispatch(getProductGenderAsync());
    dispatch(getOMCATAsync());
    dispatch(getOMShippingMethodAsync());
    dispatch(getOMFreightTermAsync());
    dispatch(getOMBergenBillingAsync());
    dispatch(getOMShipViaAsync());
    dispatch(getOMOrderTypeAsync());
    dispatch(getOMCountryAsync());
    dispatch(getOMCityAsync());
    dispatch(getOMStateAsync());
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [modalName, setModalName] = useState("");
  const handleOpenModal = (name) => {
    setOpenModal(true);
    setModalName(name);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setModalName("");
  };

  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={
          "Are you sure you want to delete this customer information?"
        }
        heading={"Delete request"}
      />
      <CustomModal
        handleClose={handleClose}
        open={openModal}
        children={
          <Stack
            width={"100%"}
            minWidth={{ xl: 700, lg: 600, md: 500, sm: 300, xs: 200 }}
            alignItems={"center"}
            padding={1}
          >
            {modalName === "Address" ? (
              <Stack width={1} spacing={2}>
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={14} fontWeight={"bold"}>
                    Address Form
                  </Typography>
                  <IconButton onClick={handleCloseModal}>
                    <RxCross1 color="red" size={20} />
                  </IconButton>
                </Stack>
                <Divider />
                <Stack>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        First Name
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            first_name: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Last Name
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            last_name: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Addressee
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            addressee: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Address 1
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            adddress_1: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Address 2
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            address_2: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Country
                      </Typography>

                      <VirtualizedSelect
                        required={true}
                        options={getOMCountryData?.length && getOMCountryData}
                        value={
                          getOMCountryData?.find(
                            (x) =>
                              customerAddInfoData?.country === x?.country_id
                          ) || ""
                        }
                        // disabled={
                        //   getOMCountryStatus === asyncStatus.IDLE ||
                        //   getOMCountryStatus === asyncStatus.LOADING ||
                        //   !(addMode || editMode)
                        // }
                        onChange={(e) => {
                          if (e?.country_id) {
                            setCustomerAddInfoData({
                              ...customerAddInfoData,
                              country: e?.country_id,
                            });
                          }
                        }}
                        name={"country_name"}
                        id_key_name={"country_id"}
                      />

                      {/* <InputComp style={{ padding: 3 }} placeholder="" /> */}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        City
                      </Typography>
                      {/* <InputComp style={{ padding: 3 }} placeholder="" /> */}
                      <VirtualizedSelect
                        required={true}
                        options={getOMCityData?.length && getOMCityData}
                        value={
                          getOMCityData?.find(
                            (x) => customerAddInfoData?.city === x?.city_id
                          ) || ""
                        }
                        // disabled={
                        //   getOMCountryStatus === asyncStatus.IDLE ||
                        //   getOMCountryStatus === asyncStatus.LOADING ||
                        //   !(addMode || editMode)
                        // }
                        onChange={(e) => {
                          // console.log(getOMCityData, "getOMCityData");
                          if (e?.city_id) {
                            setCustomerAddInfoData({
                              ...customerAddInfoData,
                              city: e?.city_id,
                            });
                          }
                        }}
                        name={"city_name"}
                        id_key_name={"city_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        State
                      </Typography>
                      {/* <InputComp style={{ padding: 3 }} placeholder="" /> */}
                      <VirtualizedSelect
                        required={true}
                        options={getOMStateData?.length && getOMStateData}
                        value={
                          getOMStateData?.find(
                            (x) => customerAddInfoData?.state === x?.state_id
                          ) || ""
                        }
                        // disabled={
                        //   getOMCountryStatus === asyncStatus.IDLE ||
                        //   getOMCountryStatus === asyncStatus.LOADING ||
                        //   !(addMode || editMode)
                        // }
                        onChange={(e) => {
                          if (e?.state_id) {
                            setCustomerAddInfoData({
                              ...customerAddInfoData,
                              state: e?.state_id,
                            });
                          }
                        }}
                        name={"state_name"}
                        id_key_name={"state_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Email
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            email: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Fax
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            fax: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Phone
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Zip
                      </Typography>
                      <InputComp
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            zip: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Active
                      </Typography>
                      {/* <Checkbox
                          defaultChecked
                          defaultValue={true}
                          sx={{ padding: 0, margin: 0 }}
                          // onChange={(e) =>
                          //   setAddVendorData({
                          //     ...addVendorData,
                          //     is_active: e.target.checked,
                          //   })
                          // }
                        /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            is_active: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Default Billing
                      </Typography>
                      {/* <Checkbox
                          defaultChecked
                          defaultValue={true}
                          sx={{ padding: 0, margin: 0 }}
                          // onChange={(e) =>
                          //   setAddVendorData({
                          //     ...addVendorData,
                          //     is_active: e.target.checked,
                          //   })
                          // }
                        /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            default_billing: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      xs={6}
                      flexDirection={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Default shipping
                      </Typography>
                      {/* <Checkbox
                          defaultChecked
                          defaultValue={true}
                          sx={{ padding: 0, margin: 0 }}
                          // onChange={(e) =>
                          //   setAddVendorData({
                          //     ...addVendorData,
                          //     is_active: e.target.checked,
                          //   })
                          // }
                        /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) =>
                          setCustomerAddInfoData({
                            ...customerAddInfoData,
                            default_shipping: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={2}
                >
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    variant="outlined"
                    // disabled={!(addVendorMode || editMode)}
                    onClick={handleCloseModal}
                  >
                    Back
                  </Btn>
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    // disabled={!(addVendorMode || editMode)}
                    onClick={() => {
                      generateCombinations("address");
                      handleCloseModal();
                    }}
                  >
                    Submit
                  </Btn>
                </Stack>
              </Stack>
            ) : modalName === "Category" ? (
              <Stack width={1} spacing={2}>
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={14} fontWeight={"bold"}>
                    Category Form
                  </Typography>
                  <IconButton onClick={handleCloseModal}>
                    <RxCross1 color="red" size={20} />
                  </IconButton>
                </Stack>
                <Divider />
                <Stack>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Gender
                      </Typography>
                      <VirtualizedSelect
                        required={true}
                        options={
                          getProductGenderData?.length && getProductGenderData
                        }
                        value={
                          getProductGenderData?.find(
                            (x) =>
                              customerCatInfoData?.gender_id === x?.gender_id
                          ) || ""
                        }
                        disabled={
                          getProductGenderStatus === asyncStatus.IDLE ||
                          getProductGenderStatus === asyncStatus.LOADING
                        }
                        onChange={(e) => {
                          if (e?.gender_id) {
                            setCustomerCatInfoData({
                              ...customerCatInfoData,
                              gender: e?.gender_id,
                            });
                          }
                        }}
                        name={"gender_name"}
                        id_key_name={"gender_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Region Category
                      </Typography>
                      <VirtualizedSelect
                        required={true}
                        options={getOMCATData?.length && getOMCATData}
                        value={
                          getOMCATData?.find(
                            (x) =>
                              addCustomerInformationData?.sp_region_category_id ===
                              x?.sp_region_category_id
                          ) || ""
                        }
                        disabled={
                          getOMCATStatus === asyncStatus.IDLE ||
                          getOMCATStatus === asyncStatus.LOADING
                        }
                        onChange={(e) => {
                          if (e?.sp_region_category_id) {
                            setCustomerCatInfoData({
                              ...customerCatInfoData,
                              category_id: e?.sp_region_category_id,
                            });
                          }
                        }}
                        name={"description"}
                        id_key_name={"sp_region_category_id"}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "gray" }}
                      >
                        Active
                      </Typography>
                      {/* <Checkbox
                          defaultChecked
                          defaultValue={true}
                          sx={{ padding: 0, margin: 0 }}
                          // onChange={(e) =>
                          //   setAddVendorData({
                          //     ...addVendorData,
                          //     is_active: e.target.checked,
                          //   })
                          // }
                        /> */}
                      <Switch
                        defaultValue={true}
                        onChange={(e) => {
                          setCustomerCatInfoData({
                            ...customerCatInfoData,
                            active: e?.target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  width={"100%"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={2}
                >
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    variant="outlined"
                    // disabled={!(addVendorMode || editMode)}
                    onClick={handleCloseModal}
                  >
                    Back
                  </Btn>
                  <Btn
                    type="button"
                    style={{ textTransform: "none", minWidth: "100px" }}
                    // disabled={!(addVendorMode || editMode)}
                    onClick={() => {
                      generateCombinations("cat");
                      handleCloseModal();
                    }}
                  >
                    Submit
                  </Btn>
                </Stack>
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        }
      />
      <ScreenCard
        title="Sales Person"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Sales Person{" "}
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddMode(false);
                  setAddCustomerInformationMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addCustomerInformationMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addCustomerInformationMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddCustomerInformationMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={
                  addCustomerInformationMode || !selectedCustomerInformation
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMCustomerInformationStatus === asyncStatus.LOADING ||
                  editOMCustomerInformationStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addCustomerInformationData) {
                    dispatch(
                      addOMCustomerInformationAsync({
                        addCustomerInformationData,
                        addressDataSource,
                        catDataSource,
                      })
                    );
                  } else if (editMode) {
                    let a;
                    a = { ...selectedCustomerInformation, ...editData };
                    delete a.id;
                    dispatch(editOMCustomerInformationAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        {" "}
        {!searchMode ? (
          <Stack>
            <InfoFormComp
              customColumns={4}
              title="Primary Information"
              fieldsArray={[
                {
                  label: "Company :",
                  comp: (
                    <VirtualizedSelect
                      required={true}
                      options={
                        getProductCompanyData?.length && getProductCompanyData
                      }
                      value={
                        getProductCompanyData?.find(
                          (x) =>
                            addCustomerInformationData?.company_id ===
                            x?.company_id
                        ) || ""
                      }
                      disabled={
                        getProductCompanyStatus === asyncStatus.IDLE ||
                        getProductCompanyStatus === asyncStatus.LOADING ||
                        !(addCustomerInformationMode || editMode)
                      }
                      onChange={(e) => {
                        if (e?.company_id) {
                          setAddCustomerInformationData({
                            ...addCustomerInformationData,
                            company_id: e?.company_id,
                          });
                        }
                      }}
                      name={"company_name"}
                      id_key_name={"company_id"}
                    />
                  ),
                },
                {
                  label: "Employee Id :",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setAddVendorData({
                      //     ...addVendorData,
                      //     vendor_id: e.target.value,
                      //   })
                      // }
                      // value={addVendorData?.vendor_id}
                      disabled={true}
                      name={"customer_code"}
                    />
                  ),
                },
                {
                  label: "Name :",
                  comp: (
                    <InputComp
                      type={"text"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddCustomerInformationData({
                          ...addCustomerInformationData,
                          customer_name: e.target.value,
                        })
                      }
                      // value={addVendorData?.vendor_id}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"customer_name"}
                    />
                  ),
                },
                {
                  label: "Hire Date :",
                  comp: (
                    <InputComp
                    type={"date"}
                    required={true}
                    // validator={(value) => {
                    //   if (!/[0-9]/.test(value.target.value))
                    //     return "Characters not allowed";
                    //   return "";
                    // }}
                    style={{ padding: 3 }}
                    placeholder=""
                    onChange={(e) =>
                      setAddCustomerInformationData({
                        ...addCustomerInformationData,
                        resale_expiry_date: e.target.value,
                      })
                    }
                    value={addCustomerInformationData?.resale_expiry_date}
                    disabled={!(addCustomerInformationMode || editMode)}
                    name={"resale_expiry_date"}
                  />
                  ),
                },
              ]}
            />
            <InfoFormComp
              customColumns={4}
              title="Email | Phone | Address"
              fieldsArray={[
                {
                  label: "Email :",
                  comp: (
                    <InputComp
                      type={"email"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddCustomerInformationData({
                          ...addCustomerInformationData,
                          other_email_address: e.target.value,
                        })
                      }
                      value={addCustomerInformationData?.other_email_address}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"other_email_address"}
                    />
                  ),
                },
                {
                  label: "Office Phone :",
                  comp: (
                    <InputComp
                      type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddCustomerInformationData({
                          ...addCustomerInformationData,
                          phone: e.target.value,
                        })
                      }
                      value={addCustomerInformationData?.phone}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"phone"}
                    />
                  ),
                },
                {
                  label: "Mobile Phone :",
                  comp: (
                    <InputComp
                      type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddCustomerInformationData({
                          ...addCustomerInformationData,
                          phone: e.target.value,
                        })
                      }
                      value={addCustomerInformationData?.phone}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"phone"}
                    />
                  ),
                },
                {
                  label: "Home Phone :",
                  comp: (
                    <InputComp
                      type={"number"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddCustomerInformationData({
                          ...addCustomerInformationData,
                          phone: e.target.value,
                        })
                      }
                      value={addCustomerInformationData?.phone}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"phone"}
                    />
                  ),
                },
                {
                  label: "Fax :",
                  comp: (
                    <InputComp
                      type={"text"}
                      required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      onChange={(e) =>
                        setAddCustomerInformationData({
                          ...addCustomerInformationData,
                          fax: e.target.value,
                        })
                      }
                      value={addCustomerInformationData?.fax}
                      disabled={!(addCustomerInformationMode || editMode)}
                      name={"fax"}
                    />
                  ),
                },
                {
                    label: "Is SalesPerson :",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        // onChange={(e) =>
                        //   setAddAdminCurrencyData({
                        //     ...addAdminCurrencyData,
                        //     is_active: e.target.checked,
                        //   })
                        // }
                      />
                    ),
                  },
              ]}
            />
          
          

          
          </Stack>
        ) : (
          <Stack
            padding={2}
            // id="vendorSearchForm"
            // component={"form"}
            // onSubmit={(event) => {
            //   event.preventDefault();
            //   console.log(searchData);
            // }}
          >
            <FiltersAccordionFom
              allFields={[
                {
                  title: "Vendor Id",
                  comp: (
                    <InputComp
                      type={"number"}
                      // required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_id: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_id}
                      // disabled={true}
                      name={"vendor_id"}
                    />
                  ),
                },
                {
                  title: "Mobile Phone",
                  comp: (
                    <InputComp
                      type={"number"}
                      // required={true}
                      validator={(value) => {
                        if (!/[0-9]/.test(value.target.value))
                          return "Characters not allowed";
                        return "";
                      }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     mobile: e.target.value,
                      //   })
                      // }
                      // value={searchData?.mobile}
                      // disabled={true}
                      name={"mobile"}
                    />
                  ),
                },
                {
                  title: "Fax",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     fax: e.target.value,
                      //   })
                      // }
                      // value={searchData?.fax}
                      // disabled={true}
                      name={"fax"}
                    />
                  ),
                },
                {
                  title: "Vendor Code",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_code: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_code}
                      // disabled={true}
                      name={"vendor_code"}
                    />
                  ),
                },
                {
                  title: "Vendor Name",
                  comp: (
                    <InputComp
                      // type={"number"}
                      // required={true}
                      // validator={(value) => {
                      //   if (!/[0-9]/.test(value.target.value))
                      //     return "Characters not allowed";
                      //   return "";
                      // }}
                      style={{ padding: 3 }}
                      placeholder=""
                      // onChange={(e) =>
                      //   setSearchData({
                      //     ...searchData,
                      //     vendor_name: e.target.value,
                      //   })
                      // }
                      // value={searchData?.vendor_name}
                      // disabled={true}
                      name={"vendor_name"}
                    />
                  ),
                },
              ]}
              headerRightComp={
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    history("/customerInformation");
                  }}
                >
                  <AddIcon />
                </IconButton>
              }
              bottomChild={
                <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                  <Btn
                    // type="submit"
                    // onClick={() => {
                    //   if (!(!searchData?.from_date || !searchData?.to_date)) {
                    //     console.log(searchData);
                    //     dispatch(searchOMVendorAsync(searchData));
                    //   } else {
                    //     error_toast_message(
                    //       "Please select from data and to date first! "
                    //     );
                    //   }
                    // }}
                    style={{ width: "auto", textTransform: "none" }}
                    // loading={searchOMVendorStatus === asyncStatus.LOADING}
                  >
                    Search
                  </Btn>
                </Stack>
              }
            />

            <Stack height={500} mt={4} width={"100%"}>
              <TableComp
                // onRowClick={(e) => {
                //   console.log("aaaaaaaaaaaaaaa", e.row);
                //   history(`/vendor/${e?.row?.vendor_id}`);
                //   setCurrentVendorId(e?.row?.vendor_id);
                //   setSearchMode(false);
                // }}
                exportToExcel={true}
                // rows={formattedData}
                rows={[]}
                columns={[
                  { field: "vendor_id", headerName: "Vendor Id", flex: true },
                  {
                    field: "vendor_name",
                    headerName: "Vendor Name",
                    flex: true,
                  },
                  {
                    field: "vendor_code",
                    headerName: "Vendor Code",
                    flex: true,
                  },
                  {
                    field: "web_address",
                    headerName: "Web Address",
                    flex: true,
                  },
                  { field: "office", headerName: "Office", flex: true },
                  { field: "mobile", headerName: "Mobile", flex: true },
                  { field: "fax", headerName: "Fax", flex: true },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </ScreenCard>
    </Stack>
  );
};

export default SalesPerson;
