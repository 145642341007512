import React, { useState } from "react";
import InputComp from "../../../../components/common/InputComp/Input";
import Btn from "../../../../components/common/ButtonComp/Btn";
import FiltersAccordionFom from "../../../../components/FiltersAccordionFom/FiltersAccordionFom";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import { IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { primaryColor } from "../../../../utils/themeColors";
import TableComp from "../../../../components/common/TableComp/TableComp";

const ReturnAuthorization = () => {
    const [filterFields, setFilterFields] = useState({});

    const handleChange = (fieldName, e) => {
      setFilterFields({
        ...filterFields,
        [fieldName]: e.target.value,
      });
    };
  return (
    <Stack padding={2}>
      <ScreenCard
        title="Sale Return List"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Sale Return List
            </Typography>
            <Stack flexDirection={"row"} gap={1}></Stack>
          </Stack>
        }
      >
        {" "}
        <Stack
          padding={2}
          // id="vendorSearchForm"
          // component={"form"}
          // onSubmit={(event) => {
          //   event.preventDefault();
          //   console.log(searchData);
          // }}
        >
          <FiltersAccordionFom
            allFields={[
              {
                title: "RA Approval#:",
                comp: (
                  <InputComp
                    type={"number"}
                    // required={true}
                    validator={(value) => {
                      if (!/[0-9]/.test(value.target.value))
                        return "Characters not allowed";
                      return "";
                    }}
                    style={{ padding: 3 }}
                    onChange={(e) => handleChange("raApprovalNo", e)}
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_id: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_id}
                    // disabled={true}
                    name={"raApprovalNo"}
                  />
                ),
              },
              {
                title: "Return# :",
                comp: (
                  <InputComp
                    style={{ padding: 3 }}
                    onChange={(e) => handleChange("returnNo", e)}
                    name={"returnNo"}
                  />
                ),
              },
              {
                title: "Invoice# :",
                comp: (
                  <InputComp
                    type={"number"}
                    style={{ padding: 3 }}
                    onChange={(e) => handleChange("invoiceNo", e)}
                    name={"invoiceNo"}
                  />
                ),
              },
              {
                title: "Order# :",
                comp: (
                  <InputComp
                    type={"number"}
                    // required={true}
                    // validator={(value) => {
                    //   if (!/[0-9]/.test(value.target.value))
                    //     return "Characters not allowed";
                    //   return "";
                    // }}
                    style={{ padding: 3 }}
                    placeholder=""
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_code: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_code}
                    // disabled={true}
                    onChange={(e) => handleChange("orderNo", e)}
                    name={"orderNo"}
                  />
                ),
              },
              {
                title: "From Date :",
                comp: (
                  <InputComp
                    type={"date"}
                    onChange={(e) => handleChange("fromDate", e)}
                    style={{ padding: 3 }}
                  />
                ),
              },
              {
                title: "To Date :",
                comp: (
                  <InputComp
                  type={'date'}
                    style={{ padding: 3 }}
                    onChange={(e) => handleChange("toDate", e)}
                  />
                ),
              },
              {
                title: "Customer Name:",
                comp: (
                  <InputComp
                    type={"text"}
                    // required={true}
                    // validator={(value) => {
                    //   if (!/[0-9]/.test(value.target.value))
                    //     return "Characters not allowed";
                    //   return "";
                    // }}
                    style={{ padding: 3 }}
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_name: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_name}
                    // disabled={true}
                    onChange={e=>handleChange('customerName',e)}
                    name={"customerName"}
                  />
                ),
              },
              {
                title: "Memo :",
                comp: <InputComp style={{ padding: 3 }}  onChange={e=>handleChange('memo',e)} />,
              },
              {
                title: "Gender :",
                comp: <InputComp style={{ padding: 3 }}  onChange={e=>handleChange('gender_name',e)} name={'gender_name'}/>,
              },
              {
                title: "Region :",
                comp: (
                  <InputComp
                    // type={"number"}
                    // required={true}
                    // validator={(value) => {
                    //   if (!/[0-9]/.test(value.target.value))
                    //     return "Characters not allowed";
                    //   return "";
                    // }}
                    style={{ padding: 3 }}
                    // onChange={(e) =>
                    //   setSearchData({
                    //     ...searchData,
                    //     vendor_name: e.target.value,
                    //   })
                    // }
                    // value={searchData?.vendor_name}
                    // disabled={true}
                    onChange={e=>handleChange('region',e)}
                    name={"region"}
                  />
                ),
              },
              {
                title: "P.O No :",
                comp: <InputComp style={{ padding: 3 }}  onChange={e=>handleChange('poNo',e)} name={'poNo'}/>,
              },
              {
                title: "Location :",
                comp: <InputComp style={{ padding: 3 }}  onChange={e=>handleChange('location',e)} name={'location'}/>,
              },
              {
                title: "Sales Person :",
                comp: <InputComp style={{ padding: 3 }}  onChange={e=>handleChange('salesPerson',e)} name={'salesPerson'}/>,
              },
              {
                title: "PaymentTerm :",
                comp: <InputComp style={{ padding: 3 }}  onChange={e=>handleChange('paymentTerm',e)} name={'paymentTerm'}/>,
              },
            ]}
            headerRightComp={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // history("/customerInformation");
                }}
              >
                <AddIcon />
              </IconButton>
            }
            bottomChild={
              <Stack flexDirection={"row"} justifyContent={"flex-end"}>
                <Btn
                  // type="submit"
                  // onClick={() => {
                  //   if (!(!searchData?.from_date || !searchData?.to_date)) {
                  //     console.log(searchData);
                  //     dispatch(searchOMVendorAsync(searchData));
                  //   } else {
                  //     error_toast_message(
                  //       "Please select from data and to date first! "
                  //     );
                  //   }
                  // }}
                  style={{ width: "auto", textTransform: "none" }}
                  // loading={searchOMVendorStatus === asyncStatus.LOADING}
                >
                  Search
                </Btn>
              </Stack>
            }
          />

          <Stack height={500} mt={4} width={"100%"}>
            <TableComp
              onRowClick={(e) => {
                //   history(`/customerInformation/${e?.row?.vendor_id}`);
                // setCurrentVendorId(e?.row?.vendor_id);
                //   setSearchMode(false);
              }}
              exportToExcel={true}
              // rows={formattedData}
              rows={[]}
              columns={[
                {
                  field: "returnNo",
                  headerName: "Return#",
                  flex: true,
                },
                {
                  field: "raApprovalNo",
                  headerName: "RA Approval#",
                  flex: true,
                },
                {
                  field: "returnDate",
                  headerName: "Return Date",
                  flex: true,
                },
                {
                  field: "invoiceNo",
                  headerName: "Invoice#",
                  flex: true,
                },
                { field: "memo", headerName: "Memo", flex: true },
                { field: "orderNo", headerName: "Order#", flex: true },
                {
                  field: "poNo",
                  headerName: "PO#",
                  flex: true,
                },
                {
                  field: "customer",
                  headerName: "Customer",
                  flex: true,
                },
                {
                  field: "salesPerson",
                  headerName: "Sales Person",
                  flex: true,
                },
                {
                  field: "genderName",
                  headerName: "Gender",
                  flex: true,
                },
                {
                  field: "region",
                  headerName: "Region",
                  flex: true,
                },
                {
                  field: "Location",
                  headerName: "Location",
                  flex: true,
                },
                {
                  field: "Term",
                  headerName: "Term",
                  flex: true,
                },
              ]}
            />
          </Stack>
        </Stack>
      </ScreenCard>
    </Stack>
  )
}

export default ReturnAuthorization
