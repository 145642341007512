function setTokenCookie(token, expirationInMinutes) {
  const expirationDate = new Date(
    Date.now() + expirationInMinutes * 60 * 60 * 1000
  );
  const cookieValue = `token=${token}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = cookieValue;
}

// Example usage:
// const authToken = "yourJWTTokenHere";
// setTokenCookie(authToken, 30); // Expires in 30 minutes
function getTokenFromCookie() {
  const cookieName = "token";
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === "token") {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

// // Example usage:
// const storedToken = getTokenFromCookie();
// console.log(storedToken);
function deleteTokenCookie() {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

// // Example usage:
// deleteTokenCookie();
export { setTokenCookie, getTokenFromCookie, deleteTokenCookie };
