import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
// import {
//   addOMUserCompanyAccessAsync,
//   deleteOMUserCompanyAccessAsync,
//   editOMUserCompanyAccessAsync,
//   getOMUserCompanyAccessAsync,
// } from "../../../store/services/orderManagementService";
const UserCompanyAccess = () => {
  const dispatch = useDispatch();
  const {
    getOMUserCompanyAccessStatus,
    getOMUserCompanyAccessError,
    getOMUserCompanyAccessData,
    addOMUserCompanyAccessStatus,
    addOMUserCompanyAccessData,
    addOMUserCompanyAccessError,

    editOMUserCompanyAccessStatus,
    editOMUserCompanyAccessError,
    editOMUserCompanyAccessData,

    deleteOMUserCompanyAccessData,
    deleteOMUserCompanyAccessStatus,
    deleteOMUserCompanyAccessError,
  } = useSelector((state) => state.om);

  //   useEffect(() => {
  //     dispatch(getOMUserCompanyAccessAsync());
  //   }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMUserCompanyAccessData?.length
    ? getOMUserCompanyAccessData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedUserCompanyAccess, setSelectedUserCompanyAccess] = useState();
  const [addUserCompanyAccessMode, setAddUserCompanyAccessMode] =
    useState(false);
  const [addUserCompanyAccessData, setAddUserCompanyAccessData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedUserCompanyAccess", selectedUserCompanyAccess);
  // console.log("editData", editData);
  //   useEffect(() => {
  //     if (addOMUserCompanyAccessStatus === asyncStatus.SUCCEEDED) {
  //       setAddUserCompanyAccessMode(false);
  //       setSaveDisabled(true);
  //       setAddUserCompanyAccessData();
  //     }
  //   }, [, addOMUserCompanyAccessStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    // dispatch(deleteOMUserCompanyAccessAsync(selectedUserCompanyAccess?.term_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  //   useEffect(() => {
  //     if (
  //       deleteOMUserCompanyAccessStatus === asyncStatus.SUCCEEDED ||
  //       deleteOMUserCompanyAccessStatus === asyncStatus.ERROR
  //     ) {
  //       setOpen(false);
  //       setSelectedUserCompanyAccess();
  //     }
  //   }, [, deleteOMUserCompanyAccessStatus]);
  //   useEffect(() => {
  //     if (editOMUserCompanyAccessStatus === asyncStatus.SUCCEEDED) {
  //       setSelectedUserCompanyAccess();
  //       setEditMode(false);
  //       setEditData();
  //     }
  //   }, [, editOMUserCompanyAccessStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this company?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Company"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Company
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddUserCompanyAccessMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addUserCompanyAccessMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addUserCompanyAccessMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddUserCompanyAccessMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={
                  addUserCompanyAccessMode || !selectedUserCompanyAccess
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMUserCompanyAccessStatus === asyncStatus.LOADING ||
                  editOMUserCompanyAccessStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                // onClick={() => {
                //   if (addUserCompanyAccessData) {
                //     dispatch(addOMUserCompanyAccessAsync(addUserCompanyAccessData));
                //   } else if (editMode) {
                //     let a;
                //     a = { ...selectedUserCompanyAccess, ...editData };
                //     delete a.id;
                //     dispatch(editOMUserCompanyAccessAsync(a));
                //   }
                // }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={500} width={"100%"}>
              <TableComp
                columns={[
                  {
                    field: "term_id",
                    headerName: "User Company ID",
                    flex: true,
                  },
                  {
                    field: "user_id",
                    headerName: "User Id",
                    flex: true,
                  },
                  {
                    field: "company_id",
                    headerName: "Company Id",
                    flex: true,
                  },
                  {
                    field: "user_name",
                    headerName: "User Name",
                    flex: true,
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedUserCompanyAccess(e?.row);
                }}
                rows={formattedData}
                loading={
                  getOMUserCompanyAccessStatus === asyncStatus.IDLE ||
                  getOMUserCompanyAccessStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addUserCompanyAccessMode ? (
              //   <Grid container>

              //     <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              //       <Typography
              //         sx={{ color: "gray", fontSize: 14, wordWrap: "break-word" }}
              //       >
              //         sad
              //       </Typography>
              //     </Grid>
              //     <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              //       <InputComp
              //         style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
              //         placeholder=""
              //         // onChange={(e) =>
              //         //   setAddUserCompanyAccessData({
              //         //     ...addUserCompanyAccessData,
              //         //     term_name: e.target.value,
              //         //   })
              //         // }
              //       />
              //     </Grid>
              //   </Grid>
              <Stack gap={2}>
                <Stack paddingLeft={2} gap={2}>

                <Grid container>
                  <Grid item container xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          color: "gray",
                          fontSize: 14,
                          wordWrap: "break-word",
                        }}
                      >
                        User:
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        // onChange={(e) =>
                        //   setAddUserCompanyAccessData({
                        //     ...addUserCompanyAccessData,
                        //     term_name: e.target.value,
                        //   })
                        // }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item container xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          color: "gray",
                          fontSize: 14,
                          wordWrap: "break-word",
                        }}
                      >
                        Company:
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        // onChange={(e) =>
                        //   setAddUserCompanyAccessData({
                        //     ...addUserCompanyAccessData,
                        //     term_name: e.target.value,
                        //   })
                        // }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                </Stack>

                <Grid container>
                  <Grid item container xl={3} lg={3} md={4} sm={6} xs={12} >
                    <Grid  item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          color: "gray",
                          fontSize: 14,
                          wordWrap: "break-word",
                        }}
                      >
                        Active:
                      </Typography>
                    </Grid>
                    <Grid  item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Checkbox
                            defaultChecked
                            defaultValue={true}
                            sx={{padding:0}}
                            // onChange={(e) =>
                            //   setAddUserCompanyAccessData({
                            //     ...addUserCompanyAccessData,
                            //     is_active: e.target.checked,
                            //   })
                            // }
                          />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item container xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          color: "gray",
                          fontSize: 14,
                          wordWrap: "break-word",
                        }}
                      >
                        Default:
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Checkbox
                            defaultChecked
                            sx={{padding:0}}
                            defaultValue={true}
                            // onChange={(e) =>
                            //   setAddUserCompanyAccessData({
                            //     ...addUserCompanyAccessData,
                            //     is_active: e.target.checked,
                            //   })
                            // }
                          />
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            ) : //   <InputWithBtnLayoutComp
            //         error={addOMUserCompanyAccessError}
            //         inputArray={[
            //           {
            //             label: "Company:",
            //             comp: (
            //               <InputComp
            //                 style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
            //                 placeholder=""
            //                 onChange={(e) =>
            //                   setAddUserCompanyAccessData({
            //                     ...addUserCompanyAccessData,
            //                     term_name: e.target.value,
            //                   })
            //                 }
            //               />
            //             ),
            //           },
            //           {
            //             label: "Active:",
            //             comp: (
            //               <Checkbox
            //                 defaultChecked
            //                 defaultValue={true}
            //                 onChange={(e) =>
            //                   setAddUserCompanyAccessData({
            //                     ...addUserCompanyAccessData,
            //                     is_active: e.target.checked,
            //                   })
            //                 }
            //               />
            //             ),
            //           },
            //         ]}
            //         secondaryComp={<></>}
            //       />
            selectedUserCompanyAccess ? (
              <InputWithBtnLayoutComp
                error={editOMUserCompanyAccessError}
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedUserCompanyAccess?.term_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "UserCompanyAccess:",
                    comp: (
                      <InputComp
                        key={selectedUserCompanyAccess?.term_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            term_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedUserCompanyAccess?.term_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={
                          selectedUserCompanyAccess?.is_active ||
                          editData?.is_active
                        }
                        disabled={!editMode}
                        // defaultValue={selectedUserCompanyAccess?.is_active || editData?.is_active}
                        defaultChecked={
                          selectedUserCompanyAccess?.is_active ||
                          editData?.is_active
                        }
                        // checked={selectedUserCompanyAccess?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Stack>
                <Typography>Select any company</Typography>
                <Typography color={"red"} fontSize={12}>
                  {getOMUserCompanyAccessError}
                </Typography>
              </Stack>
            )}
            <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedUserCompanyAccess(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedUserCompanyAccess(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default UserCompanyAccess;
