export const session_expired = "@session_expired"

export const save_tokens_constant = "@usertokens"
export const type_constants = {
  LOGIN: "LOGIN",
  GET_STYLE: "GET_STYLE",
  ADD_STYLE: "ADD_STYLE",
  EDIT_STYLE: "EDIT_STYLE",
  DELETE_STYLE: "DELETE_STYLE",
  GET_WASH: "GET_WASH",
  ADD_WASH: "ADD_WASH",
  EDIT_WASH: "EDIT_WASH",
  DELETE_WASH: "DELETE_WASH",
  GET_CUT: "GET_CUT",
  ADD_CUT: "ADD_CUT",
  EDIT_CUT: "EDIT_CUT",
  DELETE_CUT: "DELETE_CUT",
  GET_FABRIC_CLASS: "GET_FABRIC_CLASS",
  ADD_FABRIC_CLASS: "ADD_FABRIC_CLASS",
  EDIT_FABRIC_CLASS: "EDIT_FABRIC_CLASS",
  DELETE_FABRIC_CLASS: "DELETE_FABRIC_CLASS",
  GET_FABRIC_TYPE: "GET_FABRIC_TYPE",
  ADD_FABRIC_TYPE: "ADD_FABRIC_TYPE",
  EDIT_FABRIC_TYPE: "EDIT_FABRIC_TYPE",
  DELETE_FABRIC_TYPE: "DELETE_FABRIC_TYPE",
  GET_FABRIC_USABLE: "GET_FABRIC_USABLE",
  ADD_FABRIC_USABLE: "ADD_FABRIC_USABLE",
  EDIT_FABRIC_USABLE: "EDIT_FABRIC_USABLE",
  DELETE_FABRIC_USABLE: "DELETE_FABRIC_USABLE",
  GET_FABRIC_CLASS_TWO: "GET_FABRIC_CLASS_TWO",
  ADD_FABRIC_CLASS_TWO: "ADD_FABRIC_CLASS_TWO",
  EDIT_FABRIC_CLASS_TWO: "EDIT_FABRIC_CLASS_TWO",
  DELETE_FABRIC_CLASS_TWO: "DELETE_FABRIC_CLASS_TWO",
  GET_FABRIC_COMPOSITION: "GET_FABRIC_COMPOSITION",
  ADD_FABRIC_COMPOSITION: "ADD_FABRIC_COMPOSITION",
  EDIT_FABRIC_COMPOSITION: "EDIT_FABRIC_COMPOSITION",
  DELETE_FABRIC_COMPOSITION: "DELETE_FABRIC_COMPOSITION",
  GET_FABRIC: "GET_FABRIC",
  ADD_FABRIC: "ADD_FABRIC",
  EDIT_FABRIC: "EDIT_FABRIC",
  DELETE_FABRIC: "DELETE_FABRIC",
  GET_SUBSIDIARY: "GET_SUBSIDIARY",
  ADD_SUBSIDIARY: "ADD_SUBSIDIARY",
  EDIT_SUBSIDIARY: "EDIT_SUBSIDIARY",
  DELETE_SUBSIDIARY: "DELETE_SUBSIDIARY",
  GET_GENDER: "GET_GENDER",
  ADD_GENDER: "ADD_GENDER",
  EDIT_GENDER: "EDIT_GENDER",
  DELETE_GENDER: "DELETE_GENDER",
  GET_WASH_TYPE: "GET_WASH_TYPE",
  ADD_WASH_TYPE: "ADD_WASH_TYPE",
  EDIT_WASH_TYPE: "EDIT_WASH_TYPE",
  DELETE_WASH_TYPE: "DELETE_WASH_TYPE",
  GET_SEASON: "GET_SEASON",
  ADD_SEASON: "ADD_SEASON",
  EDIT_SEASON: "EDIT_SEASON",
  DELETE_SEASON: "DELETE_SEASON",
  GET_GENDER_CATEGORY: "GET_GENDER_CATEGORY",
  ADD_GENDER_CATEGORY: "ADD_GENDER_CATEGORY",
  EDIT_GENDER_CATEGORY: "EDIT_GENDER_CATEGORY",
  DELETE_GENDER_CATEGORY: "DELETE_GENDER_CATEGORY",
  GET_FIT_CATEGORY: "GET_FIT_CATEGORY",
  ADD_FIT_CATEGORY: "ADD_FIT_CATEGORY",
  EDIT_FIT_CATEGORY: "EDIT_FIT_CATEGORY",
  DELETE_FIT_CATEGORY: "DELETE_FIT_CATEGORY",
  GET_RISE: "GET_RISE",
  ADD_RISE: "ADD_RISE",
  EDIT_RISE: "EDIT_RISE",
  DELETE_RISE: "DELETE_RISE",
  GET_CHARGE_BACK_TYPE: "GET_CHARGE_BACK_TYPE",
  ADD_CHARGE_BACK_TYPE: "ADD_CHARGE_BACK_TYPE",
  EDIT_CHARGE_BACK_TYPE: "EDIT_CHARGE_BACK_TYPE",
  DELETE_CHARGE_BACK_TYPE: "DELETE_CHARGE_BACK_TYPE",
  GET_RISE_LABEL: "GET_RISE_LABEL",
  ADD_RISE_LABEL: "ADD_RISE_LABEL",
  EDIT_RISE_LABEL: "EDIT_RISE_LABEL",
  DELETE_RISE_LABEL: "DELETE_RISE_LABEL",
  GET_PRODUCT_TYPE: "GET_PRODUCT_TYPE",
  ADD_PRODUCT_TYPE: "ADD_PRODUCT_TYPE",
  EDIT_PRODUCT_TYPE: "EDIT_PRODUCT_TYPE",
  DELETE_PRODUCT_TYPE: "DELETE_PRODUCT_TYPE",
  GET_SEASON_YEAR: "GET_SEASON_YEAR",
  ADD_SEASON_YEAR: "ADD_SEASON_YEAR",
  EDIT_SEASON_YEAR: "EDIT_SEASON_YEAR",
  DELETE_SEASON_YEAR: "DELETE_SEASON_YEAR",
  GET_PRODUCT_STATUS: "GET_PRODUCT_STATUS",
  ADD_PRODUCT_STATUS: "ADD_PRODUCT_STATUS",
  EDIT_PRODUCT_STATUS: "EDIT_PRODUCT_STATUS",
  DELETE_PRODUCT_STATUS: "DELETE_PRODUCT_STATUS",
  GET_PRODUCT_CLASS: "GET_PRODUCT_CLASS",
  ADD_PRODUCT_CLASS: "ADD_PRODUCT_CLASS",
  EDIT_PRODUCT_CLASS: "EDIT_PRODUCT_CLASS",
  DELETE_PRODUCT_CLASS: "DELETE_PRODUCT_CLASS",
  GET_INSEAM: "GET_INSEAM",
  ADD_INSEAM: "ADD_INSEAM",
  EDIT_INSEAM: "EDIT_INSEAM",
  DELETE_INSEAM: "DELETE_INSEAM",
  GET_SIZE: "GET_SIZE",
  ADD_SIZE: "ADD_SIZE",
  EDIT_SIZE: "EDIT_SIZE",
  DELETE_SIZE: "DELETE_SIZE",
  GET_ITEM_TYPE: "GET_ITEM_TYPE",
  ADD_ITEM_TYPE: "ADD_ITEM_TYPE",
  EDIT_ITEM_TYPE: "EDIT_ITEM_TYPE",
  DELETE_ITEM_TYPE: "DELETE_ITEM_TYPE",
  GET_INSEAM_LABEL: "GET_INSEAM_LABEL",
  ADD_INSEAM_LABEL: "ADD_INSEAM_LABEL",
  EDIT_INSEAM_LABEL: "EDIT_INSEAM_LABEL",
  DELETE_INSEAM_LABEL: "DELETE_INSEAM_LABEL",
  GET_GENDER_CATEGORY_BY_ID: "GET_GENDER_CATEGORY_BY_ID",
  GET_COMPANY: "GET_COMPANY",
  GET_SIZE_CHART_BY_GENDER_CATEGORY_ID: "GET_SIZE_CHART_BY_GENDER_CATEGORY_ID",
  ADD_ITEM: "ADD_ITEM",
  PUT_ITEM: "PUT_ITEM",
  GET_ITEM: "GET_ITEM",
  GET_ITEM_BY_ID: "GET_ITEM_BY_ID",
  GET_COMBINATIONS_BY_ITEM_ID: "GET_COMBINATIONS_BY_ITEM_ID",
  DELETE_ITEM:"DELETE_ITEM",
  GET_LOCATION: "GET_LOCATION",



  // Order Management 
  GET_OM_CITY:"GET_OM_CITY",
  POST_OM_CITY:"POST_OM_CITY",
  EDIT_OM_CITY:"EDIT_OM_CITY",
  DELETE_OM_CITY:"DELETE_OM_CITY",
  GET_OM_PAYMENT_METHOD_TYPE:"GET_OM_PAYMENT_METHOD_TYPE",
  POST_OM_PAYMENT_METHOD_TYPE:"POST_OM_PAYMENT_METHOD_TYPE",
  EDIT_OM_PAYMENT_METHOD_TYPE:"EDIT_OM_PAYMENT_METHOD_TYPE",
  DELETE_OM_PAYMENT_METHOD_TYPE:"DELETE_OM_PAYMENT_METHOD_TYPE",
  GET_OM_ADMIN_JOB:"GET_OM_ADMIN_JOB",
  POST_OM_ADMIN_JOB:"POST_OM_ADMIN_JOB",
  EDIT_OM_ADMIN_JOB:"EDIT_OM_ADMIN_JOB",
  DELETE_OM_ADMIN_JOB:"DELETE_OM_ADMIN_JOB",
  GET_OM_TERM_FOR_PRINT:"GET_OM_TERM_FOR_PRINT",
  POST_OM_TERM_FOR_PRINT:"POST_OM_TERM_FOR_PRINT",
  EDIT_OM_TERM_FOR_PRINT:"EDIT_OM_TERM_FOR_PRINT",
  DELETE_OM_TERM_FOR_PRINT:"DELETE_OM_TERM_FOR_PRINT",
  GET_OM_ADMIN_DISCOUNT:"GET_OM_ADMIN_DISCOUNT",
  POST_OM_ADMIN_DISCOUNT:"POST_OM_ADMIN_DISCOUNT",
  EDIT_OM_ADMIN_DISCOUNT:"EDIT_OM_ADMIN_DISCOUNT",
  DELETE_OM_ADMIN_DISCOUNT:"DELETE_OM_ADMIN_DISCOUNT",
  GET_OM_ADMIN_CURRENCY:"GET_OM_ADMIN_CURRENCY",
  POST_OM_ADMIN_CURRENCY:"POST_OM_ADMIN_CURRENCY",
  EDIT_OM_ADMIN_CURRENCY:"EDIT_OM_ADMIN_CURRENCY",
  DELETE_OM_ADMIN_CURRENCY:"DELETE_OM_ADMIN_CURRENCY",
  GET_OM_STATE:"GET_OM_STATE",
  POST_OM_STATE:"POST_OM_STATE",
  EDIT_OM_STATE:"EDIT_OM_STATE",
  DELETE_OM_STATE:"DELETE_OM_STATE",
  GET_OM_FACTOR:"GET_OM_FACTOR",
  POST_OM_FACTOR:"POST_OM_FACTOR",
  EDIT_OM_FACTOR:"EDIT_OM_FACTOR",
  DELETE_OM_FACTOR:"DELETE_OM_FACTOR",
  GET_OM_CUSTOMER_TYPE:"GET_OM_CUSTOMER_TYPE",
  POST_OM_CUSTOMER_TYPE:"POST_OM_CUSTOMER_TYPE",
  EDIT_OM_CUSTOMER_TYPE:"EDIT_OM_CUSTOMER_TYPE",
  DELETE_OM_CUSTOMER_TYPE:"DELETE_OM_CUSTOMER_TYPE",
  GET_OM_COUNTRY:"GET_OM_COUNTRY",
  POST_OM_COUNTRY:"POST_OM_COUNTRY",
  EDIT_OM_COUNTRY:"EDIT_OM_COUNTRY",
  DELETE_OM_COUNTRY:"DELETE_OM_COUNTRY",
  GET_OM_PAYMENT_TYPE:"GET_OM_PAYMENT_TYPE",
  POST_OM_PAYMENT_TYPE:"POST_OM_PAYMENT_TYPE",
  EDIT_OM_PAYMENT_TYPE:"EDIT_OM_PAYMENT_TYPE",
  DELETE_OM_PAYMENT_TYPE:"DELETE_OM_PAYMENT_TYPE",
  GET_OM_PAYMENT_TERM:"GET_OM_PAYMENT_TERM",
  POST_OM_PAYMENT_TERM:"POST_OM_PAYMENT_TERM",
  EDIT_OM_PAYMENT_TERM:"EDIT_OM_PAYMENT_TERM",
  DELETE_OM_PAYMENT_TERM:"DELETE_OM_PAYMENT_TERM",
  GET_OM_BERGEN_PAYMENT_TERM:"GET_OM_BERGEN_PAYMENT_TERM",
  POST_OM_BERGEN_PAYMENT_TERM:"POST_OM_BERGEN_PAYMENT_TERM",
  EDIT_OM_BERGEN_PAYMENT_TERM:"EDIT_OM_BERGEN_PAYMENT_TERM",
  DELETE_OM_BERGEN_PAYMENT_TERM:"DELETE_OM_BERGEN_PAYMENT_TERM",
  GET_OM_FREIGHT_TERM:"GET_OM_FREIGHT_TERM",
  POST_OM_FREIGHT_TERM:"POST_OM_FREIGHT_TERM",
  EDIT_OM_FREIGHT_TERM:"EDIT_OM_FREIGHT_TERM",
  DELETE_OM_FREIGHT_TERM:"DELETE_OM_FREIGHT_TERM",
  GET_OM_ORDER_STATUS:"GET_OM_ORDER_STATUS",
  POST_OM_ORDER_STATUS:"POST_OM_ORDER_STATUS",
  EDIT_OM_ORDER_STATUS:"EDIT_OM_ORDER_STATUS",
  DELETE_OM_ORDER_STATUS:"DELETE_OM_ORDER_STATUS",
  GET_OM_REGION:"GET_OM_REGION",
  POST_OM_REGION:"POST_OM_REGION",
  EDIT_OM_REGION:"EDIT_OM_REGION",
  DELETE_OM_REGION:"DELETE_OM_REGION",
  GET_OM_COMMIT_CRITERIA:"GET_OM_COMMIT_CRITERIA",
  POST_OM_COMMIT_CRITERIA:"POST_OM_COMMIT_CRITERIA",
  EDIT_OM_COMMIT_CRITERIA:"EDIT_OM_COMMIT_CRITERIA",
  DELETE_OM_COMMIT_CRITERIA:"DELETE_OM_COMMIT_CRITERIA",
  GET_OM_SHIP_STATUS:"GET_OM_SHIP_STATUS",
  POST_OM_SHIP_STATUS:"POST_OM_SHIP_STATUS",
  EDIT_OM_SHIP_STATUS:"EDIT_OM_SHIP_STATUS",
  DELETE_OM_SHIP_STATUS:"DELETE_OM_SHIP_STATUS",
  GET_OM_SHIP_STATUS:"GET_OM_SHIP_STATUS",
  POST_OM_SHIP_STATUS:"POST_OM_SHIP_STATUS",
  EDIT_OM_SHIP_STATUS:"EDIT_OM_SHIP_STATUS",
  DELETE_OM_SHIP_STATUS:"DELETE_OM_SHIP_STATUS",
  GET_OM_VENDOR_CATEGORY:"GET_OM_VENDOR_CATEGORY",
  POST_OM_VENDOR_CATEGORY:"POST_OM_VENDOR_CATEGORY",
  EDIT_OM_VENDOR_CATEGORY:"EDIT_OM_VENDOR_CATEGORY",
  DELETE_OM_VENDOR_CATEGORY:"DELETE_OM_VENDOR_CATEGORY",
  GET_OM_COURIER_SERVICE:"GET_OM_COURIER_SERVICE",
  POST_OM_COURIER_SERVICE:"POST_OM_COURIER_SERVICE",
  EDIT_OM_COURIER_SERVICE:"EDIT_OM_COURIER_SERVICE",
  DELETE_OM_COURIER_SERVICE:"DELETE_OM_COURIER_SERVICE",
  GET_OM_RETURN_REASON:"GET_OM_RETURN_REASON",
  POST_OM_RETURN_REASON:"POST_OM_RETURN_REASON",
  EDIT_OM_RETURN_REASON:"EDIT_OM_RETURN_REASON",
  DELETE_OM_RETURN_REASON:"DELETE_OM_RETURN_REASON",
  GET_OM_SHIP_VIA:"GET_OM_SHIP_VIA",
  POST_OM_SHIP_VIA:"POST_OM_SHIP_VIA",
  EDIT_OM_SHIP_VIA:"EDIT_OM_SHIP_VIA",
  DELETE_OM_SHIP_VIA:"DELETE_OM_SHIP_VIA",
  GET_OM_PT_STATUS:"GET_OM_PT_STATUS",
  POST_OM_PT_STATUS:"POST_OM_PT_STATUS",
  EDIT_OM_PT_STATUS:"EDIT_OM_PT_STATUS",
  DELETE_OM_PT_STATUS:"DELETE_OM_PT_STATUS",
  GET_OM_RETURN_TYPE:"GET_OM_RETURN_TYPE",
  POST_OM_RETURN_TYPE:"POST_OM_RETURN_TYPE",
  EDIT_OM_RETURN_TYPE:"EDIT_OM_RETURN_TYPE",
  DELETE_OM_RETURN_TYPE:"DELETE_OM_RETURN_TYPE",
  POST_OM_VENDOR:"POST_OM_VENDOR",
  SEARCH_OM_VENDOR:"SEARCH_OM_VENDOR",
  GET_OM_VENDOR_BY_ID:"GET_OM_VENDOR_BY_ID",
  EDIT_OM_VENDOR_BY_ID:"EDIT_OM_VENDOR_BY_ID",
  DELETE_OM_VENDOR_BY_ID:"DELETE_OM_VENDOR_BY_ID",
  GET_OM_SALES_TARGET:"GET_OM_SALES_TARGET",
  POST_OM_SALES_TARGET:"POST_OM_SALES_TARGET",
  EDIT_OM_SALES_TARGET:"EDIT_OM_SALES_TARGET",
  DELETE_OM_SALES_TARGET:"DELETE_OM_SALES_TARGET",
  GET_OM_BRAND:"GET_OM_BRAND",
  POST_OM_BRAND:"POST_OM_BRAND",
  EDIT_OM_BRAND:"EDIT_OM_BRAND",
  DELETE_OM_BRAND:"DELETE_OM_BRAND",
  GET_OM_FIGURE:"GET_OM_FIGURE",
  POST_OM_FIGURE:"POST_OM_FIGURE",
  EDIT_OM_FIGURE:"EDIT_OM_FIGURE",
  DELETE_OM_FIGURE:"DELETE_OM_FIGURE",
  GET_OM_CUSTOMER_CATEGORY:"GET_OM_CUSTOMER_CATEGORY",
  POST_OM_CUSTOMER_CATEGORY:"POST_OM_CUSTOMER_CATEGORY",
  EDIT_OM_CUSTOMER_CATEGORY:"EDIT_OM_CUSTOMER_CATEGORY",
  DELETE_OM_CUSTOMER_CATEGORY:"DELETE_OM_CUSTOMER_CATEGORY",
  GET_OM_CAT:"GET_OM_CAT",
  POST_OM_CAT:"POST_OM_CAT",
  EDIT_OM_CAT:"EDIT_OM_CAT",
  DELETE_OM_CAT:"DELETE_OM_CAT",
  GET_OM_BERGEN_BILLING:"GET_OM_BERGEN_BILLING",
  POST_OM_BERGEN_BILLING:"POST_OM_BERGEN_BILLING",
  EDIT_OM_BERGEN_BILLING:"EDIT_OM_BERGEN_BILLING",
  DELETE_OM_BERGEN_BILLING:"DELETE_OM_BERGEN_BILLING",
  GET_OM_MONTHS:"GET_OM_MONTHS",
  GET_OM_YEARS:"GET_OM_YEARS",
  GET_OM_SALES_PERSON:"GET_OM_SALES_PERSON",
  POST_OM_SALES_PERSON:"POST_OM_SALES_PERSON",
  EDIT_OM_SALES_PERSON:"EDIT_OM_SALES_PERSON",
  DELETE_OM_SALES_PERSON:"DELETE_OM_SALES_PERSON",
  GET_OM_CUSTOMER_INFORMATION:"GET_OM_CUSTOMER_INFORMATION",
  POST_OM_CUSTOMER_INFORMATION:"POST_OM_CUSTOMER_INFORMATION",
  EDIT_OM_CUSTOMER_INFORMATION:"EDIT_OM_CUSTOMER_INFORMATION",
  DELETE_OM_CUSTOMER_INFORMATION:"DELETE_OM_CUSTOMER_INFORMATION",
  GET_OM_SHIPPING_METHOD:"GET_OM_SHIPPING_METHOD",
  POST_OM_SHIPPING_METHOD:"GET_OM_SHIPPING_METHOD",
  EDIT_OM_SHIPPING_METHOD:"GET_OM_SHIPPING_METHOD",
  DELETE_OM_ORDER_TYPE:"GET_OM_ORDER_TYPE",
}
