import { Stack } from '@mui/material'
import React from 'react'

const Home = () => {
  return (
    <Stack>
    </Stack>
  )
}

export default Home