import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { primaryColor } from "../../utils/themeColors";

function CustomTabPanel(props) {
  const { children, value, index,header, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {header||null}
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

export default function TabComp({
  tabs = [
    {
      name: "Tab 1",
      content: <Typography>Tab 1 Content</Typography>,
    },
    {
      name: "Tab 2",
      content: <Typography>Tab 2 Content</Typography>,
    },
  ],
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="none"
          aria-label="basic tabs example"
        >
          {tabs?.map((e, i) => {
            return (
              <Tab
                label={e?.name}
                sx={{
                  backgroundColor: value === i ? primaryColor : "white",
                  padding: 0,
                  border: `1px solid ${primaryColor}`,
                  marginX: 0.2,
                  fontSize: 14,
                  minHeight: "30px",
                  textTransform: "none",
                  color: value === i ? "white !important" : "black !important",
                  ":focus": {
                    color: value === i ? "white !important" : "black !important",
                  },
                  ":active": {
                    color: value === i ? "white !important" : "black !important",
                  },
                }}
                key={i}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabs?.map((e, i) => {
        return (
          <CustomTabPanel value={value} key={i} index={i} header={e?.header}>
            {e?.content}
          </CustomTabPanel>
        );
      })}
      {/* <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
    </Box>
  );
}
