export const menuItems = [
  {
    col_menu_id: 69,
    col_menu_name: "Administration",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "-",
    col_sequence: "0.100000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 70,
        col_menu_name: "Master",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: null,
        col_sequence: "1.000000",
        col_parent_menu_id: 69,
        col_parent_menu_name: "Administration",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 71,
            col_menu_name: "User Company Access",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/company",
            col_sequence: "1.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 79,
            col_menu_name: "Subsidiary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/subsidiary",
            col_sequence: "1.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 76,
            col_menu_name: "User",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/adminuser",
            col_sequence: "1.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 77,
            col_menu_name: "User Rights",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/userrights",
            col_sequence: "2.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 738,
            col_menu_name: "Admin Menu Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/adminMenuType",
            col_sequence: "2.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 186,
            col_menu_name: "Location",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/location",
            col_sequence: "2.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 121,
            col_menu_name: "Admin Menu",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/admin-menu",
            col_sequence: "3.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 573,
            col_menu_name: "Admin Module",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/admin-module",
            col_sequence: "3.000000",
            col_parent_menu_id: 70,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 1,
    col_menu_name: "Accounting",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: null,
    col_sequence: "1.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 4,
        col_menu_name: "Master",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: null,
        col_sequence: "1.000000",
        col_parent_menu_id: 1,
        col_parent_menu_name: "Accounting",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 60,
            col_menu_name: "Chart Of Account",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/chartofacct",
            col_sequence: "1.000000",
            col_parent_menu_id: 4,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 61,
            col_menu_name: "Accounts Period",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/acct-period",
            col_sequence: "2.000000",
            col_parent_menu_id: 4,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 179,
            col_menu_name: "Account Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/accounttype",
            col_sequence: "2.000000",
            col_parent_menu_id: 4,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 62,
            col_menu_name: "Default Account Setup",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: null,
            col_sequence: "3.000000",
            col_parent_menu_id: 4,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 63,
            col_menu_name: "Tax Setup",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/tax-setup",
            col_sequence: "4.000000",
            col_parent_menu_id: 4,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 2,
        col_menu_name: "Transaction",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: null,
        col_sequence: "2.000000",
        col_parent_menu_id: 1,
        col_parent_menu_name: "Accounting",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 64,
            col_menu_name: "Cash Receipt Voucher",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: null,
            col_sequence: "5.000000",
            col_parent_menu_id: 2,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 66,
            col_menu_name: "Bank Receipt Voucher",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: null,
            col_sequence: "7.000000",
            col_parent_menu_id: 2,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 67,
            col_menu_name: "Bank Payment Voucher",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: null,
            col_sequence: "8.000000",
            col_parent_menu_id: 2,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 1309,
    col_menu_name: "DL UK",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "/",
    col_sequence: "1.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 1316,
        col_menu_name: "Transaction",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: " ",
        col_sequence: "1.000000",
        col_parent_menu_id: 1309,
        col_parent_menu_name: "DL UK",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1310,
            col_menu_name: "Upload Style Master",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/product-to-bergen",
            col_sequence: "1.000000",
            col_parent_menu_id: 1316,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1317,
            col_menu_name: "Upload Packing List",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/packing-list-to-bergen",
            col_sequence: "2.000000",
            col_parent_menu_id: 1316,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1327,
            col_menu_name: "Upload PickTicket",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pt-uk-to-bergen",
            col_sequence: "3.000000",
            col_parent_menu_id: 1316,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1333,
            col_menu_name: "Upload Return Authorization",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/ra-to-bergen",
            col_sequence: "4.000000",
            col_parent_menu_id: 1316,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 5,
    col_menu_name: "Product Information",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: null,
    col_sequence: "2.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 6,
        col_menu_name: "Master",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: null,
        col_sequence: "1.000000",
        col_parent_menu_id: 5,
        col_parent_menu_name: "Product Information",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 8,
            col_menu_name: "Product Information",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/productInfo",
            col_sequence: "1.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 10,
            col_menu_name: "Fit/Cut",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fitCut",
            col_sequence: "2.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 9,
            col_menu_name: "Style",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/style",
            col_sequence: "2.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 11,
            col_menu_name: "Fit Category",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fitCategory",
            col_sequence: "3.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 12,
            col_menu_name: "Wash Name",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/wash",
            col_sequence: "4.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 13,
            col_menu_name: "Wash Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/washType",
            col_sequence: "5.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 14,
            col_menu_name: "Fabric",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fabric",
            col_sequence: "6.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 15,
            col_menu_name: "Fabric Composition",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fabricComposition",
            col_sequence: "7.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 16,
            col_menu_name: "Gender",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/gender",
            col_sequence: "8.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 68,
            col_menu_name: "Season",
            col_menu_type_id: 2,
            col_menu_level: 2,
            col_runtime_name: "/season",
            col_sequence: "9.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 17,
            col_menu_name: "Gender Category",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/genderCategory",
            col_sequence: "9.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1196,
            col_menu_name: "UPC Setup",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/upc-setup",
            col_sequence: "9.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 47,
            col_menu_name: "Product Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/productType",
            col_sequence: "9.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 18,
            col_menu_name: "Season Year",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/seasonYear",
            col_sequence: "10.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 19,
            col_menu_name: "Current Season Year",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: null,
            col_sequence: "11.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 20,
            col_menu_name: "Rise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/rise",
            col_sequence: "12.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 21,
            col_menu_name: "Rise Label",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/riseLabel",
            col_sequence: "14.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 22,
            col_menu_name: "Item Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/itemtype",
            col_sequence: "15.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          // {
          //   col_menu_id: 1296,
          //   col_menu_name: "Inter Log",
          //   col_menu_type_id: 3,
          //   col_menu_level: 2,
          //   col_runtime_name: "/inter-log",
          //   col_sequence: "15.000000",
          //   col_parent_menu_id: 6,
          //   col_parent_menu_name: "Master",
          //   col_toggle: false,
          //   col_anchor: null,
          //   children: [],
          // },
          {
            col_menu_id: 23,
            col_menu_name: "Product Class",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/productclass",
            col_sequence: "16.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 24,
            col_menu_name: "Product Status",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/ProductStatus",
            col_sequence: "17.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 25,
            col_menu_name: "Inseam Label",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/inseamlabel",
            col_sequence: "19.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 26,
            col_menu_name: "Inseam",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/inseam",
            col_sequence: "20.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 27,
            col_menu_name: "Sizes",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/size",
            col_sequence: "21.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 342,
            col_menu_name: "Charge Back Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/chargeBackType",
            col_sequence: "59.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 245,
            col_menu_name: "Fabric Class",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fabricClass",
            col_sequence: "101.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 246,
            col_menu_name: "Fabric Class 2",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fabricClass2",
            col_sequence: "102.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 247,
            col_menu_name: "Fabric Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fabricType",
            col_sequence: "103.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 248,
            col_menu_name: "Fabric Usable",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/fabricUsable",
            col_sequence: "104.000000",
            col_parent_menu_id: 6,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 7,
        col_menu_name: "Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: null,
        col_sequence: "2.000000",
        col_parent_menu_id: 5,
        col_parent_menu_name: "Product Information",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 29,
            col_menu_name: "Product List",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "product-list",
            col_sequence: "1.000000",
            col_parent_menu_id: 7,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 30,
            col_menu_name: "Other Producs Reports",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: null,
            col_sequence: "2.000000",
            col_parent_menu_id: 7,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 31,
    col_menu_name: "Order Management",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: null,
    col_sequence: "3.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 32,
        col_menu_name: "Master",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: null,
        col_sequence: "1.000000",
        col_parent_menu_id: 31,
        col_parent_menu_name: "Order Management",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 33,
            col_menu_name: "Customer Information",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customerInformation",
            col_sequence: "1.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 607,
            col_menu_name: "Payment Method Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/paymentMethodType",
            col_sequence: "1.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 706,
            col_menu_name: "Admin Job",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/adminJob",
            col_sequence: "1.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 673,
            col_menu_name: "Term for Print",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/termForPrint",
            col_sequence: "2.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 34,
            col_menu_name: "Supplier Information",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: null,
            col_sequence: "2.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 35,
            col_menu_name: "City",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/city",
            col_sequence: "3.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 707,
            col_menu_name: "Admin Discount",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/adminDiscount",
            col_sequence: "3.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 739,
            col_menu_name: "Admin Currency",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/adminCurrency",
            col_sequence: "3.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 36,
            col_menu_name: "State",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/state",
            col_sequence: "4.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 705,
            col_menu_name: "Factor",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/factor",
            col_sequence: "4.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 309,
            col_menu_name: "Customer Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customerType",
            col_sequence: "4.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 606,
            col_menu_name: "Payment Method",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/paymentMethod",
            col_sequence: "5.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 540,
            col_menu_name: "Payment Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/paymentType",
            col_sequence: "5.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 39,
            col_menu_name: "Country",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/country",
            col_sequence: "5.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 773,
            col_menu_name: "Brand",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/brand",
            col_sequence: "5.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 740,
            col_menu_name: "Bergen Payment Term",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/bergenPaymentTerm",
            col_sequence: "6.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 40,
            col_menu_name: "Payment Terms",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/paymentTerm",
            col_sequence: "6.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 41,
            col_menu_name: "Freight Terms",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/freightTerm",
            col_sequence: "7.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1167,
            col_menu_name: "Customer Category",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "customerCategory",
            col_sequence: "8.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 181,
            col_menu_name: "Order Status",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "orderStatus",
            col_sequence: "8.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 43,
            col_menu_name: "Region",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/region",
            col_sequence: "9.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 44,
            col_menu_name: "Sales Person",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/sales-person",
            col_sequence: "10.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 45,
            col_menu_name: "CAT=Region + Sale Person",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/cat",
            col_sequence: "11.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 182,
            col_menu_name: "Ship Status",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/shipStatus",
            col_sequence: "11.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 187,
            col_menu_name: "Sales Targets",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/salesTarget",
            col_sequence: "12.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 190,
            col_menu_name: "Vendor Category",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/vendorCategory",
            col_sequence: "14.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 189,
            col_menu_name: "Vendor",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/vendor",
            col_sequence: "15.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 276,
            col_menu_name: "Commit Criteria",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/commitCriteria",
            col_sequence: "55.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 75,
            col_menu_name: "Shipping Method",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/shippingMethod",
            col_sequence: "90.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 74,
            col_menu_name: "Courier Service",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/courierService",
            col_sequence: "97.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 73,
            col_menu_name: "Bergen Billing Option",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/bergenBillingOption",
            col_sequence: "98.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 185,
            col_menu_name: "Return Reason",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/returnReason",
            col_sequence: "99.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 72,
            col_menu_name: "Ship Via",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/shipvia",
            col_sequence: "99.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 180,
            col_menu_name: "Pick Ticket Status",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "ptStatus",
            col_sequence: "99.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 210,
            col_menu_name: "Return Type",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/returnType",
            col_sequence: "100.000000",
            col_parent_menu_id: 32,
            col_parent_menu_name: "Master",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 37,
        col_menu_name: "Transaction",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: null,
        col_sequence: "2.000000",
        col_parent_menu_id: 31,
        col_parent_menu_name: "Order Management",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 53,
            col_menu_name: "Sales Order",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/sale-order",
            col_sequence: "1.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 54,
            col_menu_name: "Sales Order Adjustment",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/sale-order-adjustment-new-list",
            col_sequence: "2.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1254,
            col_menu_name: "Pick Ticket",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/order-shipment",
            col_sequence: "2.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 56,
            col_menu_name: "Return Authorization",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/return-authorization-list",
            col_sequence: "5.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 188,
            col_menu_name: "Item Receipt",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/item-receipt/list",
            col_sequence: "6.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1173,
            col_menu_name: "Re-Allocation",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/relocate-items",
            col_sequence: "7.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1323,
            col_menu_name: "Bergen EDI",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/bergen-edi",
            col_sequence: "8.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1324,
            col_menu_name: "Stitch Fix EDI",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/stitchfix-edi",
            col_sequence: "9.000000",
            col_parent_menu_id: 37,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 191,
    col_menu_name: "Inventory",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "-",
    col_sequence: "4.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 194,
        col_menu_name: "Transaction",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "-",
        col_sequence: "1.000000",
        col_parent_menu_id: 191,
        col_parent_menu_name: "Inventory",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 195,
            col_menu_name: "Inventory Transfer",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/item-transfer/add",
            col_sequence: "1.000000",
            col_parent_menu_id: 194,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 474,
            col_menu_name: "Inventory Adjustment",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/inventory-adjustment",
            col_sequence: "2.000000",
            col_parent_menu_id: 194,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1257,
            col_menu_name: "B Grade Stock",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/b-grade-stock",
            col_sequence: "3.000000",
            col_parent_menu_id: 194,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 837,
    col_menu_name: "Payable",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "-",
    col_sequence: "4.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 838,
        col_menu_name: "Transaction",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "-",
        col_sequence: "1.000000",
        col_parent_menu_id: 837,
        col_parent_menu_name: "Payable",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 839,
            col_menu_name: "Bill Payments",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/bill-payments/add",
            col_sequence: "1.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1068,
            col_menu_name: "Bill Direct Payments",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/bill-direct-payments/add",
            col_sequence: "2.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1101,
            col_menu_name: "Fund Transfer",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/transfer/add",
            col_sequence: "3.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1103,
            col_menu_name: "Deposit",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/deposit/add",
            col_sequence: "4.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1260,
            col_menu_name: "Bill Credit",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/bill-credit/add",
            col_sequence: "5.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 65,
            col_menu_name: "Journal Voucher",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/journal-voucher",
            col_sequence: "6.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1271,
            col_menu_name: "Purchase Bill",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/purchase_bill",
            col_sequence: "7.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 59,
            col_menu_name: "Bill",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/bill/add",
            col_sequence: "10.000000",
            col_parent_menu_id: 838,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 1179,
    col_menu_name: "DL PK Reports",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "/",
    col_sequence: "12.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 1180,
        col_menu_name: "Booked",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "1.000000",
        col_parent_menu_id: 1179,
        col_parent_menu_name: "DL PK Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1181,
            col_menu_name: "Booked Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/booked-order-summary-report",
            col_sequence: "1.000000",
            col_parent_menu_id: 1180,
            col_parent_menu_name: "Booked",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1182,
            col_menu_name: "Booked Detail",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/booked-detail-report",
            col_sequence: "2.000000",
            col_parent_menu_id: 1180,
            col_parent_menu_name: "Booked",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1256,
            col_menu_name: "Sales Invoice report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/sales-inovice-report",
            col_sequence: "8.000000",
            col_parent_menu_id: 1180,
            col_parent_menu_name: "Booked",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1183,
        col_menu_name: "Pending",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "2.000000",
        col_parent_menu_id: 1179,
        col_parent_menu_name: "DL PK Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1184,
            col_menu_name: "Pending Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pending-order-summary-report",
            col_sequence: "1.000000",
            col_parent_menu_id: 1183,
            col_parent_menu_name: "Pending",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1185,
            col_menu_name: "Pending Detail",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pending-detail-summary-report",
            col_sequence: "2.000000",
            col_parent_menu_id: 1183,
            col_parent_menu_name: "Pending",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1292,
        col_menu_name: "Sales Order",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "3.000000",
        col_parent_menu_id: 1179,
        col_parent_menu_name: "DL PK Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1294,
            col_menu_name: "Sales Order Adj Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/order-adjustment-report",
            col_sequence: "1.000000",
            col_parent_menu_id: 1292,
            col_parent_menu_name: "Sales Order",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1300,
            col_menu_name: "Sales Order Adj Detail Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/order-adjustment-detail-report",
            col_sequence: "6.000000",
            col_parent_menu_id: 1292,
            col_parent_menu_name: "Sales Order",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1186,
        col_menu_name: "Pick Ticket",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "3.000000",
        col_parent_menu_id: 1179,
        col_parent_menu_name: "DL PK Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1270,
            col_menu_name: "Pending POD Summary AR",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/Pending-POD-summary-ar",
            col_sequence: "1.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1199,
            col_menu_name: "Pending POD Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/Pending-POD-summary-report",
            col_sequence: "1.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1187,
            col_menu_name: "Create Pick Ticket",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/create-pick-ticket",
            col_sequence: "1.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1191,
            col_menu_name: "Pick Ticket Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pick-ticket-summary-report",
            col_sequence: "2.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1192,
            col_menu_name: "Pick Ticket Detail Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "pick-ticket-detail-report",
            col_sequence: "3.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1253,
            col_menu_name: "Control Sheet",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/control-sheet",
            col_sequence: "5.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1308,
            col_menu_name: "Control Sheet Log",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/control-sheet-log",
            col_sequence: "5.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1278,
            col_menu_name: "Pending POD Detail",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pod-detail-report",
            col_sequence: "10.000000",
            col_parent_menu_id: 1186,
            col_parent_menu_name: "Pick Ticket",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1188,
        col_menu_name: "Shipped",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "4.000000",
        col_parent_menu_id: 1179,
        col_parent_menu_name: "DL PK Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1205,
            col_menu_name: "Credit Open-Invoice",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/credit-open-invoice-detail",
            col_sequence: "1.000000",
            col_parent_menu_id: 1188,
            col_parent_menu_name: "Shipped",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1206,
        col_menu_name: "Inventory Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "-",
        col_sequence: "6.000000",
        col_parent_menu_id: 1179,
        col_parent_menu_name: "DL PK Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1337,
            col_menu_name: "Stock Summary ATS",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/oms-stock-summary-ats",
            col_sequence: "1.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1207,
            col_menu_name: "Stock Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/oms-stock-summary",
            col_sequence: "1.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1313,
            col_menu_name: "OMS Style Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/style-oms-report",
            col_sequence: "2.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1208,
            col_menu_name: "Stock Style Wise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/distributor",
            col_sequence: "2.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1322,
            col_menu_name: "Transaction Ledger",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/transaction-ledger",
            col_sequence: "3.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1314,
            col_menu_name: "OMS Wash Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/wash-oms-report",
            col_sequence: "3.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1280,
            col_menu_name: "Location Wise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "location-wise-report",
            col_sequence: "4.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1326,
            col_menu_name: "All Style Ledger",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/all_style_ledger",
            col_sequence: "9.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1283,
            col_menu_name: "Product Information By Size",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "product-information-by-size",
            col_sequence: "14.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1285,
            col_menu_name: "Goods Required",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/good-required",
            col_sequence: "16.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1276,
            col_menu_name: "D2C Inventory",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/inventory-report",
            col_sequence: "18.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1290,
            col_menu_name: "Pending SKU Lookup",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pending-sku-lookup",
            col_sequence: "30.000000",
            col_parent_menu_id: 1206,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1262,
        col_menu_name: "Accounts Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "-",
        col_sequence: "167.000000",
        col_parent_menu_id: 1179,
        col_parent_menu_name: "DL PK Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1263,
            col_menu_name: "Customer Refund",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customer-refund-report",
            col_sequence: "1.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1336,
            col_menu_name: "General Ledger",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/General-ledger",
            col_sequence: "2.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1264,
            col_menu_name: "Payment Receipt",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/payment-receipt-report",
            col_sequence: "2.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1288,
            col_menu_name: "Customer Ledger AR",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customer-payment-ledger-AR",
            col_sequence: "3.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1311,
            col_menu_name: "Customer Ledger Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/payment-ledger-summary-report",
            col_sequence: "4.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1332,
            col_menu_name: "Expense",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/expense",
            col_sequence: "5.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1277,
            col_menu_name: "DSCO Invoices",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/dsco-invoice-report",
            col_sequence: "17.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1274,
            col_menu_name: "CIT Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/cit-report",
            col_sequence: "30.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1281,
            col_menu_name: "Invoice Email",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/invoice-email",
            col_sequence: "40.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1291,
            col_menu_name: "Invoice Printing",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/invoice-pending",
            col_sequence: "41.000000",
            col_parent_menu_id: 1262,
            col_parent_menu_name: "Accounts Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 1239,
    col_menu_name: "DL US Reports",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "/",
    col_sequence: "189.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 1215,
        col_menu_name: "Style Wise Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "1.000000",
        col_parent_menu_id: 1239,
        col_parent_menu_name: "DL US Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1216,
            col_menu_name: "Booked",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/booked-style-wise",
            col_sequence: "1.000000",
            col_parent_menu_id: 1215,
            col_parent_menu_name: "Style Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1217,
            col_menu_name: "Shipped",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/shipped-style-wise",
            col_sequence: "2.000000",
            col_parent_menu_id: 1215,
            col_parent_menu_name: "Style Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1218,
            col_menu_name: "Pending",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pending-style-wise",
            col_sequence: "3.000000",
            col_parent_menu_id: 1215,
            col_parent_menu_name: "Style Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1219,
            col_menu_name: "POD",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pod-style-wise",
            col_sequence: "4.000000",
            col_parent_menu_id: 1215,
            col_parent_menu_name: "Style Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1220,
            col_menu_name: "Return",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/return-style-wise",
            col_sequence: "5.000000",
            col_parent_menu_id: 1215,
            col_parent_menu_name: "Style Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1223,
        col_menu_name: "Region Wise Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "1.000000",
        col_parent_menu_id: 1239,
        col_parent_menu_name: "DL US Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1224,
            col_menu_name: "Booked Region Wise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/booked-region-wise",
            col_sequence: "1.000000",
            col_parent_menu_id: 1223,
            col_parent_menu_name: "Region Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1225,
            col_menu_name: "Shipped Region Wise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/shipped-region-wise",
            col_sequence: "2.000000",
            col_parent_menu_id: 1223,
            col_parent_menu_name: "Region Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1226,
            col_menu_name: "Pending Region Wise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pending-region-wise",
            col_sequence: "3.000000",
            col_parent_menu_id: 1223,
            col_parent_menu_name: "Region Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1227,
            col_menu_name: "Return Region Wise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/return-region-wise",
            col_sequence: "4.000000",
            col_parent_menu_id: 1223,
            col_parent_menu_name: "Region Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1228,
            col_menu_name: "POD Region Wise",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/pod-region-wise",
            col_sequence: "5.000000",
            col_parent_menu_id: 1223,
            col_parent_menu_name: "Region Wise Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1246,
        col_menu_name: "Inventory Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "3.000000",
        col_parent_menu_id: 1239,
        col_parent_menu_name: "DL US Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1247,
            col_menu_name: "Stock Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/oms-stock-summary",
            col_sequence: "1.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1248,
            col_menu_name: "Stock Style Wise ",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/distributor",
            col_sequence: "2.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1312,
            col_menu_name: "Immediate OTS By Size",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/immediate-ots",
            col_sequence: "3.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1329,
            col_menu_name: "Style Shot",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/style-shot",
            col_sequence: "3.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1330,
            col_menu_name: "Style Distro",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/style-distro",
            col_sequence: "3.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1325,
            col_menu_name: "Total OTS By Size",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/total-ots",
            col_sequence: "4.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1265,
            col_menu_name: "In Transit",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/intransit-Report",
            col_sequence: "5.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1266,
            col_menu_name: "Production",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/production-Report",
            col_sequence: "10.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1268,
            col_menu_name: "OTS Dashboard",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/ots-dashboard",
            col_sequence: "10.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1267,
            col_menu_name: "SKU Lookup",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/sku-lookup",
            col_sequence: "11.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1287,
            col_menu_name: "SKU Lookup Nu",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/sku-lookup-nu",
            col_sequence: "12.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1275,
            col_menu_name: "DL Showroom",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "dl-Showroom-Report",
            col_sequence: "13.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1255,
            col_menu_name: "Immediate OTS By Size$",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/immediate-ots-by-size",
            col_sequence: "13.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1289,
            col_menu_name: "Total OTS By Size$",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/total-ots-by-size",
            col_sequence: "14.000000",
            col_parent_menu_id: 1246,
            col_parent_menu_name: "Inventory Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1249,
        col_menu_name: "Dashboard Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "4.000000",
        col_parent_menu_id: 1239,
        col_parent_menu_name: "DL US Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1251,
            col_menu_name: "Business Dashboard",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/business-dashboard",
            col_sequence: "1.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1252,
            col_menu_name: "Sales Target",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/sales-target-report",
            col_sequence: "2.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1250,
            col_menu_name: "Quick PO Lookup",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/quick-po-lookup",
            col_sequence: "3.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1307,
            col_menu_name: "UPC Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/upc-report",
            col_sequence: "4.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1259,
            col_menu_name: "Product",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "product-information",
            col_sequence: "5.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1269,
            col_menu_name: "Customer Balance",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customer-balances",
            col_sequence: "20.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1272,
            col_menu_name: "International Order",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/international-order",
            col_sequence: "20.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1273,
            col_menu_name: "Canada Order",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/canada-order",
            col_sequence: "22.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1284,
            col_menu_name: "Product Information By Size",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "product-information-by-size",
            col_sequence: "50.000000",
            col_parent_menu_id: 1249,
            col_parent_menu_name: "Dashboard Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1320,
        col_menu_name: "Product Report",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "-",
        col_sequence: "2434.000000",
        col_parent_menu_id: 1239,
        col_parent_menu_name: "DL US Reports",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1321,
            col_menu_name: "Product Image Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/product-image-report",
            col_sequence: "4234.000000",
            col_parent_menu_id: 1320,
            col_parent_menu_name: "Product Report",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 1297,
    col_menu_name: "Routines",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "/",
    col_sequence: "200.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 1298,
        col_menu_name: "Routines",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "1.000000",
        col_parent_menu_id: 1297,
        col_parent_menu_name: "Routines",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1299,
            col_menu_name: "Routines",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/routines",
            col_sequence: "1.000000",
            col_parent_menu_id: 1298,
            col_parent_menu_name: "Routines",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    col_menu_id: 1301,
    col_menu_name: "Accounts",
    col_menu_type_id: 1,
    col_menu_level: 0,
    col_runtime_name: "Accounts",
    col_sequence: "509.000000",
    col_parent_menu_id: null,
    col_parent_menu_name: "",
    col_toggle: false,
    col_anchor: null,
    children: [
      {
        col_menu_id: 1302,
        col_menu_name: "Transaction",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "1.000000",
        col_parent_menu_id: 1301,
        col_parent_menu_name: "Accounts",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 184,
            col_menu_name: "Invoice",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/invoice-list",
            col_sequence: "1.000000",
            col_parent_menu_id: 1302,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 408,
            col_menu_name: "Customer Payment",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customer-payment",
            col_sequence: "2.000000",
            col_parent_menu_id: 1302,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 870,
            col_menu_name: "Customer Refund",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customer-refund",
            col_sequence: "3.000000",
            col_parent_menu_id: 1302,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1261,
            col_menu_name: "Credit Memo",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/credit-memo/add",
            col_sequence: "4.000000",
            col_parent_menu_id: 1302,
            col_parent_menu_name: "Transaction",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
      {
        col_menu_id: 1304,
        col_menu_name: "Reports",
        col_menu_type_id: 2,
        col_menu_level: 1,
        col_runtime_name: "/",
        col_sequence: "2.000000",
        col_parent_menu_id: 1301,
        col_parent_menu_name: "Accounts",
        col_toggle: false,
        col_anchor: null,
        children: [
          {
            col_menu_id: 1189,
            col_menu_name: "Shipped Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/shipped-summary",
            col_sequence: "1.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1335,
            col_menu_name: "General Ledger",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/journal-ledger",
            col_sequence: "2.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1190,
            col_menu_name: "Shipped Detail",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/shipped-detail",
            col_sequence: "2.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1202,
            col_menu_name: "Return Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/return-summary",
            col_sequence: "3.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1328,
            col_menu_name: "Chart of Account",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/chart-of-account-report",
            col_sequence: "4.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1203,
            col_menu_name: "Return Detail",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/return-detail",
            col_sequence: "4.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1305,
            col_menu_name: "Vendor Ledger",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/vendor-ledger",
            col_sequence: "5.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1135,
            col_menu_name: "Customer Ledger",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customer-payment-ledger",
            col_sequence: "5.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1286,
            col_menu_name: "Customer Aging",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/Ar-aging-summary",
            col_sequence: "6.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1295,
            col_menu_name: "Income Statement",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "income-statement",
            col_sequence: "7.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1282,
            col_menu_name: "Commission Report",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/commission-report",
            col_sequence: "8.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1306,
            col_menu_name: "Balance Sheet",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/balance-sheet",
            col_sequence: "8.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1258,
            col_menu_name: "Customer",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/customer-information",
            col_sequence: "9.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1334,
            col_menu_name: "Inventory Valuation",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/inventory-valuation",
            col_sequence: "10.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
          {
            col_menu_id: 1331,
            col_menu_name: "Vendor Summary",
            col_menu_type_id: 3,
            col_menu_level: 2,
            col_runtime_name: "/vendor-summary",
            col_sequence: "12.000000",
            col_parent_menu_id: 1304,
            col_parent_menu_name: "Reports",
            col_toggle: false,
            col_anchor: null,
            children: [],
          },
        ],
      },
    ],
  },
];
