import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import {
  addOMFactorAsync,
  deleteOMFactorAsync,
  editOMFactorAsync,
  getOMFactorAsync,
} from "../../../store/services/orderManagementService";
const Factor = () => {
  const dispatch = useDispatch();
  const {
    getOMFactorStatus,
    getOMFactorError,
    getOMFactorData,
    addOMFactorStatus,
    addOMFactorData,
    addOMFactorError,

    editOMFactorStatus,
    editOMFactorError,
    editOMFactorData,

    deleteOMFactorData,
    deleteOMFactorStatus,
    deleteOMFactorError,
  } = useSelector((state) => state.om);

  useEffect(() => {
    dispatch(getOMFactorAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMFactorData?.length
    ? getOMFactorData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedFactor, setSelectedFactor] = useState();
  const [addFactorMode, setAddFactorMode] = useState(false);
  const [addFactorData, setAddFactorData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedFactor", selectedFactor);
  // console.log("editData", editData);
  useEffect(() => {
    if (addOMFactorStatus === asyncStatus.SUCCEEDED) {
      setAddFactorMode(false);
      setSaveDisabled(true);
      setAddFactorData();
    }
  }, [, addOMFactorStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteOMFactorAsync(selectedFactor?.factor_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (deleteOMFactorStatus === asyncStatus.SUCCEEDED || deleteOMFactorStatus === asyncStatus.ERROR ) {
      setOpen(false);
      setSelectedFactor();
    }
  }, [, deleteOMFactorStatus]);
  useEffect(() => {
    if (editOMFactorStatus === asyncStatus.SUCCEEDED) {
      setSelectedFactor();
      setEditMode(false);
      setEditData()
    }
  }, [, editOMFactorStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this factor?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Factor"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Factor
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddFactorMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addFactorMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFactorMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddFactorMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addFactorMode || !selectedFactor}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMFactorStatus === asyncStatus.LOADING ||
                  editOMFactorStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addFactorData) {
                    dispatch(addOMFactorAsync(addFactorData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedFactor, ...editData };
                    delete a.id;
                    dispatch(editOMFactorAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={500} width={"100%"}>
              <TableComp
                columns={[
                  { field: "factor_id", headerName: "ID", flex: true },
                  { field: "factor_name", headerName: "Factor", flex: true },
                ]}
                onRowClick={(e) => {
                  setSelectedFactor(e?.row);
                }}
                rows={formattedData}
                loading={
                  getOMFactorStatus === asyncStatus.IDLE ||
                  getOMFactorStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addFactorMode ? (
              <InputWithBtnLayoutComp
              error={addOMFactorError}
                inputArray={[
                  {
                    label: "Factor:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddFactorData({
                            ...addFactorData,
                            factor_name: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  {
                    label: "Active:",
                    comp: (
                      <Checkbox
                        defaultChecked
                        defaultValue={true}
                        onChange={(e) =>
                          setAddFactorData({
                            ...addFactorData,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    ),
                  },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedFactor ? (
              <InputWithBtnLayoutComp
              error={editOMFactorError}
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedFactor?.factor_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "Factor:",
                    comp: (
                      <InputComp
                        key={selectedFactor?.factor_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            factor_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedFactor?.factor_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={selectedFactor?.is_active|| editData?.is_active}
                        disabled={!editMode}
                        // defaultValue={selectedFactor?.is_active || editData?.is_active}
                        defaultChecked={selectedFactor?.is_active || editData?.is_active}
                        // checked={selectedFactor?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (<Stack>
              <Typography>Select any factor</Typography>
              <Typography color={"red"} fontSize={12}>{getOMFactorError}</Typography>
            </Stack>
            )}
            <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedFactor(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedFactor(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default Factor;
