import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import InfoFormComp from "../../../components/InfoForm/InfoFormComp";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
// import {
//   addOMAdminUsersAsync,
//   deleteOMAdminUsersAsync,
//   editOMAdminUsersAsync,
//   getOMAdminUsersAsync,
// } from "../../../store/services/orderManagementService";
const AdminUsers = () => {
  const dispatch = useDispatch();
  const {
    getOMAdminUsersStatus,
    getOMAdminUsersError,
    getOMAdminUsersData,
    addOMAdminUsersStatus,
    addOMAdminUsersData,
    addOMAdminUsersError,

    editOMAdminUsersStatus,
    editOMAdminUsersError,
    editOMAdminUsersData,

    deleteOMAdminUsersData,
    deleteOMAdminUsersStatus,
    deleteOMAdminUsersError,
  } = useSelector((state) => state.om);

//   useEffect(() => {
//     dispatch(getOMAdminUsersAsync());
//   }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMAdminUsersData?.length
    ? getOMAdminUsersData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
      }))
    : [];
  const [selectedAdminUsers, setSelectedAdminUsers] = useState();
  const [addAdminUsersMode, setAddAdminUsersMode] = useState(false);
  const [addAdminUsersData, setAddAdminUsersData] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedAdminUsers", selectedAdminUsers);
  // console.log("editData", editData);
//   useEffect(() => {
//     if (addOMAdminUsersStatus === asyncStatus.SUCCEEDED) {
//       setAddAdminUsersMode(false);
//       setSaveDisabled(true);
//       setAddAdminUsersData();
//     }
//   }, [, addOMAdminUsersStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    // dispatch(deleteOMAdminUsersAsync(selectedAdminUsers?.term_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
//   useEffect(() => {
//     if (deleteOMAdminUsersStatus === asyncStatus.SUCCEEDED || deleteOMAdminUsersStatus === asyncStatus.ERROR ) {
//       setOpen(false);
//       setSelectedAdminUsers();
//     }
//   }, [, deleteOMAdminUsersStatus]);
//   useEffect(() => {
//     if (editOMAdminUsersStatus === asyncStatus.SUCCEEDED) {
//       setSelectedAdminUsers();
//       setEditMode(false);
//       setEditData()
//     }
//   }, [, editOMAdminUsersStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this admin user?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Admin Users"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Admin Users
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddAdminUsersMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addAdminUsersMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addAdminUsersMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddAdminUsersMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addAdminUsersMode || !selectedAdminUsers}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMAdminUsersStatus === asyncStatus.LOADING ||
                  editOMAdminUsersStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                // onClick={() => {
                //   if (addAdminUsersData) {
                //     dispatch(addOMAdminUsersAsync(addAdminUsersData));
                //   } else if (editMode) {
                //     let a;
                //     a = { ...selectedAdminUsers, ...editData };
                //     delete a.id;
                //     dispatch(editOMAdminUsersAsync(a));
                //   }
                // }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={500} width={"100%"}>
              <TableComp
                columns={[
                  { field: "term_id", headerName: "User ID", flex: true },
                  { field: "term_name", headerName: "Login Id", flex: true },
                ]}
                onRowClick={(e) => {
                  setSelectedAdminUsers(e?.row);
                }}
                rows={formattedData}
                loading={
                  getOMAdminUsersStatus === asyncStatus.IDLE ||
                  getOMAdminUsersStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addAdminUsersMode ? (
            //   <InputWithBtnLayoutComp
            //   error={addOMAdminUsersError}
            //     inputArray={[
            //       {
            //         label: "Admin Users:",
            //         comp: (
            //           <InputComp
            //             style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
            //             placeholder=""
            //             onChange={(e) =>
            //               setAddAdminUsersData({
            //                 ...addAdminUsersData,
            //                 term_name: e.target.value,
            //               })
            //             }
            //           />
            //         ),
            //       },
            //       {
            //         label: "Active:",
            //         comp: (
            //           <Checkbox
            //             defaultChecked
            //             defaultValue={true}
            //             onChange={(e) =>
            //               setAddAdminUsersData({
            //                 ...addAdminUsersData,
            //                 is_active: e.target.checked,
            //               })
            //             }
            //           />
            //         ),
            //       },
            //     ]}
            //     secondaryComp={<></>}
            //   />
            <InfoFormComp
              customColumns={6}
                noHeader={true}
                fieldsArray={[
                  {
                    label: "Login ID:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        // onChange={(e) =>
                        //   setAddGenderCategoryData({
                        //     ...addGenderCategoryData,
                        //     gender_category_name: e.target.value,
                        //   })
                        // }
                      />
                    ),
                  },
                  {
                    label: "First Name:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        // onChange={(e) =>
                        //   setAddGenderCategoryData({
                        //     ...addGenderCategoryData,
                        //     gender_category_name: e.target.value,
                        //   })
                        // }
                      />
                    ),
                  },
                  {
                    label: "Last Name:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        // onChange={(e) =>
                        //   setAddGenderCategoryData({
                        //     ...addGenderCategoryData,
                        //     gender_category_name: e.target.value,
                        //   })
                        // }
                      />
                    ),
                  },
                  {
                    label: "Email:",
                    comp: (
                      <InputComp
                      type={"email"}
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        // onChange={(e) =>
                        //   setAddGenderCategoryData({
                        //     ...addGenderCategoryData,
                        //     gender_category_name: e.target.value,
                        //   })
                        // }
                      />
                    ),
                  },
                  {
                    label: "Password:",
                    comp: (
                      <InputComp
                      type={"password"}
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        // onChange={(e) =>
                        //   setAddGenderCategoryData({
                        //     ...addGenderCategoryData,
                        //     gender_category_name: e.target.value,
                        //   })
                        // }
                      />
                    ),
                  },
                  {
                    label: "User Type:",
                    comp: (
                        <VirtualizedSelect
                        required={true}
                        // options={
                        //   getProductGenderData?.length && getProductGenderData
                        // }
                        // value={
                        //   getProductGenderData?.find(
                        //     (x) =>
                        //       addGenderCategoryData?.gender_id === x?.gender_id
                        //   ) || ""
                        // }
                        // disabled={
                        //   getProductGenderStatus === asyncStatus.IDLE ||
                        //   getProductGenderStatus === asyncStatus.LOADING ||
                        //   !(addGenderCategoryMode || editMode)
                        // }
                        // onChange={(e) => {
                        //   if (e?.gender_id) {
                        //     setAddGenderCategoryData({
                        //       ...addGenderCategoryData,
                        //       gender_id: e?.gender_id,
                        //     });
                        //   }
                        // }}
                        name={"gender_name"}
                        id_key_name={"gender_id"}
                      />
                    ),
                  },
                  {
                    label: "Mobile:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        type={"number"}
                        defaultValue={"+"}
                        // onChange={(e) =>
                        //   setAddGenderCategoryData({
                        //     ...addGenderCategoryData,
                        //     gender_category_name: e.target.value,
                        //   })
                        // }
                      />
                    ),
                  },
                  
                  
                  
                  
                  
                  
                  
                  ,
                ]}
              />
            ) : selectedAdminUsers ? (
              <InputWithBtnLayoutComp
              error={editOMAdminUsersError}
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedAdminUsers?.term_id}
                      </Typography>
                    ),
                  },
                  {
                    label: "AdminUsers:",
                    comp: (
                      <InputComp
                        key={selectedAdminUsers?.term_id} // Add key prop to force remount
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            term_name: e.target.value,
                          })
                        }
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        defaultValue={selectedAdminUsers?.term_name}
                      />
                    ),
                  },
                  {
                    label: "Active:",

                    comp: (
                      <Checkbox
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_active: e.target.checked,
                          })
                        }
                        value={selectedAdminUsers?.is_active|| editData?.is_active}
                        disabled={!editMode}
                        // defaultValue={selectedAdminUsers?.is_active || editData?.is_active}
                        defaultChecked={selectedAdminUsers?.is_active || editData?.is_active}
                        // checked={selectedAdminUsers?.is_active}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (<Stack>
              <Typography>Select any admin user</Typography>
              <Typography color={"red"} fontSize={12}>{getOMAdminUsersError}</Typography>
            </Stack>
            )}
            {/* <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedAdminUsers(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedAdminUsers(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack> */}
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default AdminUsers;
