import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogBoxComp from "../../../components/common/DialogBox/DialogBoxComp";
import ScreenCard from "../../../components/common/ScreenCard/ScreenCard";
import TableComp from "../../../components/common/TableComp/TableComp";
import InputWithBtnLayoutComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutComp";
import InputComp from "../../../components/common/InputComp/Input";
import TabComp from "../../../components/TabsComp/TabComp";
import Btn from "../../../components/common/ButtonComp/Btn";
import { asyncStatus } from "../../../utils/asyncStatus";
import { primaryColor } from "../../../utils/themeColors";
import {
  addOMSalesTargetAsync,
  deleteOMSalesTargetAsync,
  editOMSalesTargetAsync,
  getOMBrandAsync,
  getOMFigureAsync,
  getOMMonthsAsync,
  getOMRegionAsync,
  getOMSalesTargetAsync,
  getOMYearsAsync,
} from "../../../store/services/orderManagementService";
import InputWithBtnLayoutGridComp from "../../../components/InputWithBtnLayout/InputWithBtnLayoutGridComp";
import VirtualizedSelect from "../../../components/common/VirtualizedSelectComp/VirtualizedSelect";
import { getProductGenderAsync } from "../../../store/services/productInfoServices";
import moment from "moment";
const SalesTarget = () => {
  const dispatch = useDispatch();
  const {
    getOMSalesTargetStatus,
    getOMSalesTargetError,
    getOMSalesTargetData,
    addOMSalesTargetStatus,
    addOMSalesTargetData,
    addOMSalesTargetError,

    editOMSalesTargetStatus,
    editOMSalesTargetError,
    editOMSalesTargetData,

    deleteOMSalesTargetData,
    deleteOMSalesTargetStatus,
    deleteOMSalesTargetError,

    getOMBrandStatus,
    getOMBrandError,
    getOMBrandData,

    getOMRegionStatus,
    getOMRegionError,
    getOMRegionData,

    getOMFigureStatus,
    getOMFigureError,
    getOMFigureData,

    getOMMonthsStatus,
    getOMMonthsError,
    getOMMonthsData,

    getOMYearsStatus,
    getOMYearsError,
    getOMYearsData,
  } = useSelector((state) => state.om);
  const {
    getProductGenderStatus,
    getProductGenderError,
    getProductGenderData,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getOMSalesTargetAsync());
    dispatch(getOMBrandAsync());
    dispatch(getOMRegionAsync());
    dispatch(getOMFigureAsync());
    dispatch(getProductGenderAsync());
    dispatch(getOMMonthsAsync());
    dispatch(getOMYearsAsync());
  }, []);

  // console.log("getProductStyleData", getProductStyleData);
  const formattedData = getOMSalesTargetData?.length
    ? getOMSalesTargetData.map((style, index) => ({
        id: index + 1, // Assuming the index is a unique identifier for each row
        ...style,
        month: getOMMonthsData?.find((x) => x?.month_id === style?.target_month)
          ?.month_name,
      }))
    : [];
  const [selectedSalesTarget, setSelectedSalesTarget] = useState();
  const [addSalesTargetMode, setAddSalesTargetMode] = useState(false);
  const [addSalesTargetData, setAddSalesTargetData] = useState({
    is_active: true,
    target_unit: null,
  });
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editData, setEditData] = useState();
  const [editMode, setEditMode] = useState(false);
  // console.log("selectedSalesTarget", selectedSalesTarget);
  // console.log("editData", editData);
  useEffect(() => {
    if (addOMSalesTargetStatus === asyncStatus.SUCCEEDED) {
      setAddSalesTargetMode(false);
      setSaveDisabled(true);
      setAddSalesTargetData();
    }
  }, [, addOMSalesTargetStatus]);

  const [open, setOpen] = useState(false);
  const agree = () => {
    dispatch(deleteOMSalesTargetAsync(selectedSalesTarget?.target_id));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (
      deleteOMSalesTargetStatus === asyncStatus.SUCCEEDED ||
      deleteOMSalesTargetStatus === asyncStatus.ERROR
    ) {
      setOpen(false);
      setSelectedSalesTarget();
    }
  }, [, deleteOMSalesTargetStatus]);
  useEffect(() => {
    if (editOMSalesTargetStatus === asyncStatus.SUCCEEDED) {
      setSelectedSalesTarget();
      setEditMode(false);
      setEditData();
    }
  }, [, editOMSalesTargetStatus]);

  //   const [tableHeight, setTableHeight] = useState("");
  //   let elem = document.getElementById("containerHeight");
  //   useEffect(() => {
  //     console.log("elem?.offsetHeight",elem?.clientHeight);
  //     setTableHeight(elem?.offsetHeight);
  //   }, [ elem?.offsetHeight]);
  // console.log(containerHeight)
  console.log("selectedSalesTarget", selectedSalesTarget);
  console.log(
    "bbbbbbbbbbb",
    window.performance.timing.domContentLoadedEventEnd -
      window.performance.timing.navigationStart
  );
  console.log(
    "yes",
    editData?.target_month
      ? getOMMonthsData?.find((x) => editData?.target_month === x?.month_id) ||
          ""
      : getOMMonthsData?.find(
          (x) => selectedSalesTarget?.target_month === x?.month_id
        )
  );
  return (
    <Stack padding={2}>
      <DialogBoxComp
        open={open}
        handleClose={handleClose}
        agree={agree}
        innerContent={"Are you sure you want to delete this sales target?"}
        heading={"Delete request"}
      />
      <ScreenCard
        title="Sales Target"
        customHeader={
          <Stack
            sx={{
              padding: 0.5,
              px: 4,
              backgroundColor: primaryColor,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Sales Target
            </Typography>
            <Stack flexDirection={"row"} gap={1}>
              <IconButton
                onClick={() => {
                  setEditMode(false);
                  setAddSalesTargetMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!addSalesTargetMode ? (
                  <AddIcon sx={{ color: "white" }} />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addSalesTargetMode}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  setAddSalesTargetMode(false);
                  setEditMode((prev) => !prev);
                  setSaveDisabled(false);
                }}
              >
                {!editMode ? (
                  <EditIcon />
                ) : (
                  <CancelIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <IconButton
                disabled={addSalesTargetMode || !selectedSalesTarget}
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                disabled={
                  addOMSalesTargetStatus === asyncStatus.LOADING ||
                  editOMSalesTargetStatus === asyncStatus.LOADING ||
                  saveDisabled
                }
                sx={{ color: "white", ":disabled": { color: "gray" } }}
                onClick={() => {
                  if (addSalesTargetData && addSalesTargetMode) {
                    dispatch(addOMSalesTargetAsync(addSalesTargetData));
                  } else if (editMode) {
                    let a;
                    a = { ...selectedSalesTarget, ...editData };
                    delete a.id;
                    dispatch(editOMSalesTargetAsync(a));
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </Stack>
          </Stack>
        }
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Stack height={500} width={"100%"}>
              <TableComp
                columns={[
                  {
                    field: "target_id",
                    headerName: "Target Id",
                    flex: true,
                  },
                  {
                    field: "month",
                    headerName: "Target Month",
                    flex: true,
                  },
                  {
                    field: "target_amount",
                    headerName: "Target Amount",
                    flex: true,
                  },
                ]}
                onRowClick={(e) => {
                  setSelectedSalesTarget(e?.row);
                }}
                rows={formattedData}
                loading={
                  getOMSalesTargetStatus === asyncStatus.IDLE ||
                  getOMSalesTargetStatus === asyncStatus.LOADING
                }
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12} padding={2}>
            {addSalesTargetMode ? (
              <InputWithBtnLayoutGridComp
                // error={addOMSalesTargetError}
                inputArray={[
                  // {
                  //   label: "Sales Target:",
                  //   comp: (
                  //     <InputComp
                  //       style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                  //       placeholder=""
                  //       onChange={(e) =>
                  //         setAddSalesTargetData({
                  //           ...addSalesTargetData,
                  //           sales_target: e.target.value,
                  //         })
                  //       }
                  //     />
                  //   ),
                  // },
                  {
                    label: "Brand :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMBrandData?.length && getOMBrandData}
                        value={
                          getOMBrandData?.find(
                            (x) => addSalesTargetData?.brand_id === x?.brand_id
                          ) || ""
                        }
                        disabled={
                          getOMBrandStatus === asyncStatus.IDLE ||
                          getOMBrandStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.brand_id) {
                            setAddSalesTargetData({
                              ...addSalesTargetData,
                              brand_id: e?.brand_id,
                            });
                          }
                          console.log("bbb", e);
                        }}
                        name={"brand_name"}
                        id_key_name={"brand_id"}
                      />
                    ),
                  },
                  {
                    label: "Region :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMRegionData?.length && getOMRegionData}
                        value={
                          getOMRegionData?.find(
                            (x) =>
                              addSalesTargetData?.region_id === x?.region_id
                          ) || ""
                        }
                        disabled={
                          getOMRegionStatus === asyncStatus.IDLE ||
                          getOMRegionStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.region_id) {
                            setAddSalesTargetData({
                              ...addSalesTargetData,
                              region_id: e?.region_id,
                            });
                          }
                        }}
                        name={"region_name"}
                        id_key_name={"region_id"}
                      />
                    ),
                  },
                  {
                    label: "Figure :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMFigureData?.length && getOMFigureData}
                        value={
                          getOMFigureData?.find(
                            (x) =>
                              addSalesTargetData?.figure_id === x?.figure_id
                          ) || ""
                        }
                        disabled={
                          getOMFigureStatus === asyncStatus.IDLE ||
                          getOMFigureStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.figure_id) {
                            setAddSalesTargetData({
                              ...addSalesTargetData,
                              figure_id: e?.figure_id,
                            });
                          }
                        }}
                        name={"figure_name"}
                        id_key_name={"figure_id"}
                      />
                    ),
                  },
                  {
                    label: "Month :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMMonthsData?.length && getOMMonthsData}
                        value={
                          getOMMonthsData?.find(
                            (x) =>
                              addSalesTargetData?.target_month === x?.month_id
                          ) || ""
                        }
                        disabled={
                          getOMMonthsStatus === asyncStatus.IDLE ||
                          getOMMonthsStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.month_id) {
                            setAddSalesTargetData({
                              ...addSalesTargetData,
                              target_month: e?.month_id,
                            });
                          }
                        }}
                        name={"month_name"}
                        id_key_name={"month_id"}
                      />
                    ),
                  },
                  {
                    label: "Year :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMYearsData?.length && getOMYearsData}
                        value={
                          getOMYearsData?.find(
                            (x) =>
                              addSalesTargetData?.target_year === x?.year_id
                          ) || ""
                        }
                        disabled={
                          getOMYearsStatus === asyncStatus.IDLE ||
                          getOMYearsStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.year_id) {
                            setAddSalesTargetData({
                              ...addSalesTargetData,
                              target_year: e?.year_id,
                            });
                          }
                        }}
                        name={"year_name"}
                        id_key_name={"year_id"}
                      />
                    ),
                  },
                  {
                    label: "Gender :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={
                          getProductGenderData?.length && getProductGenderData
                        }
                        value={
                          getProductGenderData?.find(
                            (x) =>
                              addSalesTargetData?.gender_id === x?.gender_id
                          ) || ""
                        }
                        disabled={
                          getProductGenderStatus === asyncStatus.IDLE ||
                          getProductGenderStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.gender_id) {
                            setAddSalesTargetData({
                              ...addSalesTargetData,
                              gender_id: e?.gender_id,
                            });
                          }
                        }}
                        name={"gender_name"}
                        id_key_name={"gender_id"}
                      />
                    ),
                  },
                  {
                    label: "Target Date",
                    comp: (
                      <InputComp
                        // type={"number"}
                        required={true}
                        // validator={(value) => {
                        //   if (!/[0-9]/.test(value.target.value))
                        //     return "Characters not allowed";
                        //   return "";
                        // }}
                        type={"date"}
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddSalesTargetData({
                            ...addSalesTargetData,
                            target_date: moment(
                              new Date(e.target.value)
                            ).format("MM-DD-YY"),
                          })
                        }
                        value={moment(
                          new Date(addSalesTargetData?.target_date)
                        ).format("YYYY-MM-DD")}
                        // disabled={true}
                        name={"from_date"}
                      />
                    ),
                  },
                  // {
                  //   label: "Sales Target:",
                  //   comp: (
                  //     <InputComp
                  //       style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                  //       placeholder=""
                  //       onChange={(e) =>
                  //         setAddSalesTargetData({
                  //           ...addSalesTargetData,
                  //           sales_target: e.target.value,
                  //         })
                  //       }
                  //     />
                  //   ),
                  // },
                  {
                    label: "Target Amount:",
                    comp: (
                      <InputComp
                      type={"number"}
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setAddSalesTargetData({
                            ...addSalesTargetData,
                            target_amount: e.target.value,
                          })
                        }
                      />
                    ),
                  },
                  //
                  // Figure
                  // Month
                  // Year
                  // Gender
                  // Target Date
                  // Sales Target
                  // Target Amount

                  // {
                  //   label: "Active:",
                  //   comp: (
                  //     <Checkbox
                  //       defaultChecked
                  //       defaultValue={true}
                  //       onChange={(e) =>
                  //         setAddSalesTargetData({
                  //           ...addSalesTargetData,
                  //           is_active: e.target.checked,
                  //         })
                  //       }
                  //     />
                  //   ),
                  // },
                ]}
                secondaryComp={<></>}
              />
            ) : selectedSalesTarget ? (
              <InputWithBtnLayoutGridComp
                error={editOMSalesTargetError}
                inputArray={[
                  {
                    label: "ID:",
                    comp: (
                      <Typography sx={{ fontSize: 14 }}>
                        {selectedSalesTarget?.target_id}
                      </Typography>
                    ),
                  },
                  // {
                  //   label: "SalesTarget:",
                  //   comp: (
                  //     <InputComp
                  //       key={selectedSalesTarget?.target_id} // Add key prop to force remount
                  //       disabled={!editMode}
                  //       onChange={(e) =>
                  //         setEditData({
                  //           ...editData,
                  //           sales_target: e.target.value,
                  //         })
                  //       }
                  //       style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                  //       placeholder=""
                  //       defaultValue={selectedSalesTarget?.sales_target}
                  //     />
                  //   ),
                  // },
                  // {
                  //   label: "Active:",

                  //   comp: (
                  //     <Checkbox
                  //       onChange={(e) =>
                  //         setEditData({
                  //           ...editData,
                  //           is_active: e.target.checked,
                  //         })
                  //       }
                  //       value={
                  //         selectedSalesTarget?.is_active || editData?.is_active
                  //       }
                  //       disabled={!editMode}
                  //       // defaultValue={selectedSalesTarget?.is_active || editData?.is_active}
                  //       defaultChecked={
                  //         selectedSalesTarget?.is_active || editData?.is_active
                  //       }
                  //       // checked={selectedSalesTarget?.is_active}
                  //     />
                  //   ),
                  // },
                  {
                    label: "Brand :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMBrandData?.length && getOMBrandData}
                        value={
                          editData?.brand_id
                            ? getOMBrandData?.find(
                                (x) => editData?.brand_id === x?.brand_id
                              ) || ""
                            : getOMBrandData?.find(
                                (x) =>
                                  selectedSalesTarget?.brand_id === x?.brand_id
                              )
                        }
                        disabled={
                          getOMBrandStatus === asyncStatus.IDLE ||
                          getOMBrandStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.brand_id) {
                            setEditData({
                              ...editData,
                              brand_id: e?.brand_id,
                            });
                          }
                          console.log("bbb", e);
                        }}
                        name={"brand_name"}
                        id_key_name={"brand_id"}
                        defaultValue={selectedSalesTarget?.brand_id}
                      />
                    ),
                  },
                  {
                    label: "Region :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMRegionData?.length && getOMRegionData}
                        value={
                          editData?.region_id
                            ? getOMRegionData?.find(
                                (x) => editData?.region_id === x?.region_id
                              ) || ""
                            : getOMRegionData?.find(
                                (x) =>
                                  selectedSalesTarget?.region_id ===
                                  x?.region_id
                              )
                        }
                        disabled={
                          getOMRegionStatus === asyncStatus.IDLE ||
                          getOMRegionStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.region_id) {
                            setEditData({
                              ...editData,
                              region_id: e?.region_id,
                            });
                          }
                        }}
                        name={"region_name"}
                        id_key_name={"region_id"}
                      />
                    ),
                  },
                  {
                    label: "Figure :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMFigureData?.length && getOMFigureData}
                        value={
                          editData?.figure_id
                            ? getOMFigureData?.find(
                                (x) => editData?.figure_id === x?.figure_id
                              )
                            : getOMFigureData?.find(
                                (x) =>
                                  selectedSalesTarget?.figure_id ===
                                  x?.figure_id
                              )
                        }
                        disabled={
                          getOMFigureStatus === asyncStatus.IDLE ||
                          getOMFigureStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.figure_id) {
                            setEditData({
                              ...editData,
                              figure_id: e?.figure_id,
                            });
                          }
                        }}
                        name={"figure_name"}
                        id_key_name={"figure_id"}
                      />
                    ),
                  },
                  {
                    label: "Month :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMMonthsData?.length && getOMMonthsData}
                        value={
                          editData?.target_month
                            ? getOMMonthsData?.find(
                                (x) => editData?.target_month === x?.month_id
                              ) || ""
                            : getOMMonthsData?.find(
                                (x) =>
                                  selectedSalesTarget?.target_month ===
                                  x?.month_id
                              )
                        }
                        disabled={
                          getOMMonthsStatus === asyncStatus.IDLE ||
                          getOMMonthsStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.month_id) {
                            setEditData({
                              ...editData,
                              target_month: e?.month_id,
                            });
                          }
                        }}
                        name={"month_name"}
                        id_key_name={"month_id"}
                      />
                    ),
                  },
                  {
                    label: "Year :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={getOMYearsData?.length && getOMYearsData}
                        value={
                          editData?.target_year
                            ? getOMYearsData?.find(
                                (x) => editData?.target_year === x?.year_id
                              ) || ""
                            : getOMYearsData?.find(
                                (x) =>
                                  selectedSalesTarget?.target_year ===
                                  x?.year_id
                              )
                        }
                        disabled={
                          getOMYearsStatus === asyncStatus.IDLE ||
                          getOMYearsStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.year_id) {
                            setEditData({
                              ...editData,
                              target_year: e?.year_id,
                            });
                          }
                        }}
                        name={"year_name"}
                        id_key_name={"year_id"}
                      />
                    ),
                  },
                  {
                    label: "Gender :",
                    comp: (
                      <VirtualizedSelect
                        required={true}
                        options={
                          getProductGenderData?.length && getProductGenderData
                        }
                        value={
                          editData?.gender_id
                            ? getProductGenderData?.find(
                                (x) => editData?.gender_id === x?.gender_id
                              ) || ""
                            : getProductGenderData?.find(
                                (x) =>
                                  selectedSalesTarget?.gender_id ===
                                  x?.gender_id
                              )
                        }
                        disabled={
                          getProductGenderStatus === asyncStatus.IDLE ||
                          getProductGenderStatus === asyncStatus.LOADING ||
                          !(addSalesTargetMode || editMode)
                        }
                        onChange={(e) => {
                          if (e?.gender_id) {
                            setEditData({
                              ...editData,
                              gender_id: e?.gender_id,
                            });
                          }
                        }}
                        name={"gender_name"}
                        id_key_name={"gender_id"}
                      />
                    ),
                  },
                  {
                    label: "Target Date",
                    comp: (
                      <InputComp
                        // type={"number"}
                        required={true}
                        // validator={(value) => {
                        //   if (!/[0-9]/.test(value.target.value))
                        //     return "Characters not allowed";
                        //   return "";
                        // }}
                        type={"date"}
                        style={{ padding: 3 }}
                        placeholder=""
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            target_date: moment(
                              new Date(e.target.value)
                            ).format("MM-DD-YY"),
                          })
                        }
                        disabled={
                          !(addSalesTargetMode || editMode)
                        }
                        value={moment(
                          new Date(
                            editData?.target_date
                              ? editData?.target_date
                              : selectedSalesTarget?.target_date
                          )
                        ).format("YYYY-MM-DD")}
                        // disabled={true}
                        name={"from_date"}
                      />
                    ),
                  },
                  // {
                  //   label: "Sales Target:",
                  //   comp: (
                  //     <InputComp
                  //       style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                  //       placeholder=""
                  //       onChange={(e) =>
                  //         setEditData({
                  //           ...editData,
                  //           sales_target: e.target.value,
                  //         })
                  //       }
                  //     />
                  //   ),
                  // },
                  {
                    label: "Target Amount:",
                    comp: (
                      <InputComp
                        style={{ borderRadius: 5, fontSize: 12, padding: 4 }}
                        placeholder=""
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            target_amount: e.target.value,
                          })
                        }
                        disabled={
                          !(addSalesTargetMode || editMode)
                        }
                        defaultValue={selectedSalesTarget?.target_amount}
                      />
                    ),
                  },
                ]}
                secondaryComp={
                  <Btn
                    style={{ fontSize: 10, fontWeight: "bold" }}
                    disabled={editMode}
                  >
                    Assign Companies
                  </Btn>
                }
              />
            ) : (
              <Stack>
                <Typography>Select any sales target</Typography>
                <Typography color={"red"} fontSize={12}>
                  {getOMSalesTargetError}
                </Typography>
              </Stack>
            )}
            <Stack my={2}>
              <TabComp
                tabs={[
                  //   {
                  //     name: "Companies",
                  //     content: (
                  //       <Stack height={400} width={"100%"}>
                  //         <TableComp
                  //           columns={[
                  //             {
                  //               field: "companyId",
                  //               headerName: "Company ID",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "companyName",
                  //               headerName: "Company Name",
                  //               flex: true,
                  //             },
                  //             {
                  //               field: "action",
                  //               headerName: "Action",
                  //               flex: true,
                  //             },
                  //           ]}
                  //           //   onRowClick={(e) => {
                  //           //     setSelectedSalesTarget(e?.row);
                  //           //   }}
                  //           rows={[]}
                  //           //   loading={
                  //           //     getProductStyleStatus === asyncStatus.IDLE ||
                  //           //     getProductStyleStatus === asyncStatus.LOADING
                  //           //   }
                  //         />
                  //       </Stack>
                  //     ),
                  //   },
                  {
                    name: "Log Info",
                    content: (
                      <Stack height={400} width={"100%"}>
                        <TableComp
                          columns={[
                            {
                              field: "logId",
                              headerName: "Log ID",
                              flex: true,
                            },
                            {
                              field: "recordId",
                              headerName: "Record_id",
                              flex: true,
                            },
                            {
                              field: "columnName",
                              headerName: "Column Name",
                              flex: true,
                            },
                            {
                              field: "columnValue",
                              headerName: "Column Value",
                              flex: true,
                            },
                            {
                              field: "actionName",
                              headerName: "Action Name",
                              flex: true,
                            },
                            {
                              field: "actionDate",
                              headerName: "Action Date",
                              flex: true,
                            },
                            {
                              field: "userId",
                              headerName: " User ID",
                              flex: true,
                            },
                          ]}
                          //   onRowClick={(e) => {
                          //     setSelectedSalesTarget(e?.row);
                          //   }}
                          rows={[]}
                          //   loading={
                          //     getProductStyleStatus === asyncStatus.IDLE ||
                          //     getProductStyleStatus === asyncStatus.LOADING
                          //   }
                        />
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      sty
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div> */}
      </ScreenCard>
    </Stack>
  );
};

export default SalesTarget;
