import React, { useState } from "react";
import InputComp from "../../../../components/common/InputComp/Input";
import Btn from "../../../../components/common/ButtonComp/Btn";
import FiltersAccordionFom from "../../../../components/FiltersAccordionFom/FiltersAccordionFom";
import ScreenCard from "../../../../components/common/ScreenCard/ScreenCard";
import { IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { primaryColor } from "../../../../utils/themeColors";
import TableComp from "../../../../components/common/TableComp/TableComp";
import VirtualizedSelect from "../../../../components/common/VirtualizedSelectComp/VirtualizedSelect";

const RelocateItems = () => {
    const [filterFields, setFilterFields] = useState({});

    const handleChange = (fieldName, e) => {
      setFilterFields({
        ...filterFields,
        [fieldName]: e.target.value,
      });
    };
  return (
    <Stack padding={2}>
    <ScreenCard
      title="Relocate Items"
      customHeader={
        <Stack
          sx={{
            padding: 0.5,
            px: 4,
            backgroundColor: primaryColor,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
          Relocate Items
          </Typography>
          <Stack flexDirection={"row"} gap={1}></Stack>
        </Stack>
      }
    >
      {" "}
      <Stack
        padding={2}
        // id="vendorSearchForm"
        // component={"form"}
        // onSubmit={(event) => {
        //   event.preventDefault();
        //   console.log(searchData);
        // }}
      >
        <FiltersAccordionFom
        flexDirection={'column'}
        justifyContent={'flex-start'}
          allFields={[
            {
              title: "Item :",
              comp: (
                <InputComp
                  style={{ padding: 3 }}
                  onChange={(e) => handleChange("item", e)}
                  name={"item"}
                />
              ),
            },
            {
              title: "Location :",
              comp: (
               <>
               hek
               </> 
              ),
            },
          ]}
          headerRightComp={
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                // history("/customerInformation");
              }}
            >
              <AddIcon />
            </IconButton>
          }
          bottomChild={
            <Stack flexDirection={"row"} justifyContent={"flex-end"}>
              <Btn
                // type="submit"
                // onClick={() => {
                //   if (!(!searchData?.from_date || !searchData?.to_date)) {
                //     console.log(searchData);
                //     dispatch(searchOMVendorAsync(searchData));
                //   } else {
                //     error_toast_message(
                //       "Please select from data and to date first! "
                //     );
                //   }
                // }}
                style={{ width: "auto", textTransform: "none" }}
                // loading={searchOMVendorStatus === asyncStatus.LOADING}
              >
                Search
              </Btn>
            </Stack>
          }
        />

        <Stack height={500} mt={4} width={"100%"}>
          <TableComp
            onRowClick={(e) => {
              //   history(`/customerInformation/${e?.row?.vendor_id}`);
              // setCurrentVendorId(e?.row?.vendor_id);
              //   setSearchMode(false);
            }}
            exportToExcel={true}
            // rows={formattedData}
            rows={[]}
            columns={[
              {
                field: "orderDate",
                headerName: "Order Date",
                flex: true,
              },
              {
                field: "startDate",
                headerName: "Start Date",
                flex: true,
              },
              {
                field: "cancelDate",
                headerName: "Cancel Date",
                flex: true,
              },
              {
                field: "orderNo",
                headerName: "Order No",
                flex: true,
              },
              {
                field: "customer",
                headerName: "Customer",
                flex: true,
              },
              { field: "quantityOrder", headerName: "Quantity Order", flex: true },
              {
                field: "quantityRemaining",
                headerName: "Quantity Remaining	",
                flex: true,
              },
              {
                field: "commit",
                headerName: "Commit",
                flex: true,
              },
              {
                field: "quantityCommited",
                headerName: "Quantity Commited",
                flex: true,
              },
            ]}
          />
        </Stack>
      </Stack>
    </ScreenCard>
  </Stack>
  )
}

export default RelocateItems
