import { Grid, Stack, Typography, keyframes } from "@mui/material";
import React from "react";
import InputComp from "../common/InputComp/Input";
import Btn from "../common/ButtonComp/Btn";

const InputWithBtnLayoutGridComp = ({
  inputArray = [
    {
      label: "abc",
      comp: <InputComp />,
    },
    {
      label: "sads",
      comp: <InputComp />,
    },
  ],
  secondaryComp = <Btn />,
}) => {
  return (
    <Stack>
      {/* <Grid container>
        {inputArray?.length &&
          inputArray?.map((e, i) => (
            <Grid
              item
              container
              xl={4}
              lg={4}
              md={4}
              sm={4}
              xs={4}
              alignItems={"center"}
              key={i}
            >
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{ fontWeight: "bold", color: "gray", fontSize: 14 }}
                >
                  {e?.label}
                </Typography>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                {e?.comp}
              </Grid>
            </Grid>
          ))}

   
      </Grid> */}
      <Grid container padding={4} spacing={2}>
          {inputArray?.length &&
            inputArray?.map((e) => {
              const { label, comp } = e;

              return (
                <>
                  <Grid
                    item
                    container
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{ alignItems: "center",justifyContent:"space-between" }} 
                  >
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}  >
                      <Typography   sx={{  color: "gray", fontSize: 14 }}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      {comp}
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <Stack flexDirection={"row"} justifyContent={"flex-end"}>
        {secondaryComp}
        </Stack>
    </Stack>
  );
};

export default InputWithBtnLayoutGridComp;
